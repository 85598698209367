import { FC } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Box, Paper } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useAzureApplicationOwnersAndDeputies } from 'hooks'
import { Skeleton } from '@material-ui/lab'
import { ApplicationOwnerDeputies } from './ApplicationsOwnerDeputies'

export type ApplicationAdditionalInformationProps = {
    className?: string
    buttonPosition?: 'right' | 'bottom'
    data: any
    applicationId: string
    isAzureApplication: boolean
}
const smallScreenWidth = 960

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            position: 'relative',
            padding: theme.spacing(2),
            display: 'flex',
            miHeight: '156px',
            justifyContent: 'space-between',

            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                flexDirection: 'column',
            },
        },
        paddingdiv: {
            padding: '0 20px',
            width: '100%',
        },
        heading: {},
        result: {
            display: 'flex',
            flexWrap: 'wrap',
        },
    }),
)

export const ApplicationAdditionalInformation: FC<ApplicationAdditionalInformationProps> = (
    props,
) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const {
        data: ownersAndDeputies,
        isLoading,
    } = useAzureApplicationOwnersAndDeputies(props.applicationId)

    return (
        <Paper className={classes.root}>
            <Box className={classes.paddingdiv}>
                {isLoading ? (
                    <Box paddingRight={4}>
                        <Skeleton height={40} />
                    </Box>
                ) : (
                    <>
                        <ApplicationOwnerDeputies
                            label={
                                props.isAzureApplication ? 'Deputies' : 'Owners'
                            }
                            data={
                                props.isAzureApplication
                                    ? ownersAndDeputies.deputies
                                    : ownersAndDeputies.owners
                            }
                        />
                        <ApplicationOwnerDeputies
                            label={'Approvers'}
                            data={ownersAndDeputies.approvers}
                        />
                    </>
                )}
            </Box>
        </Paper>
    )
}
