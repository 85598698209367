import { Button } from 'components'
import { Typography, Box } from '@material-ui/core'


const styles = {
    normal: {
        backgroundColor: '#307fc1',
        color: '#fff',
        border: 'solid 1px #307fc1',
        borderRadius: '5px',
        padding: '5px 8px 4px',

    },
    disabled: {
        backgroundColor: '#c5cbda !important',
        border: 'solid 1px #c5cbda',
        color: '#ffffff !important',
        opacity: '0.8',
    },

    label: {
        fontSize: '11px',
        lineHeight: 'normal',
    },
}

const ComputersTerminateButton = ({ data }) => {
    const item = data?.resource
    return (
        <>
            {item?.canTerminateSession ? (
                <Button style={styles.normal} disabledStyles={styles.disabled}>
                    <Typography style={styles.label}>Terminate</Typography>
                </Button>
            ) : (
                <></>
            )}
        </>

    )
}

export default ComputersTerminateButton
