import { Grid } from '@material-ui/core'
import React from 'react'
import { Icon } from 'packages/eid-icons'
import './workFlowItem.css'
type props = {
    title: string
    description: string
    onClick: () => void
    isEnabled: any
}
const WorkFlowsItems: React.FC<props> = ({
    title,
    description,
    onClick,
    isEnabled,
}) => {
    return (
        <Grid
            onClick={onClick}
            item
            md={4}
            xs={12}
            spacing={2}
            className={`workflowinnergrid ${
                isEnabled ? 'show-workflow' : 'disable-workflow'
            }`}
        >
            <div className="gridtitle">
                <h2>{title}</h2>
            </div>
            <div className="workflowdescription inActive">
                <Icon name="WorkFlowChart" /> <p>{description}</p>
            </div>
            <div className="workflowdescription active">
                <Icon name="WorkFlowChartSelected" /> <p>{description}</p>
            </div>
        </Grid>
    )
}

export default WorkFlowsItems
