import React, { useState, useEffect } from 'react'
import { ToggleSwitch, Avatar, WithCloseButton } from 'packages/eid-ui'
import { Box, Collapse, styled, Typography } from '@material-ui/core'
import { Card, ExpansionPanel, Tooltip } from 'packages/eid-ui'
import { useIsSmallScreen } from 'packages/core'
import { useTranslation } from 'react-i18next'
import { useResourceTypeContext } from 'resourceTypeContext'


const ShowOnlyAzureApplications = (props) => {
    const { t } = useTranslation()
    const [{ referencePerson }, dispatch] = useResourceTypeContext()

    const [showOnlyAzureApplications, setShowOnlyAzureApplications] = useState(false)

    const changeSelection = () => {
        dispatch({
            type: 'SET_PROP',
            payload: {
                key:"showOnlyAzureApplications",
                value:showOnlyAzureApplications
            },
        })
    }

    useEffect(() => {
       changeSelection()
    }, [showOnlyAzureApplications])

    const content = () => {
        return (
            <>
                <Box padding="0px 20px 0px 20px">
                    <ToggleSwitch
                        onChange={() => {
                            setShowOnlyAzureApplications((prev) => !prev)
                        }}
                        value={showOnlyAzureApplications}
                        label={"On"}
                    />
                </Box>
            </>
        )
    } 
    

    const isSmallScreen = useIsSmallScreen()

    return isSmallScreen ? (
        <ExpansionPanel
            expanded={false}
            title={t('Common_ShowAzureApplication')}
        >
            <Box padding="20px 0px">{content()}</Box>
        </ExpansionPanel>
    ) : (
        <Card
            cardTitle={t('Common_ShowAzureApplication')}
            color="#fff"
            collapsible
            expanded={false}
        >
            {content()}
        </Card>
    )
}

export default ShowOnlyAzureApplications
