import React from 'react'
import PropTypes from 'prop-types'
import { styled, Box } from '@material-ui/core'
import { isNilOrEmpty } from 'packages/core'
import { TextFormatter } from 'components'

const tooltipProps = {
    color: '#5D6870',
    fontColor: '#ffffff',
    enterDelay: 500,
    enterNextDelay: 500,
    interactive: true,
    tooltipStyles: {
        boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
        padding: '8px',
        maxWidth: '500px',
    },
}
const AttributeLabel = styled(Box)({
    fontSize: '12px',
    lineHeight: 1.33,
    color: '#9b9b9b',
    textTransform: 'uppercase',
    display: 'block',
    wordBreak: 'break-word',
    wordWrap: 'break-word',
})

const AttributeValue = styled(Box)({
    fontSize: '14px',
    lineHeight: 1.33,
    color: '#000000',
    display: 'block',
    wordBreak: 'break-word',
    wordWrap: 'break-word',
})

const Attribute = ({
    label,
    value,
    orientation,
    labelProps,
    valueProps,
    className,
    ...rest
}) => {
    return (
        <Box
            style={{
                display: 'flex',
                flexDirection: 'column',
            }}
            paddingTop="8px"
            className={className}
            {...rest}
        >
            <AttributeLabel {...labelProps}>{label}</AttributeLabel>
            <AttributeValue marginTop={'5px'} {...valueProps}>
                <TextFormatter
                    value={isNilOrEmpty(value) ? '-' : value}
                    tooltipProps={tooltipProps}
                    style={{ fontSize: '14px' }}
                    maxCharacters={50}
                />
            </AttributeValue>
        </Box>
    )
}

Attribute.propTypes = {
    label: PropTypes.any,
    value: PropTypes.any,
    orientation: PropTypes.oneOf(['vertical', 'horizontal']),
}

Attribute.defaultProps = {
    orientation: 'horizontal',
}

export default Attribute
