import { Box, makeStyles } from '@material-ui/core'
import { Pagination } from 'packages/eid-ui'
import { FC } from 'react'

const useStyles = makeStyles({
  sessionpagination: {
    maxWidth: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '40px',
    marginBottom: '30px',
    borderRadius: '5px',
    '& ul': {
      height: '40px',
      maxWidth: '420px',
      boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1)',
      border: 'solid 1px #ebebed',
      overflow: 'hidden',
      borderRadius: '5px',
      '& li': {
        height: '40px',
        '& button': {
          height: '40px',
          minWidth: 'auto',
          width: '40px',
          borderRadius: '0',
          margin: '0  !important',
          borderRight: 'solid 1px #ebebed !important',
          fontSize: '14px !important',

        }
      }
    }
  },
})

export interface ComputersPaginationProps {
  page: number
  pageCount: number
  handlePageChange: (e:any, value:any) => void
}

export const ComputersPagination: FC<ComputersPaginationProps> = (props) => {
  const { page, handlePageChange, pageCount } = props

  const classes = useStyles()
  return (
    <Box className={classes.sessionpagination}>
      <Pagination
        count={pageCount}
        size="small"
        page={page}
        onChange={handlePageChange}
        variant={'rounded'}
      />
    </Box>
  )
}

export default ComputersPagination
