import { queryCache, useMutation, useQuery } from 'react-query'
import { isNilOrEmpty, useAuthState, useAxios } from 'packages/core'
import useConfiguration from 'useConfiguration'

export const DEFAULT_FILTERS_KEY = 'ONBOARDING_FILTERS'

export const useGuidedShopFilters = () => {
    const [{ currentUserId }] = useAuthState()
    const storageKey = `guidedShop.${currentUserId}:${window.location.origin}`

    const getGuidedShopFilters = () =>
        JSON.parse(sessionStorage.getItem(storageKey))

    const clearGuidedShopFilters = () => {
        sessionStorage.removeItem(storageKey)
    }

    return {
        getGuidedShopFilters,
        clearGuidedShopFilters,
    }
}

export const useDefaultFilters = (resourceType) => {
    const [{ currentUserId }] = useAuthState()
    const { getResourceType } = useConfiguration()

    const currentResourceType = getResourceType(resourceType)

    const callApi = useAxios()
    const { isLoading, data } = useQuery(
        DEFAULT_FILTERS_KEY,
        () =>
            callApi({
                method: 'GET',
                url: '/api/filters',
            }).then((data) => data.data),
        {
            staleTime: Infinity,
        },
    )
    const storageKey = `guidedShop.${currentUserId}:${window.location.origin}`

    const item = JSON.parse(sessionStorage.getItem(storageKey))
    if (isNilOrEmpty(item)) {
        if (isLoading) {
            return { isLoading, data }
        } else {
            if (data.showGuidedShop) {
                return { isLoading, data }
            } else {
                if (currentResourceType) {
                    const resourceTypeFilters =
                        data.resourceTypeFilters[currentResourceType.name]

                    if (resourceTypeFilters) {
                        return {
                            data: resourceTypeFilters.filters,
                        }
                    }
                }
            }
        }
    } else {
        const resItem = {
            data: {
                selectedResource: item.resource,
                selectedResourceLocationId: item?.secondLevel
                    ? item?.secondLevel?.id
                    : item?.firstLevel?.id,
            },
            isLoading: false,
        }
        return resItem
    }
    return { isLoading: true, data: {} }
}

export const useUpdateDefaultFilters = () => {
    const callApi = useAxios()
    return useMutation(
        ({ resourceType, ...data }) =>
            callApi({
                method: 'POST',
                url: `/api/filters/resourceTypeFilter/${resourceType}`,
                data,
            }),
        {
            onSuccess: () => {
                queryCache.invalidateQueries(DEFAULT_FILTERS_KEY)
            },
        },
    )
}
