import React from 'react'
import MobileSelectionBox from './MobileSelectionBox'
import DesktopSelectionBox from './DesktopSelectionBox'
import { useIsSmallScreen } from 'packages/core'

const SelectionBox = ({ children, ...rest }) => {
    const isSmallScreen = useIsSmallScreen()
    return isSmallScreen ? (
        <MobileSelectionBox {...rest}>{children(true)}</MobileSelectionBox>
    ) : (
        <DesktopSelectionBox {...rest}>{children(false)}</DesktopSelectionBox>
    )
}

export default SelectionBox
