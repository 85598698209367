import React from 'react'
import { styled } from '@material-ui/core'
import { WithCloseButton } from 'packages/eid-ui'

const Wrapper = styled('div')(({ styles }) => ({
    borderRadius: '5px',
    backgroundColor: '#ffffff',
    width: '100%',
    maxWidth: '425px',
    margin: 'auto',
    border: 'solid 1px #d2d2d9',
    ...styles,
    overflow: 'hidden',
}))

const ItemsDetailsMobileWrapper = ({ children, styles, toggleDrawer }) => {
    return (
        <Wrapper styles={styles}>
            <WithCloseButton
                color="#000000"
                iconPosition={{ top: '20px', right: 0 }}
                onClose={toggleDrawer}
            >
                {children}
            </WithCloseButton>
        </Wrapper>
    )
}

export default ItemsDetailsMobileWrapper
