import { IResourceType } from '../types/IResourceType'
import { AccessLevel } from '../types/AccessLevel'
import { IResourceTypeIcon } from '../types/IResourceTypeIcon'
import { IResourceTypeComponents } from '../types/IResourceTypeComponents'
import { Sample } from './Sample'
import { AlreadyInCartType } from '../types/helperTypes'
import { ICartItem } from '../types/ICartItem'
import { IResource } from '../types/IResource'

export class CredentialType implements IResourceType {
    name = 'Credentials'

    route = '/credentials'

    title = 'Common_Credentials'

    access: AccessLevel = {
        page: 'ITShop-Credentials-Page',
    }

    icon: IResourceTypeIcon = {
        small: 'SharedFoldersSmall',
        large: 'SharedFoldersLarge',
    }

    manageAccessComponents: IResourceTypeComponents = {
        cardComponent: Sample,

        rowComponent: Sample,

        detailComponent: Sample,
    }

    requestAccessComponents: IResourceTypeComponents = {
        cardComponent: Sample,

        rowComponent: Sample,

        detailComponent: Sample,
    }

    filters = []

    attributes = []

    cartComponent = null

    alreadyInCart: AlreadyInCartType = (
        cartItems: ICartItem[],
        resource: IResource,
        accessLevel: any,
    ) =>
        cartItems.find(
            (c) =>
                c.requestableResourceId === resource.id &&
                c.resourceTypeRoleId ===
                    accessLevel?.resourceAssignment?.resourceAssignmentId,
        ) !== undefined
}
