import { Fragment, useEffect, useState } from 'react'
import { Box, styled, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useCheckSharedFolderAccess, useTargetPerson } from 'hooks'
import { Icon } from 'packages/eid-icons'
import {
    DifferentiationValueSelector as AccessLevelValueSelector,
    ItemDetails,
} from 'components'
import { useIsSmallScreen } from 'packages/core'
import cartHelpers from 'containers/Cart/cartHelpers'
import { OverviewSection } from './OverviewSection'
import { MoreInformationSection } from './MoreInformationSection'
import { AddToCart } from 'components/AddToCart'
import { ResourceTypesNamespace, useRegistry } from 'core'
import { Skeleton } from '@material-ui/lab'

const PromptContainer = styled('div')({
    padding: '48px 31px 20px 31px',
    minHeight: '232px',
    display: 'flex',
})

const PaddedDiv = styled(Box)({
    padding: '0 31px',
})

const SharedFolderDetails = ({ sharedFolder, toggleDrawer }) => {
    const { t } = useTranslation()

    const registry = useRegistry()

    const sharedFolderType = registry.get(
        ResourceTypesNamespace,
        'SharedFolders',
    )

    const isSmallScreen = useIsSmallScreen()

    const [targetPerson] = useTargetPerson()

    const [activeTab, setActiveTab] = useState('overview')

    const {
        data: accessLevels,
        isLoading: isCheckingAccess,
    } = useCheckSharedFolderAccess(sharedFolder.id, targetPerson.id)
    const [accessLevel, setAccessLevelValue] = useState(null)

    useEffect(() => {
        if (accessLevel && accessLevels.length === 1) {
            setAccessLevelValue(accessLevels[0])
        }
    }, [accessLevels, setAccessLevelValue])

    const preAdd = (baseObj) => {
        const itemToAdd = cartHelpers.sharedFolderToCartItem({
            targetPerson,
            assignmentType: 'Add',
            sharedFolder,
            assignmentStatus: accessLevel,
            ...baseObj,
        })
        if (!itemToAdd) return undefined

        return itemToAdd
    }

    const getPreValidationMessage = () => {
        if (!Boolean(accessLevel)) {
            return t('Common_SelectAccessLevel')
        } else if (accessLevel && accessLevel.isAssigned) {
            return t('ResourceAlreadyAssigned')
        }
        return ''
    }

    return (
        <Fragment>
            <ItemDetails.Tabs
                variant="standard"
                value={activeTab}
                onChange={(_, value) => setActiveTab(value)}
            >
                <ItemDetails.Tabs.Tab
                    value="overview"
                    label={t('Common_Overview')}
                />
                <ItemDetails.Tabs.Tab
                    value="additionalInformation"
                    label={t('Common_MoreInformation')}
                />
            </ItemDetails.Tabs>
            {activeTab === 'overview' && (
                <OverviewSection sharedFolder={sharedFolder} />
            )}
            {activeTab === 'additionalInformation' && (
                <MoreInformationSection sharedFolder={sharedFolder} />
            )}

            {!isSmallScreen && (
                <PromptContainer>
                    <Box
                        width="172px"
                        display="flex"
                        flexDirection="column"
                        zIndex={1}
                    >
                        <Typography
                            style={{
                                fontSize: '14px',
                                lineHeight: 1.43,
                                fontWeight: 600,
                                textTransform: 'uppercase',
                                wordBreak: 'break-word',
                            }}
                        >
                            {t('Common_SelectAccessLevel')}
                        </Typography>
                        <Box
                            marginTop="24px"
                            width="100%"
                            display="flex"
                            justifyContent="center"
                        >
                            <Icon
                                name="AccessLevel"
                                height="150px"
                                width="150px"
                                color="#D2D2D9"
                            />
                        </Box>
                    </Box>
                    <Box width="334px" paddingLeft="16px">
                        <AccessLevelValueSelector
                            loading={!accessLevels}
                            onChange={(val) => setAccessLevelValue(val)}
                            data={
                                accessLevels &&
                                accessLevels.filter((o) => !o.isAssigned)
                            }
                            value={
                                accessLevel?.resourceAssignment
                                    .resourceAssignmentId
                            }
                            getOptionLabel={(o) =>
                                o.resourceAssignment.friendlyName
                            }
                            checkSelectedOption={(o, selectedValueId) =>
                                o.resourceAssignment.resourceAssignmentId ===
                                selectedValueId
                            }
                            readOnlyOptions={
                                accessLevels &&
                                accessLevels.filter((o) => o.isAssigned)
                            }
                        />
                    </Box>
                </PromptContainer>
            )}

            {isSmallScreen && (
                <PaddedDiv>
                    <Box
                        paddingY="24px"
                        display="flex"
                        flexDirection="column"
                        position="relative"
                    >
                        <Box position="absolute" top="16px" right="0">
                            <Icon
                                name="AccessLevel"
                                height="100px"
                                width="100px"
                                color="#D2D2D9"
                            />
                        </Box>
                        <Box width="172px" paddingY="4px" zIndex={1}>
                            <Typography
                                style={{
                                    fontSize: '14px',
                                    lineHeight: 1.43,
                                    fontWeight: 600,
                                    textTransform: 'uppercase',
                                    wordBreak: 'break-word',
                                }}
                            >
                                {t('Common_SelectAccessLevel')}
                            </Typography>
                        </Box>
                        <Box paddingY="16px">
                            <AccessLevelValueSelector
                                loading={!accessLevels}
                                onChange={(val) => setAccessLevelValue(val)}
                                data={
                                    accessLevels &&
                                    accessLevels.filter((o) => !o.isAssigned)
                                }
                                value={
                                    accessLevel?.resourceAssignment
                                        .resourceAssignmentId
                                }
                                getOptionLabel={(o) =>
                                    o.resourceAssignment.friendlyName
                                }
                                checkSelectedOption={(o, selectedValueId) =>
                                    o.resourceAssignment
                                        .resourceAssignmentId ===
                                    selectedValueId
                                }
                                readOnlyOptions={
                                    accessLevels &&
                                    accessLevels.filter((o) => o.isAssigned)
                                }
                            />
                        </Box>
                    </Box>
                </PaddedDiv>
            )}

            {isCheckingAccess ? (
                <Box padding={'24px'}>
                    <Skeleton height={40} />
                </Box>
            ) : (
                <AddToCart
                    resourceType={sharedFolderType}
                    resource={sharedFolder}
                    secondary={accessLevel}
                    preValidate={getPreValidationMessage}
                    preAdd={preAdd}
                    postAdd={toggleDrawer}
                />
            )}
        </Fragment>
    )
}

export default SharedFolderDetails
