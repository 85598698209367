import { ItemDetails } from 'components'
import { styled } from '@material-ui/core'
import { useIsSmallScreen } from 'packages/core'
import { useTranslation } from 'react-i18next'
import { CommonAttributes } from '../../CommonAttributes'

const PaddedDiv = styled('div')({
    padding: '0 31px',
})

export const OverviewSection = ({ azureRbacRole }: any) => {
    const { t } = useTranslation()

    const attributeOrientation = useIsSmallScreen() ? 'vertical' : 'horizontal'

    return (
        <ItemDetails.AttributesContainer bottomPadding="0">
            <PaddedDiv>
                <ItemDetails.Attribute
                    label={t('Common_SystemType')}
                    value={azureRbacRole.resourceSystemTypeFriendlyName}
                    orientation={attributeOrientation}
                />
                <ItemDetails.Attribute
                    label={t('Common_RoleType')}
                    value={azureRbacRole.roleTypeFriendlyName}
                    orientation={attributeOrientation}
                />

                {azureRbacRole.resourceSystemFriendlyName && (
                    <ItemDetails.Attribute
                        label={t('Common_Tenant')}
                        value={azureRbacRole.resourceSystemFriendlyName}
                        orientation={attributeOrientation}
                    />
                )}

                <ItemDetails.Attribute
                    label={t('Common_Description')}
                    value={azureRbacRole.description}
                    orientation={attributeOrientation}
                />

                <CommonAttributes resource={azureRbacRole} />
            </PaddedDiv>
        </ItemDetails.AttributesContainer>
    )
}
