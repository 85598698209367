import { useState, useEffect } from 'react'
import { Box, Popper } from '@material-ui/core'
import { ArrowIcon } from 'packages/eid-icons'
import { Autocomplete } from 'packages/eid-ui'
import { useSwrApiGet } from 'packages/core'
import { useTranslation } from 'react-i18next'
import { useResourceTypeContext } from 'resourceTypeContext'
import { useIsSmallScreen } from 'packages/core'
import { ExpansionPanel } from 'packages/eid-ui'
import { pipe, prop, map, uniqBy } from 'ramda'

const styles = {
    '& > div': {
        boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.1) !important',
        borderColor: 'transparent',
        padding: '1.5px !important',
        '& > div': {
            right: '0px !important',
            pointerEvents: 'none',
        },
        '& > input': {
            height: '35px',
            cursor: 'pointer',
            width: '100%',
            // color: '#7d7c7c'
        },
    },
}

const AccountStoreFilter = () => {
    const { t } = useTranslation()
    const isSmallScreen = useIsSmallScreen()

    const [callEndpoint, setCallEndpoint] = useState(false)
    const [activeType, setActiveType] = useState('')
    const [options, setOptions] = useState([])

    const { data } = useSwrApiGet(
        () => (callEndpoint ? '/api/ApplicationRoles/targetSystemInfo' : false),
        {
            dedupingInterval: 1800000,
        },
    )
    useEffect(() => {
        if (data) {
            setOptions(data.data)
        }
    }, [data])

    const [{ manageAccess }, dispatch] = useResourceTypeContext()

    const selectedAccountStoreUsageType =
        manageAccess['accountStoreUsageType'] ?? null

    const fetchAccountStoreUsageType = pipe(
        uniqBy(prop('accountStoreUsageTypeId')),
        map((x) => ({
            id: x.accountStoreUsageTypeId,
            friendlyName: x.accountStoreUsageTypeFriendlyName,
        })),
    )

    const fetchAccountStores = () => {
        let filtered = options

        if (selectedAccountStoreUsageType) {
            filtered = options.filter(
                (x) =>
                    x.accountStoreUsageTypeId ===
                    selectedAccountStoreUsageType.id,
            )
        }

        return pipe(
            uniqBy((x) => x.accountStoreId),
            map((x) => ({
                id: x.accountStoreId,
                friendlyName: x.accountStoreFriendlyName,
            })),
        )(filtered)
    }

    const accountStoreUsageTypeActive = activeType === 'accountStoreUsageType'
    const accountStoreActive = activeType === 'accountStore'

    const accountStoreUsageTypeControl = (
        <Autocomplete
            placeholder={t('ApplicationRoles_SelectAccountStoreType')}
            options={fetchAccountStoreUsageType(options)}
            loading={callEndpoint && !data && accountStoreUsageTypeActive}
            loadingText=""
            noOptionsText=""
            multiple={false}
            onInputChange={() => {}}
            onChange={(_, selectedItem) =>
                dispatch({
                    type: 'SET_MANAGE_ACCESS_FILTERS',
                    payload: {
                        accountStoreUsageType: selectedItem,
                        accountStore: null,
                    },
                })
            }
            value={selectedAccountStoreUsageType}
            getOptionLabel={(option) => option.friendlyName}
            onOpen={() => {
                setCallEndpoint(true)
                setActiveType('accountStoreUsageType')
            }}
            onClose={() => {
                setCallEndpoint(false)
                setActiveType('')
            }}
            endAdornmentComponent={() =>
                !isSmallScreen && (
                    <ArrowIcon
                        width="12px"
                        height="12px"
                        direction={accountStoreUsageTypeActive ? 'up' : 'down'}
                        color="#969798"
                    ></ArrowIcon>
                )
            }
            rootStyleProps={!isSmallScreen && styles}
            filterOptions={undefined}
        />
    )

    const accountStoreControl = (
        <Autocomplete
            placeholder={t('ApplicationRoles_SelectAccountStore')}
            options={fetchAccountStores()}
            loading={callEndpoint && !data && accountStoreActive}
            loadingText=""
            noOptionsText=""
            multiple={false}
            onChange={(_, selectedItem) =>
                dispatch({
                    type: 'SET_MANAGE_ACCESS_FILTERS',
                    payload: { accountStore: selectedItem },
                })
            }
            value={manageAccess.accountStore}
            getOptionLabel={(option) => option.friendlyName}
            filterOptions={undefined}
            onOpen={() => {
                setCallEndpoint(true)
                setActiveType('accountStore')
            }}
            onClose={() => {
                setCallEndpoint(false)
                setActiveType('')
            }}
            endAdornmentComponent={() =>
                !isSmallScreen && (
                    <ArrowIcon
                        width="12px"
                        height="12px"
                        direction={accountStoreActive ? 'up' : 'down'}
                        color="#969798"
                    ></ArrowIcon>
                )
            }
            rootStyleProps={
                isSmallScreen
                    ? { '& > div': { paddingRight: '0px !important' } }
                    : styles
            }
            PopperComponent={(props) => (
                <Popper
                    {...props}
                    style={{
                        minWidth: 200,
                        width: 320,
                    }}
                    placement="bottom-start"
                />
            )}
        />
    )

    return isSmallScreen ? (
        <ExpansionPanel
            expanded={Boolean(manageAccess.accountStore)}
            title={t('ApplicationRoles_SelectAccountStore')}
        >
            <Box padding="20px">{accountStoreUsageTypeControl}</Box>

            <Box padding="20px">{accountStoreControl}</Box>
        </ExpansionPanel>
    ) : (
        <>
            <Box marginBottom="30px">{accountStoreUsageTypeControl}</Box>
            <Box>{accountStoreControl}</Box>
        </>
    )
}

export default AccountStoreFilter
