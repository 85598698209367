import { requireAuth, useAuthState } from 'packages/core'
import { Loader } from 'packages/eid-ui'
import useConfiguration from 'useConfiguration'
import GuidedShopOrApp from './GuidedShopOrApp'
import GuidedShop from 'containers/GuidedShop'
import ManageAccess from 'containers/ManageAccess'
import ListingPage from 'containers/ListingPage'
import { Redirect, Route, Switch } from 'react-router-dom'
import { ResourceTypeContextProvider } from 'resourceTypeContext'
import withRequiredData from './withRequiredData'
import { ApplicationsListing } from 'containers/ApplicationsListing'
import { ApplicationBasedListing } from 'containers/ApplicationsListing/ApplicationBasedListing'
import RequestAccess from 'containers/CredentialsListing/RequestAccess'
import CredentialsManageAccessListing from 'containers/CredentialsListing/MangeAccess'
import CredentialsHistory from 'containers/CredentialsListing/History'
import WorkFlows from 'containers/Workflows'
import { Fragment, useEffect } from 'react'
import appConfig from 'config'
import { setIsWorkflowRenderable } from 'packages/core/auth/actions'
import ComputerConnectWorkFlow from 'containers/ComputerWorkflow'

const App = () => {
    const { manageAccess, resourceTypes } = useConfiguration()
    const [{ isWorkflowIRenderable }, authDispatch]: any = useAuthState()

    useEffect(() => {
        if (!isWorkflowIRenderable) {
            setTimeout(() => {
                authDispatch(setIsWorkflowRenderable(true))
            }, 30000)
        }
    }, [])

    return (
        <Fragment>
            {!isWorkflowIRenderable && (
                <div style={{ display: 'none' }}>
                    <iframe
                        title={`EmpowerID`}
                        src={`${appConfig.BASE_EID_URL}/ui`}
                    />
                </div>
            )}

            <Switch>
                <Route exact path="/" component={GuidedShopOrApp} />
                <Route
                    exact
                    path={`${appConfig.APP_SUBPATH}/`}
                    component={GuidedShopOrApp}
                />
                <Route
                    exact
                    path={`${appConfig.APP_SUBPATH}/guidedShop`}
                    component={GuidedShop}
                />

                <Route
                    exact
                    path={`${appConfig.APP_SUBPATH}/:resourceType/workflows`}
                    render={(props) => (
                        <ResourceTypeContextProvider>
                            <WorkFlows {...props} />
                        </ResourceTypeContextProvider>
                    )}
                />
                <Route
                    exact
                    path={`${appConfig.APP_SUBPATH}/computers/manageAccess/workflow/`}
                    render={(props) => (
                        <ResourceTypeContextProvider>
                            <ComputerConnectWorkFlow {...props} />
                        </ResourceTypeContextProvider>
                    )}
                />

                <Route
                    exact
                    path={`${appConfig.APP_SUBPATH}/credentials/manageAccess`}
                    key="credentials_manage_access"
                    render={(props) => (
                        <ResourceTypeContextProvider>
                            <CredentialsManageAccessListing
                                resourceType={'credentials'}
                                {...props}
                            />
                        </ResourceTypeContextProvider>
                    )}
                />
                <Route
                    path={`${appConfig.APP_SUBPATH}/credentials/manageAccess/history`}
                    exact
                    key="credentials_manage_access_history"
                    render={(props) => (
                        <ResourceTypeContextProvider>
                            <CredentialsHistory
                                resourceType={'credentials'}
                                {...props}
                            />
                        </ResourceTypeContextProvider>
                    )}
                />
                {manageAccess &&
                    resourceTypes.map((r) => (
                        <Route
                            key={`${manageAccess.name}_${r.name}`}
                            exact
                            path={`${appConfig.APP_SUBPATH}${r.route}${manageAccess.route}/:tab?`}
                            render={(props) => (
                                <ResourceTypeContextProvider>
                                    <ManageAccess
                                        resourceType={r.name}
                                        {...props}
                                    />
                                </ResourceTypeContextProvider>
                            )}
                        />
                    ))}

                <Route
                    path={`${appConfig.APP_SUBPATH}/applications`}
                    render={(props) => (
                        <ResourceTypeContextProvider>
                            <ApplicationsListing {...props} />
                        </ResourceTypeContextProvider>
                    )}
                />

                <Route
                    exact
                    path={`${appConfig.APP_SUBPATH}/applications/:resourceType`}
                    render={(props) => (
                        <ResourceTypeContextProvider>
                            <ApplicationBasedListing />
                        </ResourceTypeContextProvider>
                    )}
                />

                <Route
                    path={`${appConfig.APP_SUBPATH}/credentials`}
                    exact
                    key="credentials_request_access"
                    render={(props) => (
                        <ResourceTypeContextProvider>
                            <RequestAccess
                                resourceType={'credentials'}
                                {...props}
                            />
                        </ResourceTypeContextProvider>
                    )}
                />

                {resourceTypes.map((r) => (
                    <Route
                        key={r.name}
                        exact
                        path={`${appConfig.APP_SUBPATH}${r.route}`}
                        render={(props) => (
                            <ResourceTypeContextProvider>
                                <ListingPage resourceType={r.name} {...props} />
                            </ResourceTypeContextProvider>
                        )}
                    />
                ))}
                <Redirect from="" to="/notfound" />
            </Switch>
        </Fragment>
    )
}

//@ts-ignore
export default requireAuth(withRequiredData(App), <Loader />)
