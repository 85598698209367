import { FC, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { PreApprovedItemsAccessTile } from './PreApprovedItemsAccessTile'

export type PreApprpovedLoginAccessProps = {
    item?: any
    handleViewRequest: () => void
}

export const PreApprpovedItems: FC<PreApprpovedLoginAccessProps> = (props) => {
    const { item, handleViewRequest } = props
    const { t } = useTranslation()

    return (
        <Fragment>
            <PreApprovedItemsAccessTile
                handleViewRequest={handleViewRequest}
                showArrows={false}
                item={{ ...item, type: 'preapprovedCheckout' }}
                accessType={t('Common_PreApproved')}
            />
            <PreApprovedItemsAccessTile
                showCount={true}
                handleViewRequest={handleViewRequest}
                item={{ ...item, type: 'checkoutRecords' }}
                accessType={t('Common_CheckoutRequests')}
                accessName={t('Common_CheckoutRecords')}
            />
        </Fragment>
    )
}
