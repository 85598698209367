import React, { useEffect, useState } from 'react'
import { Autocomplete } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'
import { useSwrApiGet } from 'packages/core'
import { Icon } from 'packages/eid-icons'
import { pipe, prop, map, uniqBy } from 'ramda'
import { styled, Box, Divider } from '@material-ui/core'
import { SelectionBox } from 'components'
import { useIsSmallScreen } from 'packages/core'

const mobileStyles = {
    hedaerStyles: { height: '13vh !important' },
    contentStyles: { minHeight: '75vh !important' },
}

const OptionsDivider = styled((props) => (
    <Divider orientation="vertical" variant="middle" {...props} />
))({
    height: '58%',
    top: '32%',
    border: 'solid 0.5px #d2d2d9',
    opacity: '0.6',
    position: 'relative',
})

const OptionContainer = styled('div')({
    width: '271px',
    height: '354px',
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
})

const Container = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
})

const TargetSystemSelection = ({ onSelect, onSkipClick, onBackClick }) => {
    const { t } = useTranslation()
    const isSmallScreen = useIsSmallScreen()

    const [callEndpoint, setCallEndpoint] = useState(false)
    const [options, setOptions] = useState([])
    const { data } = useSwrApiGet(
        () => (callEndpoint ? '/api/ApplicationRoles/targetSystemInfo' : false),
        {
            dedupingInterval: 1800000,
        },
    )

    useEffect(() => {
        if (data) {
            setOptions(data.data)
        }
    }, [data])

    const fetchAccountStoreUsageType = pipe(
        uniqBy(prop('accountStoreUsageTypeId')),
        map((x) => ({
            id: x.accountStoreUsageTypeId,
            friendlyName: x.accountStoreUsageTypeFriendlyName,
        })),
    )

    const [accountStoreUsageType, setAccountStoreUsageType] = useState(null)
    const [accountStore, setAccountStore] = useState(null)

    const fetchAccountStoresByAccountStoreUsage = () => {
        if (accountStoreUsageType === null) return []
        const filtered = options.filter(
            (x) => x.accountStoreUsageTypeId === accountStoreUsageType.id,
        )

        return pipe(
            uniqBy((x) => x.accountStoreId),
            map((x) => ({
                id: x.accountStoreId,
                friendlyName: x.accountStoreFriendlyName,
            })),
        )(filtered)
    }

    const accountStoreTypeControl = (
        <Container>
            <Icon name="AccountStoreType" color="#000000" />
            <Box marginTop="30px" width="260px">
                <Autocomplete
                    label={t('ApplicationRoles_SelectAccountStoreType')}
                    placeholder={t('ApplicationRoles_SelectAccountStoreType')}
                    options={fetchAccountStoreUsageType(options)}
                    loading={callEndpoint && !data}
                    loadingText=""
                    noOptionsText=""
                    multiple={false}
                    onInputChange={() => {}}
                    onChange={(_, selectedItem) =>
                        setAccountStoreUsageType(selectedItem)
                    }
                    value={accountStoreUsageType}
                    getOptionLabel={(option) => option.friendlyName}
                    onOpen={() => setCallEndpoint(true)}
                    onClose={() => setCallEndpoint(false)}
                    filterOptions={undefined}
                ></Autocomplete>
            </Box>
        </Container>
    )
    const accountStoreControl = (
        <Container>
            <Icon name="AccountStore" color="#000000" />
            <Box marginTop="30px" width="260px">
                <Autocomplete
                    disabled={!Boolean(accountStoreUsageType)}
                    label={t('ApplicationRoles_SelectAccountStore')}
                    placeholder={t('ApplicationRoles_SelectAccountStore')}
                    options={fetchAccountStoresByAccountStoreUsage()}
                    loading={false}
                    loadingText=""
                    noOptionsText=""
                    multiple={false}
                    onChange={(_, selectedItem) => {
                        setAccountStore(selectedItem)
                        onSelect(accountStoreUsageType, selectedItem)
                    }}
                    value={accountStore}
                    getOptionLabel={(option) => option.friendlyName}
                    filterOptions={undefined}
                />
            </Box>
        </Container>
    )

    const desktop = (
        <Box width="680px" display="flex">
            <OptionContainer style={{ flex: 1 }}>
                {accountStoreTypeControl}
            </OptionContainer>
            <div>
                <OptionsDivider />
            </div>

            <OptionContainer style={{ flex: 1 }}>
                {accountStoreControl}
            </OptionContainer>
        </Box>
    )

    const mobile = (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            paddingTop="16px"
        >
            <OptionContainer style={{ height: '100%', padding: '12px 0px' }}>
                {accountStoreTypeControl}
            </OptionContainer>

            <OptionContainer style={{ height: '100%', padding: '12px 0px' }}>
                {accountStoreControl}
            </OptionContainer>
        </Box>
    )

    return (
        <SelectionBox
            headingText={t('OnBoarding_ChooseTargetSystem')}
            footerText={t('OnBoarding_FooterTextMessage')}
            onSkipClick={onSkipClick}
            onBackClick={onBackClick}
            style={isSmallScreen ? mobileStyles : {}}
        >
            {(isSmallDevice) => (isSmallDevice ? mobile : desktop)}
        </SelectionBox>
    )
}

export default TargetSystemSelection
