import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography, makeStyles, Dialog, RadioGroup, FormControlLabel, Radio } from '@material-ui/core'
import { useIsSmallScreen } from 'packages/core'
import { IconButton, EidButton, TextInput, Loader } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import { ItemDetails } from 'components'
import { TimeDurationControl } from 'components/TimeDurationControl'
import { ReactComponent as GroupIconSvg } from './Resources/group.svg'
import { ReactComponent as OvalIconSvg } from './Resources/oval.svg'
import CredentialsSearch from './AutoCompleteCredentials'
import { GetComputerCredentials } from 'hooks'
import moment from 'moment'
const overflowStyleProps = {
    maxHeight: '220px',
    overflow: 'auto',
}

const mobileScreenWidth = '767px'

const useStyles = makeStyles((theme) => ({
    body: ({ }) => ({
        backgroundColor: '#fbfbfd',
        padding: '24px 40px',
        boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
        display: 'flex',
        '&:focus': {
            outline: 'none',
        },
    }),
    modalHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        height: '80px',
        alignItems: 'center',
        padding: '16px 12px 16px 32px',
        backgroundColor: '#ffffff',
    },
    closeIcon: {
        display: 'flex',
    },
    radioBtn: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row'
    },
    title: {
        fontSize: '18px',
        fontWeight: 'bold',
        color: '#3b454d',
    },
    sectionHeader: {
        overflowWrap: 'break-word',
        wordBreak: 'normal',
        textTransform: 'uppercase',
        color: '#000000 !important',
        overflow: 'hidden',
        lineHeight: '15px',
        fontSize: '14px',
        padding: '15px 31px',
        borderTop: '1px solid rgba(0, 0, 0, .05)',
        borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
        fontWeight: 'bold',
        boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
        backgroundImage:
            'linear-gradient(0deg, rgb(0, 0, 0, 0.02) 12.5%, rgb(255, 255, 255) 12.5%, rgb(255, 255, 255) 50%, rgb(0, 0, 0, 0.02) 50%, rgb(0, 0, 0, 0.02) 62.5%, rgb(255, 255, 255) 62.5%, rgb(255, 255, 255) 100%)',
        backgroundSize: '8.00px 8.00px',
        '& svg': {
            margin: '0px !important',
        },

    },

    invalidDatesMessage: {
        position: 'absolute',
        fontSize: '12px',
        color: theme.palette.error.main,
        marginRight: '12px',
    },
    footer: {},
    formControlLabel: {
        marginLeft: '0px',
        marginRight: '20px',
        fontSize: '12px',
        color: '#767676',
        height: '30px',
        '&:focus-within': {
            color: '#000000',
        },
    },
}))

const useDialogStyles = makeStyles(() => ({
    paper: {
        maxWidth: '1000px',
        width: '840px',
        [`@media (max-width:${mobileScreenWidth})`]: {
            margin: '8px ',
            width: 'calc(100% - 16px) !important',
            maxWidth: 'calc(100% - 16px) !important ',
        },
    },
}))

export interface ConnectAdvancedProps {
    open?: any
    onClose?: any
    onReset?: any
    onCancel?: any
    onSucces?: any
    loading?: boolean
    error?: boolean
    requestPolicy: any
    selectedStartDate: any
    setSelectedStartDate: any
    selectedDuration: any
    setSelectedDuration: any
    hasInvalidDates?: boolean
    invalidDatesMessage?: any,
    setCredentialsType: any,
    credentialsType: any,
    item: any,
    setSelectedCredentials: any,
    selectedCredentials: any
}

export const ConnectAdvanced: FC<ConnectAdvancedProps> = (props) => {
    const {
        open,
        onClose,
        onSucces,
        loading,
        requestPolicy,
        selectedStartDate,
        setSelectedStartDate,
        selectedDuration,
        setSelectedDuration,
        hasInvalidDates,
        invalidDatesMessage,
        credentialsType,
        setCredentialsType,
        selectedCredentials,
        setSelectedCredentials,
        item
    } = props
    const { t } = useTranslation()
    const classes = useStyles()
    const dialogClasses = useDialogStyles()
    const attributeOrientation = useIsSmallScreen() ? 'vertical' : 'horizontal'
    const [credentialsOptions, setCredentialsOptions] = useState<any>([])
    const {
        data,
        isLoading,
    } = GetComputerCredentials()

    useEffect(() => {
        if (data) {
            if (data && data?.length > 0) {
                bindOptions(data)
            }
        }
    }, [data])

    const bindOptions = (personalCredentials: any) => {
        let options: any = []
        personalCredentials.forEach((element: any) => {
            options.push({
                id: element.id,
                friendlyName: element.friendlyName,
                value: element.id
            })
        });
        setCredentialsOptions(options)
    }

    const bindButtonLabel = () => {
        var a = moment(selectedStartDate);
        var b = moment().format();
        return a.diff(b) <= 0 ? true : false
    }

    const footerContent = (
        <Box display="flex" width="100%" padding="24px 32px">
            <Box minWidth="167px">
                <EidButton.DrawerActionButton
                    fontColor="#ffffff"
                    bgColor="#307fc1"
                    width="100%"
                    onClick={() => onSucces(bindButtonLabel() ? "Connect" : "BookNow")}
                    loading={loading}
                    disabled={hasInvalidDates}
                >
                    <Box display="flex" alignItems="center">
                        <Icon name="RightArrowWithBorder" color="#ffffff" />
                    </Box>
                    <Box display="flex" alignItems="center" marginLeft="10px">
                        <Typography style={{ color: '#ffffff' }}>
                            {bindButtonLabel() ? t('Connect') : t('Common_BookNow')}
                        </Typography>
                    </Box>
                </EidButton.DrawerActionButton>
            </Box>
        </Box>
    )


    const title = t('AdvancedMode')

    function StyledRadio(props: any) {
        return (
            <Radio
                disableRipple
                color="default"
                checkedIcon={
                    <Box display="flex" alignItems="center">
                        <GroupIconSvg></GroupIconSvg>
                    </Box>
                }
                icon={<OvalIconSvg></OvalIconSvg>}
                {...props}
            />
        )
    }

    const credentialsTypeOptions = [
        {
            label: "Computer_PersonalCredentials",
            value: "personalCredentials"
        },
        {
            label: "Computer_SharedCredentials",
            value: "sharedCredentials"
        }
    ]

    const handleCredentialsSelect = (e: any) => {
        setCredentialsType(e.target.value)
        if (e.target.value === "sharedCredentials") {
            setSelectedCredentials(null)
        }
    }
    return (
        <Dialog
            scroll={'body'}
            open={open}
            onClose={onClose}
            aria-labelledby={title}
            aria-describedby={title}
            classes={dialogClasses}
        >
            <Box className={classes.modalHeader}>
                <Typography className={classes.title}>{title}</Typography>

                <Box className={classes.closeIcon}>
                    <IconButton onClick={onClose}>
                        <Icon name="Close" color="#959598" />
                    </IconButton>
                </Box>
            </Box>
            {isLoading ? (
                <Box>
                    <Loader />
                </Box>
            ) : (<>
                {credentialsOptions && credentialsOptions.length > 0 && <>
                    <Box className={classes.sectionHeader}>{t('Common_SelectCredentialsType')}</Box>
                    <Box className={classes.body}>
                        <Box >
                            <RadioGroup
                                defaultValue="100"
                                aria-label="100"
                                name={""}
                                value={'value'}
                                onChange={(e) => handleCredentialsSelect(e)}
                                className={classes.radioBtn}
                            >
                                {credentialsTypeOptions.map((item, index) => (
                                    <FormControlLabel
                                        value={item.value}
                                        className={classes.formControlLabel}
                                        control={<StyledRadio />}
                                        label={t(item.label)}
                                        checked={
                                            credentialsType === item.value
                                                ? true
                                                : false
                                        }
                                    />
                                ))}

                            </RadioGroup>
                        </Box>
                    </Box>
                    {credentialsType === "personalCredentials" && <>
                        <Box className={classes.sectionHeader}>{t('Common_SelectCredentials')}</Box>
                        <Box className={classes.body}>
                            <CredentialsSearch isLoading={isLoading} credentialsOptions={credentialsOptions} item={item} selectedCredentials={selectedCredentials} setSelectedCredentials={(e: any) => setSelectedCredentials(e)} />
                        </Box>
                    </>}
                </>}
            </>
            )}

            <Box className={classes.sectionHeader}>{t('SetDuration')}</Box>
            <Box className={classes.body}>
                <Box>
                    <ItemDetails.Attribute
                        label={t('DefaultAccessDurationInMinutes')}
                        labelProps={{ marginRight: '10px' }}
                        value={requestPolicy?.defaultValueInMinutes}
                        orientation={attributeOrientation}
                        valueProps={overflowStyleProps}
                    />
                    <ItemDetails.Attribute
                        label={t('MaxAccessDurationInMinutes')}
                        labelProps={{ marginRight: '10px' }}
                        value={requestPolicy?.maximumValueInMinutes}
                        orientation={attributeOrientation}
                        valueProps={overflowStyleProps}
                    />
                    <ItemDetails.Attribute
                        label={t('SetDuration')}
                        labelProps={{
                            style: {
                                marginRight: '10px',
                                alignSelf: 'center',
                                minWidth: '100px',
                            },
                        }}
                        value={
                            <>
                                <TimeDurationControl
                                    selectedStartDate={selectedStartDate}
                                    setSelectedStartDate={setSelectedStartDate}
                                    selectedDuration={selectedDuration}
                                    setSelectedDuration={setSelectedDuration}
                                    maximumDuration={
                                        requestPolicy?.maximumValueInMinutes
                                    }
                                />
                                {hasInvalidDates && invalidDatesMessage && (
                                    <Box className={classes.invalidDatesMessage}>
                                        {invalidDatesMessage}
                                    </Box>
                                )}</>

                        }
                        orientation={attributeOrientation}
                        valueProps={overflowStyleProps}
                    />
                </Box>
            </Box>

            <Box className={classes.footer}>{footerContent}</Box>
        </Dialog>
    )
}
