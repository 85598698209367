import React from 'react'
import { Box } from '@material-ui/core'
import { Card, ExpansionPanel } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'
import { useIsSmallScreen, useQuery } from 'packages/core'
import useConfiguration from 'useConfiguration'
import { VerticalTabs } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import appConfig from 'config'

export interface ManageAccessNavigationProps {}

export const ManageAccessNavigation: React.FC<ManageAccessNavigationProps> = ({}) => {
    const { t } = useTranslation()
    const isSmallScreen = useIsSmallScreen()

    const query = useQuery()

    const { resourceTypes } = useConfiguration()

    const links = resourceTypes.map((r) => (
        <Box key={r.name}>
            <VerticalTabs.Tab
                key={r.name}
                to={`${appConfig.APP_SUBPATH}${
                    r.route
                }/manageAccess?${query.toString()}`}
                label={t(r.title)}
                icon={r.icon && <Icon name={r.icon.small} color="#000" />}
            />
        </Box>
    ))
    return isSmallScreen ? (
        <ExpansionPanel expanded={false} title={t('Common_ManageAccess')}>
            {links}
        </ExpansionPanel>
    ) : (
        <Card
            isTitleJsx={true}
            cardTitle={<Box paddingLeft="18px">{t('Common_ManageAccess')}</Box>}
            expanded={false}
            collapsible
            icon={
                <Box marginLeft="10px">
                    <Icon name="ManageAccess" />
                </Box>
            }
        >
            <Box marginBottom="-24px">{links}</Box>
        </Card>
    )
}
