import React, { useEffect, useState } from 'react'
import { styled, Typography } from '@material-ui/core'
import { Tooltip } from 'packages/eid-ui'
import { isNilOrEmpty } from 'packages/core'

const StyledTypography = styled(Typography)({
    width: '100%',
    wordBreak: 'break-word',
    wordWrap: 'break-word',
    overflow: 'auto',
})

const TextFormatter = (props) => {
    const {
        value,
        maxCharacters = 100,
        tooltipDelay = 1000,
        tooltipStyles,
        tooltipProps,
        showTooltip,
        ellipsis,
        ...rest
    } = props

    const [truncatedText, setTruncatedText] = useState()

    useEffect(() => {
        let text

        if (value && value.length > maxCharacters) {
            text = `${value.substring(0, maxCharacters).trimEnd()}...`
        } else {
            text = value
        }

        return setTruncatedText(text)
    }, [value, maxCharacters])

    const nullValue = <span style={{ color: '#9b9b9b' }}>-</span>
    const ellipsisValue = (
        <Tooltip
            title={value ? value : ''}
            enterDelay={tooltipDelay}
            enterNextDelay={tooltipDelay}
            tooltipStyles={tooltipStyles}
            placement="bottom-start"
            {...tooltipProps}
        >
            <Typography noWrap {...rest}>
                {value}
            </Typography>
        </Tooltip>
    )

    const truncatedValue = (
        <Tooltip
            title={
                isNilOrEmpty(value)
                    ? ''
                    : showTooltip === 'always'
                    ? value
                    : value.length > maxCharacters
                    ? value
                    : ''
            }
            enterDelay={tooltipDelay}
            enterNextDelay={tooltipDelay}
            tooltipStyles={tooltipStyles}
            {...tooltipProps}
        >
            <StyledTypography {...rest}>{truncatedText}</StyledTypography>
        </Tooltip>
    )

    return isNilOrEmpty(value)
        ? nullValue
        : ellipsis
        ? ellipsisValue
        : truncatedValue
}

export default TextFormatter
