import React from 'react'
import { FilterChip } from 'packages/eid-ui'

const Filter = ({ bgColor, textColor, ...rest }) => {
    return (
        <FilterChip
            {...rest}
            style={{
                borderRadius: '10px',
                overflow: 'hidden',
                height: '20px',
                backgroundColor: bgColor,
                color: textColor,
            }}
        />
    )
}

export default Filter
