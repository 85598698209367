import { useState } from 'react'
import { Box, Typography, styled, Grid, Paper } from '@material-ui/core'
import { Loader, useViewIndicator } from 'packages/eid-ui'
import ManageAccessTableView from './ManageAccessTableView'
import { useQuery } from 'packages/core'
import AccessItem from '../ManageAccess/AccessItem'
import { CardList } from 'packages/eid-controls'
import { useTranslation } from 'react-i18next'
import AccessItemDrawer from '../ManageAccess/AccessItemDrawer'
import {
    getResourceItemFromList,
    getUniqueId,
} from '../ManageAccess/resourceTypeHelpers'
import { subHeaderHeight } from 'utils'
import Selectors from 'containers/Selectors'
import useSubcomponents from 'useSubcomponents'
import { AppliedFilters } from 'components'
import ManageAccessTabs from './ManageAccessTabs'
import { matchPath } from 'react-router'
import { useLocation } from 'react-router-dom'
import appConfig from 'config'

const GridItem = styled(Box)({
    marginRight: '16px',
})

const DesktopVersion = ({
    id,
    list,
    loading,
    onSort,
    sortBy,
    sortOrder,
    loadingMore,
    currentResourceType,
    resourceTypeAttributes,
    infiniteScrollObervableDiv,
    history,
}) => {
    const { t } = useTranslation()
    const {
        canSeeAtLeastOneFilter,
        canSeeManageAccessFiltersBar,
    } = useSubcomponents()

    const CURRENT_SCREEN = 'ManageAccess'
    const canSeeAnyFilter = canSeeAtLeastOneFilter(
        currentResourceType,
        CURRENT_SCREEN,
    )

    const query = useQuery()
    const location = useLocation()

    let currentPath = location.pathname
    const isHistoryView = matchPath(currentPath, {
        path: '/credentials/manageAccess/history',
        exact: true,
        strict: false,
    })

    const [activeTab, setActiveTab] = useState(
        isHistoryView ? 'history' : 'manage',
    )

    const { view } = useViewIndicator({
        view: query.get('view'),
        onCardViewClick: () => {
            query.set('view', 'card')
            history.push(`${history.location.pathname}?${query.toString()}`)
        },
        onListViewClick: () => {
            query.delete('view')
            history.push(`${history.location.pathname}?${query.toString()}`)
        },
    })

    return (
        <>
            <div
                style={{
                    flexGrow: 1,
                    marginTop: `${subHeaderHeight + 20}px`,
                }}
            >
                <div
                    style={{
                        flexGrow: 1,
                        display: 'flex',
                    }}
                >
                    {/* Filter based on configurations */}
                    <Box
                        display="flex"
                        flexDirection="column"
                        flex="27%"
                        maxWidth="370px"
                    >
                        <GridItem style={{ padding: '8px', width: '100%' }}>
                            <Paper>
                                <ManageAccessTabs
                                    active={activeTab}
                                    onChange={(tab) => {
                                        setActiveTab(tab)
                                        tab === 'history'
                                            ? history.push(
                                                  `${appConfig.APP_SUBPATH}/credentials/manageAccess/history`,
                                              )
                                            : history.push(
                                                  `${appConfig.APP_SUBPATH}/credentials/manageAccess`,
                                              )
                                    }}
                                />
                            </Paper>
                        </GridItem>
                        <Selectors
                            resourceType={currentResourceType.name}
                            screen={CURRENT_SCREEN}
                        />
                    </Box>
                    <Box
                        flex="73%"
                        display="flex"
                        flexWrap="wrap"
                        height="100%"
                    >
                        <Grid container spacing={0} style={view === 'card' ? {
                        margin:'0 14px',
                    } : {}}>
                            {canSeeAnyFilter && canSeeManageAccessFiltersBar && (
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    width="100%"
                                    padding="8px"
                                >
                                    <GridItem minWidth="355px" width="100%">
                                        <AppliedFilters
                                            resourceType={
                                                currentResourceType.name
                                            }
                                        />
                                    </GridItem>
                                </Box>
                            )}

                            {view === 'card' ? (
                                <CardList
                                    fetcher={() => ({
                                        loading,
                                        loadingMore,
                                        list,
                                    })}
                                    infiniteScrollObervableDiv={
                                        infiniteScrollObervableDiv
                                    }
                                    noItemMessage={
                                        <Typography
                                            variant="h5"
                                            style={{ color: '#b4b4b4' }}
                                        >
                                            {t('Common_NoItemsFound', {
                                                itemType: t(
                                                    currentResourceType.title,
                                                ),
                                            })}
                                        </Typography>
                                    }
                                    renderItem={(item, index) => (
                                        <div
                                            key={`${getUniqueId(
                                                item,
                                            )}-${index} `}
                                            style={{
                                                height: 'inline-block',
                                                width: '50%',
                                                overflow: 'hidden',
                                            }}
                                        >
                                            <AccessItem
                                                resourceType={
                                                    currentResourceType.name
                                                }
                                                cardStyles={{
                                                    margin: '8px',
                                                }}
                                                buttonStyles={{
                                                    '&:hover': {
                                                        backgroundColor:
                                                            '#02997E',
                                                    },
                                                }}
                                                attributes={
                                                    resourceTypeAttributes
                                                }
                                                cardHeaderStyles={{}}
                                                item={item}
                                                onItemClick={() => {
                                                    query.set(
                                                        'selected',
                                                        getUniqueId(item),
                                                    )
                                                    history.push(
                                                        `${
                                                            history.location
                                                                .pathname
                                                        }?${query.toString()}`,
                                                    )
                                                }}
                                                color={{
                                                    primary: '#01ae8f',
                                                }}
                                            />
                                        </div>
                                    )}
                                />
                            ) : (
                                <Box
                                    padding="8px"
                                    marginRight="8px"
                                    width="100%"
                                >
                                    <ManageAccessTableView
                                        onSort={onSort}
                                        sortOrder={sortOrder}
                                        sortBy={sortBy}
                                        data={list}
                                        attributes={resourceTypeAttributes}
                                        loading={loading}
                                        fallbackMessage={t(
                                            'Common_NoItemsFound',
                                            {
                                                itemType: t(
                                                    currentResourceType.title,
                                                ),
                                            },
                                        )}
                                        onItemClick={(item) => {
                                            query.set(
                                                'selected',
                                                getUniqueId(item),
                                            )
                                            history.push(
                                                `${
                                                    history.location.pathname
                                                }?${query.toString()}`,
                                            )
                                        }}
                                        currentResourceType={
                                            currentResourceType
                                        }
                                    />
                                    {loadingMore && <Loader />}
                                    {infiniteScrollObervableDiv}
                                </Box>
                            )}
                        </Grid>
                    </Box>
                </div>
            </div>

            {id &&
                list &&
                (isHistoryView ||
                    getResourceItemFromList(
                        id,
                        list,
                        currentResourceType.name,
                    )) && (
                    <AccessItemDrawer
                        type={
                            isHistoryView
                                ? 'CredentialsHistory'
                                : currentResourceType.name
                        }
                        item={getResourceItemFromList(
                            id,
                            list,
                            isHistoryView
                                ? 'CredentialsHistory'
                                : currentResourceType.name,
                        )}
                        attributes={resourceTypeAttributes}
                        toggleDrawer={() => {
                            if (query.get('showCheckouts')) {
                                query.delete('showCheckouts')
                            }
                            query.delete('selected')
                            history.push(
                                `${
                                    history.location.pathname
                                }?${query.toString()}`,
                            )
                        }}
                        color={{
                            primary: '#01ae8f',
                        }}
                        currentResourceType={currentResourceType}
                    />
                )}
        </>
    )
}

export default DesktopVersion
