import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { PreApprovedItemsAccessTile } from '../AccessTile'
import { PreApprovedItemWrapper } from '../WrapperForPreApprovedAccessItem'

export type PreApprpovedLoginAccessProps = {
    item?: any
    handleViewRequest: () => void
}



export const PreApprpovedLoginAccess: FC<PreApprpovedLoginAccessProps> = (props) => {
    const { item, handleViewRequest } = props
    const { t } = useTranslation()
    return (
        <PreApprovedItemWrapper title={t('Common_LoginSessionExistingAccess')} itemCount={2}>
            <PreApprovedItemsAccessTile handleViewRequest={handleViewRequest} showArrows={false} item={{ ...item, type: "preapprovedConnect" }} accessType={t('Common_PreApproved')} />
            <PreApprovedItemsAccessTile showCount={true} handleViewRequest={handleViewRequest} item={{ ...item, type: "request" }} accessType={t('Common_Requests')} accessName={t('Common_LoginSession')} />
        </PreApprovedItemWrapper>
    )
}


