import React, { useState } from 'react'
import { Box, styled } from '@material-ui/core'
import { Pagination, Loader } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'
import { StyledSearchInput } from 'components'
import { useIsSmallScreen, useDebounce } from 'packages/core'
import { getFullImageUrl } from 'utils'

const ApplicationsList = ({ resource, locationId, useDataHook }) => {
    const take = 9
    const { t } = useTranslation()

    const isSmallScreen = useIsSmallScreen()

    const [page, setPage] = React.useState(1)
    const [searchKey, setSearchKey] = useState('')

    const handlePageChange = (_, value) => {
        setPage(value)
    }
    const debouncedSearchValue = useDebounce(searchKey)

    const handleSearchChange = (event) => {
        setPage(1)
        const keyword = event.target.value
        setSearchKey(keyword)
    }

    const { latestData } = useDataHook(
        resource.id,
        (page - 1) * take,
        take,
        debouncedSearchValue && encodeURIComponent(debouncedSearchValue),
        locationId,
    )

    const applications = latestData ? latestData.data : undefined

    const applicationsLoading = !Boolean(latestData)

    const numberOfPages = latestData
        ? Math.ceil(latestData.totalCount / take)
        : 0
    return (
        <Box
            display="flex"
            flexDirection="column"
            position="relative"
            boxShadow="0 2px 16px 0 rgba(0, 0, 0, 0.11)"
        >
            <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                padding="16px 32px 16px 32px"
                style={{ backgroundColor: '#ebebed' }}
            >
                <Box width={!isSmallScreen ? '308px' : '200px'}>
                    <StyledSearchInput
                        fullWidth
                        type="text"
                        placeholder={t('Common_Search')}
                        value={searchKey}
                        onChange={handleSearchChange}
                    />
                </Box>
            </Box>

            {applicationsLoading ? (
                <Loader />
            ) : (
                <>
                    <PaddedDiv
                        display="flex"
                        flexWrap="wrap"
                        paddingY="20px !important"
                    >
                        {applications.length === 0 ? (
                            <Box
                                width="100%"
                                display="flex"
                                justifyContent="center"
                            >
                                {t('Common_NoDataFound')}
                            </Box>
                        ) : (
                            applications.map((i) => (
                                <Box
                                    minWidth="33%"
                                    maxWidth="240px"
                                    display="flex"
                                    alignItems="center"
                                    padding="16px"
                                >
                                    <img
                                        style={{ maxHeight: '40px' }}
                                        src={getFullImageUrl(
                                            i.applicationLogoImageUrl,
                                        )}
                                    ></img>

                                    <Box
                                        marginLeft="10px"
                                        color="#5d6870"
                                        fontSize="14px"
                                    >
                                        {i.friendlyName}
                                    </Box>
                                </Box>
                            ))
                        )}
                    </PaddedDiv>

                    {numberOfPages > 1 && (
                        <Box
                            padding={!isSmallScreen ? '20px 32px' : '16px'}
                            display="flex"
                            alignItems="center"
                            justifyContent={
                                !isSmallScreen ? 'flex-start' : 'center'
                            }
                        >
                            <Pagination
                                count={numberOfPages}
                                size={!isSmallScreen ? 'large' : 'small'}
                                page={page}
                                onChange={handlePageChange}
                            />
                        </Box>
                    )}
                </>
            )}
        </Box>
    )
}

export default ApplicationsList

const PaddedDiv = styled(Box)({
    padding: '0 31px',
})
