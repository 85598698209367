import { FC, Fragment, useState } from 'react'
import { Button } from './Button'
import { useTranslation } from 'react-i18next'
import {
    Box,
    createStyles,
    Dialog,
    makeStyles,
    Typography,
} from '@material-ui/core'
import { Icon } from 'packages/eid-icons'
import { IconButton } from 'packages/eid-ui'
import DomPurify from 'dompurify'
import ReactHtmlParser from 'react-html-parser'

const useModalStyles = makeStyles({
    modal: {
        backgroundColor: '#f7f8fa',
        borderRadius: '8px',
        padding: 0,
        '&:focus': {
            outline: 'none',
        },
    },
    modalHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        minHeight: '20px',
        padding: '16px 8px',
    },
    closeIcon: {
        display: 'flex',
        marginRight: '-16px',
        marginTop: '-16px',
        height: '10px',
    },

    title: {
        fontSize: '24px',
        fontWeight: 'normal',
        color: '#282828',
    },
})

const InstructionsModal: FC<any> = (props) => {
    const { open, onClose, title, header, children } = props
    const classes = useModalStyles()

    return (
        <Dialog
            fullWidth
            scroll={'body'}
            open={open}
            onClose={onClose}
            aria-labelledby={title}
            aria-describedby={title}
        >
            <Box className={classes.modal}>
                {header ? (
                    header
                ) : (
                    <Box className={classes.modalHeader}>
                        <Typography className={classes.title}>
                            {title}
                        </Typography>
                        <Box className={classes.closeIcon}>
                            <IconButton onClick={onClose}>
                                <Icon name="Close" color="#959598" />
                            </IconButton>
                        </Box>
                    </Box>
                )}

                {children}
            </Box>
        </Dialog>
    )
}

const useStyles = makeStyles(
    createStyles({
        modalHeader: {
            padding: '32px 32px 16px 32px',
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
        },
        content: {
            color: '#000000',
            marginTop: '52px',
            padding: '20px 20px 32px 32px',
            backgroundColor: '#ffffff',
        },
        title: {
            fontSize: '16px',
            fontWeight: 'bold',
            color: '#000000',
        },
        closeIcon: {
            position: 'absolute',
            right: 20,
            top: 16,
            height: '10px',
            cursor: 'pointer',
        },
    }),
)

export type InstructionsProps = {
    title: string
    instructions: string
    button?: any
}
export const Instructions: FC<InstructionsProps> = (props) => {
    const { t } = useTranslation()

    const [showModal, setShowModal] = useState(false)

    const classes = useStyles()

    const header = (
        <Box className={classes.modalHeader}>
            <Typography className={classes.title}>{props.title}</Typography>
            <Box className={classes.closeIcon}>
                <Icon
                    name="Close"
                    color="#000000"
                    onClick={() => setShowModal(false)}
                />
            </Box>
        </Box>
    )

    const parsedHtml = ReactHtmlParser(DomPurify.sanitize(props.instructions))

    return (
        <Fragment>
            {props.button ? (
                props.button(setShowModal)
            ) : (
                <Button onClick={() => setShowModal(true)}>
                    {t('Common_MoreInfo')}
                </Button>
            )}
            <InstructionsModal open={showModal} header={header}>
                <Box className={classes.content}>{parsedHtml}</Box>
            </InstructionsModal>
        </Fragment>
    )
}
