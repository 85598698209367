import { Fragment, useEffect, useState } from 'react'
import { Box, styled, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {
    Loader,
    Select,
    Attribute,
    AttributeLabel,
    AttributeValue,
} from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'
import {
    useCart,
    useCheckComputerAccess,
    useTargetPerson,
    useComputerRequestableDetailOptions,
    useAccessRequestPolicy,
    useCurrentPerson,
} from 'hooks'
import { Icon } from 'packages/eid-icons'
import {
    DifferentiationValueSelector as ValueSelector,
    ItemDetails,
} from 'components'
import { useIsSmallScreen } from 'packages/core'
import cartHelpers from 'containers/Cart/cartHelpers'
import { AddToCart } from './AddToCart'
import { ResourceTypesNamespace, useRegistry } from 'core'
import { OverviewSection } from './OverviewSection'
import { MoreInformationSection } from './MoreInformationSection'
import { SelectorSection } from './SelectorSection'
import { useTimeConstrainedControl } from './TimeConstrainedControl'
import ComputerSessionRequest from 'components/ComputerSessionRequest'
import { PreApprovedItems } from './PreApprovedItems'
import { Button } from 'components'

const useStyles = makeStyles(() => ({
    customselect: {
        width: '320px',
    },
    addToCartPreApproved: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    disabledButton: {
        '& button:nth-child(1) > div': {
            background: '#307fc1',
        },
    },
    taskbadge: {
        position: 'relative',
        top: '12px',
        right: '16px',
        background: '#ff3c00',
        height: '15px',
        width: '15px',
        borderRadius: '50%',
        fontSize: '10px',
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '1px',
    },
    attributelabel: {
        minWidth: '220px',
    },
}))

const PaddedDiv = styled(Box)({
    padding: '0 31px',
})

const ComputerDetails = ({ computer, toggleDrawer }) => {
    const { t } = useTranslation()
    const classes = useStyles()

    const MEMBERSHIP_BASED_ACCESS = t('Computer_MembershipBasedAccess')
    const LOGIN_SESSION_ACCESS = t('Computer_LoginSessionAccess')
    const ONE_TIME_ACCESS = t('Computer_OneTimeAccess')
    const PRE_APPROVED_ACCESS = t('Computer_PreApprovedAccess')
    const PERSONAL_CREDENTIALS = t('Computer_PersonalCredentials')
    const SHARED_CREDENTIALS = t('Computer_SharedCredentials')

    const allRequestTypes = [
        { id: 1, friendlyName: MEMBERSHIP_BASED_ACCESS },
        { id: 2, friendlyName: LOGIN_SESSION_ACCESS },
    ]
    const allAccessTypes = [
        { id: 1, friendlyName: ONE_TIME_ACCESS },
        { id: 2, friendlyName: PRE_APPROVED_ACCESS },
    ]
    const allCredentialTypes = [
        { id: 1, friendlyName: PERSONAL_CREDENTIALS },
        { id: 2, friendlyName: SHARED_CREDENTIALS },
    ]

    const isSmallScreen = useIsSmallScreen()

    const [targetPerson] = useTargetPerson()

    const { data: cart } = useCart()

    const [activeTab, setActiveTab] = useState('overview')
    const [requestType, setRequestType] = useState(null)
    const [requestTypes, setRequestTypes] = useState(allRequestTypes)
    const [accessTypes, setAccessTypes] = useState(allAccessTypes)
    const [credentialType, setCredentialType] = useState(null)
    const [credentialTypes, setCredentialTypes] = useState(allCredentialTypes)
    const [accessType, setAccessType] = useState(null)
    const [accessLevel, setAccessLevelValue] = useState(null)
    const [person, setPerson] = useState(null)
    const [credentials, setCredentials] = useState(null)
    const [showScheduledOptions, setShowScheduledOptions] = useState(false)

    const { data: currentPerson } = useCurrentPerson()
    const shoppingForSomeoneElse = targetPerson.id !== currentPerson.id

    const { data, isLoading } = useComputerRequestableDetailOptions(
        computer.id,
        targetPerson.id,
    )

    const {
        data: accessLevels,
        isLoading: isCheckingAccess,
    } = useCheckComputerAccess(computer.id, targetPerson.id)

    const requestPolicy = useAccessRequestPolicy(computer.requestPolicyId)

    const credentialsOptions = data?.personalCredentials

    const computerAdditionalProps = {
        linkedCoreIdentity: person?.id,
        isPreApproved: accessType === PRE_APPROVED_ACCESS,
        personalCredentialId: credentials?.id,
    }

    const {
        selectedStartDate,
        selectedEndDate,
        hasInvalidDates,
        timeConstrainedControl,
    } = useTimeConstrainedControl(requestPolicy, true)

    useEffect(() => {
        if (data && !data.isMembershipEnabled) {
            const newRequestTypes = requestTypes.filter(
                (x) => x.friendlyName !== MEMBERSHIP_BASED_ACCESS,
            )
            setRequestTypes(newRequestTypes)
        }
        if (data && !data.isPsmEnabled) {
            const newRequestTypes = requestTypes.filter(
                (x) => x.friendlyName !== LOGIN_SESSION_ACCESS,
            )
            setRequestTypes(newRequestTypes)
        }

        if (data && !data.isPreApproved) {
            const newAccessTypes = accessTypes.filter(
                (x) => x.friendlyName !== PRE_APPROVED_ACCESS,
            )
            setAccessTypes(newAccessTypes)
        }

        if (data && data.personalCredentials.length === 0) {
            const newCredentialTypes = credentialTypes.filter(
                (x) => x.friendlyName !== PERSONAL_CREDENTIALS,
            )
            setCredentialTypes(newCredentialTypes)
        }
    }, [data])

    useEffect(() => {
        if (requestType?.friendlyName === LOGIN_SESSION_ACCESS) {
            setAccessLevelValue(null)
            setPerson(null)
        }
        if (requestType?.friendlyName === MEMBERSHIP_BASED_ACCESS) {
            setCredentialType(null)
            setCredentials(null)
        }
    }, [requestType])

    const registry = useRegistry()

    const computerType = registry.get(ResourceTypesNamespace, 'Computers')

    const getPreValidationMessage = () => {
        if (!Boolean(requestType)) {
            return t('Common_SelectRequestType')
        }
        if (!Boolean(accessType)) {
            return t('Common_SelectAccessType')
        }
        if (
            requestType?.friendlyName === MEMBERSHIP_BASED_ACCESS &&
            !Boolean(person)
        ) {
            return t('Common_SelectPerson')
        }
        if (
            requestType?.friendlyName === MEMBERSHIP_BASED_ACCESS &&
            !Boolean(accessLevel)
        ) {
            return t('Common_SelectAccessLevel')
        }
        if (
            requestType?.friendlyName === LOGIN_SESSION_ACCESS &&
            !Boolean(credentialType)
        ) {
            return t('Common_SelectCredentialType')
        }
        if (
            credentialType?.friendlyName === PERSONAL_CREDENTIALS &&
            !Boolean(credentials)
        ) {
            return t('Common_SelectCredentials')
        }
        if (accessType?.friendlyName === ONE_TIME_ACCESS && hasInvalidDates) {
            return t('Common_InvalidDates')
        }
        if (accessLevels && accessLevels.isAssigned) {
            return t('ResourceAlreadyAssigned')
        }
        return ''
    }

    const preAdd = () => {
        const itemToAdd = cartHelpers.computerToCartItem({
            targetPerson,
            assignmentType: 'Add',
            computer,
            assignmentStatus: accessLevel,
            computerAdditionalProps,
            timeConstrained: accessType?.friendlyName === ONE_TIME_ACCESS,
            startDateUtc:
                accessType?.friendlyName === ONE_TIME_ACCESS
                    ? selectedStartDate
                    : null,
            endDateUtc:
                accessType?.friendlyName === ONE_TIME_ACCESS
                    ? selectedEndDate
                    : null,
            computerAdditionalProperties: {
                personalCredentialID: credentials ? credentials.id : null,
            },
        })
        if (!itemToAdd) return undefined

        return itemToAdd
    }

    const handleCredentialsChange = (credential) => {
        setCredentials(credential)
    }

    const requestTypeSelectorSection = (
        <SelectorSection
            sectionLabel={t('Common_SelectRequestType')}
            iconName="RequestType"
        >
            <ValueSelector
                onChange={(val) => setRequestType(val)}
                data={requestTypes}
                value={requestType?.id}
                getOptionLabel={(o) => o.friendlyName}
                checkSelectedOption={(o, selectedValueId) =>
                    o.id === selectedValueId
                }
            />
        </SelectorSection>
    )

    const accessTypeSelectorSection = (
        <SelectorSection
            sectionLabel={t('Common_SelectAccessType')}
            iconName="MonitorClock"
        >
            <ValueSelector
                onChange={(val) => setAccessType(val)}
                data={accessTypes}
                value={accessType?.id}
                getOptionLabel={(o) => o.friendlyName}
                checkSelectedOption={(o, selectedValueId) =>
                    o.id === selectedValueId
                }
            />
        </SelectorSection>
    )

    const accessLevelSelectorSection = (
        <SelectorSection
            sectionLabel={t('Common_SelectAccessLevel')}
            iconName="AccessLevel"
        >
            <ValueSelector
                loading={isCheckingAccess}
                onChange={(val) => setAccessLevelValue(val)}
                data={
                    accessLevels &&
                    accessLevels.filter(
                        (o) =>
                            !o.isAssigned &&
                            o?.resourceAssignment?.assigneeId ===
                                targetPerson.id,
                    )
                }
                value={accessLevel?.resourceAssignment.resourceAssignmentId}
                getOptionLabel={(o) => o.resourceAssignment.friendlyName}
                checkSelectedOption={(o, selectedValueId) =>
                    o.resourceAssignment.resourceAssignmentId ===
                    selectedValueId
                }
                readOnlyOptions={
                    accessLevels &&
                    accessLevels.filter(
                        (o) =>
                            o.isAssigned &&
                            o?.resourceAssignment?.assigneeId ===
                                targetPerson.id,
                    )
                }
            />
        </SelectorSection>
    )

    const durationSelectorSection = (
        <SelectorSection sectionLabel={t('Common_SelectDuration')}>
            <Box style={{ position: 'relative' }}>
                <Attribute orientation="horizontal">
                    <AttributeLabel className={classes.attributelabel}>
                        {t('Common_DefaultAccessDuration')}
                    </AttributeLabel>
                    <AttributeValue>
                        {requestPolicy && requestPolicy.defaultValueInMinutes}
                    </AttributeValue>
                </Attribute>

                <Attribute orientation="horizontal">
                    <AttributeLabel className={classes.attributelabel}>
                        {t('Common_MaxAccessDuration')}
                    </AttributeLabel>
                    <AttributeValue>
                        {requestPolicy && requestPolicy.maximumValueInMinutes}
                    </AttributeValue>
                </Attribute>
                <Box>{timeConstrainedControl}</Box>
            </Box>
        </SelectorSection>
    )

    const credentialsTypeSelectorSection = (
        <SelectorSection
            sectionLabel={t('Common_SelectCredentialType')}
            iconName="MonitorSettings"
        >
            <ValueSelector
                onChange={(val) => setCredentialType(val)}
                data={credentialTypes}
                value={credentialType?.id}
                getOptionLabel={(o) => o.friendlyName}
                checkSelectedOption={(o, selectedValueId) =>
                    o.id === selectedValueId
                }
            />
        </SelectorSection>
    )

    const credentialsSelectorSection = (
        <SelectorSection
            parentStyle={{
                padding: '18px 31px 20px 31px',
                minHeight: '100px',
            }}
            sectionLabel={t('Common_SelectCredentials')}
        >
            {data && (
                <Box className={classes.customselect}>
                    <Select
                        placeholder={t('Common_SelectCredentials')}
                        options={credentialsOptions}
                        loading={isLoading}
                        onChange={handleCredentialsChange}
                        value={credentials}
                        getOptionLabel={(option) => option.friendlyName}
                    />
                </Box>
            )}
        </SelectorSection>
    )

    const getPreValidationMessageForScheduleAccessPreApproved = () => {
        if (!Boolean(credentialType)) {
            return t('Common_SelectCredentialType')
        }
        if (
            credentialType?.friendlyName === PERSONAL_CREDENTIALS &&
            !Boolean(credentials)
        ) {
            return t('Common_SelectCredentials')
        }
        if (hasInvalidDates) {
            return t('Common_InvalidDates')
        }
        if (accessLevels && accessLevels.isAssigned) {
            return t('ResourceAlreadyAssigned')
        }

        const checkIfAlreadyExistInCart = cart.cartItems.find(
            (x) => x.requestableResourceId === computer.id,
        )
        if (checkIfAlreadyExistInCart) {
            return t('Common_ItemAlreadyInCart')
        }
        return ''
    }

    if (!cart) return <Loader />
    return (
        <Fragment>
            <ItemDetails.Tabs
                variant="standard"
                value={activeTab}
                onChange={(_, value) => setActiveTab(value)}
            >
                <ItemDetails.Tabs.Tab
                    value="overview"
                    label={t('Common_Overview')}
                />
                <ItemDetails.Tabs.Tab
                    value="additionalInformation"
                    label={t('Common_MoreInformation')}
                />

                <ItemDetails.Tabs.Tab
                    value="sessionRequest"
                    label={t('Common_SessionRequest')}
                />
                <span className={classes.taskbadge}>
                    {computer.checkOutCount}
                </span>
            </ItemDetails.Tabs>
            {activeTab === 'overview' && (
                <OverviewSection computerRequestableDetails={data} />
            )}
            {activeTab === 'additionalInformation' && (
                <MoreInformationSection computer={computer} />
            )}
            {activeTab === 'sessionRequest' && (
                <ComputerSessionRequest item={computer} />
            )}

            {activeTab !== 'sessionRequest' && (
                <>
                    {!isLoading && data && !shoppingForSomeoneElse && (
                        <PreApprovedItems
                            handleViewRequest={() =>
                                setActiveTab('sessionRequest')
                            }
                            showScheduledOptions={showScheduledOptions}
                            item={data}
                            setShowScheduledOptions={() =>
                                setShowScheduledOptions(!showScheduledOptions)
                            }
                        />
                    )}
                    {/* Section for scheduled if preapproved and want to schedule start */}

                    {showScheduledOptions && (
                        <>
                            {!isSmallScreen && (
                                <Fragment>
                                    {credentialsTypeSelectorSection}
                                    {credentialType?.friendlyName ===
                                        PERSONAL_CREDENTIALS &&
                                        credentialsSelectorSection}
                                    {durationSelectorSection}
                                    {/* Section for Request access button and cancel for sceduled */}
                                    <Box
                                        className={classes.addToCartPreApproved}
                                    >
                                        <Box
                                            className={
                                                !getPreValidationMessageForScheduleAccessPreApproved() &&
                                                classes.disabledButton
                                            }
                                        >
                                            <AddToCart
                                                resourceType={computerType}
                                                resource={computer}
                                                secondary={accessLevel}
                                                preValidate={
                                                    getPreValidationMessageForScheduleAccessPreApproved
                                                }
                                                preAdd={preAdd}
                                                postAdd={toggleDrawer}
                                                type={'RequestAccess'}
                                            />
                                        </Box>
                                        <Box
                                            style={{
                                                height: '80px',
                                                position: 'relative',
                                                alignItems: 'center',
                                                display: 'flex',
                                                marginBottom: '16px',
                                            }}
                                        >
                                            <Button
                                                rootStylesProp={{
                                                    width: '113px',
                                                    height: '48px',
                                                    borderRadius: '5px',
                                                    backgroundColor: '#fff',
                                                    border: 'solid 1px #d0021b',
                                                    margin:
                                                        '16px 49px 16px 36px',
                                                    float: 'right',
                                                    color: '#d0021b',
                                                }}
                                                onClick={() =>
                                                    setShowScheduledOptions(
                                                        !showScheduledOptions,
                                                    )
                                                }
                                            >
                                                <Icon
                                                    name="Close"
                                                    color="#d0021b"
                                                    height="16px"
                                                    width="16px"
                                                />
                                                <Typography
                                                    style={{
                                                        paddingLeft: '12px',
                                                    }}
                                                >
                                                    {t('Common_Cancel')}
                                                </Typography>
                                            </Button>
                                        </Box>
                                    </Box>
                                </Fragment>
                            )}
                        </>
                    )}

                    {/* Section for scheduled if preapproved and want to schedule End */}
                    {!isLoading &&
                        (!data?.computer?.isPreApproved ||
                            shoppingForSomeoneElse) && (
                            <>
                                {!isSmallScreen && (
                                    <Fragment>
                                        {requestTypeSelectorSection}
                                        {accessTypeSelectorSection}
                                        {accessType?.friendlyName ===
                                            ONE_TIME_ACCESS &&
                                            durationSelectorSection}
                                        {requestType?.friendlyName ===
                                            LOGIN_SESSION_ACCESS &&
                                            credentialsTypeSelectorSection}
                                        {credentialType?.friendlyName ===
                                            PERSONAL_CREDENTIALS &&
                                            credentialsSelectorSection}
                                        {requestType?.friendlyName ===
                                            MEMBERSHIP_BASED_ACCESS &&
                                            accessLevelSelectorSection}
                                    </Fragment>
                                )}

                                {/* Need to setup UI for small screen as per requirements */}
                                {isSmallScreen && (
                                    <PaddedDiv>
                                        <Box
                                            paddingY="24px"
                                            display="flex"
                                            flexDirection="column"
                                            position="relative"
                                        >
                                            <Box
                                                position="absolute"
                                                top="16px"
                                                right="0"
                                            >
                                                <Icon
                                                    name="AccessLevel"
                                                    height="100px"
                                                    width="100px"
                                                    color="#D2D2D9"
                                                />
                                            </Box>
                                            <Box
                                                width="172px"
                                                paddingY="4px"
                                                zIndex={1}
                                            >
                                                <Typography
                                                    style={{
                                                        fontSize: '14px',
                                                        lineHeight: 1.43,
                                                        fontWeight: 600,
                                                        textTransform:
                                                            'uppercase',
                                                        wordBreak: 'break-word',
                                                    }}
                                                >
                                                    {t(
                                                        'Common_SelectAccessLevel',
                                                    )}
                                                </Typography>
                                            </Box>
                                            <Box paddingY="16px">
                                                {' '}
                                                <ValueSelector
                                                    loading={isCheckingAccess}
                                                    onChange={(val) =>
                                                        setAccessLevelValue(val)
                                                    }
                                                    data={
                                                        accessLevels &&
                                                        accessLevels.filter(
                                                            (o) =>
                                                                !o.isAssigned,
                                                        )
                                                    }
                                                    value={
                                                        accessLevel
                                                            ?.resourceAssignment
                                                            .resourceAssignmentId
                                                    }
                                                    getOptionLabel={(o) =>
                                                        o.resourceAssignment
                                                            .friendlyName
                                                    }
                                                    checkSelectedOption={(
                                                        o,
                                                        selectedValueId,
                                                    ) =>
                                                        o.resourceAssignment
                                                            .resourceAssignmentId ===
                                                        selectedValueId
                                                    }
                                                    readOnlyOptions={
                                                        accessLevels &&
                                                        accessLevels.filter(
                                                            (o) => o.isAssigned,
                                                        )
                                                    }
                                                />
                                            </Box>
                                        </Box>
                                    </PaddedDiv>
                                )}

                                <AddToCart
                                    resourceType={computerType}
                                    resource={computer}
                                    secondary={accessLevel}
                                    preValidate={getPreValidationMessage}
                                    preAdd={preAdd}
                                    postAdd={toggleDrawer}
                                />
                            </>
                        )}
                </>
            )}
        </Fragment>
    )
}

export default ComputerDetails
