import useConfiguration from 'useConfiguration'
import { isNilOrEmpty } from 'packages/core'

const useDefaultState = (resourceType) => {
    const { getResourceType } = useConfiguration()
    const currentResourceType = getResourceType(resourceType)

    const defaultState = {}

    const allDynamicFilters = currentResourceType.filters.filter(
        (f) => f.dynamic === true,
    )
    for (let i = 0; i < allDynamicFilters.length; i++) {
        const currentFilter = allDynamicFilters[i]

        if (currentFilter.type !== 'advancedSearch') {
            defaultState[currentFilter.contextProp] = currentFilter.defaultValue
        } else {
            if (!isNilOrEmpty(currentFilter.forms)) {
                currentFilter.forms.filters.forEach(
                    (f) => (defaultState[f.contextProp] = f.defaultValue),
                )
            }
            if (!isNilOrEmpty(currentFilter.tags)) {
                defaultState[currentFilter.tags.contextProp] = []
            }
        }
    }

    if (
        currentResourceType.filters.findIndex(
            (f) => f.name === 'TargetSystem',
        ) > -1
    ) {
        defaultState['accountStoreUsageType'] = null
        defaultState['accountStore'] = null
    }

    return defaultState
}

export default useDefaultState
