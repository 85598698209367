import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { Avatar } from 'packages/eid-ui'

const nameStyle = {
    fontSize: '14px',
    color: '#5d6870',
}

const Person = ({ imgSrc, name }) => (
    <Box display="flex" alignItems="center">
        <Box component="span" paddingRight="8px">
            <Avatar src={imgSrc} />
        </Box>
        <Typography style={nameStyle}>{name}</Typography>
    </Box>
)

export default Person
