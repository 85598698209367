import React from 'react'
import {
    styled,
    Box,
    Typography,
    InputBase,
    withStyles,
} from '@material-ui/core'
import { Icon } from 'packages/eid-icons'
import { NativeDatePicker } from 'packages/eid-ui/DateTimePickers'
import { isNilOrEmpty } from 'packages/core'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

const iconContainer = {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
}

const clearContainer = {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
}
const Container = styled(Box)({
    height: '38px',
    width: '100%',
    fontSize: '14px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 0px 0px 15px',
    boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1)',
    position: 'relative',
})
const StyledBox = styled(Box)({
    border: 'solid 1px #ebebed',
    backgroundColor: '#fff',
    borderRadius: '5px',
})

const StyledInput = withStyles((theme) => ({
    root: {
        width: '100% !important',
        borderRadius: '5px',
        marginRight: '12px',
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        position: 'relative',
        boxShadow: 'none',
        border: 'none',
        fontSize: 16,
        color: '#5d6870',
    },

    focused: {
        backgroundColor: theme.palette.common.white,
    },
}))(InputBase)

const DateTimePicker = ({
    label,
    showLabelWithin,
    value,
    placeholder,
    onChange,
    minDate,
    disablePast,
    disabled,
    onClick,
    handleClear,
    boxStyle,
    maxDate,
    disableFuture,
    ...rest
}) => {
    const { t } = useTranslation()
    const InputComponent = () => {
        return (
            <StyledBox {...boxStyle}>
                <NativeDatePicker
                    handleChange={(e) => {
                        if (isNilOrEmpty(e)) {
                            handleClear()
                        } else {
                            const date = moment(e)
                            onChange(date)
                        }
                    }}
                    value={value?.local().format('YYYY-MM-DD')}
                    minDate={
                        minDate
                            ? minDate.format('YYYY-MM-DD')
                            : disablePast
                                ? moment(new Date()).format('YYYY-MM-DD')
                                : ''
                    }
                    disabled={disabled}
                >
                    <Container className="Input-Container">

                        <Box>
                            {label && (
                                <Typography
                                    style={{
                                        color: '#b4b4b4',
                                        fontSize: '10px',
                                        fontWeight: 'bold',
                                        textTransform: 'uppercase',
                                        minWidth: '60px',
                                        marginRight: '12px',
                                    }}
                                >
                                    {label}
                                </Typography>
                            )}
                        </Box>


                        <StyledInput
                            value={
                                value
                                    ? moment(value)
                                        .clone()
                                        .local()
                                        .format('D MMMM YYYY')
                                    : ''
                            }
                            placeholder={placeholder}
                        />
                        <Box style={iconContainer} paddingRight="15px">
                            <Icon name="Date" color="#D8D8DD" />
                        </Box>
                    </Container>
                </NativeDatePicker>

            </StyledBox >
        )
    }

    const InputWithLabel = () => {
        return (
            <StyledBox {...boxStyle}>
                <Container
                    padding="0px 5px !important"
                    boxShadow="none !important"
                    className="Input-Container"
                >
                    <Box style={iconContainer} padding="0 5px">
                        <Icon name="Calendar" color="#959598" />
                        <Typography
                            style={{
                                color: '#b4b4b4',
                                fontSize: '10px',
                                textTransform: 'uppercase',
                                padding: '10px 10px',
                            }}
                        >
                            {label}
                        </Typography>
                    </Box>
                    <NativeDatePicker
                        handleChange={(e) => {
                            if (isNilOrEmpty(e)) {
                                handleClear()
                            } else {
                                const date = moment(e)
                                onChange(date)
                            }
                        }}
                        value={value?.local().format('YYYY-MM-DD')}
                        minDate={
                            minDate
                                ? minDate.format('YYYY-MM-DD')
                                : disablePast
                                    ? moment(new Date()).format('YYYY-MM-DD')
                                    : ''
                        }
                        maxDate={
                            maxDate
                                ? maxDate.format('YYYY-MM-DD')
                                : disableFuture
                                    ? moment(new Date()).format('YYYY-MM-DD')
                                    : ''
                        }
                        disabled={disabled}
                    >
                        <StyledInput
                            value={
                                value
                                    ? moment(value)
                                        .clone()
                                        .local()
                                        .format('D MMMM YYYY')
                                    : ''
                            }
                            placeholder={placeholder}
                        />
                    </NativeDatePicker>

                    {!isNilOrEmpty(value) && (
                        <Box
                            style={clearContainer}
                            padding="0 12px 0 0"
                            onClick={(e) => {
                                e.stopPropagation()
                                handleClear()
                            }}
                        >
                            <Icon name="Close" color="#959598" />
                        </Box>
                    )}
                </Container>
            </StyledBox>
        )
    }

    return <>{showLabelWithin ? <InputComponent /> : <InputWithLabel />}</>
}

export default DateTimePicker
