import { Box, styled } from '@material-ui/core'
import { ItemDetails } from 'components'
import ComputersPagination from 'components/ComputerSessionRequest/Pagination'
import { useComputerLoginSessionHistoryDetails, useTargetPerson } from 'hooks'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ComputerSessionHistoryFilters from './Filters'
import ComputerSessionHistoryTile from './SessionHistoryTile'

const PaddedDiv = styled(Box)({
    padding: '0 31px',
})

export interface ComputerSessionHistoryProps {
    item: any
}

export const ComputerSessionHistory: FC<ComputerSessionHistoryProps> = (props) => {
    const { item } = props
    const { t } = useTranslation()
    const [targetPerson] = useTargetPerson()
    const [isActiveConnectionOnly, setIsActiveConnectionOnly] = useState<boolean>(false)
    const [startDate, setStartDate] = useState<any>(null)
    const [endDate, setEndDate] = useState<any>(null)
    const [queryParms, setQueryParams] = useState<string>("")
    const [page, setPage] = useState(1)
    const defaultTake = 10
    const handlePageChange = (_: any, value: any) => {
        setPage(value)
    }
    const skip = (page - 1) * defaultTake
    const take = defaultTake
    const { data: loginHistorySessionDetails, isLoading } = useComputerLoginSessionHistoryDetails(queryParms)
    const numberOfPages = loginHistorySessionDetails ? Math.ceil(loginHistorySessionDetails.totalCount / take) : 0

    useEffect(() => {
        let queryParams = `?ShowActiveConnections=${isActiveConnectionOnly ? isActiveConnectionOnly : ''}&skip=${skip}&take=${take}`;
        if (startDate) {
            queryParams = queryParams + `&StartDateAfter=${startDate}`
        }
        if (endDate) {
            queryParams = queryParams + `&EndDateBefore=${endDate}`
        }
        if (item.id) {
            queryParams = queryParams + `&ComputerId=${item.id}`
        }
        if (targetPerson.id) {
            queryParams = queryParams + `&TargetPersonId=${targetPerson.id}`
        }

        setQueryParams(queryParams)
    }, [isActiveConnectionOnly, startDate, endDate, page])

    return (
        <ItemDetails.AttributesContainer>
            <PaddedDiv>
                <ComputerSessionHistoryFilters setEndDate={(e: string) => setEndDate(e)} setStartDate={(e: string) => setStartDate(e)} endDate={endDate} startDate={startDate} isActiveConnectionOnly={isActiveConnectionOnly} handleCheckedClick={(e: React.ChangeEvent<HTMLInputElement>) => setIsActiveConnectionOnly(e.target.checked)} />
            </PaddedDiv>
            {isLoading && <PaddedDiv><ItemDetails.AttributesLoader /></PaddedDiv>}
            {loginHistorySessionDetails?.data?.length ? <>
                {loginHistorySessionDetails.data.map((sesReq: any, index: number) => (
                    <ComputerSessionHistoryTile parent={item} key={`session-request-${index}`} item={sesReq}></ComputerSessionHistoryTile>
                ))}
                <ComputersPagination page={page} handlePageChange={handlePageChange} pageCount={numberOfPages} />
            </> : null}
            {!isLoading && loginHistorySessionDetails && loginHistorySessionDetails.data && loginHistorySessionDetails.data.length <= 0 && <PaddedDiv><p>{t('Common_NoItemsFound', {
                itemType: t('Common_SessionHistory'),
            })}</p></PaddedDiv>}
        </ItemDetails.AttributesContainer>
    )
}

export default ComputerSessionHistory
