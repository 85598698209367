import { Box } from '@material-ui/core'
import {
    useAccessRequestPolicy,
    useAddItemToCart,
    useTargetPerson,
} from 'hooks'
import { EidButton } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import { useTranslation } from 'react-i18next'
import cartHelpers from 'containers/Cart/cartHelpers'
import moment from 'moment'

const ExtendButton = ({ data }) => {
    const item = data?.resource
    const assignment = data?.assignment
    const { t } = useTranslation()
    const [addItemToCart] = useAddItemToCart()
    const [targetPerson] = useTargetPerson()
    const accessRequestPolicy = useAccessRequestPolicy(item?.requestPolicyId)

    const handleExtension = (item) => {
        const itemToAdd = cartHelpers.sharedCredentialToCartItem({
            targetPerson,
            assignmentType: 'Add',
            credential: item,
            timeConstrained: true,
            startDateUtc: assignment?.assignmentDetails?.endDateUtc,
            endDateUtc: moment(assignment?.assignmentDetails?.endDateUtc).add(
                accessRequestPolicy?.defaultValueInMinutes,
                'm',
            ),
            ...item,
        })

        addItemToCart(itemToAdd)
    }

    return (
        <Box style={{ height: '24px', width: '70px' }}>
            <EidButton.ExtendListing onClick={() => handleExtension(item)}>
                <span style={{ fontSize: '11px' }}>{t('Common_Extend')}</span>
                <Icon
                    name="ArrowSmall"
                    fill="white"
                    color="white"
                    direction="right"
                    style={{ marginLeft: '5px' }}
                />
            </EidButton.ExtendListing>
        </Box>
    )
}

export default ExtendButton
