import { Layout, ScrollToTopButton } from 'packages/eid-ui'
import ITShopHeader from './ITShopHeader'
import ITShopGlobalMenu from './ITShopGlobalMenu'
import ScrollToTop from 'react-scroll-up'
import config from 'config'
import { useQuery } from 'packages/core'
import { useTranslation } from 'react-i18next'

const styles = {
    rootStyles: {
        position: 'relative',
        paddingBottom: '30px',
    },
    scrollButton: {
        bottom: 20,
        right: 20,
        zIndex: 5,
    },
}

const ITShopLayout = (props) => {
    const { t } = useTranslation()
    const { children, subHeader } = props

    const query = useQuery()
    const showFilters = query.get('filters') === 'visible'
    const showScrollToTopButton = config.SHOW_SCROLL_TO_TOP && !showFilters

    const scrollToTopComponent = (
        <ScrollToTop showUnder={160} style={styles.scrollButton}>
            <ScrollToTopButton size="medium" label={t('Common_BackToTop')} />
        </ScrollToTop>
    )
    return (
        <Layout
            header={<ITShopHeader />}
            globalMenu={
                <div style={{ marginTop: '24px' }}>
                    <ITShopGlobalMenu />
                </div>
            }
            children={children}
            scrollToTopComponent={showScrollToTopButton && scrollToTopComponent}
            rootStyles={showScrollToTopButton && styles.rootStyles}
            subHeader={subHeader}
        />
    )
}

export default ITShopLayout
