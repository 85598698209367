import React, { useState } from 'react'

/**
 * Slices a list down to only show a subset of items,
 * so UI can show a "Show All" button to load all of those.
 * @param {*} list An array of items
 * @param {*} defaultShowCount Items count to show initially. Default value is 2.
 * @param {*} fallback Fallback to render when no items are available. Defaults to a dash (-).
 */
const useTrimmedDownList = (list, defaultShowCount = 2, fallback = <>-</>) => {
    const [count, setCount] = useState(
        list.length > defaultShowCount ? defaultShowCount : list.length,
    )

    const showAll = () => setCount(list.length)

    const showLess = () => setCount(list.length > defaultShowCount ? defaultShowCount : list.length)

    const data = list.length === 0 ? fallback : list.slice(0, count)

    return { data, showAll, showLess, remainingItemsCount: list.length - count }
}

export default useTrimmedDownList
