import { FC } from 'react'
import { useIsSmallScreen, useQuery } from 'packages/core'
import { Layout } from 'components'
import { useApplications } from 'hooks'
import { useResourceTypeContext } from 'resourceTypeContext'
import { DesktopVersion } from './DesktopVersion'
import { MobileVersion } from './MobileVersion'
import { useViewIndicator } from 'packages/eid-ui'
import { Box, styled } from '@material-ui/core'
import { useHistory } from 'react-router'
import SubHeader from 'components/SubHeader'
import useConfiguration from 'useConfiguration'

const subHeaderHeight = 70

const SubHeaderContainer = styled(Box)({
    position: 'fixed',
    top: 70,
    width: '100%',
    maxWidth: '1920px',

    display: 'flex',

    paddingTop: '10px',
    paddingRight: '20px',
    paddingLeft: '90px',

    backgroundColor: '#eef0f4',

    zIndex: 5,
})

export const ApplicationsListing: FC<any> = () => {
    const [{ sorting: sortQuery }, dispatch]: any = useResourceTypeContext()

    const setSortQuery = (newSortQuery: any) => {
        dispatch({
            type: 'SET_PROP',
            payload: {
                key: 'sorting',
                value: newSortQuery,
            },
        })
    }

    const {
        totalCount,
        loading,
        list,
        loadingMore,
        infiniteScrollObervableDiv,
    }: any = useApplications()

    const isSmallScreen = useIsSmallScreen()
    const query = useQuery()

    const { resourceTypes } = useConfiguration()

    const history = useHistory()

    const {
        location: { pathname },
    } = history

    const { view, viewIndicator } = useViewIndicator({
        view: query.get('view'),
        onCardViewClick: () => {
            query.set('view', 'card')
            history.push(`${history.location.pathname}?${query.toString()}`)
        },
        onListViewClick: () => {
            query.delete('view')
            history.push(`${history.location.pathname}?${query.toString()}`)
        },
    })

    const applicationId = query.get('applicationId')

    const showBackButton = query.get('workflow')
    return (
        <Layout
            subHeader={
                !isSmallScreen && (
                    <SubHeaderContainer>
                        <SubHeader
                            totalCount={totalCount}
                            viewSwitcher={viewIndicator}
                            resourceTypes={resourceTypes}
                            showNavTabs={!applicationId}
                            showWorkFlows={true}
                            showBackButton={showBackButton}
                            currentResourceType={{
                                name: 'Applications',
                                title: 'Common_Applications',
                                route: '/applications',
                            }}
                        />
                    </SubHeaderContainer>
                )
            }
        >
            {!isSmallScreen ? (
                <DesktopVersion
                    totalCount={totalCount}
                    loading={loading}
                    loadingMore={loadingMore}
                    list={list}
                    infiniteScrollObervableDiv={infiniteScrollObervableDiv}
                    onSort={(sortBy: any, sortOrder: any) => {
                        setSortQuery({ sortBy: sortBy.columnName && sortBy.columnName.length > 0 ? sortBy.columnName : sortBy.name, sortOrder })
                    }}
                    sortOrder={sortQuery.sortOrder}
                    sortBy={sortQuery.sortBy}
                    currentResourceType={{
                        name: 'Applications',
                        title: 'Common_Applications',
                        route: '/applications',
                    }}
                />
            ) : (
                <MobileVersion
                    totalCount={totalCount}
                    loading={loading}
                    loadingMore={loadingMore}
                    list={list}
                    infiniteScrollObervableDiv={infiniteScrollObervableDiv}
                    onSort={(sortBy: any, sortOrder: any) => {
                        setSortQuery({ sortBy: sortBy.columnName && sortBy.columnName.length > 0 ? sortBy.columnName : sortBy.name, sortOrder })
                    }}
                    sortOrder={sortQuery.sortOrder}
                    sortBy={sortQuery.sortBy}
                />
            )}
        </Layout>
    )
}
