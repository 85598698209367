import React, { useState, useRef, useEffect, createRef } from 'react'
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableSortLabel,
    makeStyles,
    Box,
} from '@material-ui/core'
import Button from './Button'
import clsx from 'clsx'
import { Loader, Tooltip } from 'packages/eid-ui'
import TextFormatter from '../ManageAccess/TextFormatter'
import { useTranslation } from 'react-i18next'
import { getUniqueId } from '../ManageAccess/resourceTypeHelpers'
import DynamicComponents from 'components/DynamicComponents'
import { useRouteMatch } from 'react-router-dom'
import { MasterPasswordLock } from 'components'
import { useContainerDimensions } from 'hooks'
import { useAppState } from 'appContext'
import { CredentialsActionsButton } from 'components/CredentialsActions/CredentialsActionsButton'
import { getMasterLockFieldsWidth, getMasterLockLeftSpace } from 'utils'

const colors = {
    primary: '#307fc1',
    grey: '#7d7c7c',
}
const maxCharacters = 80

const tooltipStyles = {
    boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
    padding: '8px',
    maxWidth: '500px',
}

var getValue = (ci, a) => {
    let value

    switch (a.name) {
        case 'differentiationValue':
            value = ci.assignment.resourceAssignment.locationFriendlyName
            break
        case 'accessLevel':
            value = ci.assignment.resourceAssignment.friendlyName
            break
        case 'assignee':
            value = ci.assignment.resourceAssignment.assigneePreviewName
            break
        default:
            value = ci.resource[a.name]
    }

    return value ?? ''
}

const useStyles = makeStyles({
    headGrey: {
        color: '#7d7c7c !important',
    },

    blue: {
        color: colors.primary,
    },
    boldBlue: {
        color: colors.primary,
        fontWeight: 'bold',
    },
    underline: {
        textDecoration: 'underline',
    },

    table: {
        backgroundColor: '#ffffff',
        '& tr:hover': {
            backgroundColor: '#f7f8fa',
        },
        position: 'relative',
    },
    tableRow: {
        border: ' solid 1px #efeff1',
    },
    highlightedRow: {
        border: ' solid 1px #01ae8f',
        boxShadow: '4px 4px 15px 0 rgba(48, 127, 193, 0.15)',
    },

    tableCell: {
        maxWidth: '180px',
        overflow: 'clip',
        padding: '15px 6px',
        fontSize: '13px',
        borderBottom: '0',
        '&:first-child': {
            padding: '0px 20px !important',
        },
    },
    tableCellHighlighted: {
        borderTop: ' solid 1px #01ae8f',
        padding: '15px 6px',
        fontSize: '13px',
        borderBottom: '0',
        '&:first-child': {
            padding: '15px 20px',
        },
    },
    hilightFont: {
        fontSize: '15px',
    },
    tableHeaderCell: {
        maxWidth: '180px',
        overflowWrap: 'break-word',
        wordBreak: 'normal',
        overflow: 'clip',
        lineHeight: '15px',
        fontSize: '13px !important',
        backgroundColor: '#fbfbfd',
        '& svg': {
            margin: '0px !important',
        },
    },
    tableActionCell: {
        padding: '15px 6px',
        display: 'flex',
        borderBottom: '0',
        '& > button:nth-child(2)': {
            marginLeft: '12px',
        },
    },
    rowSelect: {
        borderBottom: '1px solid grey',
        borderRadius: '0',
    },
    tablePagination: {
        color: 'grey !important',
    },

    bold1: {
        color: '#000000',
        fontSize: '15px',
        fontWeight: 'bold',
    },

    tableSortLabel: {
        color: '#7d7c7c !important',
        fill: '#7d7c7c !important',

        '& svg': {
            color: `#D2D2D9 !important`,
            marginLeft: '4px !important',
        },

        '&:hover': {
            color: `${colors.primary} !important`,

            '& svg': {
                color: `${colors.primary} !important`,
            },
        },
    },
    activeTableSortLabel: {
        '& svg': {
            color: `${colors.primary} !important`,
        },
    },
})

const renderComponent = (attribute, data) => {
    const DynamicComponentToRender = DynamicComponents[attribute.component.name]

    return <DynamicComponentToRender attribute={attribute} data={data} />
}

const TableView = ({
    data,
    attributes,
    onSort,
    loading,
    fallbackMessage,
    onItemClick,
    currentResourceType,
    sortBy,
    sortOrder,
}) => {
    const { t } = useTranslation()
    const headings = attributes
        .filter((rt) => !rt.hideInManageAccess)
        .map((a) => ({
            ...a,
            label: t(a.label),
            sort: a.sortable ? a.sortable : false,
        }))

    const toolTipValue = (items) => {
        const newItems = []
        items.map((item) => newItems.push(item.friendlyName))
        return newItems.join(`, `)
    }

    const handleSort = (data) => {
        const sortingProperty =
            data.manageAccessColumnName &&
            data.manageAccessColumnName.length > 0
                ? data.manageAccessColumnName
                : data.name
        onSort(
            data,
            sortBy !== sortingProperty
                ? 'desc'
                : !sortOrder || sortOrder === '' || sortOrder === 'asc'
                ? 'desc'
                : 'asc',
        )
    }

    const classes = useStyles({})

    const isManageAccessScreen = useRouteMatch({
        path: '/credentials/manageAccess',
        exact: true,
    })

    // State and logic to bind master lock on columns Start
    const [{ masterPassword }] = useAppState()

    const [masterPasswordWidths, setMasterPasswordWidths] = useState({
        leftSpace: '',
        maxWidth: '',
    })

    const tableHeadingRefs = useRef(
        headings?.length > 0 ? headings.map(() => createRef()) : null,
    )

    const getCorespondingRef = (name) => {
        const itemIndex = headings.findIndex((x) => x.name === name)
        return itemIndex >= 0 ? tableHeadingRefs.current[itemIndex] : null
    }

    const handleResize = () => {
        setMasterPasswordWidths({
            leftSpace: getMasterLockLeftSpace(headings, tableHeadingRefs),
            maxWidth: getMasterLockFieldsWidth(headings, tableHeadingRefs),
        })
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize)
        handleResize()
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    useEffect(() => {
        if (data) {
            handleResize()
        }
    }, [data])

    const masterPasswordLockStyle = {
        height: 'calc(100% - 50px)',
        width: '100%',
        maxWidth: `${masterPasswordWidths.maxWidth}px`,
        left: `${masterPasswordWidths.leftSpace}px`,
    }

    // State and logic to bind master lock on columns End

    return (
        <>
            <Table id="identities_list_table" className={classes.table}>
                <TableHead>
                    <TableRow className={classes.tableRow}>
                        {headings.map((heading, index) => {
                            const sortingProperty =
                                heading.manageAccessColumnName &&
                                heading.manageAccessColumnName.length > 0
                                    ? heading.manageAccessColumnName
                                    : heading.name
                            return (
                                <TableCell
                                    key={'pd-head-cell-' + index}
                                    className={clsx(
                                        classes.tableHeaderCell,
                                        classes.tableCell,
                                        classes.headGrey,
                                    )}
                                    ref={getCorespondingRef(heading.name)}
                                    align={heading.align}
                                    style={heading.headerCellStyles}
                                >
                                    {!heading.sort ? (
                                        <span style={{ display: 'flex' }}>
                                            {heading.label}
                                        </span>
                                    ) : (
                                        <span style={{ display: 'flex' }}>
                                            <Tooltip
                                                title={
                                                    sortBy ===
                                                        sortingProperty &&
                                                    sortOrder === 'desc'
                                                        ? t(
                                                              'Common_SortAscending',
                                                          )
                                                        : t(
                                                              'Common_SortDescending',
                                                          )
                                                }
                                            >
                                                <TableSortLabel
                                                    active={heading.sort}
                                                    direction={
                                                        sortBy ===
                                                        sortingProperty
                                                            ? sortOrder
                                                            : 'asc'
                                                    }
                                                    className={
                                                        sortBy !==
                                                        sortingProperty
                                                            ? classes.tableSortLabel
                                                            : clsx(
                                                                  classes.tableSortLabel,
                                                                  classes.activeTableSortLabel,
                                                              )
                                                    }
                                                    style={{
                                                        color:
                                                            sortBy ===
                                                                sortingProperty &&
                                                            'yellow !important',
                                                    }}
                                                    onClick={() =>
                                                        handleSort(heading)
                                                    }
                                                >
                                                    {heading.label}
                                                </TableSortLabel>
                                            </Tooltip>
                                        </span>
                                    )}
                                </TableCell>
                            )
                        })}
                        <TableCell
                            className={clsx(
                                classes.tableHeaderCell,
                                classes.tableCell,
                                classes.headGrey,
                            )}
                        ></TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {loading && (
                        <TableRow>
                            <TableCell
                                colSpan={headings.length + 1}
                                style={{ textAlign: 'center' }}
                            >
                                <Loader />
                            </TableCell>
                        </TableRow>
                    )}
                    {data &&
                        (data.length === 0 ? (
                            <TableRow className={classes.tableRow}>
                                <TableCell
                                    colSpan={headings.length + 1}
                                    style={{ textAlign: 'center' }}
                                >
                                    {fallbackMessage}
                                </TableCell>
                            </TableRow>
                        ) : (
                            <>
                                {!masterPassword && isManageAccessScreen && (
                                    <MasterPasswordLock
                                        style={masterPasswordLockStyle}
                                    />
                                )}
                                {data.map((ci, index) => {
                                    return (
                                        <TableRow
                                            key={`${getUniqueId(ci)}-${index}`}
                                            className={classes.tableRow}
                                        >
                                            {attributes
                                                .filter(
                                                    (rt) =>
                                                        !rt.hideInManageAccess,
                                                )
                                                .map((a) => {
                                                    let wrappedCellContent

                                                    if (a.component) {
                                                        wrappedCellContent = renderComponent(
                                                            a,
                                                            ci,
                                                        )
                                                    } else if (
                                                        a.type !== 'array'
                                                    ) {
                                                        const cellContent = (
                                                            <Box
                                                                maxHeight="200px"
                                                                display="block"
                                                                maxWidth="200px"
                                                                overflow="auto"
                                                                style={{
                                                                    display:
                                                                        'block',
                                                                    wordBreak:
                                                                        'break-word',
                                                                    wordWrap:
                                                                        'break-word',
                                                                }}
                                                            >
                                                                {getValue(ci, a)
                                                                    .length >
                                                                maxCharacters
                                                                    ? `${getValue(
                                                                          ci,
                                                                          a,
                                                                      ).substring(
                                                                          0,
                                                                          maxCharacters,
                                                                      )}...`
                                                                    : getValue(
                                                                          ci,
                                                                          a,
                                                                      )}
                                                            </Box>
                                                        )
                                                        wrappedCellContent =
                                                            getValue(ci, a)
                                                                .length >
                                                            maxCharacters ? (
                                                                <Tooltip
                                                                    title={getValue(
                                                                        ci,
                                                                        a,
                                                                    )}
                                                                    color="#5D6870"
                                                                    fontColor="#ffffff"
                                                                    enterDelay={
                                                                        500
                                                                    }
                                                                    enterNextDelay={
                                                                        500
                                                                    }
                                                                    interactive={
                                                                        true
                                                                    }
                                                                    tooltipStyles={
                                                                        tooltipStyles
                                                                    }
                                                                >
                                                                    {
                                                                        cellContent
                                                                    }
                                                                </Tooltip>
                                                            ) : (
                                                                cellContent
                                                            )
                                                    } else {
                                                        wrappedCellContent = (
                                                            <TextFormatter
                                                                style={{
                                                                    textDecoration:
                                                                        'underline',
                                                                }}
                                                                value={toolTipValue(
                                                                    getValue(
                                                                        ci,
                                                                        a,
                                                                    ),
                                                                )}
                                                            />
                                                        )
                                                    }
                                                    return (
                                                        <TableCell
                                                            key={`${ci.resource.id}${a.name}`}
                                                            className={
                                                                classes.tableCell
                                                            }
                                                            style={{
                                                                padding:
                                                                    a.component
                                                                        ?.name ===
                                                                    'CredentialsLockedAttribute'
                                                                        ? 0
                                                                        : '15px 6px',
                                                            }}
                                                        >
                                                            {wrappedCellContent}
                                                        </TableCell>
                                                    )
                                                })}

                                            <TableCell
                                                className={
                                                    classes.tableActionCell
                                                }
                                                style={{
                                                    display: 'flex',

                                                    justifyContent: 'flex-end',
                                                }}
                                            >
                                                <Button
                                                    color="#56BEAC"
                                                    onClick={() => {
                                                        onItemClick(ci)
                                                    }}
                                                    label={'Details'}
                                                />

                                                {isManageAccessScreen && (
                                                    <CredentialsActionsButton
                                                        item={ci.resource}
                                                    />
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </>
                        ))}
                </TableBody>
            </Table>
        </>
    )
}

export default TableView
