import React from 'react'
import { ItemDetails } from 'components'
import { useIsSmallScreen } from 'packages/core'
import { useTranslation } from 'react-i18next'
import { Box, styled } from '@material-ui/core'
import { GlobalSensitiveFunctions } from './GlobalSensitiveFunctions'
import { CommonAttributes } from '../../CommonAttributes'

const PaddedDiv = styled(Box)({
    padding: '0 31px',
})

export const OverviewSection = ({ azureAdminRole }) => {
    const { t } = useTranslation()

    const attributeOrientation = useIsSmallScreen() ? 'vertical' : 'horizontal'

    return (
        <ItemDetails.AttributesContainer bottomPadding="0">
            <PaddedDiv>
                <ItemDetails.Attribute
                    label={t('Common_SystemType')}
                    value={azureAdminRole.resourceSystemTypeFriendlyName}
                    orientation={attributeOrientation}
                />
                <ItemDetails.Attribute
                    label={t('Common_RoleType')}
                    value={azureAdminRole.roleTypeFriendlyName}
                    orientation={attributeOrientation}
                />

                {azureAdminRole.resourceSystemFriendlyName && (
                    <ItemDetails.Attribute
                        label={t('Common_Tenant')}
                        value={azureAdminRole.resourceSystemFriendlyName}
                        orientation={attributeOrientation}
                    />
                )}

                <ItemDetails.Attribute
                    label={t('Common_Description')}
                    value={azureAdminRole.description}
                    orientation={attributeOrientation}
                />
                <CommonAttributes resource={azureAdminRole} />
            </PaddedDiv>

            <GlobalSensitiveFunctions azureAdminRole={azureAdminRole} />
        </ItemDetails.AttributesContainer>
    )
}
