import { directAssignedOnlyFilterColors } from './colors'

const filters = [
    {
        forRequestAccess: true,
        forManageAccess: true,
        name: 'ShoppingFor',
        requireAccess: {
            control: 'ITShop-ShopForTargetPerson-Control',
        },
        order: 1,
    },
    {
        forManageAccess: true,
        dynamic: true,
        name: 'ShowOnlyDirectAssigned',
        title: 'Common_ShowDirectAssignedOnly',
        requireAccess: {
            control: 'ITShop-DirectAssignedAzureLisences-Control',
        },
        order: 2,
        colorCode: directAssignedOnlyFilterColors,
        label: 'Common_ShowDirectAssignedOnly',
        type: 'checkbox',
        contextProp: 'showDirectAssignedOnly',
        trueValueIndicator: 'Common_ShowDirectAssignedOnly',
        queryParam: {
            name: 'showDirectAssignedOnly',
        },
    },
    {
        forRequestAccess: true,
        dynamic: true,
        name: 'AzureLicensePool',
        title: 'AzureLicenses_AzureLicensePool',
        requireAccess: {
            control: 'ITShop-AzureLicensePool-Control',
        },
        order: 2,
        colorCode: {
            primary: '#01745f',
            secondary: '#e6f1ef',
        },
        type: 'autocomplete',
        label: 'AzureLicenses_AzureLicensePool',
        placeholder: 'AzureLicenses_SelectAzureLicensePool',
        optionConfig: {
            type: 'labelWithSubLabel',
            labelProp: 'friendlyName',
            subLabelProp: 'description',
        },
        url: '/api/azureLicenses/licensePools',
        queryParams: {
            take: 25,
        },
        debounceInterval: 500,
        dedupingInterval: 1800000,
        contextProp: 'azureLicensePool',
        queryParam: {
            name: 'licensePoolId',
            valueProp: 'id',
        },
    },
    {
        forRequestAccess: true,
        dynamic: true,
        name: 'AzureTenant',
        title: 'AzureLicenses_AzureTenant',
        requireAccess: {
            control: 'ITShop-AzureLicencesTenants-Control',
        },
        order: 3,
        colorCode: {
            primary: '#dfba00',
            secondary: '#fdfae5',
        },
        type: 'autocomplete',
        label: 'AzureLicenses_AzureTenant',
        placeholder: 'AzureLicenses_SelectAzureTenant',
        optionConfig: {
            type: 'label',
            labelProp: 'friendlyName',
        },
        url: '/api/azureLicenses/tenants',
        queryParams: {
            take: 25,
        },
        debounceInterval: 500,
        dedupingInterval: 1800000,
        contextProp: 'azureTenant',
        queryParam: {
            name: 'tenantId',
            valueProp: 'id',
        },
    },
    {
        forRequestAccess: true,
        dynamic: true,
        name: 'AzureTenantSubscription',
        title: 'AzureLicenses_AzureTenantSubscription',
        requireAccess: {
            control: 'ITShop-AzureSubscription-Control',
        },
        order: 4,
        colorCode: {
            primary: 'rgb(249, 64, 0)',
            secondary: '#ffffff',
        },
        type: 'autocomplete',
        label: 'AzureLicenses_AzureTenantSubscription',
        placeholder: 'AzureLicenses_SelectAzureTenantSubscription',
        optionConfig: {
            type: 'labelWithSubLabel',
            labelProp: 'friendlyName',
            subLabelProp: 'description',
        },
        url: '/api/azureLicenses/tenantSubscriptions',
        queryParams: {
            take: 25,
        },
        basedOn: {
            type: 'optional',
            contextProp: 'azureTenant',
            valueProp: 'id',
            queryParamName: 'tenantId',
        },
        debounceInterval: 500,
        dedupingInterval: 1800000,
        contextProp: 'azureTenantSubscription',
        queryParam: {
            name: 'tenantSubscriptionId',
            valueProp: 'id',
        },
    },
    {
        forRequestAccess: true,
        dynamic: true,
        name: 'AdvancedSearch',
        title: 'Common_AdvancedSearch',
        requireAccess: {
            control: 'ITShop-AzureLicensesAdvancedSearch-Control',
        },
        order: 5,
        icon: {
            name: 'AdvancedSearch',
            color: '#959598',
        },
        type: 'advancedSearch',
        forms: {
            title: 'Common_Forms',
            colorCode: {
                primary: '#2920ad',
                secondary: '#ebeaf7',
            },
            filters: [
                {
                    name: 'FriendlyName',
                    type: 'text',
                    placeholder: 'Common_FriendlyName',
                    contextProp: 'advancedSearch.forms.friendlyName',
                    queryParam: {
                        name: 'friendlyName',
                        type: 'string',
                    },
                },
                {
                    name: 'LicenseGroup',
                    type: 'text',
                    placeholder: 'AzureLicenses_LicenseGroup',
                    contextProp: 'advancedSearch.forms.licenseGroup',
                    queryParam: {
                        name: 'licensedAssignee_FriendlyName',
                        type: 'string',
                    },
                },
                {
                    name: 'Description',
                    type: 'text',
                    placeholder: 'Common_Description',
                    contextProp: 'advancedSearch.forms.description',
                    queryParam: {
                        name: 'description',
                        type: 'string',
                    },
                },
            ],
        },
        tags: {
            title: 'Common_Tags',
            colorCode: {
                primary: '#f81983',
                secondary: '#fee8f2',
            },
            contextProp: 'advancedSearch.tags',
        },
    },
]

const attributes = [
    //Add columnName property as sorting property for Request Access and manageAccessColumnName property for Manage Access if sorting can't be done on name property
    {
        name: 'friendlyName',
        label: 'Common_FriendlyName',
        isHeader: true,
        sortable: true,
        style: { fontWeight: 'bold', color: '#282828' },
        moreInfoIcon: { hideInManageAccess: true },
    },
    {
        name: 'name',
        label: 'AzureLicenses_LicensePoolSubBundle',
        sortable: true,
        requireAccess: {
            control: 'ITShop-AzureLicensesNameAttribute-Control',
        },
    },
    {
        name: 'resourceSystemFriendlyName',
        label: 'AzureLicenses_Tenant',
        sortable: true,
        requireAccess: {
            control: 'ITShop-AzureLicensesResourceSystemAttribute-Control',
        },
    },
    {
        name: 'azureLicensePool_FriendlyName',
        label: 'AzureLicenses_LicensePool',
        sortable: true,
        requireAccess: {
            control: 'ITShop-AzureLicensesLicensePoolAttribute-Control',
        },
    },
    {
        name: 'azureTenantSubscription_FriendlyName',
        label: 'AzureLicenses_TenantSubscription',
        sortable: true,
        requireAccess: {
            control: 'ITShop-AzureLicensesTenantSubscriptionAttribute-Control',
        },
    },
    {
        name: 'licensedAssignee_FriendlyName',
        label: 'AzureLicenses_LicenseGroup',
        sortable: true,
        requireAccess: {
            control: 'ITShop-AzureLicensesLicensedAssigneeAttribute-Control',
        },
    },
    {
        name: 'pricePerUnit',
        label: 'AzureLicenses_PricePerUnit',
        sortable: true,
        requireAccess: {
            control: 'ITShop-AzureLicensesPricePerUnitAttribute-Control',
        },
    },
    {
        name: 'description',
        label: 'Common_Description',
        sortable: true,
    },
]

const azureLicensesConfig = {
    name: 'AzureLicenses',
    resourceTypeName: 'AzureLicense',
    title: 'Common_AzureLicenses',
    route: '/azureLicenses',
    requireAccess: {
        page: 'ITShop-AzureLicenses-Page',
    },
    order: 4,
    icon: {
        small: 'AzureLicensesSmall',
        large: 'AzureLicensesLarge',
    },
    dataSource: {
        type: 'MS_DS',
        url: '/api/azureLicenses',
    },
    showTimeConstrainedFilter: false,
    showDirectAssignedOnlyFilter: true,
    workflows: [],
    filters: filters,
    attributes: attributes,
}

export default azureLicensesConfig
