import { Box, makeStyles, Grid, Card } from '@material-ui/core'

import moment from 'moment'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useAppState } from 'appContext'
import { PasswordModal } from 'components/PasswordModal'

const useCardStyles = makeStyles({
    root: () => ({
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: '16px',
        position: 'relative',
        overflow: 'hidden',
        boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.05) !important',
        border: '1px solid #00000036 !important',
        borderRadius: '8px !important',
    }),
    lineGlow: {
        width: '100%',
        height: '1px',
        opacity: 0.25,
        borderStyle: 'solid',
        borderWidth: '1px',
        borderImageSource:
            'linear-gradient(to right, rgba(1, 174, 143, 0), #04ab93 7%, #2c83bd 91%, rgba(48, 127, 193, 0))',
        borderImageSlice: 1,
    },
})
const useStyles = makeStyles({
    requestmaincontainer: {
        borderRadius: '8px',
        boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.05)',
        border: 'solid 1px rgba(0, 0, 0, 0.11)',
        background: '#fff',
        marginTop: '16px',
        padding: '0px 16px',
        minWidth: '200px',
    },
    requestitemtitle: {
        fontSize: '12px',
        fontWeight: 'bold',
        color: '#9b9b9b',
        height: '16px',
        marginBottom: '1px',
        textTransform: 'uppercase',
    },
    requestitemheading: {
        fontSize: '14px',
        fontWeight: 500,
        color: '#307fc1',
        margin: '0px',
    },
    requestitemtime: {
        fontSize: '14px',
        fontWeight: 500,
        color: '#000',
        margin: '0px',
    },
})

export interface CredentialCheckoutTileProps {
    item: any
    parent: any
}

export const CredentialCheckoutTile: FC<CredentialCheckoutTileProps> = (
    props,
) => {
    const { item } = props
    const [{ masterPassword }]: any = useAppState()
    const [open, setOpen] = useState('')
    const classes = useStyles()
    const cardClasses = useCardStyles()
    const { t } = useTranslation()

    return (
        <>
            {!masterPassword && (
                <PasswordModal
                    showDefaultContent={false}
                    open={open}
                    setOpen={setOpen}
                />
            )}

            <Grid item md={4}>
                <Card classes={cardClasses}>
                    <Box component="h2" className={classes.requestitemtitle}>
                        {t('Common_BusinessRequest')}
                    </Box>
                    <Box component="h2" className={classes.requestitemheading}>
                        {item.businessRequestName
                            ? item.businessRequestName
                            : '-'}
                    </Box>

                    <Box component="h2" className={classes.requestitemtitle}>
                        {t('Common_BusinessRequestItem')}
                    </Box>
                    <Box component="h2" className={classes.requestitemheading}>
                        {item.businessRequestItemName
                            ? item.businessRequestItemName
                            : '-'}
                    </Box>

                    <Box component="h2" className={classes.requestitemtitle}>
                        {t('Common_RequestedBy')}
                    </Box>
                    <Box component="h2" className={classes.requestitemtime}>
                        {item.requestorFriendlyName
                            ? item.requestorFriendlyName
                            : '-'}
                    </Box>

                    <Box component="h2" className={classes.requestitemtitle}>
                        {t('Common_StartTime')}
                    </Box>
                    <Box component="h2" className={classes.requestitemtime}>
                        {item.startTimeUtc
                            ? moment
                                  .utc(item.startTimeUtc)
                                  .local()
                                  .format('L LT')
                            : '-'}
                    </Box>

                    <Box component="h2" className={classes.requestitemtitle}>
                        {t('Common_EndTime')}
                    </Box>
                    <Box component="h2" className={classes.requestitemtime}>
                        {item.endTimeUtc
                            ? moment.utc(item.endTimeUtc).local().format('L LT')
                            : '-'}
                    </Box>

                    <Box component="h2" className={classes.requestitemtitle}>
                        {t('Common_ActualCheckOutTime')}
                        <Box
                            component="h2"
                            className={classes.requestitemtime}
                        ></Box>
                        {item.actualCheckedOutTimeUtc
                            ? moment
                                  .utc(item.actualCheckedOutTimeUtc)
                                  .local()
                                  .format('L LT')
                            : '-'}
                    </Box>
                </Card>
                <div className={cardClasses.lineGlow}></div>
            </Grid>
        </>
    )
}

export default CredentialCheckoutTile
