import { useQuery, usePaginatedQuery } from 'react-query'
import { useAxios } from 'packages/core'

export const BUSINESS_ROLE_KEY_PREFIX = 'BUSINESS_ROLE'

export const useBusinessDomains = (targetPersonId) => {
    const callApi = useAxios()
    return useQuery(
        `${BUSINESS_ROLE_KEY_PREFIX}_DOMAINS_${targetPersonId}`,
        () =>
            callApi({
                method: 'GET',
                url: `/api/businessRoles/businessDomains?targetPersonId=${targetPersonId}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(targetPersonId),
            staleTime: 1800000,
        },
    )
}

export const useBusinessRole = (id) => {
    const callApi = useAxios()
    return useQuery(
        [BUSINESS_ROLE_KEY_PREFIX, id],
        () =>
            callApi({
                method: 'GET',
                url: `/api/businessRoles/${id}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id),
        },
    )
}

export const useCheckBusinessRoleAccess = (
    id,
    differentiationValueId,
    targetPersonId,
) => {
    const callApi = useAxios()
    return useQuery(
        [
            `${BUSINESS_ROLE_KEY_PREFIX}_ACCESS`,
            id,
            differentiationValueId,
            targetPersonId,
        ],
        () =>
            callApi({
                method: 'GET',
                url: `/api/businessRoles/checkAssigmentStatus/${id}/${differentiationValueId}/${targetPersonId}`,
            }).then((data) => data.data[0]),
        {
            enabled:
                Boolean(id) &&
                Boolean(differentiationValueId) &&
                Boolean(targetPersonId),
        },
    )
}

export const useBusinessRoleOwners = (id) => {
    const callApi = useAxios()
    return useQuery(
        [`${BUSINESS_ROLE_KEY_PREFIX}_OWNERS`, id],
        () =>
            callApi({
                method: 'GET',
                url: `/api/businessRoles/owners?roleId=${id}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id),
        },
    )
}

export const useBusinessRoleApprovers = (id, differentiationValueId) => {
    const callApi = useAxios()
    return useQuery(
        [`${BUSINESS_ROLE_KEY_PREFIX}_APPROVERS`, id, differentiationValueId],
        () =>
            callApi({
                method: 'GET',
                url: `/api/businessRoles/approvers?roleId=${id}&differentiationValueId=${differentiationValueId}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id) && Boolean(differentiationValueId),
        },
    )
}

export const useBusinessRoleGlobalSenstiveFunctions = (
    id,
    skip,
    take = 10,
    search,
) => {
    const callApi = useAxios()
    return usePaginatedQuery(
        [
            `${BUSINESS_ROLE_KEY_PREFIX}_GLOBAL_SENSITVE_FUNCTIONS`,
            id,
            skip,
            take,
            search,
        ],
        () =>
            callApi({
                method: 'GET',
                url:
                    `/api/businessRoles/globalFunctions?roleId=${id}&skip=${skip}&take=${take}` +
                    (search ? `&searchTerm=${search}` : ''),
            }),
        {
            enabled: Boolean(id),
        },
    )
}

export const useBusinessRoleDifferentiationValues = (
    id,
    targetPersonId,
    referencePersonId,
    showPreApproved,
    showSuggested,
) => {
    const callApi = useAxios()
    let apiUrl = `/api/businessRoles/differentiationValues/${id}/${targetPersonId}`
    let isParamAdded = false
    if (referencePersonId) {
        apiUrl += `?referencePersonId=${referencePersonId}`
        isParamAdded = true
    }
    if (showPreApproved) {
        if (isParamAdded) {
            apiUrl += `&showPreApproved=true`
        } else {
            apiUrl += `?showPreApproved=true`
            isParamAdded = true
        }
    }
    if (showSuggested) {
        if (isParamAdded) {
            apiUrl += `&showSuggested=true`
        } else {
            apiUrl += `?showSuggested=true`
        }
    }
    return useQuery(
        [
            `${BUSINESS_ROLE_KEY_PREFIX}_DIFFERENTIATION_VALUES`,
            id,
            targetPersonId,
            referencePersonId,
        ],
        () =>
            callApi({
                method: 'GET',
                url: apiUrl,
            }).then((data) => data.data),
        {
            enabled: Boolean(id) && Boolean(targetPersonId),
        },
    )
}

export const useBusinessRoleApplicationRolesGranted = (
    id,
    differentiationValueId,
    skip,
    take = 10,
    search,
) => {
    const callApi = useAxios()
    return usePaginatedQuery(
        [
            `${BUSINESS_ROLE_KEY_PREFIX}_APPLICATION_ROLES_GRANTED`,
            id,
            differentiationValueId,
            skip,
            take,
            search,
        ],
        () =>
            callApi({
                method: 'GET',
                url:
                    `/api/businessRoles/applicationRolesGranted/${id}/${differentiationValueId}?skip=${skip}&take=${take}` +
                    (search ? `&searchTerm=${search}` : ''),
            }),
        {
            enabled: Boolean(id) && Boolean(differentiationValueId),
        },
    )
}

export const useBusinessRoleManagementRolesGranted = (
    id,
    differentiationValueId,
    skip,
    take = 10,
    search,
) => {
    const callApi = useAxios()
    return usePaginatedQuery(
        [
            `${BUSINESS_ROLE_KEY_PREFIX}_MANAGEMENT_ROLES_GRANTED`,
            id,
            differentiationValueId,
            skip,
            take,
            search,
        ],
        () =>
            callApi({
                method: 'GET',
                url:
                    `/api/businessRoles/managementRolesGranted/${id}/${differentiationValueId}?skip=${skip}&take=${take}` +
                    (search ? `&searchTerm=${search}` : ''),
            }),
        {
            enabled: Boolean(id) && Boolean(differentiationValueId),
        },
    )
}

export const useBusinessRoleLocalSensitiveFunctions = (
    id,
    differentiationValueId,
    skip,
    take = 10,
    search,
) => {
    const callApi = useAxios()
    return usePaginatedQuery(
        [
            `${BUSINESS_ROLE_KEY_PREFIX}_LOCAL_SENSITIVE_FUNCTIONS`,
            id,
            differentiationValueId,
            skip,
            take,
            search,
        ],
        () =>
            callApi({
                method: 'GET',
                url:
                    `/api/businessFunctions/localFunctions?roleId=${id}&differentiationValueId=${differentiationValueId}&skip=${skip}&take=${take}` +
                    (search ? `&searchTerm=${search}` : ''),
            }),
        {
            enabled: Boolean(id) && Boolean(differentiationValueId),
        },
    )
}

export const useBusinessRoleSuggestedApplicationRoles = (
    id,
    targetPersonId,
    referencePersonId,
    skip,
    take = 10,
    search,
    differentiationValueId,
) => {
    const callApi = useAxios()

    const queryParams = [`skip=${skip}`, `take=${take}`]
    if (search) {
        queryParams.push(`searchTerm=${search}`)
    }
    if (referencePersonId) {
        queryParams.push(`referencePersonId=${referencePersonId}`)
    }

    const url =
        `/api/businessRoles/applicationRolesSuggested/${id}/${differentiationValueId}/${targetPersonId}` +
        `?${queryParams.join('&')}`

    return usePaginatedQuery(
        [
            `${BUSINESS_ROLE_KEY_PREFIX}_SUGGESTED_APPLICATION_ROLES`,
            id,
            differentiationValueId,
            targetPersonId,
            referencePersonId,
            skip,
            take,
            search,
        ],
        () =>
            callApi({
                method: 'GET',
                url,
            }),
        {
            enabled:
                Boolean(id) &&
                Boolean(differentiationValueId) &&
                Boolean(targetPersonId),
        },
    )
}

export const useBusinessRoleSuggestedManagementRoles = (
    id,
    targetPersonId,
    referencePersonId,
    skip,
    take = 10,
    search,
    differentiationValueId,
) => {
    const callApi = useAxios()

    const queryParams = [`skip=${skip}`, `take=${take}`]
    if (search) {
        queryParams.push(`searchTerm=${search}`)
    }
    if (referencePersonId) {
        queryParams.push(`referencePersonId=${referencePersonId}`)
    }

    const url =
        `/api/managementRoles/suggestedByRoleId/${id}/${targetPersonId}/${differentiationValueId}` +
        `?${queryParams.join('&')}`

    return usePaginatedQuery(
        [
            `${BUSINESS_ROLE_KEY_PREFIX}_SUGGESTED_MANAGEMENT_ROLES`,
            id,
            differentiationValueId,
            targetPersonId,
            referencePersonId,
            skip,
            take,
            search,
        ],
        () =>
            callApi({
                method: 'GET',
                url,
            }),
        {
            enabled:
                Boolean(id) &&
                Boolean(differentiationValueId) &&
                Boolean(targetPersonId),
        },
    )
}

export const useBusinessRoleApplications = (
    id,
    skip,
    take = 10,
    search,
    differentiationValueId,
) => {
    const callApi = useAxios()

    return usePaginatedQuery(
        [
            `${BUSINESS_ROLE_KEY_PREFIX}_APPLICATIONS`,
            id,
            differentiationValueId,
            skip,
            take,
            search,
        ],
        () =>
            callApi({
                method: 'GET',
                url:
                    `/api/ProtectedAppResources/linkedApplications?businessRoleId=${id}&locationId=${differentiationValueId}&skip=${skip}&take=${take}` +
                    (search ? `&searchTerm=${search}` : ''),
            }),
        {
            enabled: Boolean(id) && Boolean(differentiationValueId),
        },
    )
}

export const useBusinessRoleBr = (
    targetPersonId,
    resourceAssignmentId,
    resourceTypeId,
) => {
    const callApi = useAxios()
    return useQuery(
        [
            BUSINESS_ROLE_KEY_PREFIX,
            'Business_Request',
            targetPersonId,
            resourceAssignmentId,
            resourceTypeId,
        ],
        () =>
            callApi({
                method: 'GET',
                url: `/api/businessRoles/businessRequestItemAssignmentDetails?targetPersonId=${targetPersonId}&resourceAssignmentId=${resourceAssignmentId}&resourceTypeId=${resourceTypeId}`,
            }).then((data) => data.data),
        {
            enabled:
                Boolean(targetPersonId) &&
                Boolean(resourceAssignmentId) &&
                Boolean(resourceTypeId),
        },
    )
}
