import {
    directAssignedOnlyFilterColors,
    timeConstrainedOnlyFilterColors,
} from './colors'

const filters = [
    {
        forManageAccess: true,
        forRequestAccess: true,
        name: 'ShoppingFor',
        requireAccess: {
            control: 'ITShop-ShopForTargetPerson-Control',
        },
        order: 1,
    },
    {
        forManageAccess: true,
        dynamic: true,
        name: 'ShowOnlyDirectAssigned',
        title: 'Common_ShowDirectAssignedOnly',
        requireAccess: {
            control: 'ITShop-DirectAssignedApplications-Control',
        },
        order: 2,
        colorCode: directAssignedOnlyFilterColors,
        label: 'Common_ShowDirectAssignedOnly',
        type: 'checkbox',
        contextProp: 'showDirectAssignedOnly',
        trueValueIndicator: 'Common_ShowDirectAssignedOnly',
        queryParam: {
            name: 'showDirectAssignedOnly',
        },
    },
    {
        forManageAccess: true,
        dynamic: true,
        name: 'ShowOnlyTimeConstrained',
        title: 'ManageAccess_ShowTimeConstrained',
        requireAccess: {
            control: 'ITShop-TimeConstrainedApplications-Control',
        },
        order: 2,
        colorCode: timeConstrainedOnlyFilterColors,
        label: 'ManageAccess_ShowTimeConstrained',
        type: 'checkbox',
        contextProp: 'showTimeConstrained',
        trueValueIndicator: 'ManageAccess_ShowTimeConstrained',
        queryParam: {
            name: 'showTimeConstrained',
        },
    },
    {
        dynamic: true,
        name: 'UNCPath',
        title: 'Applications_UncPath',
        requireAccess: { control: 'ITShop-ApplicationsUncPath-Control' },
        order: 2,
        icon: {
            name: 'Applications',
            color: '#959598',
        },
        colorCode: {
            primary: '#307fc1',
            secondary: 'rgba(48, 127, 193, 0.09)',
        },
        type: 'autocomplete',
        primitiveType: true,
        label: 'Applications_UncPath',
        placeholder: 'Applications_SelectUncPath',
        optionConfig: {
            type: 'label',
            labelProp: 'value',
        },
        url: '/api/Applications/uncPaths',
        minCharactersReqForSearch: 0,
        queryParams: {
            take: 25,
        },
        debounceInterval: 500,
        dedupingInterval: 1800000,
        contextProp: 'uncPath',
        queryParam: {
            name: 'uncPath',
            valueProp: 'value',
        },
        basedOn: {
            contextType: 'global',
            type: 'required',
            contextProp: 'targetPerson',
            valueProp: 'id',
            queryParamName: 'targetPersonId',
        },
    },

    {
        dynamic: true,
        name: 'AdvancedSearch',
        title: 'Common_AdvancedSearch',
        requireAccess: {
            control: 'ITShop-ApplicationsAdvancedSearch-Control',
        },
        order: 3,
        icon: {
            name: 'AdvancedSearch',
            color: '#959598',
        },
        type: 'advancedSearch',
        forms: {
            title: 'Common_Forms',
            colorCode: {
                primary: '#2920ad',
                secondary: '#ebeaf7',
            },
            filters: [
                {
                    name: 'ShareName',
                    type: 'text',
                    placeholder: 'Applications_ShareName',
                    contextProp: 'advancedSearch.forms.shareName',
                    queryParam: {
                        name: 'shareName',
                        type: 'string',
                    },
                },
                {
                    name: 'Computer',
                    type: 'text',
                    placeholder: 'Applications_Computer',
                    contextProp: 'advancedSearch.forms.computer',
                    queryParam: {
                        name: 'Computer',
                        type: 'string',
                    },
                },
                {
                    name: 'DnsHostName',
                    type: 'text',
                    placeholder: 'Applications_DnsHostName',
                    contextProp: 'advancedSearch.forms.dnsHostName',
                    queryParam: {
                        name: 'DnsHostName',
                        type: 'string',
                    },
                },

                {
                    name: 'Description',
                    type: 'text',
                    placeholder: 'Common_Description',
                    contextProp: 'advancedSearch.forms.description',
                    queryParam: {
                        name: 'Description',
                        type: 'string',
                    },
                },
            ],
        },
        tags: {
            title: 'Common_Tags',
            colorCode: {
                primary: '#f81983',
                secondary: '#fee8f2',
            },
            contextProp: 'advancedSearch.tags',
        },
    },
]

const attributes = [
    {
        name: 'applicationLogoImageUrl',
        component: 'applicationImage',
        hideInManageAccess: true,
    },
    {
        name: 'friendlyName',
        label: 'Common_FriendlyName',
        isHeader: true,
        sortable: true,
    },

    {
        name: 'name',
        label: 'Common_TechnicalName',
        sortable: true,
    },
    {
        name: 'description',
        label: 'Common_Description',
        sortable: true,
    },
    {
        name: 'accessLevel',
        manageAccessOnlyAttribute: true,
        label: 'Common_AccessLevel',
    },
    {
        name: 'assignee',
        label: 'Common_AssignedVia',
        manageAccessOnlyAttribute: true,
    },
    {
        label: 'Common_CurrentAccessEnds',
        sortable: false,
        manageAccessOnlyAttribute: true,
        component: { name: 'EndDateManageAccess' },
    },
]

const applicationsConfig = {
    name: 'Applications',
    resourceTypeName: 'ProtectedApplicationResource',
    title: 'Common_Applications',
    route: '/applications',
    requireAccess: {
        page: 'ITShop-Applications-Page',
    },
    order: 1,
    icon: {
        small: 'ApplicationsSmall',
        large: 'ApplicationsLarge',
    },

    dataSource: {
        type: 'MS_DS',
        url: '/api/ProtectedAppResources',
    },
    showTimeConstrainedFilter: true,
    showDirectAssignedOnlyFilter: true,
    workflows: [],
    filters: filters,

    attributes: attributes,
}

export default applicationsConfig
