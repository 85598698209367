import { useState } from 'react'
import { Box, styled } from '@material-ui/core'
import { Loader } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'
import { useCart, useMailboxBr, useMailboxOwners, useTargetPerson } from 'hooks'
import { ItemDetails, PeopleList } from 'components'
import { useIsSmallScreen } from 'packages/core'
import cartHelpers from 'containers/Cart/cartHelpers'
import { ResourceTypesNamespace, useRegistry } from 'core'
import { RenewRevoke } from 'components/RenewRevoke'
import { CommonAttributes } from '../CommonAttributes'
import { Skeleton } from '@material-ui/lab'
import { useHistory } from 'react-router'

const PaddedDiv = styled(Box)({
    padding: '0 31px',
})

const overflowStyleProps = {
    maxHeight: '220px',
    overflow: 'auto',
}

const OverviewSection = ({ item }) => {
    const mailbox = item.resource
    const { t } = useTranslation()

    const [targetPerson] = useTargetPerson()

    const { data: businessRequestItem, isLoading } = useMailboxBr(
        targetPerson.id,
        item.assignment.resourceAssignment.resourceId,
        item.assignment.resourceAssignment.resourceTypeId,
        item.assignment.resourceAssignment.resourceAssignmentId,
    )

    const attributeOrientation = useIsSmallScreen() ? 'vertical' : 'horizontal'

    return (
        <ItemDetails.AttributesContainer>
            <PaddedDiv>
                <ItemDetails.Attribute
                    label={t('Common_DisplayName')}
                    value={mailbox.friendlyName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('Common_Name')}
                    value={mailbox.name}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('Common_AccessLevel')}
                    value={item.assignment.resourceAssignment.friendlyName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('Common_Alias')}
                    value={mailbox.alias}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('Common_PrimaryEmail')}
                    value={mailbox.primaryEmailAddress}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                {mailbox.mailboxTypeName && (
                    <ItemDetails.Attribute
                        label={t('Common_MailboxType')}
                        value={mailbox.mailboxTypeName}
                        orientation={attributeOrientation}
                        valueProps={overflowStyleProps}
                    />
                )}
                <ItemDetails.Attribute
                    label={t('Common_ResourceSystem')}
                    value={mailbox.resourceSystemFriendlyName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('Common_Description')}
                    value={mailbox.description}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                {isLoading && <Skeleton height={32} />}
                <CommonAttributes
                    item={item}
                    businessRequestItem={businessRequestItem}
                />
            </PaddedDiv>
        </ItemDetails.AttributesContainer>
    )
}

const AdditionalInformationSection = ({ item }) => {
    const mailbox = item.resource
    const { t } = useTranslation()

    const attributeOrientation = useIsSmallScreen() ? 'vertical' : 'horizontal'

    const { data: owners, isLoading: ownersLoading } = useMailboxOwners(
        mailbox.id,
    )

    return (
        <>
            {ownersLoading ? (
                <ItemDetails.AttributesContainer>
                    <PaddedDiv>
                        <ItemDetails.AttributesLoader />
                    </PaddedDiv>
                </ItemDetails.AttributesContainer>
            ) : (
                <ItemDetails.AttributesContainer bottomPadding="0">
                    <PaddedDiv>
                        <ItemDetails.Attribute
                            label={t('Common_Owners')}
                            value={<PeopleList list={owners} />}
                            orientation={attributeOrientation}
                        />
                    </PaddedDiv>
                </ItemDetails.AttributesContainer>
            )}
        </>
    )
}

const MailboxDetails = ({ item, toggleDrawer }) => {
    const { t } = useTranslation()
    const [targetPerson] = useTargetPerson()

    const registry = useRegistry()

    const history = useHistory()
    const isManageAccess =
        history.location.pathname.indexOf('/manageAccess') >= 0

    const mailboxType = registry.get(ResourceTypesNamespace, 'Mailboxes')

    const [activeTab, setActiveTab] = useState('overview')

    const preRevoke = () => {
        const itemToRevoke = cartHelpers.mailboxToCartItem({
            targetPerson,
            assignmentType: 'Remove',
            mailbox: item.resource,
            assignmentStatus: item.assignment,
        })
        if (!itemToRevoke) return undefined

        return itemToRevoke
    }

    const { data: cart } = useCart()

    if (!cart) return <Loader />

    return (
        <>
            <ItemDetails.Tabs
                variant="standard"
                value={activeTab}
                onChange={(_, value) => setActiveTab(value)}
            >
                <ItemDetails.Tabs.Tab
                    value="overview"
                    label={t('Common_Overview')}
                />

                <ItemDetails.Tabs.Tab
                    value="additionalInformation"
                    label={t('Common_MoreInformation')}
                />
            </ItemDetails.Tabs>

            {activeTab === 'overview' && <OverviewSection item={item} />}

            {activeTab === 'additionalInformation' && (
                <AdditionalInformationSection item={item} />
            )}

            {isManageAccess && (
                <RenewRevoke
                    resourceType={mailboxType}
                    resource={item.resource}
                    assignment={item.assignment}
                    postRenew={toggleDrawer}
                    preRevoke={preRevoke}
                    postRevoke={toggleDrawer}
                    item={item}
                />
            )}
        </>
    )
}

export default MailboxDetails
