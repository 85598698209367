import { Box, styled } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { ItemDetails } from 'components'
import { useIsSmallScreen } from 'packages/core'
import { CommonAttributes } from '../CommonAttributes'

const overflowStyleProps = {
    maxHeight: '220px',
    overflow: 'auto',
}

const PaddedDiv = styled(Box)({
    padding: '0 31px',
})

export const OverviewSection = ({ mailbox }: any) => {
    const { t } = useTranslation()

    const attributeOrientation = useIsSmallScreen() ? 'vertical' : 'horizontal'

    return (
        <ItemDetails.AttributesContainer>
            <PaddedDiv>
                <ItemDetails.Attribute
                    label={t('Common_DisplayName')}
                    value={mailbox.friendlyName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('Common_Name')}
                    value={mailbox.name}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('Common_Alias')}
                    value={mailbox.alias}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('Common_PrimaryEmail')}
                    value={mailbox.primaryEmailAddress}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                {mailbox.mailboxTypeName && (
                    <ItemDetails.Attribute
                        label={t('Common_MailboxType')}
                        value={mailbox.mailboxTypeName}
                        orientation={attributeOrientation}
                        valueProps={overflowStyleProps}
                    />
                )}

                <ItemDetails.Attribute
                    label={t('Common_ResourceSystem')}
                    value={mailbox.resourceSystemFriendlyName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('Common_Description')}
                    value={mailbox.description}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <CommonAttributes resource={mailbox} />
            </PaddedDiv>
        </ItemDetails.AttributesContainer>
    )
}
