import React, { useEffect } from 'react'
import { Box } from '@material-ui/core'
import { isNilOrEmpty, useIsSmallScreen } from 'packages/core'
import { Card, ExpansionPanel } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import AdvancedFilter from './AdvancedFilter'
import GenericFilter from './GenericFilter'
import { useResourceTypeContext } from 'resourceTypeContext'
import { useTranslation } from 'react-i18next'
import { useDefaultFilters } from 'hooks'

const EidFilter = ({ title, colorCode, icon, type, resourceType, ...rest }) => {
    const name = rest.name
    const { t } = useTranslation()

    const { data: onBoardingData } = useDefaultFilters(resourceType)

    const isSmallScreen = useIsSmallScreen()
    const [state, dispatch] = useResourceTypeContext()

    useEffect(() => {
        let payloadToDispatch = {}
        if (onBoardingData.tCode && name === 'TCode') {
            payloadToDispatch.tCode = onBoardingData.tCode
        }
        if (
            onBoardingData.showSuggestedApplicationRoles &&
            name == 'SuggestApplicationRoles'
        ) {
            payloadToDispatch.showSuggested =
                onBoardingData.showSuggestedApplicationRoles
        }
        if (onBoardingData.referencePerson) {
            payloadToDispatch.referencePerson = onBoardingData.referencePerson
        }

        if (onBoardingData.advanced) {
            if (onBoardingData.advanced['advancedSearch@forms@friendlyName']) {
                payloadToDispatch['advancedSearch.forms.friendlyName'] =
                    onBoardingData.advanced['advancedSearch@forms@friendlyName']
            }

            if (onBoardingData.advanced['advancedSearch@forms@technicalName']) {
                payloadToDispatch['advancedSearch.forms.technicalName'] =
                    onBoardingData.advanced[
                        'advancedSearch@forms@technicalName'
                    ]
            }

            if (onBoardingData.advanced['advancedSearch@forms@owner']) {
                payloadToDispatch['advancedSearch.forms.owner'] =
                    onBoardingData.advanced['advancedSearch@forms@owner']
            }

            if (
                onBoardingData.advanced[
                    'advancedSearch@forms@highLevelClassification'
                ]
            ) {
                payloadToDispatch[
                    'advancedSearch.forms.highLevelClassification'
                ] =
                    onBoardingData.advanced[
                        'advancedSearch@forms@highLevelClassification'
                    ]
            }

            if (onBoardingData.advanced['advancedSearch@forms@description']) {
                payloadToDispatch['advancedSearch.forms.description'] =
                    onBoardingData.advanced['advancedSearch@forms@description']
            }

            if (
                onBoardingData.advanced['advancedSearch@tags'] &&
                onBoardingData.advanced['advancedSearch@tags'].length > 0
            ) {
                payloadToDispatch['advancedSearch.tags'] =
                    onBoardingData.advanced['advancedSearch@tags']
            }
        }

        if (Object.keys(payloadToDispatch).length > 0) {
            dispatch({
                type: 'SET_MULTIPLE_PROPS',
                payload: payloadToDispatch,
            })
        }
    }, [onBoardingData, name])

    if (isNilOrEmpty(type)) return <></>

    const renderFilter = () => {
        switch (type) {
            case 'advancedSearch':
                return <AdvancedFilter resourceType={resourceType} {...rest} />
            default:
                return (
                    <GenericFilter
                        resourceType={resourceType}
                        type={type}
                        {...rest}
                    />
                )
        }
    }

    let expanded = false
    if (rest.contextProp) {
        if (
            !isNilOrEmpty(state[rest.contextProp]) &&
            state[rest.contextProp] !== false
        ) {
            expanded = true
        }
        if (type === 'tree' && onBoardingData.selectedResourceLocationId) {
            expanded = true
        }
    }

    return isSmallScreen ? (
        <ExpansionPanel expanded={expanded} title={t(title)}>
            <Box padding="20px 20px 20px 20px ">{renderFilter()}</Box>
        </ExpansionPanel>
    ) : (
        <Card
            cardTitle={t(title)}
            colorCode={colorCode?.primary}
            expanded={expanded}
            collapsible
            icon={
                icon && icon.name === 'AdvancedSearch' ? ( //Not removing icons from configs because it would be difficult to find icons again
                    <Icon name={icon.name} color={icon.color} />
                ) : undefined
            }
        >
            <Box padding="20px 20px 0px 20px ">{renderFilter()}</Box>
        </Card>
    )
}

export default EidFilter
