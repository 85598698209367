import { FC, Fragment } from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { ApplicationCard } from 'components/ApplicationCard'
import { isNilOrEmpty, useGetControlsAccess, useQuery } from 'packages/core'
import { useHistory } from 'react-router'
import { ShowOnlyAzureApplications } from 'components'
import { Loader, useViewIndicator, WorkflowIFrame } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'
import { EidWorkflowButton } from 'components/EidWorkflowButton'
import config from 'config'
import { CardList } from 'packages/eid-controls'
import { TableView } from './TableView'
import { attributes } from './attributes'
import { subHeaderHeight } from 'utils'
import { Card } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import { useApplication } from 'hooks'
import classNames from 'classnames'
import useSubcomponents from 'useSubcomponents'
import ReactHtmlParser from 'react-html-parser'
import DomPurify from 'dompurify'
import SideDrawer from 'containers/ListingPage/SideDrawer'
import Selectors from 'containers/Selectors'
import { useAppState } from 'appContext'

const useStyles = makeStyles(
    createStyles({
        root: {
            flexGrow: 1,
            marginTop: `${subHeaderHeight + 20}px`,
        },
        page: {
            flexGrow: 1,
            display: 'flex',
        },
        secondaryGrid: {
            display: 'flex',
            flexDirection: 'column',
            flex: '27%',
            maxWidth: '370px',
        },
        hideSecondaryGrid: {
            display: 'none',
        },
        applicationBanner: {
            marginTop: '16px',
        },
        rootGrid: {
            flexGrow: 1,
            display: 'flex',
            marginTop: '16px',
        },
        primaryGrid: {
            flex: '73%',
            display: 'flex',
            flexWrap: 'wrap',
            height: '100%',
        },
        primaryGridFullWidth: {
            flex: '100%',
        },
        filterContainer: {
            padding: '8px',
        },
        filterBar: {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            padding: '8px',
        },
        appliedFilters: {
            minWidth: '355px',
            width: '100%',
        },
        listingContainer: {
            display: 'flex',
            width: '100%',
            padding: '8px',
        },
        cardGrid: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            padding: '8px',
            width: '100%',
        },
        cardContainer: {
            width: '31%',
            margin: '8px',
        },
        tableContainer: {
            padding: '8px',
            flex: '1',
            width: '100%',
        },
        gridItem: {
            marginRight: '16px',
        },
    }),
)

export interface IDesktopVersionProps {
    totalCount?: any
    loading?: any
    list?: any
    loadingMore?: any
    infiniteScrollObervableDiv?: any
    sortBy?: any
    sortOrder?: any
    onSort?: any
    currentResourceType?: any
}

export const DesktopVersion: FC<IDesktopVersionProps> = (props) => {
    const {
        loading,
        list,
        loadingMore,
        infiniteScrollObervableDiv,
        sortBy,
        sortOrder,
        onSort,
        currentResourceType,
    } = props

    const { t } = useTranslation()
    const classes = useStyles()
    const query = useQuery()
    const history = useHistory()
    const controls = useGetControlsAccess()

    const hasAccessToCreateAzureApplicationWorkflow =
        controls.findIndex(
            (c: any) =>
                c.name === 'ITShop-CreateAzureApplicationWorkflow-Control',
        ) >= 0
    const hasAccessToParsedHtmlMoreInfo =
        controls.findIndex(
            (c: any) => c.name === 'ITShop-ParsedHtmlMoreInformation-Control',
        ) >= 0
    const { canSeeShowOnlyAzureApplications } = useSubcomponents()
    const [{ appConfig }]: any = useAppState()
    const { staticWorkflows }: any = appConfig
    const onboardAzureApplicationWF =
        staticWorkflows && staticWorkflows.length > 0
            ? staticWorkflows.filter(
                  (w: any) => w.name === 'OnboardAzureApplication',
              )
            : []
    const applicationId: string | null = query.get('selected')
    const { view } = useViewIndicator({
        view: query.get('view'),
        onCardViewClick: () => {
            query.set('view', 'card')
            history.push(`${history.location.pathname}?${query.toString()}`)
        },
        onListViewClick: () => {
            query.delete('view')
            history.push(`${history.location.pathname}?${query.toString()}`)
        },
    })

    const applicationCardLoader = [0, 1, 2, 3].map((i, index) => (
        <Box height="inline-block" width="48%" overflow="hidden" key={index}>
            <ApplicationCard loading={true} />
        </Box>
    ))

    const workflowName = query.get('workflow')
    const { data: application } = useApplication(applicationId)

    const wfParams = []
    if (application) {
        wfParams.push(`SelectedResourceID=${application.resourceID}`)
    }

    const workflowIFrameHeight = isNilOrEmpty(applicationId) ? '82vh' : '62vh'

    const parsedHtmlMoreInfo = ReactHtmlParser(
        DomPurify.sanitize(t('MoreInfo_Html'), { ADD_ATTR: ['target'] }),
    )
    return (
        <Box className={classes.root}>
            {!workflowName ? (
                <>
                    <Box className={classes.page}>
                        <Box
                            className={classNames(classes.secondaryGrid, {
                                [classes.hideSecondaryGrid]: Boolean(
                                    workflowName,
                                ),
                            })}
                        >
                            {hasAccessToCreateAzureApplicationWorkflow &&
                                onboardAzureApplicationWF.length > 0 && (
                                    <Fragment>
                                        <Box
                                            className={classes.filterContainer}
                                        >
                                            <EidWorkflowButton
                                                title={t(
                                                    onboardAzureApplicationWF[0]
                                                        .displayName_LocaleKey,
                                                )}
                                                name={
                                                    onboardAzureApplicationWF[0]
                                                        .requestWorkflowName
                                                }
                                                path={'/applications'}
                                                disabled={workflowName}
                                            />
                                        </Box>
                                    </Fragment>
                                )}
                            {hasAccessToParsedHtmlMoreInfo && (
                                <Fragment>
                                    <Box className={classes.filterContainer}>
                                        <Card
                                            cardTitle={t(
                                                'Common_More_Information',
                                            )}
                                            icon={
                                                <Icon
                                                    name="AdvancedSearch"
                                                    color="#959598"
                                                />
                                            }
                                            collapsible
                                            expanded
                                            disabled={applicationId}
                                        >
                                            <Box
                                                padding="0px 10px"
                                                maxWidth="350px"
                                                overflow="scroll"
                                            >
                                                {parsedHtmlMoreInfo}
                                            </Box>
                                        </Card>
                                    </Box>
                                </Fragment>
                            )}
                            <Selectors
                                resourceType={currentResourceType.name}
                                screen={'RequestAccess'}
                            />
                            {/* {canShopForOthers && (
                                <Box className={classes.filterContainer}>
                                    <ShoppingFor />
                                </Box>
                            )} */}
                            {canSeeShowOnlyAzureApplications && (
                                <Box className={classes.filterContainer}>
                                    <ShowOnlyAzureApplications />
                                </Box>
                            )}
                        </Box>

                        <Box
                            className={classNames(classes.primaryGrid, {
                                [classes.primaryGridFullWidth]: Boolean(
                                    workflowName,
                                ),
                            })}
                        >
                            <Grid container spacing={0}>
                                <Fragment>
                                    {view === 'card' ? (
                                        <CardList
                                            infiniteScrollObervableDiv={
                                                infiniteScrollObervableDiv
                                            }
                                            noItemMessage={
                                                <Typography
                                                    variant="h5"
                                                    style={{
                                                        color: '#b4b4b4',
                                                    }}
                                                >
                                                    {t('Common_NoItemsFound', {
                                                        itemType: t(
                                                            'Common_Applications',
                                                        ),
                                                    })}
                                                </Typography>
                                            }
                                            loadingIndicator={
                                                applicationCardLoader
                                            }
                                            fetcher={() => ({
                                                loading,
                                                loadingMore,
                                                list,
                                            })}
                                            renderItem={(item: any) => (
                                                <Box
                                                    margin="8px"
                                                    width="31%"
                                                    minWidth="250px"
                                                >
                                                    <ApplicationCard
                                                        data={item}
                                                        selectedApplicationId={
                                                            applicationId
                                                        }
                                                    />
                                                </Box>
                                            )}
                                        />
                                    ) : (
                                        <Box className={classes.tableContainer}>
                                            <TableView
                                                data={list}
                                                attributes={attributes}
                                                loading={loading}
                                                onSort={onSort}
                                                sortOrder={sortOrder}
                                                sortBy={sortBy}
                                                fallbackMessage={t(
                                                    'Common_NoItemsFound',
                                                    {
                                                        itemType: t(
                                                            'Common_Applications',
                                                        ),
                                                    },
                                                )}
                                                selectedApplicationId={
                                                    applicationId
                                                }
                                                currentResourceType={
                                                    currentResourceType
                                                }
                                            />

                                            {loadingMore && <Loader />}
                                            {infiniteScrollObervableDiv}
                                        </Box>
                                    )}
                                </Fragment>
                            </Grid>
                        </Box>
                    </Box>
                </>
            ) : (
                <Box minWidth={'100%'}>
                    <WorkflowIFrame
                        baseEidUrl={config.BASE_EID_URL}
                        workflowName={workflowName}
                        onComplete={() => {
                            query.delete('workflow')
                            history.push(
                                `${config.APP_SUBPATH}/applications`,
                                query.toString(),
                            )
                        }}
                        workflowParams={wfParams}
                        height={workflowIFrameHeight}
                    />
                </Box>
            )}

            <SideDrawer
                id={applicationId}
                toggleDrawer={() => {
                    query.delete('selected')
                    history.push(
                        `${config.APP_SUBPATH}/${
                            currentResourceType.name
                        }?${query.toString()}`,
                    )
                }}
                type={currentResourceType.name}
            />
        </Box>
    )
}
