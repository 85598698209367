import React, { useReducer, createContext, useContext } from 'react'
import reducer, { initialState } from './reducer'

export const ResourceTypeContext = createContext({})

export const ResourceTypeContextProvider = ({ children }) => (
    <ResourceTypeContext.Provider value={useReducer(reducer, initialState)}>
        {children}
    </ResourceTypeContext.Provider>
)

export const useResourceTypeContext = () => useContext(ResourceTypeContext)
