import { usePaginatedQuery } from 'react-query'
import { useAxios } from 'packages/core'

const TCODES_KEY_PREFIX = 'TCODES_KEY_PREFIX'

export const useTCodes = (
    resourceId,
    differentiationValueId,
    skip,
    take,
    search,
) => {
    const queryParams = [`skip=${skip}`, `take=${take}`]
    if (search) {
        queryParams.push(`searchTerm=${search}`)
    }
    if (differentiationValueId) {
        queryParams.push(`locationId=${differentiationValueId}`)
    }

    const url =
        `api/localRights/localRightsByAssigneeId/${resourceId}` +
        `?${queryParams.join('&')}`

    const callApi = useAxios()
    return usePaginatedQuery(
        [
            TCODES_KEY_PREFIX,
            resourceId,
            differentiationValueId,
            skip,
            take,
            search,
        ],
        () =>
            callApi({
                method: 'GET',
                url,
            }),
        {
            enabled: Boolean(resourceId),
        },
    )
}
