import React from 'react'
import { Box, Link, Typography } from '@material-ui/core'
import { CloseIcon } from '../../eid-icons'

const SideClosButton = ({ toggleDrawer, width, top, closeLabel }) => {
    return (
        <Box
            height="100%"
            width={width}
            position="fixed"
            right="17px"
            top={top}
            style={{
                backgroundColor: '#fff',
            }}
        >
            <Link
                component="button"
                style={{ height: '100%', width: '100%' }}
                underline="none"
                onClick={() => toggleDrawer(false)}
            >
                <Box
                    height="100%"
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <CloseIcon />
                    <Typography
                        style={{
                            color: '#838383',
                            fontSize: '18px',
                            paddingTop: '5px',
                        }}
                    >
                        {closeLabel}
                    </Typography>
                </Box>
            </Link>
        </Box>
    )
}

export default SideClosButton
