import React from 'react'
import PropTypes from 'prop-types'
import { styled, Box } from '@material-ui/core'
import { Icon } from 'packages/eid-icons'
import { useTranslation } from 'react-i18next'

const StyledDiv = styled('div')(({ colors }) => ({
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '80px',
    height: '21px',
    padding: '4px 8px',
    backgroundColor: colors && colors.backgroundColor,
    color: colors && colors.color,
    border: ` solid 1px ${colors && colors.color}`,
    borderRadius: '5px',
    fontSize: '11px',
    opacity: 0.76,
}))

const NoRiskIcon = styled('div')({
    width: '8px',
    height: '2px',
    backgroundColor: '#029435',
})

const risks = {
    Low: {
        colors: {
            backgroundColor: 'rgb(2, 148,53,0.07)',
            color: '#029435',
        },
        icon: <NoRiskIcon />,
    },
    Medium: {
        colors: {
            backgroundColor: 'rgba(252, 165, 0, 0.07)',
            color: '#fca500',
        },
        icon: <Icon name="LowRisk" color="#fca500" />,
    },
    High: {
        colors: {
            backgroundColor: 'rgba(208, 2, 27, 0.07)',
            color: '#d0021b',
        },
        icon: <Icon name="HighRisk" color="#d0021b" />,
    },
    Critical: {
        colors: {
            backgroundColor: '#d0021b',
            color: '#ffffff',
        },
        icon: <Icon name="CriticalRisk" color="#ffffff" />,
    },
}

const RiskIndicator = ({ variant }) => {
    const { t } = useTranslation()
    const risk = risks[variant]
    return (
        <StyledDiv colors={risk?.colors}>
            <Box display="flex">{risk?.icon}</Box>
            <div>{t(`Common_${variant}Risk`)}</div>
        </StyledDiv>
    )
}

RiskIndicator.propTypes = {
    variant: PropTypes.oneOf(['Low', 'Medium', 'High', 'Critical']),
}

export default RiskIndicator
