import React from 'react'
import { Drawer, Loader } from 'packages/eid-ui'
import ItemHeader from '../ItemHeader'
import { isNilOrEmpty, useIsSmallScreen } from 'packages/core'
import { useManagementRole, useTargetPerson } from 'hooks'
import ItemsDetailsMobileWrapper from 'components/ItemsDetailsMobileWrapper'
import ManagementRoleDetails from './ManagementRoleDetails'

const ManagementRoleDrawer = ({ id, toggleDrawer, attributes }) => {
    const isSmallScreen = useIsSmallScreen()
    const [targetPerson] = useTargetPerson()

    const { data: managementRole } = useManagementRole(id, targetPerson.id)

    return isSmallScreen ? (
        <ItemsDetailsMobileWrapper toggleDrawer={toggleDrawer}>
            <ItemHeader title={managementRole?.localizedFriendlyName} />
            {!managementRole && <Loader />}
            {managementRole && (
                <ManagementRoleDetails
                    toggleDrawer={toggleDrawer}
                    attributes={attributes}
                    managementRole={managementRole}
                />
            )}
        </ItemsDetailsMobileWrapper>
    ) : (
        <Drawer
            width="768px"
            open={!isNilOrEmpty(id)}
            toggleDrawer={toggleDrawer}
            stickyHeader
            header={
                <ItemHeader title={managementRole?.localizedFriendlyName} />
            }
            closeOnOutsideClick
        >
            {!managementRole && <Loader />}
            {managementRole && (
                <ManagementRoleDetails
                    toggleDrawer={toggleDrawer}
                    attributes={attributes}
                    managementRole={managementRole}
                />
            )}
        </Drawer>
    )
}

export default ManagementRoleDrawer
