import React from 'react'
import { Typography, styled } from '@material-ui/core'
import { Loader } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'

const Root = styled('div')({
    height: '100%',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
})

const ContentContainer = styled('div')({
    height: '530px',
    width: '865px',
    backgroundColor: '#fff',
    boxShadow: '0 0 10px 2px rgba(0, 0, 0, 0.06)',
    border: 'solid 1px #d2d2d9',
    position: 'relative',
})

const Header = styled('div')({
    height: '88px',
    width: '100%',
    paddingTop: '53px',
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: 300,
})

const Content = styled('div')({
    height: '354px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
})

const Footer = styled('div')({
    height: '88px',
    paddingTop: '35px',
    '& > p': {
        maxWidth: '431px',
        fontSize: '12px',
        lineHeight: '1.42',
        color: '#7d7c7c',
        textAlign: 'center',
        margin: 'auto',
    },
})

const NavigationButton = styled('div')({
    position: 'absolute',
    top: '30px',
    color: '#848992',
    fontSize: '14px',
    cursor: 'pointer',
    '&:hover': {
        color: '#000',
    },
})

const SkipButton = styled(NavigationButton)(({ label }) => ({
    right: '30px',
    '&::before': {
        content: `"${label}"`,
    },
}))

const BackButton = styled(NavigationButton)(({ label }) => ({
    left: '30px',
    '&::before': {
        content: `"${label}"`,
    },
}))

const DesktopSelectionBox = ({
    icon,
    onBackClick,
    onSkipClick,
    headingText,
    footerText,
    loading,
    children,
    header,
}) => {
    const { t } = useTranslation()
    return (
        <Root>
            <ContentContainer>
                {onBackClick && (
                    <BackButton
                        onClick={onBackClick}
                        label={t('Common_Back')}
                    />
                )}
                {onSkipClick && (
                    <SkipButton
                        onClick={onSkipClick}
                        label={t('OnBoarding_Skip')}
                    />
                )}

                {headingText && <Header>{headingText}</Header>}
                {header && header}

                <Content>
                    {loading && <Loader />}
                    {!loading && children}
                </Content>
                <Footer>
                    <Typography component="p">{footerText}</Typography>
                </Footer>
            </ContentContainer>
        </Root>
    )
}

export default DesktopSelectionBox
