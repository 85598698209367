import { useRef } from 'react'
import { Registry } from './Registry'
import { ResourceTypesNamespace } from './RegistryNamespaces'
import { ApplicationRoleType } from './resourceTypes/ApplicationRoleType'
import { AzureAdminRoleType } from './resourceTypes/AzureAdminRoleType'
import { AzureLicenseType } from './resourceTypes/AzureLicenseType'
import { AzureRbacRoleType } from './resourceTypes/AzureRbacRoleType'
import { BusinessRoleType } from './resourceTypes/BusinessRoleType'
import { ManagementRoleType } from './resourceTypes/ManagementRoleType'
import { SharedFolderType } from './resourceTypes/SharedFolderType'
import { MailboxType } from './resourceTypes/MailboxType'
import { ComputerType } from './resourceTypes/ComputerType'
import { CredentialType } from './resourceTypes/CredentialType'
import { ApplicationsType } from './resourceTypes/ApplicationsType'

/**
 * React hook for using a registry. Only initializes
 * Registry once and on subsequent usages, it returns
 * the same initialized instance.
 */
export const useRegistry: () => Registry = () => {
    const registryRef = useRef<Registry>()

    if (registryRef.current === undefined) {
        const registry = new Registry()

        registry.registerNamespace(ResourceTypesNamespace)

        const applicationRole = new ApplicationRoleType()
        const azureAdminRole = new AzureAdminRoleType()
        const azureLicense = new AzureLicenseType()
        const azureRbacRole = new AzureRbacRoleType()
        const businessRole = new BusinessRoleType()
        const managementRole = new ManagementRoleType()
        const sharedFolder = new SharedFolderType()
        const mailbox = new MailboxType()
        const computer = new ComputerType()
        const credential = new CredentialType()
        const applications = new ApplicationsType()

        registry.register(ResourceTypesNamespace, businessRole)
        registry.register(ResourceTypesNamespace, applicationRole)
        registry.register(ResourceTypesNamespace, azureLicense)
        registry.register(ResourceTypesNamespace, azureAdminRole)
        registry.register(ResourceTypesNamespace, azureRbacRole)
        registry.register(ResourceTypesNamespace, managementRole)
        registry.register(ResourceTypesNamespace, sharedFolder)
        registry.register(ResourceTypesNamespace, mailbox)
        registry.register(ResourceTypesNamespace, computer)
        registry.register(ResourceTypesNamespace, credential)
        registry.register(ResourceTypesNamespace, applications)

        registryRef.current = registry
    }

    return registryRef.current
}
