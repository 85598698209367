import { Box } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { PeopleList } from 'components'
import { useBusinessRoleApprovers } from 'hooks'

export const Approvers = ({ businessRole, differentiationValue }) => {
    const { data: approvers, isLoading } = useBusinessRoleApprovers(
        businessRole.id,
        differentiationValue.id,
    )
    if (isLoading)
        return (
            <Box padding="16px 32px 16px 32px" borderBottom="solid 1px #d8dadd">
                <Box margin="4px 8px">
                    <Skeleton animation="wave" variant="rect" height={20} />
                </Box>
                <Box margin="4px 8px">
                    <Skeleton animation="wave" variant="rect" height={20} />
                </Box>
            </Box>
        )
    return (
        <Box
            width="100%"
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            padding="16px 32px 16px 32px"
            borderBottom="solid 1px #d8dadd"
        >
            <PeopleList list={approvers} expandedStyles={false} />
        </Box>
    )
}
