import { Fragment } from 'react'
import { Box, styled, withTheme, Paper } from '@material-ui/core'
import {
    isNilOrEmpty,
    useGetControlsAccess,
    useIsSmallScreen,
    useQuery,
} from 'packages/core'
import { EidFilter, ShoppingFor, TargetSystem } from 'components'
import useConfiguration from 'useConfiguration'
import { useResourceTypeContext } from 'resourceTypeContext'
import { EidWorkflowButton } from 'components/EidWorkflowButton'
import ManageAccessTabs from './../ManageAccess/ManageAccessTabs'
import { useHistory } from 'react-router'
import useDefaultState from 'components/DeleteFilters/useDefaultState'

const filters = {
    ShoppingFor: ShoppingFor,
    TargetSystem: TargetSystem,
}

const GridItem = styled(withTheme(Box))((props) => ({
    width: '100%',
    [props.theme.breakpoints.up('md')]: {
        padding: '8px',
    },
}))

const Selectors = ({ resourceType, screen }) => {
    const isSmallScreen = useIsSmallScreen()
    const [state, dispatch] = useResourceTypeContext()
    const defaultState = useDefaultState(resourceType)
    const { getResourceType } = useConfiguration()
    const accessControlsNames = useGetControlsAccess().map((c) => c.name)

    const currentResourceType = getResourceType(resourceType)

    let filtersToRender = currentResourceType.filters.filter((f) =>
        f.requireAccess
            ? accessControlsNames.indexOf(f.requireAccess.control) >= 0
            : true,
    )
    const query = useQuery()

    let manageAccessTabs = currentResourceType.manageAccessTabs
    const history = useHistory()
    const selectedTab = query.get('tab')
    if (screen === 'RequestAccess') {
        filtersToRender = filtersToRender.filter(
            (f) => f.forRequestAccess === true,
        )
    } else if (screen === 'ManageAccess') {
        filtersToRender = filtersToRender.filter(
            (f) => f.forManageAccess === true,
        )
        // To Check filters for computer manage access tabs
        if (currentResourceType && currentResourceType.name === "Computers") {
            let selectedTabInfo = null;
            if (selectedTab) {
                selectedTabInfo = currentResourceType.manageAccessTabs ? currentResourceType.manageAccessTabs.find(x => x.path === selectedTab) : null
            } else {
                selectedTabInfo = currentResourceType.manageAccessTabs ? currentResourceType.manageAccessTabs.find(x => x.default === true) : null
            }
            filtersToRender = filtersToRender.filter((fl) => fl[selectedTabInfo.path])
        }
    } else {
        filtersToRender = []
    }

    const workflowButtonsToRender = currentResourceType.workflows.filter((f) =>
        f.requireAccess
            ? accessControlsNames.indexOf(f.requireAccess.control) >= 0
            : true,
    )




    const isApplicationsPage = Boolean(query.get('applicationId'))

    if (isApplicationsPage) {
        filtersToRender = filtersToRender.filter(
            (f) => f.name !== 'Applications',
        )
    }

    const clearAllFilters = () => {
        dispatch({
            type: 'SET_MULTIPLE_PROPS',
            payload: { ...defaultState },
        })
    }

    const resetSorting = () => {
        dispatch({
            type: 'SET_PROP',
            payload: {
                key: 'sorting',
                value: {
                    sortOrder: null,
                    sortBy: null,
                },
            },
        })
    }

    return (
        <Fragment>
            {screen === 'ManageAccess' &&
                manageAccessTabs &&
                manageAccessTabs.length && (
                    <Box style={{ padding: '8px', width: '100%' }}>
                        <Paper>
                            <ManageAccessTabs
                                tabs={manageAccessTabs}
                                active={
                                    selectedTab
                                        ? selectedTab
                                        : manageAccessTabs.filter(
                                            (t) => t.default,
                                        )[0].path
                                }
                                onChange={(tab) => {
                                    clearAllFilters()
                                    resetSorting()
                                    history.push(
                                        !tab.default
                                            ? `${history.location.pathname}?tab=${tab.path}`
                                            : `${history.location.pathname}`,
                                    )
                                    dispatch({
                                        type: 'SET_MANAGE_ACCESS_FILTERS',
                                        payload: {
                                            selectedTab: tab,
                                        },
                                    })
                                }}
                            ></ManageAccessTabs>
                        </Paper>
                    </Box>
                )}
            {workflowButtonsToRender.map((w) => {
                return isSmallScreen ? (
                    <Box marginBottom={1}>
                        <EidWorkflowButton {...w} key={w.name} />
                    </Box>
                ) : (
                    <GridItem key={w.name}>
                        <EidWorkflowButton {...w} />
                    </GridItem>
                )
            })}

            {filtersToRender
                .sort((a, b) => a.order - b.order)
                .map(({ dynamic, ...f }) => {
                    if (dynamic === true) {
                        if (
                            f.showOnly &&
                            isNilOrEmpty(state[f.showOnly.contextProp])
                        ) {
                            return <Fragment key={f.name} />
                        }

                        return isSmallScreen ? (
                            <EidFilter
                                {...f}
                                key={f.name}
                                resourceType={resourceType}
                            />
                        ) : (
                            <GridItem key={f.name}>
                                <EidFilter {...f} resourceType={resourceType} />
                            </GridItem>
                        )
                    } else {
                        const DefinedFilter = filters[f.name]

                        return isSmallScreen ? (
                            <DefinedFilter
                                key={f.name}
                                resourceType={resourceType}
                                screen={screen}
                            />
                        ) : (
                            <GridItem key={f.name}>
                                <DefinedFilter
                                    resourceType={resourceType}
                                    screen={screen}
                                />
                            </GridItem>
                        )
                    }
                })}
        </Fragment>
    )
}

export default Selectors
