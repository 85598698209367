import { Box } from '@material-ui/core'
import { Autocomplete, Select, ExpansionPanel } from 'packages/eid-ui'
import { useIsSmallScreen } from 'packages/core'
import { useTranslation } from 'react-i18next'
import { useResourceTypeContext } from '../../resourceTypeContext'
import { useAzureRoleTypes } from '../../hooks'
import { ArrowIcon } from '../../packages/eid-icons'
import { useState, useEffect } from 'react'

const styles = {
    '& > div': {
        boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.1) !important',
        borderColor: 'transparent',
        padding: '1.5px !important',
        '& > div': {
            right: '0px !important',
            pointerEvents: 'none',
        },
        '& > input': {
            height: '35px',
            cursor: 'pointer',
            width: '100%',
            // color: '#7d7c7c'
        },
    },
}

export const AzureRoleTypeFilter = () => {
    const { t } = useTranslation()

    const [
        {
            manageAccess: { roleGroupType },
        },
        dispatch,
    ]: any = useResourceTypeContext()

    const [callEndpoint, setCallEndpoint] = useState(false)

    const { data, isLoading } = useAzureRoleTypes(callEndpoint)

    const isSmallScreen = useIsSmallScreen()

    const [options, setOptions] = useState([])

    useEffect(() => {
        let options
        if (data) {
            options = data.map((o: any) => ({
                id: o,
                value: o,
            }))
            setOptions(options)
        }
    }, [data])

    const filter = (
        <Select
            placeholder={t('Common_AzureRoleType')}
            options={options}
            onChange={(selectedItem: any) =>
                dispatch({
                    type: 'SET_MANAGE_ACCESS_FILTERS',
                    payload: {
                        roleGroupType: selectedItem,
                    },
                })
            }
            value={roleGroupType ? roleGroupType : ''}
            getOptionLabel={(option: any) => option.value}
            onOpen={() => {
                setCallEndpoint(true)
            }}
            onClose={() => {
                setCallEndpoint(false)
            }}
        />
    )

    return isSmallScreen ? (
        <ExpansionPanel expanded={true} title={t('Common_AzureRoleType')}>
            <Box padding="20px">{filter}</Box>
        </ExpansionPanel>
    ) : (
        <>
            <span style={{ minWidth: '215px' }}>{filter}</span>
        </>
    )
}
