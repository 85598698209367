import { default as EndDateManageAccess } from './EndDateManageAccess'
import { default as ConnectSwitch } from './ConnectSwitch'
import { default as CredentialsLockedAttribute } from './CredentialsLockedAttribute'
import { default as CredentialStatusButton } from './CredentialStatusButton'
import { default as CredentialRemainingTime } from './CredentialRemainingTime'
import { default as DateTimeGenericComponent } from './DateTimeGenericComponent'
import { default as ComputersStatusButton } from './ComputersStatusButton'
import { default as ComputersCheckInButton } from './ComputersCheckInButton'
import { default as ComputersConnectedWhen } from './ComputersConnectedWhen'
import { default as ComputersTerminateButton } from './ComputersTerminateButton'
import { default as GenericDateTimeComponent } from './GenericDateTimeComponent'

const DynamicComponents = {
    EndDateManageAccess,
    ConnectSwitch,
    CredentialsLockedAttribute,
    CredentialStatusButton,
    DateTimeGenericComponent,
    GenericDateTimeComponent,
    CredentialRemainingTime,
    ComputersCheckInButton,
    ComputersStatusButton,
    ComputersConnectedWhen,
    ComputersTerminateButton,
}

export default DynamicComponents
