import React, { FC, Fragment } from 'react'
import useSubcomponents from 'useSubcomponents'
import { AccessRequestPolicyDetails } from 'components/AccessRequestPolicyDetails'
import { useAccessRequestPolicy } from 'hooks'
import { Divider } from 'packages/eid-ui'

export type CommonAttributesProps = {
    resource: any
}

export const CommonAttributes: FC<CommonAttributesProps> = (props) => {
    const { canSeeAccessRequestPolicy } = useSubcomponents()

    const accessRequestPolicy = useAccessRequestPolicy(
        props.resource.requestPolicyId,
    )

    return (
        <Fragment>
            {canSeeAccessRequestPolicy && accessRequestPolicy && (
                <Fragment>
                    <Divider />

                    <AccessRequestPolicyDetails policy={accessRequestPolicy} />
                </Fragment>
            )}
        </Fragment>
    )
}
