import { IResourceType } from '../types/IResourceType'
import { IResourceTypeIcon } from '../types/IResourceTypeIcon'
import { IResourceTypeComponents } from '../types/IResourceTypeComponents'
import { AccessLevel } from '../types/AccessLevel'
import { Sample } from './Sample'
import { AlreadyInCartType } from '../types/helperTypes'
import { ICartItem } from '../types/ICartItem'
import { IResource } from '../types/IResource'

export class ApplicationsType implements IResourceType {
    name = 'Applications'

    route = '/applications'

    title = 'Common_Applications'

    access: AccessLevel = {
        page: 'ITShop-Applications-Page',
    }

    icon: IResourceTypeIcon = {
        small: 'ApplicationsSmall',
        large: 'ApplicationsLarge',
    }

    manageAccessComponents: IResourceTypeComponents = {
        cardComponent: Sample,

        rowComponent: Sample,

        detailComponent: Sample,
    }

    requestAccessComponents: IResourceTypeComponents = {
        cardComponent: Sample,

        rowComponent: Sample,

        detailComponent: Sample,
    }

    readonly cartComponent = null

    alreadyInCart: AlreadyInCartType = (
        cartItems: ICartItem[],
        resource: IResource,
    ) =>
        cartItems.find((c) => c.requestableResourceId === resource.id) !==
        undefined

    filters = []

    attributes = []
}
