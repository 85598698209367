import React, { FC, Fragment } from 'react'
import { Link } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { ItemDetails } from 'components'
import { useAllApplications } from 'hooks'
import { useIsSmallScreen, useUrlGenerator } from 'packages/core'

export type BusinessRequestItemDetails = {
    id: string
    friendlyName: string
    businessRequestID: string
    businessRequestName: string
    businessRequestNumber: number
}

export const BusinessRequestNameAttribute: FC<{
    businessRequestItem: BusinessRequestItemDetails
}> = ({ businessRequestItem }) => {
    const { t } = useTranslation()
    const attributeOrientation = useIsSmallScreen() ? 'vertical' : 'horizontal'

    const { getMyTasksUrl } = useUrlGenerator(useAllApplications)

    const hasAccessToMyTasks = getMyTasksUrl()

    if (!hasAccessToMyTasks) return <Fragment />

    return (
        <Fragment>
            {businessRequestItem.businessRequestID &&
                businessRequestItem.businessRequestName && (
                    <ItemDetails.Attribute
                        label={t('Common_BusinessRequest')}
                        value={
                            <Link
                                target="_blank"
                                href={getMyTasksUrl(
                                    `/myRequests/businessRequests?id=${businessRequestItem.businessRequestID}`,
                                )}
                            >
                                {businessRequestItem.businessRequestName}
                            </Link>
                        }
                        orientation={attributeOrientation}
                    />
                )}
            {businessRequestItem.id && businessRequestItem.friendlyName && (
                <ItemDetails.Attribute
                    label={t('Common_BusinessRequestItem')}
                    value={
                        <Link
                            target="_blank"
                            href={getMyTasksUrl(
                                `/myRequests/businessRequestItems?id=${businessRequestItem.id}`,
                            )}
                        >
                            {businessRequestItem.friendlyName}
                        </Link>
                    }
                    orientation={attributeOrientation}
                />
            )}
        </Fragment>
    )
}
