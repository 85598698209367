import React from 'react'
import { isNilOrEmpty } from '../../core'
import ViewIndicator from './ViewIndicator'

const isAValidView = (view) => view === 'list' || view === 'card'

const useViewIndicator = ({
    view,
    defaultView = 'list',
    onCardViewClick,
    onListViewClick,
}) => {
    const viewToUse =
        !isNilOrEmpty(view) && isAValidView(view) ? view : defaultView

    const viewIndicator = (
        <ViewIndicator
            view={viewToUse}
            onCardViewClick={onCardViewClick}
            onListViewClick={onListViewClick}
        />
    )
    return { view, viewIndicator }
}

export default useViewIndicator
