import React, { useState } from 'react'
import {
    Box,
    InputBase,
    styled,
    Typography,
    withStyles,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import {
    ApplicationsList,
    FunctionsList,
    ItemDetails,
    PeopleList,
    SuggestedRoles,
    TableGrid,
    TCodesList,
} from 'components'
import { Pagination } from 'packages/eid-ui'
import { Skeleton } from '@material-ui/lab'
import {
    useBusinessRoleApplicationRolesGranted,
    useBusinessRoleApplications,
    useBusinessRoleApprovers,
    useBusinessRoleBr,
    useBusinessRoleGlobalSenstiveFunctions,
    useBusinessRoleLocalSensitiveFunctions,
    useBusinessRoleManagementRolesGranted,
    useBusinessRoleOwners,
    useBusinessRoleSuggestedApplicationRoles,
    useBusinessRoleSuggestedManagementRoles,
    useTargetPerson,
} from 'hooks'
import { CheckedIcon } from 'packages/eid-icons'
import { isNilOrEmpty, useDebounce, useIsSmallScreen } from 'packages/core'
import useSubcomponents from 'useSubcomponents'
import cartHelpers from 'containers/Cart/cartHelpers'
import { CommonAttributes } from '../CommonAttributes'
import { ResourceTypesNamespace, useRegistry } from 'core'
import { RenewRevoke } from 'components/RenewRevoke'
import { useHistory } from 'react-router'

const StyledInput = withStyles((theme) => ({
    root: {
        width: '100% !important',
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: '#ffffff !important',
        border: 'solid 1px #ebebed',

        fontSize: 16,
        padding: '10px 12px',
        '&:focus': {
            boxShadow: ` 0 2px 4px 0 rgba(48, 127, 193, 0.15)`,
            borderColor: '#307fc1',
            backgroundColor: theme.palette.common.white,
        },
    },

    focused: {
        backgroundColor: theme.palette.common.white,
    },
}))(InputBase)

const PaddedDiv = styled(Box)({
    padding: '0 31px',
})

const overflowStyleProps = {
    maxHeight: '220px',
    overflow: 'auto',
}

const OverviewSection = ({ item }) => {
    const { t } = useTranslation()

    const businessRole = item.resource

    const [targetPerson] = useTargetPerson()

    const { data: businessRequestItem, isLoading } = useBusinessRoleBr(
        targetPerson.id,
        item.assignment.resourceAssignment.resourceAssignmentId,
        item.assignment.resourceAssignment.resourceTypeId,
    )

    const attributeOrientation = useIsSmallScreen() ? 'vertical' : 'horizontal'

    return (
        <ItemDetails.AttributesContainer>
            <PaddedDiv>
                <ItemDetails.Attribute
                    label={t('Common_TechnicalName')}
                    value={businessRole.name}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                {businessRole.businessDomain_FriendlyName && (
                    <ItemDetails.Attribute
                        label={t('BusinessRoles_Domain')}
                        value={businessRole.businessDomain_FriendlyName}
                        orientation={attributeOrientation}
                        valueProps={overflowStyleProps}
                    />
                )}
                <ItemDetails.Attribute
                    label={t('BusinessRoles_DifferentiationValue')}
                    value={
                        item.assignment.resourceAssignment.locationFriendlyName
                    }
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('BusinessRoles_HighLevelClassification')}
                    value={businessRole.highLevelClassification}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('Common_Description')}
                    value={businessRole.description}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                {isLoading && <Skeleton height={32} />}
                <CommonAttributes
                    item={item}
                    businessRequestItem={businessRequestItem}
                />
            </PaddedDiv>
        </ItemDetails.AttributesContainer>
    )
}

const AdditionalInformationSection = ({ item }) => {
    const take = 10
    const { t } = useTranslation()
    const attributeOrientation = useIsSmallScreen() ? 'vertical' : 'horizontal'
    const businessRole = item.resource

    const isSmallScreen = useIsSmallScreen()

    const [page, setPage] = React.useState(1)
    const [searchKey, setSearchKey] = useState('')

    const handlePageChange = (_, value) => {
        setPage(value)
    }
    const debouncedSearchValue = useDebounce(searchKey)

    const handleSearchChange = (event) => {
        setPage(1)
        const keyword = event.target.value
        setSearchKey(keyword)
    }

    const { data: owners, isLoading: ownersLoading } = useBusinessRoleOwners(
        businessRole.id,
    )

    const { latestData } = useBusinessRoleGlobalSenstiveFunctions(
        businessRole.id,
        (page - 1) * take,
        take,
        debouncedSearchValue && encodeURIComponent(debouncedSearchValue),
    )

    const globalSensitiveFunctions = latestData ? latestData.data : undefined

    const globalFunctionsLoading = !Boolean(latestData)

    const numberOfPages = latestData
        ? Math.ceil(latestData.totalCount / take)
        : 0

    return (
        <>
            {ownersLoading ? (
                <ItemDetails.AttributesContainer>
                    <PaddedDiv>
                        <ItemDetails.AttributesLoader />
                    </PaddedDiv>
                </ItemDetails.AttributesContainer>
            ) : (
                <ItemDetails.AttributesContainer bottomPadding="0">
                    <Box padding="0 31px 8.5px 31px">
                        <ItemDetails.Attribute
                            label={t('BusinessRoles_Owners')}
                            value={<PeopleList list={owners} />}
                            orientation={attributeOrientation}
                        />
                    </Box>

                    {!isNilOrEmpty(searchKey) ||
                    (globalSensitiveFunctions &&
                        globalSensitiveFunctions.length > 0) ? (
                        <>
                            <PaddedDiv
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: '16px 31px',
                                }}
                            >
                                <Box width="50%">
                                    <Typography
                                        style={{
                                            fontSize: '12px',
                                            lineHeight: 1.33,
                                            color: '#9b9b9b',
                                            wordBreak: 'break-word',
                                            textTransform: 'uppercase',
                                            minWidth: '80px',
                                        }}
                                    >
                                        {t('Common_GlobalSensitiveFunctions')}
                                    </Typography>
                                </Box>

                                <Box
                                    width={!isSmallScreen ? '308px' : '50%'}
                                    minWidth="130px"
                                >
                                    <StyledInput
                                        fullWidth
                                        type="text"
                                        placeholder={t('Common_Search')}
                                        value={searchKey}
                                        onChange={handleSearchChange}
                                    />
                                </Box>
                            </PaddedDiv>
                            <Box overflow="auto">
                                <FunctionsList
                                    data={globalSensitiveFunctions}
                                    loading={globalFunctionsLoading}
                                />

                                {numberOfPages > 1 && (
                                    <Box
                                        padding={
                                            !isSmallScreen
                                                ? '20px 32px'
                                                : '16px'
                                        }
                                        display="flex"
                                        alignItems="center"
                                        justifyContent={
                                            !isSmallScreen
                                                ? 'flex-start'
                                                : 'center'
                                        }
                                    >
                                        <Pagination
                                            count={numberOfPages}
                                            size={
                                                !isSmallScreen
                                                    ? 'large'
                                                    : 'small'
                                            }
                                            page={page}
                                            onChange={handlePageChange}
                                        />
                                    </Box>
                                )}
                            </Box>
                        </>
                    ) : (
                        <Box marginY="20px" />
                    )}
                </ItemDetails.AttributesContainer>
            )}
        </>
    )
}

const ApplicationRolesGranted = ({ businessRole, differentiationValue }) => {
    const isSmallScreen = useIsSmallScreen()
    const take = 10

    const { t } = useTranslation()

    const [page, setPage] = React.useState(1)
    const [searchKey, setSearchKey] = useState('')

    const handlePageChange = (_, value) => {
        setPage(value)
    }
    const debouncedSearchValue = useDebounce(searchKey)

    const handleSearchChange = (event) => {
        setPage(1)
        const keyword = event.target.value
        setSearchKey(keyword)
    }

    const { latestData } = useBusinessRoleApplicationRolesGranted(
        businessRole.id,
        differentiationValue?.id,
        (page - 1) * take,
        take,
        debouncedSearchValue && encodeURIComponent(debouncedSearchValue),
    )
    const data = latestData ? latestData.data : undefined

    const isLoading = !Boolean(latestData)

    const numberOfPages = latestData
        ? Math.ceil(latestData.totalCount / take)
        : 0

    const attributes = [
        {
            label: '',
            resolve: () => <CheckedIcon />,
        },
        {
            label: t('Common_FriendlyName'),
            resolve: (item) => <> {item.friendlyName}</>,
        },
        {
            name: 'resourceSystemFriendlyName',
            label: t('BusinessRoles_ResourceSystem'),
        },
    ]

    return (
        <>
            <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                padding="16px 32px 16px 32px"
                style={{ backgroundColor: '#ebebed' }}
            >
                <Box width={!isSmallScreen ? '308px' : '200px'}>
                    <StyledInput
                        fullWidth
                        type="text"
                        placeholder={t('Common_Search')}
                        value={searchKey}
                        onChange={handleSearchChange}
                    />
                </Box>
            </Box>
            <TableGrid
                loading={isLoading}
                data={data}
                attributes={attributes}
                headings={attributes.map((a) => a.label)}
            />

            {numberOfPages > 1 && (
                <Box
                    padding={!isSmallScreen ? '20px 32px' : '16px'}
                    display="flex"
                    alignItems="center"
                    justifyContent={!isSmallScreen ? 'flex-start' : 'center'}
                >
                    <Pagination
                        count={numberOfPages}
                        size={!isSmallScreen ? 'large' : 'small'}
                        page={page}
                        onChange={handlePageChange}
                    />
                </Box>
            )}
        </>
    )
}

const ManagementRolesGranted = ({ businessRole, differentiationValue }) => {
    const isSmallScreen = useIsSmallScreen()
    const take = 10

    const { t } = useTranslation()

    const [page, setPage] = React.useState(1)
    const [searchKey, setSearchKey] = useState('')

    const handlePageChange = (_, value) => {
        setPage(value)
    }
    const debouncedSearchValue = useDebounce(searchKey)

    const handleSearchChange = (event) => {
        setPage(1)
        const keyword = event.target.value
        setSearchKey(keyword)
    }

    const { latestData } = useBusinessRoleManagementRolesGranted(
        businessRole.id,
        differentiationValue?.id,
        (page - 1) * take,
        take,
        debouncedSearchValue && encodeURIComponent(debouncedSearchValue),
    )
    const data = latestData ? latestData.data : undefined

    const isLoading = !Boolean(latestData)

    const numberOfPages = latestData
        ? Math.ceil(latestData.totalCount / take)
        : 0

    const attributes = [
        {
            label: '',
            resolve: () => <CheckedIcon />,
        },
        {
            label: t('Common_FriendlyName'),
            resolve: (item) => <> {item.friendlyName}</>,
        },
        {
            name: 'resourceSystemFriendlyName',
            label: t('BusinessRoles_ResourceSystem'),
        },
    ]

    return (
        <>
            <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                padding="16px 32px 16px 32px"
                style={{ backgroundColor: '#ebebed' }}
            >
                <Box width={!isSmallScreen ? '308px' : '200px'}>
                    <StyledInput
                        fullWidth
                        type="text"
                        placeholder={t('Common_Search')}
                        value={searchKey}
                        onChange={handleSearchChange}
                    />
                </Box>
            </Box>
            <TableGrid
                loading={isLoading}
                data={data}
                attributes={attributes}
                headings={attributes.map((a) => a.label)}
            />

            {numberOfPages > 1 && (
                <Box
                    padding={!isSmallScreen ? '20px 32px' : '16px'}
                    display="flex"
                    alignItems="center"
                    justifyContent={!isSmallScreen ? 'flex-start' : 'center'}
                >
                    <Pagination
                        count={numberOfPages}
                        size={!isSmallScreen ? 'large' : 'small'}
                        page={page}
                        onChange={handlePageChange}
                    />
                </Box>
            )}
        </>
    )
}

const LocalSensitiveFunctions = ({ businessRole, differentiationValue }) => {
    const take = 10

    const { t } = useTranslation()

    const isSmallScreen = useIsSmallScreen()

    const [page, setPage] = React.useState(1)
    const [searchKey, setSearchKey] = useState('')

    const handlePageChange = (_, value) => {
        setPage(value)
    }
    const debouncedSearchValue = useDebounce(searchKey)

    const handleSearchChange = (event) => {
        setPage(1)
        const keyword = event.target.value
        setSearchKey(keyword)
    }

    const { latestData } = useBusinessRoleLocalSensitiveFunctions(
        businessRole.id,
        differentiationValue?.id,
        (page - 1) * take,
        take,
        debouncedSearchValue && encodeURIComponent(debouncedSearchValue),
    )

    const data = latestData ? latestData.data : undefined
    const isLoading = !Boolean(latestData)

    const numberOfPages = latestData
        ? Math.ceil(latestData.totalCount / take)
        : 0

    return (
        <>
            <Box
                width="100%"
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                padding="16px 32px 16px 32px"
                style={{ backgroundColor: '#ebebed' }}
            >
                <Box width={!isSmallScreen ? '308px' : '200px'}>
                    <StyledInput
                        fullWidth
                        type="text"
                        placeholder={t('Common_Search')}
                        value={searchKey}
                        onChange={handleSearchChange}
                    />
                </Box>
            </Box>

            <FunctionsList loading={isLoading} data={data} />
            {numberOfPages > 1 && (
                <Box
                    padding={!isSmallScreen ? '20px 32px' : '16px'}
                    display="flex"
                    alignItems="center"
                    justifyContent={!isSmallScreen ? 'flex-start' : 'center'}
                >
                    <Pagination
                        count={numberOfPages}
                        size={!isSmallScreen ? 'large' : 'small'}
                        page={page}
                        onChange={handlePageChange}
                    />
                </Box>
            )}
        </>
    )
}

const Approvers = ({ businessRole, differentiationValue }) => {
    const { data: approvers, isLoading } = useBusinessRoleApprovers(
        businessRole.id,
        differentiationValue.id,
    )
    if (isLoading)
        return (
            <Box padding="16px 32px 16px 32px" borderBottom="solid 1px #d8dadd">
                <Box margin="4px 8px">
                    <Skeleton animation="wave" variant="rect" height={20} />
                </Box>
                <Box margin="4px 8px">
                    <Skeleton animation="wave" variant="rect" height={20} />
                </Box>
            </Box>
        )
    return (
        <Box
            width="100%"
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            padding="16px 32px 16px 32px"
            borderBottom="solid 1px #d8dadd"
        >
            <PeopleList list={approvers} expandedStyles={false} />
        </Box>
    )
}

const BusinessRoleDetails = ({ item, toggleDrawer }) => {
    const { t } = useTranslation()

    const registry = useRegistry()

    const history = useHistory()
    const isManageAccess =
        history.location.pathname.indexOf('/manageAccess') >= 0

    const businessRoleType = registry.get(
        ResourceTypesNamespace,
        'BusinessRoles',
    )

    const {
        hasAccessToTCodesGrid,
        hasAccessToLocalSensitiveFunctionsGrid,
        canSeeManagementRolesGranted,
        canSeeApplicationRolesGranted,
        canSeeApplications,
        canSeeManagementRolesSuggestedGrid,
    } = useSubcomponents()

    const businessRole = item.resource

    const [targetPerson] = useTargetPerson()

    const [activeTab, setActiveTab] = useState('overview')
    const [subActiveTab, setSubActiveTab] = useState('applicationRolesGranted')

    const differentiationValue = {
        id: item.assignment.resourceAssignment.locationId,
        friendlyName: item.assignment.resourceAssignment.locationFriendlyName,
    }

    const preRevoke = () => {
        const itemToRevoke = cartHelpers.businessRoleToCartItem({
            targetPerson,
            assignmentType: 'Remove',
            businessRole: item.resource,
            differentiationValue,
            assignmentStatus: item.assignment,
        })
        if (!itemToRevoke) return undefined

        return itemToRevoke
    }

    return (
        <>
            <Box>
                <ItemDetails.Tabs
                    variant="standard"
                    value={activeTab}
                    onChange={(_, value) => setActiveTab(value)}
                >
                    <ItemDetails.Tabs.Tab
                        value="overview"
                        label={t('Common_Overview')}
                    />
                    <ItemDetails.Tabs.Tab
                        value="additionalInformation"
                        label={t('Common_MoreInformation')}
                    />
                </ItemDetails.Tabs>
            </Box>
            {activeTab === 'overview' && <OverviewSection item={item} />}
            {activeTab === 'additionalInformation' && (
                <AdditionalInformationSection item={item} />
            )}
            <Box paddingRight="12px">
                <ItemDetails.Tabs
                    variant={'scrollable'}
                    scrollButtons={'on'}
                    value={subActiveTab}
                    onChange={(_, value) => setSubActiveTab(value)}
                >
                    {canSeeApplicationRolesGranted && (
                        <ItemDetails.Tabs.Tab
                            value="applicationRolesGranted"
                            label={t('BusinessRoles_ApplicationRolesGranted')}
                        />
                    )}

                    {canSeeManagementRolesGranted && (
                        <ItemDetails.Tabs.Tab
                            value="managementRolesGranted"
                            label={t('Common_ManagementRolesGranted')}
                        />
                    )}

                    {hasAccessToLocalSensitiveFunctionsGrid && (
                        <ItemDetails.Tabs.Tab
                            value="localSensitiveFunctions"
                            label={t('Common_LocalSensitiveFunctions')}
                        />
                    )}
                    <ItemDetails.Tabs.Tab
                        value="approvers"
                        label={t('Common_Approvers')}
                        disabled={isNilOrEmpty(differentiationValue)}
                    />
                    {hasAccessToTCodesGrid && (
                        <ItemDetails.Tabs.Tab
                            value="tCodes"
                            label={t('Common_TCodes')}
                            disabled={isNilOrEmpty(differentiationValue)}
                        />
                    )}

                    {canSeeApplications && (
                        <ItemDetails.Tabs.Tab
                            value="applications"
                            label={t('Common_Applications')}
                        />
                    )}
                </ItemDetails.Tabs>
            </Box>
            {subActiveTab === 'applicationRolesGranted' && (
                <ApplicationRolesGranted
                    businessRole={businessRole}
                    differentiationValue={differentiationValue}
                />
            )}

            {subActiveTab === 'managementRolesGranted' && (
                <ManagementRolesGranted
                    businessRole={businessRole}
                    differentiationValue={differentiationValue}
                />
            )}

            {subActiveTab === 'localSensitiveFunctions' && (
                <LocalSensitiveFunctions
                    businessRole={businessRole}
                    differentiationValue={differentiationValue}
                />
            )}
            {subActiveTab === 'approvers' && (
                <Approvers
                    businessRole={businessRole}
                    differentiationValue={differentiationValue}
                />
            )}
            {subActiveTab === 'tCodes' && (
                <TCodesList
                    resourceId={item.resource.id}
                    differentiationValueId={differentiationValue.id}
                />
            )}
            {subActiveTab == 'applications' && (
                <ApplicationsList
                    resource={businessRole}
                    useDataHook={useBusinessRoleApplications}
                    locationId={differentiationValue.id}
                />
            )}

            {isManageAccess && (
                <RenewRevoke
                    resourceType={businessRoleType}
                    resource={businessRole}
                    secondary={differentiationValue}
                    assignment={item.assignment}
                    postRenew={toggleDrawer}
                    preRevoke={preRevoke}
                    postRevoke={toggleDrawer}
                    item={item}
                />
            )}

            <Box
                overflow="auto"
                width="100%"
                borderTop=" solid 1px #d8dadd"
                id="SuggestedApplicationRoles"
            >
                <SuggestedRoles
                    title={t('BusinessRoles_SuggestedApplicationRoles')}
                    toggleDrawer={toggleDrawer}
                    resource={businessRole}
                    differentiationValue={differentiationValue}
                    targetPerson={targetPerson}
                    useDataHook={useBusinessRoleSuggestedApplicationRoles}
                />
            </Box>
            {canSeeManagementRolesSuggestedGrid && (
                <Box overflow="auto" width="100%" id="SuggestedManagementRoles">
                    <SuggestedRoles
                        title={t('Common_SuggestedManagementRoles')}
                        toggleDrawer={toggleDrawer}
                        resource={businessRole}
                        differentiationValue={differentiationValue}
                        targetPerson={targetPerson}
                        useDataHook={useBusinessRoleSuggestedManagementRoles}
                    />
                </Box>
            )}
        </>
    )
}

export default BusinessRoleDetails
