import { FC, useEffect, useState } from 'react'
import { Box, Button, Link, styled, Typography } from '@material-ui/core'
import { ApplicationCard } from 'components/ApplicationCard'
import { CardList } from 'packages/eid-controls'
import { useGetControlsAccess, useQuery, isNilOrEmpty } from 'packages/core'
import { useHistory } from 'react-router'
import { ShoppingFor } from 'components'
import { Divider } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'
import StickyContainer from 'components/StickyContainer'
import { ManageAccessNavigation } from 'components/ManageAccessNavigation'
import { EidWorkflowButton } from 'components/EidWorkflowButton'
import { ApplicationBanner } from 'components/ApplicationBanner'
import ApplicationAccessLevel from './ApplicationAccessLevel'
import {
    useApplication,
    useCheckApplicationsAccess,
    useTargetPerson,
} from 'hooks'

const maxWidth = '420px'
const flexCenter = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}

const ItemsContainer = styled('div')(({ top }: any) => ({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    paddingTop: top ? top : '16px',
    maxWidth: maxWidth,
    margin: 'auto',
    paddingLeft: '6px',
    paddingRight: '6px',
}))

const TextButton = styled(Link)({
    textDecoration: 'none',
    backgroundColor: 'transparent',
    border: 'none',
    color: '#000000',
    fontSize: '12px',
    '&:focus': {
        outline: 'none',
    },
})

const StyledButton = styled(({ children, label, subLabel, ...other }) => (
    <Button {...other}>
        {children ? (
            children
        ) : (
            <>
                <Typography
                    style={{
                        color: '#363636',
                        fontSize: '14px',
                        lineHeight: '16px',
                    }}
                >
                    {label}
                </Typography>
                <Typography
                    style={{
                        color: '#8b909a',
                        fontSize: '11px',
                        lineHeight: '13px',
                    }}
                >
                    {subLabel}
                </Typography>
            </>
        )}
    </Button>
))(({ border, styles }: any) => ({
    width: '100%',
    maxWidth: '260px',
    backgroundColor: '#ffffff',
    border: border ? border : '1px solid #d2d2d9',
    boxShadow: 'none',
    textTransform: 'capitalize',
    '&:hover': {
        backgroundColor: '#ffffff',
        border: border ? border : '1px solid #d2d2d9',
        boxShadow: 'none',
    },
    ...styles,
}))

export interface IMobileVersionProps {
    totalCount?: any
    loading?: any
    list?: any
    loadingMore?: any
    infiniteScrollObervableDiv?: any
    sortBy?: any
    sortOrder?: any
    onSort?: any
}

export const MobileVersion: FC<IMobileVersionProps> = (props) => {
    const {
        totalCount,
        loading,
        list,
        loadingMore,
        infiniteScrollObervableDiv,
    } = props

    const { t } = useTranslation()

    const query = useQuery()
    const history = useHistory()
    const controls = useGetControlsAccess()

    const hasAccessToCreateAzureApplicationWorkflow =
        controls.findIndex(
            (c: any) =>
                c.name === 'ITShop-CreateAzureApplicationWorkflow-Control',
        ) >= 0

    const showFilters = query.get('filters') === 'visible'

    const applicationCardLoader = [0, 1, 2, 3].map((i, index) => (
        <Box
            height="inline-block"
            width="100%"
            margin="12px"
            overflow="hidden"
            key={index}
        >
            <ApplicationCard loading={true} />
        </Box>
    ))

    const fixedContainerStyles = {
        position: 'sticky',
        top: 0,
        boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)',
    }

    const fixedBannerStyle = {
        overflow: 'auto',
        height: '80vh',
    }
    const [targetPerson] = useTargetPerson()

    const applicationId = query.get('applicationId')

    const { data: application } = useApplication(applicationId)
    const {
        data: accessLevels,
        isLoading: isCheckingAccess,
    } = useCheckApplicationsAccess(applicationId, targetPerson.id)
    const [accessLevel, setAccessLevelValue] = useState<any>(null)

    useEffect(() => {
        if (accessLevel && accessLevels && accessLevels.length === 1) {
            setAccessLevelValue(accessLevels[0])
        }
    }, [accessLevels, setAccessLevelValue])

    const filtersScreen = (
        <>
            <StickyContainer color="#ffffff" styles={fixedContainerStyles}>
                <Box maxWidth={maxWidth} width="100%">
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        padding="12px 12px 0px 12px"
                    >
                        <Typography
                            style={{
                                fontSize: '20px',
                                fontWeight: 'bold',
                                color: '#363636',
                            }}
                        >
                            {t('Common_Applications')}

                            {totalCount > 0 ? ` (${totalCount})` : ' (0)'}
                        </Typography>

                        <Box>
                            <StyledButton
                                onClick={() => {
                                    query.delete('filters')
                                    history.push(
                                        `${
                                            history.location.pathname
                                        }?${query.toString()}`,
                                    )
                                }}
                                border="none"
                                styles={{
                                    color: '#ffffff',
                                    backgroundColor: '#ff5e65',
                                    width: '120px',
                                    '&:hover': {
                                        backgroundColor: '#ff5e65',
                                        boxShadow: 'none',
                                    },
                                }}
                            >
                                {t('Common_ApplyFilters')}
                            </StyledButton>
                        </Box>
                    </Box>

                    <Box margin="12px 0px" padding="0px 8px">
                        <Divider color="#d2d2d9" />
                    </Box>

                    <Box
                        padding="0px 12px"
                        display="flex"
                        justifyContent="space-between"
                    />

                    <Box margin="12px 0px" padding="0px 8px">
                        <Divider color="#d2d2d9" />
                    </Box>

                    <Box
                        style={{
                            maxHeight: '90px',
                            overflow: 'auto',
                            paddingBottom: '6px',
                        }}
                    />
                </Box>
            </StickyContainer>

            <ItemsContainer>
                <ManageAccessNavigation />

                {hasAccessToCreateAzureApplicationWorkflow && (
                    <EidWorkflowButton
                        title={t('Common_CreateAzureApplication')}
                        name={'CreateAzureApplicationNoApproval'}
                        path={'/applications'}
                        mobile
                    />
                )}

                <ShoppingFor />
            </ItemsContainer>
        </>
    )
    const stickyContentListing = (
        <>
            {applicationId && (
                <Box paddingX="8px" marginBottom="16px">
                    <ApplicationBanner applicationId={applicationId} />
                </Box>
            )}
            {applicationId && accessLevels?.length > 0 && (
                <Box minWidth={'100%'}>
                    <ApplicationAccessLevel
                        accessLevel={accessLevel}
                        accessLevels={accessLevels}
                        setAccessLevelValue={(val) => setAccessLevelValue(val)}
                        isCheckingAccess={isCheckingAccess}
                        application={application}
                        targetPerson={targetPerson}
                    />
                </Box>
            )}
            {!applicationId && (
                <>
                    <Box margin="8px 0px 12px 0px" padding="0px 8px">
                        <Divider color="#d2d2d9" />
                    </Box>
                    <div style={{ ...flexCenter }}>
                        <StyledButton
                            label={t('Common_Filters')}
                            subLabel={t('Common_ApplyFilters')}
                            onClick={() => {
                                query.set('filters', 'visible')
                                history.push(
                                    `${
                                        history.location.pathname
                                    }?${query.toString()}`,
                                )
                            }}
                            styles={{
                                '& > span': {
                                    display: 'flex ',
                                    flexDirection: 'column',
                                },
                            }}
                        />
                    </div>
                </>
            )}
        </>
    )

    const listingScreen = (
        <>
            <StickyContainer styles={applicationId ? fixedBannerStyle : {}}>
                <Box maxWidth={maxWidth} width="100%" padding="12px 0px">
                    {stickyContentListing}
                </Box>
            </StickyContainer>

            {accessLevels ? (
                <></>
            ) : (
                <ItemsContainer top="90px">
                    <CardList
                        infiniteScrollObervableDiv={infiniteScrollObervableDiv}
                        noItemMessage={
                            <Typography
                                variant="h5"
                                style={{ color: '#b4b4b4' }}
                            >
                                {t('Common_NoItemsFound', {
                                    itemType: t('Common_Applications'),
                                })}
                            </Typography>
                        }
                        loadingIndicator={applicationCardLoader}
                        fetcher={() => ({
                            loading,
                            loadingMore,
                            list,
                        })}
                        renderItem={(item: any) => (
                            <div
                                key={item.id}
                                style={{
                                    padding: '8px',
                                    height: 'inline-block',
                                    width: '100%',
                                }}
                            >
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    maxWidth="420px"
                                    margin="auto"
                                >
                                    <ApplicationCard data={item} />
                                </Box>
                            </div>
                        )}
                    />
                </ItemsContainer>
            )}
        </>
    )

    return (
        <>
            <Box display={showFilters ? '' : 'none'}>{filtersScreen}</Box>

            <Box display={!showFilters ? '' : 'none'}>{listingScreen}</Box>
        </>
    )
}
