import React from 'react'
import { Box } from '@material-ui/core'
import { EidAutocomplete } from 'packages/eid-controls'

const styles = {
    '& > div': {
        fontFamily: 'Rubik !important',
        '& > input': {
            fontSize: '14px',
            height: '36px',
            padding: '12px 10px',
        },

        '&:hover': {
            borderColor: '#307fc1 !important',
        },
        '&:focus': {
            borderColor: '#307fc1 !important',
        },
    },
}

const OptionComponent = EidAutocomplete.getOptionComponent({
    type: 'label',
    labelProp: 'friendlyName',
})

const getOptionLabel = (option) => option.friendlyName

const BusinessRequestTypes = ({
    selectedRequestType,
    setSelectedRequestType,
}) => {
    return (
        <Box margin="10px 0px">
            <EidAutocomplete
                placeholder="Business Request Type"
                rootStyleProps={styles}
                url="/api/cart/businessRequestTypes"
                dedupingInterval={60000}
                value={selectedRequestType ? selectedRequestType : null}
                getOptionLabel={getOptionLabel}
                onChange={(_, selectedItem) =>
                    setSelectedRequestType(selectedItem)
                }
                optionComponent={OptionComponent}
                supportsServerSideSearch={false}
                filterOptions={undefined}
            />
        </Box>
    )
}

export default BusinessRequestTypes
