import React, { useState } from 'react'
import { FunctionsList, ItemDetails, StyledSearchInput } from 'components'
import { useDebounce, useIsSmallScreen } from 'packages/core'
import { Box, styled, Typography } from '@material-ui/core'
import { Pagination } from 'packages/eid-ui'
import { useAzureRoleGlobalSensitiveFunctions } from 'hooks'
import { useTranslation } from 'react-i18next'

const PaddedDiv = styled(Box)({
    padding: '0 31px',
})

export const GlobalSensitiveFunctions = ({ azureAdminRole }) => {
    const take = 10
    const { t } = useTranslation()

    const isSmallScreen = useIsSmallScreen()

    const [page, setPage] = React.useState(1)
    const [searchKey, setSearchKey] = useState('')

    const handlePageChange = (_, value) => {
        setPage(value)
    }

    const handleSearchChange = (event) => {
        setPage(1)
        const keyword = event.target.value
        setSearchKey(keyword)
    }

    const debouncedSearchValue = useDebounce(searchKey)

    const { latestData } = useAzureRoleGlobalSensitiveFunctions(
        azureAdminRole.id,
        (page - 1) * take,
        take,
        null,
        debouncedSearchValue && encodeURIComponent(debouncedSearchValue),
    )

    const globalSensitiveFunctions = latestData ? latestData.data : undefined

    const globalFunctionsLoading = !Boolean(latestData)

    const numberOfPages = latestData
        ? Math.ceil(latestData.totalCount / take)
        : 0

    return (
        <>
            <PaddedDiv
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '16px 31px',
                }}
            >
                <Box width="50%">
                    <Typography
                        style={{
                            fontSize: '12px',
                            lineHeight: 1.33,
                            color: '#9b9b9b',
                            wordBreak: 'break-word',
                            textTransform: 'uppercase',
                            minWidth: '80px',
                        }}
                    >
                        {t('Common_GlobalSensitiveFunctions')}
                    </Typography>
                </Box>

                <Box width={!isSmallScreen ? '308px' : '50%'} minWidth="130px">
                    <StyledSearchInput
                        fullWidth
                        type="text"
                        placeholder={t('Common_Search')}
                        value={searchKey}
                        onChange={handleSearchChange}
                    />
                </Box>
            </PaddedDiv>

            {globalFunctionsLoading ? (
                <ItemDetails.AttributesContainer>
                    <PaddedDiv>
                        <ItemDetails.AttributesLoader />
                    </PaddedDiv>
                </ItemDetails.AttributesContainer>
            ) : (
                <>
                    {globalSensitiveFunctions && (
                        <>
                            <Box overflow="auto">
                                <FunctionsList
                                    data={globalSensitiveFunctions}
                                    loading={globalFunctionsLoading}
                                />

                                {numberOfPages > 1 && (
                                    <Box
                                        padding={
                                            !isSmallScreen
                                                ? '20px 32px'
                                                : '16px'
                                        }
                                        display="flex"
                                        alignItems="center"
                                        justifyContent={
                                            !isSmallScreen
                                                ? 'flex-start'
                                                : 'center'
                                        }
                                    >
                                        <Pagination
                                            count={numberOfPages}
                                            size={
                                                !isSmallScreen
                                                    ? 'large'
                                                    : 'small'
                                            }
                                            page={page}
                                            onChange={handlePageChange}
                                        />
                                    </Box>
                                )}
                            </Box>
                        </>
                    )}
                </>
            )}
        </>
    )
}
