import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { PreApprovedItemsAccessTile } from '../AccessTile'
import { PreApprovedItemWrapper } from '../WrapperForPreApprovedAccessItem'

export type PreApprovedMembershipAccessProps = {
    item?: any
    handleViewRequest: () => void
    canActivateNow: boolean
}

export const PreApprovedMembershipAccess: FC<PreApprovedMembershipAccessProps> = (
    props: PreApprovedMembershipAccessProps,
) => {
    const { item, handleViewRequest, canActivateNow } = props
    const { t } = useTranslation()
    return (
        <PreApprovedItemWrapper
            title={t('Common_MembershipBasedExistingAccess')}
            itemCount={item?.length ? item.length : 0}
        >
            {item?.length > 0 &&
                item.map((resource: any, index: number) => (
                    <PreApprovedItemsAccessTile
                        handleViewRequest={handleViewRequest}
                        key={`item-pre-approved-${index}`}
                        item={{
                            ...resource,
                            type: resource?.resourceAssignment?.isAssigned
                                ? 'membership'
                                : 'preapprovedActivate',
                        }}
                        accessName={
                            resource?.resourceAssignment?.friendlyName ?? '-'
                        }
                        accessType={
                            resource?.resourceAssignment?.isAssigned
                                ? t('Common_Membership')
                                : t('Common_PreApproved')
                        }
                        canActivateNow={canActivateNow}
                    />
                ))}
        </PreApprovedItemWrapper>
    )
}
