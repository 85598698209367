import { Box, makeStyles, styled, Button, Dialog, Typography, IconButton } from '@material-ui/core'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Icon } from 'packages/eid-icons'
import moment from 'moment'
import MasterLockOverlay from 'packages/eid-ui/MasterLockOverlay'
import ComputerConnectBtn from 'components/DynamicComponents/ComputerConnectButton'

const mobileScreenWidth = '767px'
const PaddedDiv = styled(Box)({
  padding: '0 31px',
})

const useDialogStyles = makeStyles(() => ({
  paper: {
    maxWidth: '1000px',
    width: '840px',
    [`@media (max-width:${mobileScreenWidth})`]: {
      margin: '8px ',
      width: 'calc(100% - 16px) !important',
      maxWidth: 'calc(100% - 16px) !important ',
    },
  },
}))

const useStyles = makeStyles({
  body: ({ }) => ({
    backgroundColor: '#fbfbfd',
    padding: '24px 40px',
    boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',

    '&:focus': {
      outline: 'none',
    },
  }),
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '80px',
    alignItems: 'center',
    padding: '16px 12px 16px 32px',
    backgroundColor: '#ffffff',
  },
  closeIcon: {
    display: 'flex',
  },
  radioBtn: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row'
  },
  title: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: '#3b454d',
  },
  sectionHeader: {
    overflowWrap: 'break-word',
    wordBreak: 'normal',
    color: '#000000 !important',
    overflow: 'hidden',
    lineHeight: '15px',
    fontSize: '14px',
    padding: '15px 31px',
    borderTop: '1px solid rgba(0, 0, 0, .05)',
    borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
    fontWeight: 'bold',
    boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
    backgroundImage:
      'linear-gradient(0deg, rgb(0, 0, 0, 0.02) 12.5%, rgb(255, 255, 255) 12.5%, rgb(255, 255, 255) 50%, rgb(0, 0, 0, 0.02) 50%, rgb(0, 0, 0, 0.02) 62.5%, rgb(255, 255, 255) 62.5%, rgb(255, 255, 255) 100%)',
    backgroundSize: '8.00px 8.00px',
    '& svg': {
      margin: '0px !important',
    },

  },
  bodyinner: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginBottom: '16px',
  },
  bodyleftinner: {
    maxWidth: '232px',
    width: '100%',
  },
  bodyrightinner: {

  },
  bodyleftinnertitle: {
    fontSize: '12px',
    fontWeight: 500,
    textTransform: 'uppercase',
    color: '#9b9b9b',
    margin: 0,
  },
  bodyrightinnerresult: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#000',
    margin: 0,
  },
  bodyrightresult: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#307fc1',
    margin: 0,
  },
  footer: {


  },
  tabconnectbtn: {
    '& > div div': {
      height: 'auto !important',

    },
    '& button': {
      width: '200px',
      height: '46px',
      '& span span div': {
        width: '45px',
        display: 'flex',
        justifyContent: 'center',
        height: '100% !important',
      },
      '& div p': {
        fontSize: '16px !important',
      },
    },
  },

})

export interface ComputerSessionRequestDetailsProps {
  item: any,
  open: boolean,
  onClose: () => void,
  title: string,
  canCheckIn: boolean,
  handleCheckIn: () => void,
  handleConnect: () => void,
  isLoading: boolean,
  handleMasterUnlock: () => void,
  masterPassword: any
  parent: any
}

export const ComputerSessionRequestDetails: FC<ComputerSessionRequestDetailsProps> = (props) => {
  const { open, onClose, title, item, canCheckIn, isLoading, handleMasterUnlock, masterPassword, parent } = props
  const classes = useStyles()
  const { t } = useTranslation()
  const dialogClasses = useDialogStyles()

  const getTimeValues = (startTimeUtc: any, endTimeUtc: any) => {
    if (startTimeUtc && endTimeUtc) {
      const timeDifference = moment(endTimeUtc).diff(moment(startTimeUtc), 'minutes');
      const h = Math.floor(timeDifference / 60);
      const m = Math.floor(timeDifference % 60);
      const hDisplay = h > 0 ? h + (h == 1 ? " hour " : " hours ") : "";
      const mDisplay = m > 0 ? m + (m == 1 ? " minute " : " minutes ") : "";
      return hDisplay + mDisplay;
    }
    return '---'
  }
  return (
    <Dialog
      scroll={'body'}
      open={open}
      onClose={onClose}
      aria-labelledby={title}
      aria-describedby={title}
      classes={dialogClasses}
    >
      <Box className={classes.modalHeader}>
        <Typography className={classes.title}>{title ? title : '---'}</Typography>

        <Box className={classes.closeIcon}>
          <IconButton onClick={onClose}>
            <Icon name="Close" color="#959598" />
          </IconButton>
        </Box>
      </Box>
      <Box className={classes.sectionHeader}>{t('Common_SessionRequestDetails')}</Box>
      <Box className={classes.body}>
        <Box className={classes.bodyinner}>
          <Box className={classes.bodyleftinner}>
            <Box component="h2" className={classes.bodyleftinnertitle}>
              {t('Common_Credentials')}
            </Box>
          </Box>
          <Box className={classes.bodyrightinner}>
            <Box component="h2" className={classes.bodyrightinnerresult}>
              {item.credentialFriendlyName ? item.credentialFriendlyName : '---'}
            </Box>
          </Box>
        </Box>
        <Box className={classes.bodyinner}>
          <Box className={classes.bodyleftinner}>
            <Box component="h2" className={classes.bodyleftinnertitle}>
              {t('Common_WhoRequested')}
            </Box>
          </Box>
          <Box className={classes.bodyrightinner}>
            <Box component="h2" className={classes.bodyrightinnerresult}>
              {item.requestorFriendlyName ? item.requestorFriendlyName : '---'}
            </Box>
          </Box>
        </Box>
        <Box className={classes.bodyinner}>
          <Box className={classes.bodyleftinner}>
            <Box component="h2" className={classes.bodyleftinnertitle}>
              {t('Common_WhoApproved')}
            </Box>
          </Box>
          <Box className={classes.bodyrightinner}>
            <Box component="h2" className={classes.bodyrightinnerresult}>
              {item.approverFriendlyName ? item.approverFriendlyName : '---'}
            </Box>
          </Box>
        </Box>
        <Box className={classes.bodyinner}>
          <Box className={classes.bodyleftinner}>
            <Box component="h2" className={classes.bodyleftinnertitle}>
              {t('Common_StartTime')}
            </Box>
          </Box>
          <Box className={classes.bodyrightinner}>
            <Box component="h2" className={classes.bodyrightinnerresult}>
              {item.startTimeUtc ? moment.utc(item.startTimeUtc).local().format('L LT') : '---'}
            </Box>
          </Box>
        </Box>
        <Box className={classes.bodyinner}>
          <Box className={classes.bodyleftinner}>
            <Box component="h2" className={classes.bodyleftinnertitle}>
              {t('Common_EndTime')}
            </Box>
          </Box>
          <Box className={classes.bodyrightinner}>
            <Box component="h2" className={classes.bodyrightinnerresult}>
              {item.endTimeUtc ? moment.utc(item.endTimeUtc).local().format('L LT') : '---'}
            </Box>
          </Box>
        </Box>
        <Box className={classes.bodyinner}>
          <Box className={classes.bodyleftinner}>
            <Box component="h2" className={classes.bodyleftinnertitle}>
              {t('Common_BusinessRequest')}
            </Box>
          </Box>
          <Box className={classes.bodyrightinner}>
            <Box component="h2" className={classes.bodyrightresult}>
              {item.businessRequestName ? item.businessRequestName : '---'}
            </Box>
          </Box>
        </Box>
        <Box className={classes.bodyinner}>
          <Box className={classes.bodyleftinner}>
            <Box component="h2" className={classes.bodyleftinnertitle}>
              {t('Common_BusinessRequestItem')}
            </Box>
          </Box>
          <Box className={classes.bodyrightinner}>
            <Box component="h2" className={classes.bodyrightresult}>
              {item.businessRequestItemName ? item.businessRequestItemName : '---'}
            </Box>
          </Box>
        </Box>
        <Box className={classes.bodyinner}>
          <Box className={classes.bodyleftinner}>
            <Box component="h2" className={classes.bodyleftinnertitle}>
              {t('Common_ActualCheckOutTime')}
            </Box>
          </Box>
          <Box className={classes.bodyrightinner}>
            <Box component="h2" className={classes.bodyrightinnerresult}>
              {item.userCheckedOutTimeUtc ? moment.utc(item.userCheckedOutTimeUtc).local().format('L LT') : '---'}
            </Box>
          </Box>
        </Box>
        <Box className={classes.bodyinner}>
          <Box className={classes.bodyleftinner}>
            <Box component="h2" className={classes.bodyleftinnertitle}>
              {t('Common_ActualCheckInTime')}
            </Box>
          </Box>
          <Box className={classes.bodyrightinner}>
            <Box component="h2" className={classes.bodyrightinnerresult}>
              {item.userCheckedInTimeUtc ? moment.utc(item.userCheckedInTimeUtc).local().format('L LT') : '---'}
            </Box>
          </Box>
        </Box>
        <Box className={classes.bodyinner}>
          <Box className={classes.bodyleftinner}>
            <Box component="h2" className={classes.bodyleftinnertitle}>
              {t('Common_UserAccount')}
            </Box>
          </Box>
          <Box className={classes.bodyrightinner}>
            <Box component="h2" className={classes.bodyrightresult}>
              {item.credentialFriendlyName ? item.credentialFriendlyName : '---'}
            </Box>
          </Box>
        </Box>
        <Box className={classes.bodyinner}>
          <Box className={classes.bodyleftinner}>
            <Box component="h2" className={classes.bodyleftinnertitle}>
              {t('Common_TimeRequested')}
            </Box>
          </Box>
          <Box className={classes.bodyrightinner}>
            <Box component="h2" className={classes.bodyrightinnerresult}>
              {getTimeValues(item.startTimeUtc, item.endTimeUtc)}
            </Box>
          </Box>
        </Box>
        <Box className={classes.bodyinner}>
          <Box className={classes.bodyleftinner}>
            <Box component="h2" className={classes.bodyleftinnertitle}>
              {t('Common_TimeUsed')}
            </Box>
          </Box>
          <Box className={classes.bodyrightinner}>
            <Box component="h2" className={classes.bodyrightinnerresult}>
              {getTimeValues(item.userCheckedOutTimeUtc, item.userCheckedInTimeUtc)}
            </Box>
          </Box>
        </Box>
        <Box className={classes.bodyinner}>
          <Box className={classes.bodyleftinner}>
            <Box component="h2" className={classes.bodyleftinnertitle}>
              {t('Common_CheckoutBy')}
            </Box>
          </Box>
          <Box className={classes.bodyrightinner}>
            <Box component="h2" className={classes.bodyrightresult}>
              {item.requestorFriendlyName ? item.requestorFriendlyName : '---'}
            </Box>
          </Box>
        </Box>
        <Box className={classes.bodyinner}>
          <Box className={classes.bodyleftinner}>
            <Box component="h2" className={classes.bodyleftinnertitle}>
              {t('Common_CheckinBy')}
            </Box>
          </Box>
          <Box className={classes.bodyrightinner}>
            <Box component="h2" className={classes.bodyrightresult}>
              {item.checkedInByPersonName ? item.checkedInByPersonName : '---'}
            </Box>
          </Box>
        </Box>
      </Box>
      {canCheckIn && <Box className={classes.footer}>
        <MasterLockOverlay btnLabel={t('Common_Unlock')} showMasterLock={!masterPassword} handleMasterUnlock={() => handleMasterUnlock()}>
          <Box display="flex" width="100%" padding="24px 32px">
            <Box minWidth="167px">
              <Box className={classes.tabconnectbtn}>
                <ComputerConnectBtn parentStyle={{}} showPrefixIcon={true} data={parent} drawerView={false} showCalenderIcon={false} />
              </Box>
            </Box>
          </Box>
        </MasterLockOverlay>
      </Box>}

    </Dialog >
  )
}

export default ComputerSessionRequestDetails
