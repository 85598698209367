import { Box, Button, Link, styled, Typography } from '@material-ui/core'
import { AppliedFilters, DeleteFilters } from 'components'
import ItemCardSkeleton from '../ListingPage/ItemCardSkeleton'
import { useHistory } from 'react-router'
import { useQuery } from 'packages/core'
import { CardList } from 'packages/eid-controls'
import ItemCard from './ItemCard'
import { useTranslation } from 'react-i18next'
import { Divider, Spinner, Tooltip } from 'packages/eid-ui'
import StickyContainer from 'components/StickyContainer'
import { useCurrentPerson, useTargetPerson } from 'hooks'
import { ApplicationBanner } from 'components/ApplicationBanner'
import useSubcomponents from 'useSubcomponents'
import Selectors from 'containers/Selectors'
import SideDrawer from './DetailsDrawer'
import { ArrowIcon } from 'packages/eid-icons'
import { ManageAccessNavigation } from 'components/ManageAccessNavigation'
import appConfig from 'config'

const maxWidth = '420px'
const flexCenter = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}

const ItemsContainer = styled('div')(({ top }) => ({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    paddingTop: top ? top : '16px',
    maxWidth: maxWidth,
    margin: 'auto',
    paddingLeft: '6px',
    paddingRight: '6px',
}))

const TextButton = styled(Link)({
    textDecoration: 'none',
    backgroundColor: 'transparent',
    border: 'none',
    color: '#000000',
    fontSize: '12px',
    '&:focus': {
        outline: 'none',
    },
})

const StyledButton = styled(({ children, label, subLabel, ...other }) => (
    <Button {...other}>
        {children ? (
            children
        ) : (
            <>
                <Typography
                    style={{
                        color: '#363636',
                        fontSize: '14px',
                        lineHeight: '16px',
                    }}
                >
                    {label}
                </Typography>
                <Typography
                    style={{
                        color: '#8b909a',
                        fontSize: '11px',
                        lineHeight: '13px',
                    }}
                >
                    {subLabel}
                </Typography>
            </>
        )}
    </Button>
))(({ border, styles }) => ({
    width: '100%',
    maxWidth: '260px',
    backgroundColor: '#ffffff',
    border: border ? border : '1px solid #d2d2d9',
    boxShadow: 'none',
    textTransform: 'capitalize',
    '&:hover': {
        backgroundColor: '#ffffff',
        border: border ? border : '1px solid #d2d2d9',
        boxShadow: 'none',
    },
    ...styles,
}))

const ListingPageMobile = ({
    totalCount,
    loading,
    loadingMore,
    infiniteScrollObervableDiv,
    id,
    data,
    onItemClick,
    currentResourceType,
    resourceTypeAttributes,
    handleSaveFilters,
    savingFilters,
}) => {
    const { t } = useTranslation()
    const history = useHistory()
    const query = useQuery()

    const { data: currentPerson } = useCurrentPerson()

    const { canSeeAtLeastOneFilter } = useSubcomponents()
    const canSeeAnyFilter = canSeeAtLeastOneFilter(currentResourceType)

    const [targetPerson] = useTargetPerson()
    const shoppingForSomeoneElse = targetPerson.id !== currentPerson.id

    const cartOpen = query.get('cart') === 'visible'
    const showFilters = query.get('filters') === 'visible'
    const applicationId = query.get('applicationId')

    const stickyContentListing = (
        <>
            {applicationId && (
                <Box paddingX="8px" mb={4} mt={2}>
                    <ApplicationBanner applicationId={applicationId} />
                </Box>
            )}
            <div style={{ ...flexCenter }}>
                <Typography
                    style={{
                        fontSize: '24px',
                        fontWeight: 'bold',
                        color: '#363636',
                    }}
                >
                    {t(currentResourceType.title)}
                </Typography>
                <Typography
                    style={{
                        paddingLeft: '8px',
                        color: '#363636',
                        fontSize: '14px',
                    }}
                >
                    {totalCount > 0 ? ` (${totalCount})` : ' (0)'}
                </Typography>
            </div>

            <Box margin="8px 0px 12px 0px" padding="0px 8px">
                <Divider color="#d2d2d9" />
            </Box>
            {canSeeAnyFilter && (
                <div style={{ ...flexCenter }}>
                    <StyledButton
                        label={t('Common_Filters')}
                        subLabel={t('Common_ApplyFilters')}
                        onClick={() => {
                            query.set('filters', 'visible')
                            history.push(
                                `${
                                    history.location.pathname
                                }?${query.toString()}`,
                            )
                        }}
                        styles={{
                            '& > span': {
                                display: 'flex ',
                                flexDirection: 'column',
                            },
                        }}
                    />
                </div>
            )}
        </>
    )
    const listingScreen = (
        <>
            <Box
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    textAlign: 'center',
                }}
            >
                <Box maxWidth={maxWidth} width="100%" padding="12px 0px">
                    {stickyContentListing}
                </Box>
            </Box>

            <ItemsContainer top={20}>
                <CardList
                    infiniteScrollObervableDiv={infiniteScrollObervableDiv}
                    noItemMessage={
                        <Typography variant="h5" style={{ color: '#b4b4b4' }}>
                            {t('Common_NoItemsFound', {
                                itemType: t(currentResourceType.title),
                            })}
                        </Typography>
                    }
                    loadingIndicator={
                        <>
                            <ItemCardSkeleton />
                            <ItemCardSkeleton />
                            <ItemCardSkeleton />
                            <ItemCardSkeleton />
                        </>
                    }
                    fetcher={() => ({
                        loading,
                        loadingMore,
                        list: data,
                    })}
                    renderItem={(item) => (
                        <div
                            key={item.id}
                            style={{
                                padding: '8px',
                                height: 'inline-block',
                                width: '100%',
                            }}
                        >
                            <ItemCard
                                cardStyles={{
                                    maxWidth: '420px',
                                    margin: 'auto',
                                }}
                                key={item.id}
                                attributes={resourceTypeAttributes}
                                buttonStyles={{
                                    '&:hover': {
                                        backgroundColor: '#02997E',
                                    },
                                }}
                                item={item}
                                onItemClick={() => onItemClick(item)}
                                color={currentResourceType.color}
                            />
                        </div>
                    )}
                />
            </ItemsContainer>
        </>
    )

    const fixedContainerStyles = {
        position: 'sticky',
        top: 0,
        boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)',
    }
    const filtersScreen = (
        <>
            <StickyContainer color="#ffffff" styles={fixedContainerStyles}>
                <Box maxWidth={maxWidth} width="100%">
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        padding="12px 12px 0px 12px"
                    >
                        <Typography
                            style={{
                                fontSize: '20px',
                                fontWeight: 'bold',
                                color: '#363636',
                            }}
                        >
                            {t(currentResourceType.title)}

                            {totalCount > 0 ? ` (${totalCount})` : ' (0)'}
                        </Typography>

                        <Box>
                            <StyledButton
                                onClick={() => {
                                    query.delete('filters')
                                    history.push(
                                        `${
                                            history.location.pathname
                                        }?${query.toString()}`,
                                    )
                                }}
                                border="none"
                                styles={{
                                    color: '#ffffff',
                                    backgroundColor: '#ff5e65',
                                    width: '120px',
                                    '&:hover': {
                                        backgroundColor: '#ff5e65',
                                        boxShadow: 'none',
                                    },
                                }}
                            >
                                {t('Common_ApplyFilters')}
                            </StyledButton>
                        </Box>
                    </Box>

                    <Box margin="12px 0px" padding="0px 8px">
                        <Divider color="#d2d2d9" />
                    </Box>

                    <Box
                        padding="0px 12px"
                        display="flex"
                        justifyContent="space-between"
                    >
                        <DeleteFilters
                            resourceType={currentResourceType.name}
                        />
                        <Tooltip
                            title={
                                shoppingForSomeoneElse
                                    ? t(
                                          'Common_SaveFiltersDisableForSomeoneElseHelpText',
                                      )
                                    : t('Common_SaveFiltersHelpText')
                            }
                            aria-label={
                                shoppingForSomeoneElse
                                    ? t(
                                          'Common_SaveFiltersDisableForSomeoneElseHelpText',
                                      )
                                    : t('Common_SaveFiltersHelpText')
                            }
                        >
                            <TextButton
                                component="button"
                                style={{
                                    color: shoppingForSomeoneElse
                                        ? '#b4b4b4'
                                        : '#000000',
                                    cursor: shoppingForSomeoneElse
                                        ? 'default'
                                        : 'pointer',
                                }}
                                onClick={
                                    shoppingForSomeoneElse
                                        ? undefined
                                        : handleSaveFilters
                                }
                            >
                                <Box
                                    display="flex"
                                    width="100%"
                                    justifyContent="center"
                                >
                                    {savingFilters ? (
                                        <Spinner size={0.5} color="#01ae8f" />
                                    ) : (
                                        t('Common_SaveFilters')
                                    )}
                                </Box>
                            </TextButton>
                        </Tooltip>
                    </Box>

                    <Box margin="12px 0px" padding="0px 8px">
                        <Divider color="#d2d2d9" />
                    </Box>

                    <Box
                        style={{
                            maxHeight: '90px',
                            overflow: 'auto',
                            paddingBottom: '6px',
                        }}
                    >
                        <AppliedFilters
                            resourceType={currentResourceType.name}
                        />
                    </Box>
                </Box>
            </StickyContainer>

            <ItemsContainer>
                <ManageAccessNavigation />
                <Selectors resourceType={currentResourceType.name} />
            </ItemsContainer>
        </>
    )

    const itemDetailsScreen = (
        <>
            <StickyContainer
                color="#ffffff"
                styles={{
                    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)',
                    height: '70px',
                }}
            >
                <Box
                    maxWidth={maxWidth}
                    width="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <StyledButton
                        onClick={() => {
                            query.delete('selected')
                            history.push(
                                `${appConfig.APP_SUBPATH}/${
                                    currentResourceType.name
                                }?${query.toString()}`,
                            )
                        }}
                        border=" 1px solid #307fc1"
                        styles={{
                            color: '#307fc1',
                            fontSize: '14px',
                        }}
                        startIcon={
                            <ArrowIcon
                                style={{ transform: 'rotate(-90deg)' }}
                                color="#307fc1"
                            />
                        }
                    >
                        {t('Common_BackTo', {
                            text: t(currentResourceType.title),
                        })}
                    </StyledButton>
                </Box>
            </StickyContainer>
            <ItemsContainer top={86}>
                <SideDrawer
                    id={id}
                    toggleDrawer={() => {
                        query.delete('selected')
                        history.push(
                            `${appConfig.APP_SUBPATH}/${
                                currentResourceType.name
                            }?${query.toString()}`,
                        )
                    }}
                    attributes={resourceTypeAttributes}
                    type={currentResourceType.name}
                    color={currentResourceType.color}
                />
            </ItemsContainer>
        </>
    )

    return (
        <>
            {!cartOpen && (
                <>
                    <Box display={showFilters ? '' : 'none'}>
                        {filtersScreen}
                    </Box>
                    <Box display={!showFilters ? '' : 'none'}>
                        <Box display={!id ? '' : 'none'}>{listingScreen}</Box>
                        <Box display={id ? '' : 'none'}>
                            {itemDetailsScreen}
                        </Box>
                    </Box>
                </>
            )}
        </>
    )
}

export default ListingPageMobile
