import React from 'react'
import { useIsSmallScreen } from 'packages/core'
import DesktopMenu from './DesktopMenu'
import MobileMenu from './MobileMenu'

const ITShopHeader = () => {
    const isSmallScreen = useIsSmallScreen()
    return isSmallScreen ? <MobileMenu /> : <DesktopMenu />
}

export default ITShopHeader
