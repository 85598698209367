import { useRef } from 'react'
import {
    Box,
    styled,
    Typography,
    makeStyles,
    MenuItem,
} from '@material-ui/core'
import { Icon } from 'packages/eid-icons'
import { Dropdown } from 'packages/eid-ui'
import Loader from './Loader'

const smallScreenWidth = '1022px'

const StyledMenuItem = styled(MenuItem)({
    minWidth: '160px',
    color: '#000000',
    borderLeft: '1.5px solid transparent',
    '&.Mui-selected': {
        borderLeft: '1.5px solid #307fc1',
        backgroundColor: 'rgba(48, 127, 193, 0.07) !important',
        '&:hover': {
            backgroundColor: 'rgba(48, 127, 193, 0.07) !important',
        },
        '& svg': {
            color: '#000000',
        },
    },

    '& svg': {
        marginRight: '10px',
    },
    '&:hover': {
        color: '#307fc1',
        backgroundColor: 'inherit !important',
        borderLeft: '1.5px solid #307fc1',
        '& svg': {
            color: '#307fc1',
        },
    },
})

const menuStyles = {
    paddingRight: '0px',
}
const listStyles = {
    paddingTop: '0px',
    paddingBottom: '0px',
}

const Container = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    width: `calc(100% + 40px)`,
    position: 'relative',
    paddingBottom: '4px',
    height: '45px',
    '&:hover': {
        cursor: 'pointer',
        '& .Gradient-border': {
            backgroundImage: 'linear-gradient(91deg, #01ae8f, #307fc1)',
        },

        '& .Down-Arrow': {
            color: '#307fc1',
        },
    },

    [`@media (max-width:${smallScreenWidth})`]: {
        // minWidth: '190px !important',
    },
})

const Border = styled(Box)(({ styles, open }) => ({
    position: 'absolute',
    bottom: 0,
    left: 0,
    borderWidth: '2px',
    height: '3px',
    width: '100%',
    backgroundColor: !open && '#307fc1',
    backgroundImage: open && 'linear-gradient(91deg, #01ae8f, #307fc1)',
    marginTop: '10px',
    ...styles,
}))

const useStyles = makeStyles(() => ({
    dropdownAnchor: ({ anchorPosition }) => ({
        position: 'relative',
        top: '45px',
        ...anchorPosition,
    }),
    icon: {
        transform: 'rotate(90deg)',
    },

    subLabel: {
        color: '#307fc1',
        fontSize: '27px',
        fontWeight: 'bold',
        [`@media (max-width:${smallScreenWidth})`]: {
            fontSize: '22px',
        },
    },
    totalCountLabel: {
        borderRadius: '8px',
        minWidth: '28px',
        height: '20px',
        backgroundColor: '#307fc1',
        color: '#ffffff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: '10px',
    },
}))

const DropdownFilter = ({
    label,
    style,
    anchorPosition,
    showCone,
    loading,
    open,
    setOpen,
    totalCount,
    showTotalCount,
    children,
}) => {
    const classes = useStyles({ anchorPosition })

    const anchorRef = useRef(null)

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return
        }

        setOpen(false)
    }

    const handleListKeyDown = (event) => {
        if (event.key === 'Tab') {
            event.preventDefault()
            setOpen(false)
        }
    }

    return (
        <>
            <Container
                style={{ ...style }}
                onClick={() => setOpen((prev) => !prev)}
            >
                <span ref={anchorRef} className={classes.dropdownAnchor} />

                <Typography display="block" className={classes.subLabel}>
                    {label}
                </Typography>

                <Box
                    marginLeft="6px"
                    position="absolute"
                    top="20px"
                    right="-4px"
                    height="24px"
                    display="flex"
                    alignItems="center"
                >
                    {showTotalCount && (
                        <Box paddingX="7px" className={classes.totalCountLabel}>
                            {totalCount ? totalCount : 0}
                        </Box>
                    )}

                    <Icon
                        className="Down-Arrow"
                        name="Arrow"
                        color={open ? '#307fc1' : '#808080'}
                    />
                </Box>

                <Border className="Gradient-border" open={open} />
            </Container>
            <Dropdown
                open={open}
                anchorEl={anchorRef.current}
                handleClose={handleClose}
                handleListKeyDown={handleListKeyDown}
                showCone={showCone}
                menuHeight="50vh"
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                rootStyles={menuStyles}
                listStyles={listStyles}
            >
                {loading && <Loader />}
                {!loading && children}
            </Dropdown>
        </>
    )
}

DropdownFilter.Item = StyledMenuItem
export default DropdownFilter
