import { useEffect, useState } from 'react'
import appConfig from 'config'
import './guacamole-player.min.css'
import { useComputerLiveSessionToken } from 'hooks'
import { useTranslation } from 'react-i18next'

const GuacamolePlayer = ({ session, url, header }) => {
    const { t } = useTranslation()
    const [apiCall, setApiCall] = useState(false)
    const { data: tokenString } = useComputerLiveSessionToken(
        session.id,
        apiCall,
    )
    const isLive = session.canTerminateSession

    const guacamolePlaybackScript = document.createElement('script')
    const guacamoleMainScript = document.createElement('script')
    const jQueryScript = document.createElement('script')
    const guacamoleSnoopScript = document.createElement('script')

    useEffect(() => {
        guacamolePlaybackScript.src = '/Guacamole/guacamole-playback.js'
        guacamolePlaybackScript.async = true

        guacamoleMainScript.src = '/Guacamole/guacamole.js'
        guacamoleMainScript.async = true

        jQueryScript.src = '/Guacamole/jquery-1.9.1.min.js'
        jQueryScript.async = true

        guacamoleSnoopScript.src = '/Guacamole/guacamole-snoop.js'
        guacamoleSnoopScript.async = true

        if (!isLive) {
            document.body.appendChild(guacamolePlaybackScript)
            jQueryScript.onload = function () {
                window.get(url, header)
            }
        } else {
            document.body.appendChild(guacamoleSnoopScript)
        }

        document.body.appendChild(guacamoleMainScript)
        document.body.appendChild(jQueryScript)

        return () => {
            if (!isLive) {
                document.body.removeChild(guacamolePlaybackScript)
            } else {
                document.body.removeChild(guacamoleSnoopScript)
            }
            document.body.removeChild(guacamoleMainScript)
            document.body.removeChild(jQueryScript)
        }
    }, [])

    useEffect(() => {
        if (tokenString) {
            var win = window.open('')
            var scriptHeader =
                '<head>' +
                guacamoleMainScript.outerHTML +
                '\n' +
                jQueryScript.outerHTML +
                '\n' +
                guacamoleSnoopScript.outerHTML +
                '<title>Monitoring - ' +
                session.computerFriendlyName +
                '</title></head>'
            win.document.write(scriptHeader)
            win.document.write(
                "<body><p id='token' style='opacity: 0'>" +
                    tokenString +
                    "</p><div id='display'></div><p id='GatewayServerName' style='opacity: 0'>" +
                    session.gatewayServerName +
                    '</p></body>',
            )
        }
    }, [tokenString])

    const handleConnect = () => {
        setApiCall(true)
    }

    const LivePlayer = (
        <div
            id="session-active"
            style={{
                border: '1px solid rgba(0, 0, 0, 0.25098039215686274)',
                padding: '20px',
                width: '50%',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: '4%',
            }}
        >
            <div id="card">
                <h2>{t('Computers_LiveSession')}</h2>
                <p>{t('Computers_LiveSessionMessage')}</p>
                <br />
                <button
                    id="connect"
                    style={{
                        width: '100%',
                        paddingTop: '10px',
                        paddingBottom: '10px',
                    }}
                    onClick={() => handleConnect()}
                >
                    {t('Connect')}
                </button>
            </div>
        </div>
    )

    return (
        <>
            {!isLive && (
                <div id="player">
                    <div id="display">
                        <div className="notification-container">
                            <div className="seek-notification">
                                <p>
                                    {t('Computers_SeekInProgress')}
                                    <button id="cancel-seek">
                                        {t('Common_Cancel')}
                                    </button>
                                </p>
                            </div>
                        </div>
                        <div
                            stlye={{
                                position: 'relative',
                                width: '1536px',
                                height: '0px',
                            }}
                        >
                            <div
                                id="session"
                                style={{
                                    position: 'relative',
                                    width: '1536px',
                                    height: '518px',
                                    transformOrigin: '0px 0px',
                                }}
                            >
                                <div
                                    style={{
                                        width: '1536px',
                                        height: '518px',
                                        position: 'absolute',
                                        left: '0px',
                                        top: '0px',
                                        overflow: 'hidden',
                                    }}
                                ></div>
                            </div>
                        </div>
                    </div>
                    <div className="controls">
                        <button id="play-pause">{t('Play')}</button>
                        <img
                            id="replay"
                            style={{ height: '20px', cursor: 'pointer' }}
                            src={
                                appConfig.BASE_EID_URL +
                                '/webcdn/images/icons/32x32/reload.png'
                            }
                        />
                        <input
                            id="position-slider"
                            type="range"
                            max="2922342"
                        />
                        <span id="position">00:00</span>
                        <span>/</span>
                        <span id="duration">00:00</span>
                    </div>
                </div>
            )}
            {isLive && LivePlayer}
        </>
    )
}
export default GuacamolePlayer
