import { useQuery } from 'react-query'
import { useAxios } from 'packages/core'
import { IAccessRequestPolicy } from '../core'
import * as R from 'ramda'

export const APPLICATION_SETTINGS_KEY = 'APPLICATION_SETTINGS'
export const ALL_APPLICATIONS_KEY = 'ALL_APPLICATIONS'
export const ENVIRONMENT_NAME_KEY = 'ENVIRONMENT_NAME'
export const HELP_TEXT_KEY = 'HELP_TEXT'
export const REQUEST_POLICIES_KEY = 'REQUEST_POLICIES'

export const useApplicationSettings = () => {
    const callApi = useAxios()
    return useQuery(
        APPLICATION_SETTINGS_KEY,
        () =>
            callApi({
                method: 'GET',
                url: '/api/application/settings',
            }).then((data) => data.data),
        {
            staleTime: Infinity,
        },
    )
}

export const useAllApplications = () => {
    const callApi = useAxios()
    return useQuery(
        ALL_APPLICATIONS_KEY,
        () =>
            callApi({
                method: 'GET',
                url: '/api/access/applications',
            }).then((data) => data.data),
        {
            staleTime: Infinity,
        },
    )
}

export const useEnvironment = () => {
    const callApi = useAxios()
    return useQuery(
        ENVIRONMENT_NAME_KEY,
        () =>
            callApi({
                method: 'GET',
                url: '/api/application/environmentName',
            }).then((data) => data.data),
        {
            staleTime: Infinity,
        },
    )
}

export const useHelpText = (enabled: boolean) => {
    const callApi = useAxios()
    return useQuery(
        HELP_TEXT_KEY,
        () =>
            callApi({
                method: 'GET',
                url: '/api/application/helpText',
            }).then((data) => data.data),
        {
            staleTime: Infinity,
            enabled,
        },
    )
}

const _reducePolicies: (
    policies: IAccessRequestPolicy[],
) => Record<string, IAccessRequestPolicy> = (policies) => {
    return R.reduce<IAccessRequestPolicy, Record<string, IAccessRequestPolicy>>(
        (result, policy) => {
            result[policy.id] = policy
            return result
        },
        {},
        policies,
    )
}

export const useAccessRequestPolicies = () => {
    const callApi = useAxios()
    return useQuery<Record<string, IAccessRequestPolicy>>(
        REQUEST_POLICIES_KEY,
        () =>
            callApi({
                method: 'GET',
                url: '/api/application/requestPolicies?take=50',
            }).then((data) => _reducePolicies(data.data)),
        {
            staleTime: Infinity,
        },
    )
}

export const useBusinessRoleAccessRequestPolicy = (
    roleId: string,
    differentiationValueId: string,
) => {
    const callApi = useAxios()
    return useQuery(
        [REQUEST_POLICIES_KEY, 'BUSINESS_ROLE', roleId, differentiationValueId],
        () =>
            callApi({
                method: 'GET',
                url: `/api/businessRoles/accessRequestPolicy/${roleId}/${differentiationValueId}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(roleId) && Boolean(differentiationValueId),
        },
    )
}

export const useAccessRequestPolicy = (
    policyId: string,
    roleId: string = '',
    diffValueId: string = '',
) => {
    const { data: requestAccessPolicies } = useAccessRequestPolicies()

    const { data: brPolicy } = useBusinessRoleAccessRequestPolicy(
        roleId,
        diffValueId,
    )

    if (requestAccessPolicies === undefined) return undefined
    if (roleId !== '') return brPolicy

    const policy: IAccessRequestPolicy = requestAccessPolicies[policyId]

    return policy
}
