import { Loader } from 'packages/eid-ui'
import {
    MenuItem,
    makeStyles,
    Typography,
    ListItemIcon,
    Paper,
} from '@material-ui/core'
import { useHistory } from 'react-router'
import { useQuery } from 'packages/core'
import { Icon } from 'packages/eid-icons'
import { useUiActionsByResource } from 'hooks'
import appConfig from 'config'
import { useTranslation } from 'react-i18next'

const useMenuStyles = makeStyles({
    root: {
        fontSize: '14px',
        color: '#307fc1',
        position: 'relative',
        minHeight: '40px',
        borderLeft: '2px solid transparent',
        whiteSpace: 'normal',
        '&:hover': {
            backgroundColor: 'rgba(48, 127, 193, 0.07) !important',
            borderLeft: '2px solid #307fc1',
            '& svg': {
                opacity: 1,
                filter: 'grayscale(0%) ',
                color: 'blue',
            },
        },
    },
    icon: {
        minWidth: '20px',
        color: '#aab0b4',
        '& svg': {
            height: '16px',
            width: '16px',
        },
    },
    noActionMessage: {
        minWidth: '40px',
        fontSize: '14px',
        padding: '10px'
    }
})

const ActionsList = ({ workflows, resourceId, route }) => {
    const classes = useMenuStyles()
    const {t} = useTranslation()
    const query = useQuery()
    const history = useHistory()

    const handleClick = (name) => {
        query.set('workflowName', name)
        query.set('resourceID', `${resourceId}`)
        query.delete('selected')
        history.push(
            `${appConfig.APP_SUBPATH}/workflows/${route}?${query.toString()}`,
        )
    }

    return (
        <>
            {workflows && workflows.length > 0 ?
                workflows.map((d, i) => (
                    <MenuItem
                        onClick={() => handleClick(d.requestWorkflowName)}
                        classes={classes}
                        key={i}
                    >
                        <ListItemIcon className={classes.icon}>
                            <Icon name="Arrow" direction="right" color='#aab0b4' />
                        </ListItemIcon>
                        {d.friendlyName}
                    </MenuItem>
                )) : <Typography className={classes.noActionMessage}>
                    {t('NoActionFound')}
                </Typography>
            }
        </>
    )
}

const WorkflowActions = ({ resource, route }) => {
    const { data, isLoading } = useUiActionsByResource(resource.id)
    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <Paper
                    style={{
                        borderRadius: '5px',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <ActionsList
                        workflows={data}
                        resourceId={resource.id}
                        route={route}
                    ></ActionsList>
                </Paper>
            )}
        </>
    )
}

export default WorkflowActions
