import { useEffect } from 'react'
import {
    useIsSmallScreen,
    useQuery,
    useUrlGenerator,
    useGetControlsAccess,
} from 'packages/core'
import Layout from 'components/Layout'
import DesktopVersion from './ManageAccessDesktopVersion'
import MobileVersion from './ManageAccessMobileVersion'
import useConfiguration from 'useConfiguration'
import { useTranslation } from 'react-i18next'
import { useTargetPerson, useAllApplications, useDefaultFilters } from 'hooks'
import useManageAccessResources from '../ManageAccess/useManageAccessResources'
import useAppliedFilterValues from 'components/AppliedFilters/useAppliedFilterValues'
import ViewPendingAccess from '../ManageAccess/ViewPendingAccess'
import { useResourceTypeContext } from 'resourceTypeContext'
import SubHeader from 'components/SubHeader'
import { Box, styled } from '@material-ui/core'
import { useHistory } from 'react-router'
import { useViewIndicator } from 'packages/eid-ui'

const SubHeaderContainer = styled(Box)({
    position: 'fixed',
    top: 70,
    width: '100%',
    maxWidth: '1920px',

    display: 'flex',

    paddingTop: '10px',
    paddingRight: '20px',
    paddingLeft: '90px',

    backgroundColor: '#eef0f4',

    zIndex: 5,
})

const createStyles = () => {
    return {
        rootStyles: {
            position: 'relative',
            paddingBottom: '30px',

            overflowY: 'overlay !important',
        },
    }
}

const ManageAccess = ({ resourceType, ...rest }) => {
    const { t } = useTranslation()
    const isSmallScreen = useIsSmallScreen()

    const styles = createStyles()

    const [{ sorting: sortQuery }, dispatch] = useResourceTypeContext()

    const setSortQuery = (newSortQuery) => {
        dispatch({
            type: 'SET_PROP',
            payload: {
                key: 'sorting',
                value: newSortQuery,
            },
        })
    }

    const query = useQuery()

    const [targetPerson] = useTargetPerson()

    const {
        resourceTypes,
        manageAccess,
        getResourceType,
        getResourceTypeAttributes,
    } = useConfiguration()

    const currentResourceType = getResourceType(resourceType)
    const resourceTypeAttributes = getResourceTypeAttributes(
        currentResourceType.name,
        true,
    )

    const appliedFiltersValues = useAppliedFilterValues(currentResourceType)

    let attributes = resourceTypeAttributes
    if (appliedFiltersValues.some((x) => x.name === 'ShowOnlyDirectAssigned')) {
        attributes = resourceTypeAttributes.filter((x) => x.name !== 'assignee')
    }

    const controls = useGetControlsAccess()

    const title = `${t(manageAccess.title)} | ${t(currentResourceType.title)}`

    useEffect(() => {
        document.title = title
    }, [title])

    const id = query.get('selected')

    const {
        totalCount,
        loading,
        list,
        loadingMore,
        infiniteScrollObervableDiv,
    } = useManageAccessResources(currentResourceType)

    const history = useHistory()

    const {
        location: { pathname },
    } = history

    const { getMyTasksUrl } = useUrlGenerator(useAllApplications)

    const myTasksUrl = getMyTasksUrl()
    const hasAccessToViewPendingAccess =
        controls.findIndex(
            (c) => c.name === 'ITShop-ViewPendingAccess-Control',
        ) >= 0

    const viewPendingAccess = myTasksUrl && hasAccessToViewPendingAccess && (
        <Box marginRight="10px">
            <ViewPendingAccess
                label={t('ManageAccess_ViewPendingAccess')}
                url={myTasksUrl}
            />
        </Box>
    )

    const { viewIndicator } = useViewIndicator({
        view: query.get('view'),
        onCardViewClick: () => {
            query.set('view', 'card')
            history.push(`${history.location.pathname}?${query.toString()}`)
        },
        onListViewClick: () => {
            query.delete('view')
            history.push(`${history.location.pathname}?${query.toString()}`)
        },
    })

    return (
        <Layout
            rootStyles={styles.rootStyles}
            subHeader={
                !isSmallScreen && (
                    <SubHeaderContainer>
                        <SubHeader
                            totalCount={totalCount}
                            viewSwitcher={viewIndicator}
                            viewPendingAccess={viewPendingAccess}
                            resourceTypes={resourceTypes}
                            currentResourceType={currentResourceType}
                            showWorkFlows={true}
                        />
                    </SubHeaderContainer>
                )
            }
        >
            {isSmallScreen ? (
                <MobileVersion
                    id={id}
                    list={list}
                    loading={loading}
                    totalCount={totalCount}
                    loadingMore={loadingMore}
                    resourceTypes={resourceTypes}
                    targetPerson={targetPerson}
                    currentResourceType={currentResourceType}
                    resourceTypeAttributes={attributes}
                    infiniteScrollObervableDiv={infiniteScrollObervableDiv}
                    {...rest}
                />
            ) : (
                <DesktopVersion
                    id={id}
                    list={list}
                    loading={loading}
                    totalCount={totalCount}
                    loadingMore={loadingMore}
                    resourceTypes={resourceTypes}
                    targetPerson={targetPerson}
                    currentResourceType={currentResourceType}
                    resourceTypeAttributes={attributes}
                    infiniteScrollObervableDiv={infiniteScrollObervableDiv}
                    onSort={(sortBy, sortOrder) => {
                        setSortQuery({
                            sortBy:
                                sortBy.manageAccessColumnName &&
                                sortBy.manageAccessColumnName.length > 0
                                    ? sortBy.manageAccessColumnName
                                    : sortBy.name,
                            sortOrder,
                        })
                    }}
                    sortOrder={sortQuery.sortOrder}
                    sortBy={sortQuery.sortBy}
                    {...rest}
                />
            )}
        </Layout>
    )
}

const ensureFilters = (ChildComponent) => (props) => {
    const { data: onBoardingFilters } = useDefaultFilters(props.resourceType)

    if (onBoardingFilters === undefined) return <></>
    return <ChildComponent {...props} />
}

export default ensureFilters(ManageAccess)
