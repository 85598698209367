import React from 'react'
import {
    MenuItem,
    makeStyles,
    styled,
    Typography,
    Box,
} from '@material-ui/core'
import { useRemoveCart, useCurrentPerson, useTargetPerson } from 'hooks'
import { ShoppingCartIcon, Icon } from 'packages/eid-icons'
import { Avatar, Tooltip, Spinner } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'
import { useIsSmallScreen } from 'packages/core'

const IconContainer = styled('span')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 8px',
})

const CartIcon = styled(ShoppingCartIcon)(({ selected }) => ({
    color: selected ? '#307FC1' : '#B4B4B4',
    '&:hover': {
        color: '#01ae8f',
    },
}))

const useCheckoutIconStyles = makeStyles({
    root: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
})

const useDeleteIconStyles = makeStyles({
    root: {
        '&:hover': {
            cursor: 'pointer',
        },
        '& svg': {
            '&:hover': {
                '& path': { fill: '#d0021b !important' },
            },
        },
    },
})

const useStyles = makeStyles({
    root: {
        minWidth: '160px',
        color: '#534e52',
        fontSize: '13px',
        margin: '10px 10px',
        minHeight: '48px',
        borderRadius: '5px',
        padding: '7px 0px 7px 10px',
    },
    selected: {
        backgroundColor: '#e0ecf6 !important',
    },
})

const CartMenuItem = ({
    itemCount,
    targetPersonId,
    lastEditTime,
    displayName,
    avatarSrc,
    selected,
    isCurrentUserTarget,
    checkoutCart,
}) => {
    const isSmallScreen = useIsSmallScreen()
    const { data: currentPerson } = useCurrentPerson()

    const [, setTargetPerson] = useTargetPerson()

    const [removeCart, { isLoading: isRemovingCart }] = useRemoveCart(
        targetPersonId,
    )

    const classes = useStyles()

    const cartCheckoutIconClasses = useCheckoutIconStyles()
    const deleteIconClasses = useDeleteIconStyles()
    const { t } = useTranslation()

    const checkIcon = (
        <Tooltip
            title={
                selected ? t('Common_CurrentlyActive') : t('Common_SwitchCart')
            }
        >
            <IconContainer
                onClick={checkoutCart}
                classes={cartCheckoutIconClasses}
            >
                <CartIcon selected={selected} />
            </IconContainer>
        </Tooltip>
    )

    const handleCartRemoval = (e) => {
        e.stopPropagation()
        removeCart().then(() => {
            if (selected && !isCurrentUserTarget) {
                setTargetPerson(currentPerson)
            }
        })
    }

    const deleteIcon = (
        <>
            {isRemovingCart ? (
                <IconContainer classes={deleteIconClasses}>
                    <Spinner size={0.6} />
                </IconContainer>
            ) : (
                <Tooltip title={t('Common_DeleteCart')}>
                    <IconContainer classes={deleteIconClasses}>
                        <Icon
                            onClick={handleCartRemoval}
                            name="Delete"
                            color="#B4B4B4"
                        />
                    </IconContainer>
                </Tooltip>
            )}
        </>
    )

    return (
        <MenuItem classes={classes} selected={selected} onClick={checkoutCart}>
            <Avatar
                size="small"
                src={avatarSrc}
                style={{
                    border: isCurrentUserTarget ? '1px solid #01ae8f' : 'none',
                    boxShadow: isCurrentUserTarget
                        ? 'inset 0 0 0 1px #ffffff'
                        : 'none',
                }}
            />

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingLeft: '8px',
                }}
            >
                <Box
                    maxWidth="185px"
                    overflow="hidden"
                    display="flex"
                    flexWrap="wrap"
                >
                    <Typography
                        style={{
                            fontSize: '12px',
                            color: '#000000',
                            fontWeight: 'bold',
                            maxWidth: '185px',
                            wordBreak: 'break-all !important',
                        }}
                    >
                        {isCurrentUserTarget ? t('Common_Myself') : displayName}
                    </Typography>
                </Box>
                <Box
                    display="flex"
                    alignItems="center"
                    flexWrap="wrap"
                    fontSize="12px"
                    color="#a7a6a6"
                    paddingRight="20px"
                >
                    <Box
                        width={isSmallScreen ? '180px' : '195px'}
                        display="flex"
                        justifyContent="space-between"
                    >
                        <Box>
                            <Box
                                component="span"
                                fontWeight={600}
                                color="#000000"
                                marginLeft="2px"
                                marginRight="4px"
                            >
                                {itemCount}
                            </Box>
                            {t('Common_Items')}
                        </Box>
                        <Box marginLeft={"12%"} >{lastEditTime}</Box>
                    </Box>
                </Box>
            </div>

            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                }}
            >
                {!isCurrentUserTarget && deleteIcon}
            </div>
        </MenuItem>
    )
}

export default CartMenuItem
