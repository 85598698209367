import React from 'react'
import { Button as MuiButton, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { ArrowSmallIcon } from 'packages/eid-icons'

const moreInfoButtonStyles = makeStyles((theme) => ({
    root: {
        width: '102px',
        height: '31px',
        backgroundColor: '#8b909a',
        boxShadow: '0 0 0 0',
        color: '#fff',
        textTransform: 'capitalize !important',
        borderRadius: '16px',
        position: 'absolute',
        top: '-15px',
        zIndex: 1,
        fontSize: '14px',
        right: '22px',

        '&:hover': {
            backgroundColor: '#74787e',
            color: 'white',
            '& span': {
                color: '#fff',
            },
        },
    },
}))
const Button = ({ onClick, title }) => {
    const { t } = useTranslation()

    const classes = moreInfoButtonStyles()
    return (
        <MuiButton
            size="small"
            classes={classes}
            variant="outlined"
            onClick={onClick}
        >
            {title}
            <ArrowSmallIcon
                style={{ marginLeft: '5px' }}
                fill={'white'}
                direction={'right'}
            />
        </MuiButton>
    )
}
export default Button
