import { useTranslation } from "react-i18next"

const ComputersConnectedWhen = ({attribute,  data }) => {
    const whenData = data?.resource?.[attribute.name]
    const { t } = useTranslation()
    let connectedWhen = `0 ${t('min')}`
    if(whenData?.days > 0){
        connectedWhen = `${whenData.days} ${whenData.days > 1 ? t('days') : t('day')}`
    }
    if(whenData?.hours > 0){
        connectedWhen = connectedWhen === `0 ${t('min')}` ? `${whenData.hours} ${whenData.hours > 1 ? t('hours') : t('hour')}` : connectedWhen + ` ${whenData.hours} ${whenData.hours > 1 ? 'hours' : 'hour'}`
    }
    if(whenData?.minutes > 0){
        connectedWhen = connectedWhen === `0 ${t('min')}` ? `${whenData.minutes} ${whenData.minutes > 1 ? t('mins') : t('min')}` : connectedWhen + ` ${whenData.hours} ${whenData.hours > 1 ? 'mins' : 'min'}`
    }
    return (
        <>
            {connectedWhen}
        </>
    )
}

export default ComputersConnectedWhen
