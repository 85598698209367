import {
    MenuItem,
    makeStyles,
    ListItemText,
    ListItemIcon,
} from '@material-ui/core'
import { Icon } from 'packages/eid-icons'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles({
    root: {
        fontSize: '16px',
        position: 'relative',
        minHeight: '40px',
        paddingLeft: '30px',
        borderLeft: '2px solid transparent',
    },
    icon: {
        minWidth: '40px',
        color: 'black',

        '& svg': {
            height: '16px',
            width: '16px',
        },
    },
    selected: {
        backgroundColor: 'rgba(48, 127, 193, 0.07) !important',
        borderLeft: '2px solid #307fc1',
        '& img': {
            opacity: 1,
            filter: 'grayscale(0%) ',
        },
    },
})

const ManageAccessTabsItem = ({ label, icon, selected, onClick }) => {
    const classes = useStyles()
    const { t } = useTranslation()

    return (
        <MenuItem onClick={onClick} classes={classes} selected={selected}>
            <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
            <ListItemText>{t(label)}</ListItemText>
        </MenuItem>
    )
}

const ManageAccessTabs = ({ active, onChange, tabs }) => {
    return (
        <>
            {tabs
                .filter((x) => x.display === true)
                .map((tab, index) => (
                    <ManageAccessTabsItem
                        key={`${tab.path}_${index}`}
                        label={tab.label}
                        onClick={() => {
                            onChange(tab)
                        }}
                        selected={active === tab.path}
                        icon={<Icon name={tab.icon}></Icon>}
                    ></ManageAccessTabsItem>
                ))}
        </>
    )
}

export default ManageAccessTabs
