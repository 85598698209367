import { useRef, useState } from 'react'
import {
    Box,
    Grid,
    makeStyles,
    Popover,
    styled,
    Typography,
} from '@material-ui/core'
import { AppliedFilters, DeleteFilters } from 'components'
import ItemCardSkeleton from '../ListingPage/ItemCardSkeleton'
import Selectors from 'containers/Selectors'
import { useHistory } from 'react-router'
import { useQuery } from 'packages/core'
import { CardList } from 'packages/eid-controls'
import ItemCard from './ItemCard'
import SideDrawer from './DetailsDrawer'
import { useTranslation } from 'react-i18next'
import {
    ButtonV2,
    Loader,
    Tooltip,
    useViewIndicator,
    WithCloseButton,
} from 'packages/eid-ui'
import TableView from './RequestAccessTableView'
import { Icon } from 'packages/eid-icons'
import { useCurrentPerson, useGuidedShopFilters, useTargetPerson } from 'hooks'
import useSubcomponents from 'useSubcomponents'
import { subHeaderHeight } from 'utils'
import appConfig from 'config'

const FiltersButton = ({
    onClick,
    label,
    loadingLabel,
    loading,
    saved,
    disabled,
}) => {
    const fontColor = saved ? '#ffffff' : '#282828'
    const bgColor = saved ? '#01ae8f' : '#ffffff'
    const icon = (
        <Icon style={{ marginRight: '6px' }} name="Check" color="#ffffff" />
    )
    return (
        <ButtonV2
            width="110px"
            height="40px"
            borderRadius="5px"
            fontColor={fontColor}
            fontSize="12px"
            label={label}
            loading={loading}
            bgColor={bgColor}
            hoverColor="rgba(255,255,255,0.7)"
            loadingLabel={loadingLabel}
            spinnerColor="#01ae8f"
            showCheckIcon={saved}
            icon={saved && icon}
            disabled={disabled}
            onClick={disabled ? undefined : onClick}
        />
    )
}

const GridItem = styled(Box)({
    marginRight: '16px',
})

const usePopoverStyles = makeStyles({
    paper: {
        display: 'flex',
        alignItems: 'center',
        minHeight: 50,
        overflow: 'hidden',
    },
})

const SaveFiltersPrompt = styled(WithCloseButton)({
    height: '40px',
    borderRadius: '5px',
    width: '100%',
    display: 'flex',
    padding: '10px 15px',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    fontSize: '12px',
})

const DesktopVersion = ({
    totalCount,
    loading,
    loadingMore,
    infiniteScrollObervableDiv,
    id,
    data,
    onItemClick,
    currentResourceType,
    resourceTypeAttributes,
    handleSaveFilters,
    savingFilters,
    onSort,
    sortBy,
    sortOrder,
}) => {
    const { t } = useTranslation()
    const history = useHistory()
    const query = useQuery()
    const popoverClasses = usePopoverStyles()

    const {
        getGuidedShopFilters,
        clearGuidedShopFilters,
    } = useGuidedShopFilters()

    const anchorRef = useRef(null)

    const { data: currentPerson } = useCurrentPerson()

    const [targetPerson] = useTargetPerson()

    const { canSeeAtLeastOneFilter } = useSubcomponents()

    const CURRENT_SCREEN = 'RequestAccess'
    const canSeeAnyFilter = canSeeAtLeastOneFilter(
        currentResourceType,
        CURRENT_SCREEN,
    )

    const shoppingForSomeoneElse = targetPerson.id !== currentPerson.id

    const [showSaveFiltersTooltip, setShowSaveFiltersTooltip] = useState(
        !shoppingForSomeoneElse && getGuidedShopFilters() !== null,
    )

    const { view } = useViewIndicator({
        view: query.get('view'),
        onCardViewClick: () => {
            query.set('view', 'card')
            history.push(`${history.location.pathname}?${query.toString()}`)
        },
        onListViewClick: () => {
            query.delete('view')
            history.push(`${history.location.pathname}?${query.toString()}`)
        },
    })

    return (
        <div
            style={{
                flexGrow: 1,
                marginTop: `${subHeaderHeight + 20}px`,
            }}
        >
            <div
                style={{
                    flexGrow: 1,
                    display: 'flex',
                }}
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    flex="27%"
                    maxWidth="370px"
                >
                    <Selectors
                        resourceType={currentResourceType.name}
                        screen={CURRENT_SCREEN}
                    />
                </Box>
                <Box flex="73%" display="flex" flexWrap="wrap" height="100%">
                    <Grid container spacing={0} style={view === 'card' ? {
                        margin:'0 14px',
                    } : {}}>
                        <Box
                            display="flex"
                            alignItems="center"
                            width="100%"
                            padding="8px"
                        >
                            <GridItem minWidth="355px" width="100%">
                                {canSeeAnyFilter && (
                                    <AppliedFilters
                                        resourceType={currentResourceType.name}
                                    />
                                )}
                            </GridItem>

                            <GridItem minWidth="110px">
                                {canSeeAnyFilter && (
                                    <div ref={anchorRef}>
                                        <Tooltip
                                            title={
                                                shoppingForSomeoneElse
                                                    ? t(
                                                          'Common_SaveFiltersDisableForSomeoneElseHelpText',
                                                      )
                                                    : t(
                                                          'Common_SaveFiltersHelpText',
                                                      )
                                            }
                                        >
                                            <Box
                                                display="flex"
                                                justifyContent="flex-end"
                                            >
                                                <FiltersButton
                                                    label={t(
                                                        'Common_SaveFilters',
                                                    )}
                                                    loadingLabel={t(
                                                        'Common_Saving',
                                                    )}
                                                    loading={savingFilters}
                                                    saved={false}
                                                    onClick={handleSaveFilters}
                                                    disabled={
                                                        shoppingForSomeoneElse
                                                    }
                                                />
                                            </Box>
                                        </Tooltip>
                                        {anchorRef.current && (
                                            <Popover
                                                open={showSaveFiltersTooltip}
                                                anchorEl={anchorRef.current}
                                                onClose={() => {
                                                    clearGuidedShopFilters()
                                                    setShowSaveFiltersTooltip(
                                                        false,
                                                    )
                                                }}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'center',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'center',
                                                }}
                                                classes={popoverClasses}
                                            >
                                                <SaveFiltersPrompt
                                                    iconColor="#000000"
                                                    onClick={() => {
                                                        clearGuidedShopFilters()
                                                        setShowSaveFiltersTooltip(
                                                            false,
                                                        )
                                                    }}
                                                    style={{
                                                        color: '#000',
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    <Box marginRight="20px">
                                                        {t(
                                                            'Common_SaveFiltersTooltipText',
                                                        )}
                                                    </Box>
                                                </SaveFiltersPrompt>
                                            </Popover>
                                        )}
                                    </div>
                                )}
                            </GridItem>

                            <GridItem minWidth="150px">
                                {canSeeAnyFilter && (
                                    <DeleteFilters
                                        resourceType={currentResourceType.name}
                                    />
                                )}
                            </GridItem>
                        </Box>
                        {view === 'card' ? (
                            <CardList
                                infiniteScrollObervableDiv={
                                    infiniteScrollObervableDiv
                                }
                                noItemMessage={
                                    <Typography
                                        variant="h5"
                                        style={{ color: '#b4b4b4' }}
                                    >
                                        {t('Common_NoItemsFound', {
                                            itemType: t(
                                                currentResourceType.title,
                                            ),
                                        })}
                                    </Typography>
                                }
                                loadingIndicator={
                                    <>
                                        <ItemCardSkeleton />
                                        <ItemCardSkeleton />
                                        <ItemCardSkeleton />
                                        <ItemCardSkeleton />
                                    </>
                                }
                                fetcher={() => ({
                                    loading,
                                    loadingMore,
                                    list: data ? data : [],
                                })}
                                renderItem={(item) => (
                                    <div
                                        key={item.id}
                                        style={{
                                            height: 'inline-block',
                                            width: '50%',
                                            overflow: 'hidden',
                                        }}
                                    >
                                        <ItemCard
                                            cardStyles={{ margin: '8px' }}
                                            key={item.id}
                                            attributes={resourceTypeAttributes}
                                            buttonStyles={{
                                                '&:hover': {
                                                    backgroundColor: '#02997E',
                                                },
                                            }}
                                            item={item}
                                            onItemClick={() =>
                                                onItemClick(item)
                                            }
                                            color={currentResourceType.color}
                                        />
                                    </div>
                                )}
                            />
                        ) : (
                            <Box padding="8px" flex="1">
                                <TableView
                                    loading={loading}
                                    onSort={onSort}
                                    sortOrder={sortOrder}
                                    sortBy={sortBy}
                                    fallbackMessage={t('Common_NoItemsFound', {
                                        itemType: t(currentResourceType.title),
                                    })}
                                    data={data}
                                    attributes={resourceTypeAttributes}
                                    onItemClick={(item) => onItemClick(item)}
                                />
                                {loadingMore && <Loader />}
                                {infiniteScrollObervableDiv}
                            </Box>
                        )}
                    </Grid>
                </Box>
            </div>
            <SideDrawer
                id={id}
                toggleDrawer={() => {
                    query.delete('selected')
                    history.push(
                        `${appConfig.APP_SUBPATH}/${
                            currentResourceType.name
                        }?${query.toString()}`,
                    )
                }}
                attributes={resourceTypeAttributes}
                type={currentResourceType.name}
            />
        </div>
    )
}

export default DesktopVersion
