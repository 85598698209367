import React from 'react'
import ITShopDesktopHeader from './ITShopDesktopHeader'
import { useIsSmallScreen } from 'packages/core'
import ITShopMobileHeader from './ITShopMobileHeader'
import { useAppState } from 'appContext'

const ITShopHeader = () => {
    const [{ appConfig }] = useAppState()
    const headerStyleConfig = appConfig.style.header

    const isSmallScreen = useIsSmallScreen()
    return isSmallScreen ? (
        <ITShopMobileHeader config={headerStyleConfig} />
    ) : (
        <ITShopDesktopHeader config={headerStyleConfig} />
    )
}

export default ITShopHeader
