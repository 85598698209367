import { Box } from '@material-ui/core'
import ComputerConnectBtn from './ComputerConnectButton'
const styles = {
    parentClass: {
        background: '#01ae8f',
        borderColor: '#01ae8f',
        '&:hover': {
            backgroundColor: '#01ae8f',
        },
    }

}
const ComputersStatusButton = ({ data, showPrefixIcon = false }) => {
    return <Box ><ComputerConnectBtn showPrefixIcon={showPrefixIcon} parentStyle={styles.parentClass} data={data} drawerView={false} /> </Box>
}

ComputersStatusButton.defaultProps = {
    drawerView: false,
}

export default ComputersStatusButton
