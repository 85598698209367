import { InputBase, withStyles } from '@material-ui/core'

export const StyledInput = withStyles((theme) => ({
    root: {
        width: '100% !important',
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: '#ffffff !important',
        border: 'solid 1px #ebebed',

        fontSize: 16,
        padding: '10px 12px',
        '&:focus': {
            boxShadow: ` 0 2px 4px 0 rgba(48, 127, 193, 0.15)`,
            borderColor: '#307fc1',
            backgroundColor: theme.palette.common.white,
        },
    },

    focused: {
        backgroundColor: theme.palette.common.white,
    },
}))(InputBase)
