import { useState } from 'react'
import { Icon } from 'packages/eid-icons'
import DropdownFilter from './DropdownFilter'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useQuery } from 'packages/core'
import useConfiguration from 'useConfiguration'
import appConfig from 'config'
import { SubPathLevel } from 'appConfiguration'

const DropdownMenu = ({ currentResourceType, totalCount }) => {
    const { t } = useTranslation()
    const history = useHistory()

    const { resourceTypes } = useConfiguration()

    const [open, setOpen] = useState(false)

    const {
        location: { pathname },
    } = history

    const query = useQuery()
    query.delete('selected')

    let subPaths = pathname.split('/')

    const areaName =
        subPaths.length >= SubPathLevel + 2 ? subPaths[2 + SubPathLevel] : ''

    const applicationId = query.get('applicationId')
    const workflowName = query.get('workflowName')

    const resourceTypesToShow = resourceTypes

    const selectedRoute =
        areaName !== 'applications'
            ? resourceTypesToShow.filter(
                  (r) => r.name === currentResourceType.name,
              )[0]
            : { name: 'applications', title: 'Common_Applications' }

    return (
        <>
            <DropdownFilter
                label={t(
                    selectedRoute ? selectedRoute.title : 'Common_Applications',
                )}
                setOpen={setOpen}
                open={open}
                style={{
                    height: '60px',
                    paddingRight: '72px',
                }}
                anchorPosition={{ top: '60px' }}
                totalCount={totalCount}
                showTotalCount={!applicationId && !workflowName}
            >
                {resourceTypesToShow.map((r) => (
                    <DropdownFilter.Item
                        key={r.name}
                        selected={r.name === currentResourceType.name}
                        onClick={() => {
                            setOpen((prev) => !prev)
                            if (areaName === 'manageAccess') {
                                // This is for computers to set default tab pn navigation
                                let URL = `${appConfig.APP_SUBPATH}${r.route}/manageAccess`
                                if(r.route === "/computers"){
                                    URL =  `${URL}?tab=loginsession`
                                }
                                history.push(URL)
                            } else {
                                history.push(
                                    `${appConfig.APP_SUBPATH}${r.route}`,
                                )
                            }
                        }}
                    >
                        <Icon
                            width="16px"
                            height="16px"
                            name={r.icon.small}
                            color="#919193"
                        />
                        {t(r.title)}
                    </DropdownFilter.Item>
                ))}
            </DropdownFilter>
        </>
    )
}

export { DropdownMenu }
