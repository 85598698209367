export const initialState = {
    appConfig: null,
    targetPerson: null,
    masterPassword: sessionStorage.getItem('MasterPassword'),
}

const reducer = (state, { type, payload }) => {
    switch (type) {
        case 'SET_APP_CONFIG':
            return {
                ...state,
                appConfig: payload,
            }

        case 'SET_TARGET_PERSON':
            return {
                ...state,
                targetPerson: payload,
            }

        case 'SET_MASTER_PASSWORD':
            sessionStorage.setItem('MasterPassword', payload)
            return {
                ...state,
                masterPassword: payload,
            }

        default:
            return state
    }
}

export default reducer
