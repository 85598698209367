import { useQuery } from 'react-query'
import { useAxios } from 'packages/core'
export const MAIL_BOX_KEY_PREFIX = 'MAIL_BOXES'

export const useMailbox = (id) => {
    const callApi = useAxios()
    return useQuery(
        [MAIL_BOX_KEY_PREFIX, id],
        () =>
            callApi({
                method: 'GET',
                url: `/api/mailBoxes/${id}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id),
        },
    )
}

export const useMailboxOwners = (id) => {
    const callApi = useAxios()
    return useQuery(
        [`${MAIL_BOX_KEY_PREFIX}_OWNERS`, id],
        () =>
            callApi({
                method: 'GET',
                url: `/api/People/resourceOwners?resourceId=${id}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id),
        },
    )
}

export const useCheckMailboxAccess = (id, targetPersonId) => {
    const callApi = useAxios()
    return useQuery(
        [`${MAIL_BOX_KEY_PREFIX}_ACCESS`, id, targetPersonId],
        () =>
            callApi({
                method: 'GET',
                url: `/api/mailBoxes/checkAssigmentStatus/${id}/${targetPersonId}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id) && Boolean(targetPersonId),
        },
    )
}

export const useMailboxTypes = () => {
    const callApi = useAxios()
    return useQuery([`${MAIL_BOX_KEY_PREFIX}_MAILBOX_TYPES`], () =>
        callApi({
            method: 'GET',
            url: `/api/MailBoxes/mailBoxTypes/`,
        }).then((data) => data.data),
    )
}

export const useMailboxBr = (
    targetPersonId,
    resourceId,
    resourceTypeId,
    resourceAssignmentId,
) => {
    const callApi = useAxios()
    return useQuery(
        [
            MAIL_BOX_KEY_PREFIX,
            'Business_Request',
            targetPersonId,
            resourceId,
            resourceTypeId,
            resourceAssignmentId,
        ],
        () =>
            callApi({
                method: 'GET',
                url: `/api/mailboxes/businessRequestItemAssignmentDetails?targetPersonId=${targetPersonId}&resourceId=${resourceId}&resourceTypeId=${resourceTypeId}&resourceAssignmentId=${resourceAssignmentId}`,
            }).then((data) => data.data),
        {
            enabled:
                Boolean(targetPersonId) &&
                Boolean(resourceId) &&
                Boolean(resourceTypeId) &&
                Boolean(resourceAssignmentId),
        },
    )
}
