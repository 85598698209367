import React from 'react'
import { Header } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router'
import { useIsSmallScreen } from 'packages/core'
import { useResourceTypeContext } from 'resourceTypeContext'
import useConfiguration from 'useConfiguration'
import {
    SubPathLevel,
    AREANAME_URL_INDEX,
    RESOURCE_URL_INDEX,
} from 'appConfiguration'
import appConfig from 'config'

const Search = ({ history, ...rest }) => {
    const isSmallScreen = useIsSmallScreen()

    const {
        location: { pathname },
    } = history

    const { t } = useTranslation()
    const { resourceTypes } = useConfiguration()

    const [{ searchTerm }, dispatch] = useResourceTypeContext()

    let subPaths = pathname.split('/')

    const handleSearch = (searchKey) =>
        dispatch({
            type: 'SET_SEARCH_TERM',
            payload: searchKey,
        })

    const handleSelect = (option) => {
        history.push(`${appConfig.APP_SUBPATH}${option.route}`)
    }

    const areaName =
        subPaths.length > AREANAME_URL_INDEX + SubPathLevel
            ? subPaths[AREANAME_URL_INDEX + SubPathLevel].toLowerCase()
            : 'requestAccess'

    const isActive = (name) => {
        const resourceName =
            subPaths.length > RESOURCE_URL_INDEX + SubPathLevel &&
            subPaths[RESOURCE_URL_INDEX + SubPathLevel]

        return resourceName.toLowerCase() === name.toLowerCase()
    }

    let requestAccessResourceTypes = []

    requestAccessResourceTypes = requestAccessResourceTypes.concat(
        resourceTypes.map((r) => ({
            title: t(r.title),
            route: r.route,
            icon: r.icon,
            selected: isActive(r.name),
        })),
    )

    const manageAccessResourceTypes = resourceTypes.map((r) => ({
        title: t(r.title),
        route: `${r.route}/manageAccess`,
        icon: r.icon,
        selected: isActive(r.name),
    }))

    const options =
        areaName !== 'manageAccess'.toLowerCase()
            ? requestAccessResourceTypes
            : manageAccessResourceTypes

    if (areaName === 'workflows') {
        return <></>
    }

    return !isSmallScreen ? (
        <Header.SearchMenu
            placeholder={
                areaName !== 'manageAccess'.toLowerCase()
                    ? t('Common_SearchIn', { text: t('Common_RequestAccess') })
                    : t('Common_SearchIn', { text: t('Common_ManageAccess') })
            }
            options={options}
            handleSearch={handleSearch}
            handleSelect={handleSelect}
            value={searchTerm}
            {...rest}
        />
    ) : (
        <Header.Search
            placeholder={
                areaName !== 'manageAccess'.toLowerCase()
                    ? t('Common_SearchIn', { text: t('Common_RequestAccess') })
                    : t('Common_SearchIn', { text: t('Common_ManageAccess') })
            }
            handleSearch={handleSearch}
            value={searchTerm}
            {...rest}
        />
    )
}

export default withRouter(Search)
