import { useAxios } from 'packages/core'
import { useQuery } from 'react-query'

export const useWorkflows = (
    resourceType: string,
    resourceTypeNounAndVerb: string,
) => {
    const callApi = useAxios()
    return useQuery(
        [resourceType.toUpperCase(), 'WORKFLOWS'],
        () =>
            callApi({
                method: 'GET',
                url: `api/UIActions/byNoun?${resourceTypeNounAndVerb}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(resourceTypeNounAndVerb),
        },
    )
}
