import React from 'react'
import { Box, makeStyles, styled } from '@material-ui/core'
import Filter from './Filter'
import { CloseRoundIcon } from 'packages/eid-icons'
import useAppliedFilterValues from './useAppliedFilterValues'
import { useIsSmallScreen, useQuery } from 'packages/core'
import { useTranslation } from 'react-i18next'
import useConfiguration from 'useConfiguration'
import useDefaultState from '../DeleteFilters/useDefaultState'
import { useResourceTypeContext } from 'resourceTypeContext'
import { WithCloseButton } from 'packages/eid-ui'

const useStyles = makeStyles({
    margin: {
        margin: '2.5px 5px',
    },
})

const MobileAppliedFiltersBox = styled(WithCloseButton)({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    padding: '5px 10px 5px 10px',
    overflow: 'hidden',
})

const DesktopAppliedFiltersBox = styled(WithCloseButton)({
    backgroundColor: '#ffffff',
    minHeight: '40px',
    borderRadius: '5px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    padding: '5px 10px 5px 10px',
    overflow: 'hidden',
})

const AppliedFilters = ({ resourceType }) => {
    const { t } = useTranslation()
    const isSmallScreen = useIsSmallScreen()
    const classes = useStyles()
    const [, dispatch] = useResourceTypeContext()

    const { getResourceType } = useConfiguration()

    const currentResourceType = getResourceType(resourceType)

    const query = useQuery()
    const isApplicationScreen = Boolean(query.get('applicationId'))

    const allAppliedFilterValues = useAppliedFilterValues(currentResourceType)
    const defaultState = useDefaultState(resourceType)

    const appliedFilterValues = isApplicationScreen
        ? allAppliedFilterValues.filter((a) => a.name !== 'Applications')
        : allAppliedFilterValues
    const content = () => (
        <>
            {appliedFilterValues.length === 0 && (
                <Box className={classes.margin}>
                    <div style={{ color: '#b4b4b4' }}>
                        {t('Common_NoFiltersApplied')}
                    </div>
                </Box>
            )}

            {appliedFilterValues.map((f, index) => (
                <Box
                    key={f.label + index}
                    maxWidth="100%"
                    className={classes.margin}
                >
                    <Filter
                        label={f.label}
                        onClose={f.onRemove}
                        bgColor={f.colorCode.secondary}
                        textColor={f.colorCode.primary}
                        icon={<CloseRoundIcon color={f.colorCode.primary} />}
                    />
                </Box>
            ))}
        </>
    )
    const active = appliedFilterValues.length > 0

    const clearAllFilters = active
        ? () =>
              dispatch({
                  type: 'SET_MULTIPLE_PROPS',
                  payload: { ...defaultState },
              })
        : undefined

    return isSmallScreen ? (
        <MobileAppliedFiltersBox
            onClose={clearAllFilters}
            iconColor={active ? 'red' : '#b4b4b4'}
            closeTooltip={t('Common_ClearAllFilters')}
        >
            {content()}
        </MobileAppliedFiltersBox>
    ) : (
        <DesktopAppliedFiltersBox
            onClose={clearAllFilters}
            iconColor={active ? 'red' : '#b4b4b4'}
            closeTooltip={t('Common_ClearAllFilters')}
        >
            {content()}
        </DesktopAppliedFiltersBox>
    )
}

export default AppliedFilters
