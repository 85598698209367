import React, { useEffect } from 'react'
import ListingPageDesktop from './ListingPageDesktop'
import { isNilOrEmpty, useIsSmallScreen, useQuery } from 'packages/core'
import ListingPageMobile from './ListingPageMobile'
import { useResourceTypeContext } from 'resourceTypeContext'
import { useHistory } from 'react-router'
import useConfiguration from 'useConfiguration'
import { useTranslation } from 'react-i18next'
import useRequestAccessResources from './useRequestAccessResources'
import { Layout } from 'components'
import {
    useDefaultFilters,
    useGuidedShopFilters,
    useUpdateDefaultFilters,
} from 'hooks'
import SubHeader from 'components/SubHeader'
import { Box, styled } from '@material-ui/core'
import { useViewIndicator } from 'packages/eid-ui'
import appConfig from 'config'

const subHeaderHeight = 70

const SubHeaderContainer = styled(Box)({
    position: 'fixed',
    top: 70,
    width: '100%',
    maxWidth: '1920px',

    display: 'flex',

    paddingTop: '10px',
    paddingRight: '20px',
    paddingLeft: '90px',

    backgroundColor: '#eef0f4',

    zIndex: 5,
})

const ListingPage = ({ resourceType }) => {
    const { t } = useTranslation()
    const history = useHistory()
    const query = useQuery()

    const [
        { sorting: sortQuery, shouldLoadTags },
        dispatch,
    ] = useResourceTypeContext()

    const setSortQuery = (newSortQuery) => {
        dispatch({
            type: 'SET_PROP',
            payload: {
                key: 'sorting',
                value: newSortQuery,
            },
        })
    }

    const {
        resourceTypes,
        getResourceType,
        getResourceTypeAttributes,
    } = useConfiguration()
    const currentResourceType = getResourceType(resourceType)
    let resourceTypeAttributes = getResourceTypeAttributes(
        currentResourceType.name,
    )

    // Filter for computers only
    if (currentResourceType && currentResourceType.name === 'Computers') {
        resourceTypeAttributes = resourceTypeAttributes.filter(
            (x) => x.requestAccessOnly,
        )
    }

    const title = t(currentResourceType.title)
    useEffect(() => {
        document.title = title
    }, [title])

    const {
        totalCount,
        loading,
        list,
        rest,
        loadingMore,
        infiniteScrollObervableDiv,
    } = useRequestAccessResources(currentResourceType)

    useEffect(() => {
        if (rest && shouldLoadTags) {
            dispatch({
                type: 'SET_TAGS',
                payload: rest.tags,
            })
        }
    }, [rest])

    const id = query.get('selected')

    const isSmallScreen = useIsSmallScreen()

    const {
        clearGuidedShopFilters,
        getGuidedShopFilters,
    } = useGuidedShopFilters()

    useEffect(() => {
        const guidedShopFilters = getGuidedShopFilters()
        if (guidedShopFilters != null) {
            if (
                guidedShopFilters.accountStoreUsageType &&
                guidedShopFilters.accountStore
            ) {
                dispatch({
                    type: 'SET_MULTIPLE_PROPS',
                    payload: {
                        accountStoreUsageType: {
                            friendlyName:
                                guidedShopFilters.accountStoreUsageType
                                    .friendlyName,
                            id: guidedShopFilters.accountStoreUsageType.id,
                        },
                        accountStore: {
                            friendlyName:
                                guidedShopFilters.accountStore.friendlyName,
                            id: guidedShopFilters.accountStore.id,
                        },
                    },
                })
            }
        }
    }, [])

    const { data: onBoardingFilters } = useDefaultFilters(
        currentResourceType.name,
    )

    const [
        saveFilters,
        { isLoading: savingFilters },
    ] = useUpdateDefaultFilters()

    const [{ tags, ...restFilters }] = useResourceTypeContext()

    const formsApplied =
        Object.keys(restFilters).findIndex(
            (k) =>
                k.startsWith('advancedSearch') &&
                k !== 'advancedSearch.tags' &&
                !isNilOrEmpty(restFilters[k]),
        ) >= 0
    const tagsApplied =
        Object.keys(restFilters).findIndex(
            (k) =>
                k.startsWith('advancedSearch.tags') &&
                Array.isArray(restFilters[k]) &&
                restFilters[k].length > 0,
        ) >= 0

    const advancedSearchApplied = formsApplied || tagsApplied
    const handleSaveFilters = () => {
        const { personId, ...otherFilters } = onBoardingFilters
        const filtersToSave = {
            ...otherFilters,
        }

        filtersToSave.selectedResource = currentResourceType.name

        if (restFilters.hasOwnProperty('showSuggested')) {
            filtersToSave.showSuggestedApplicationRoles =
                restFilters.showSuggested
        } else {
            filtersToSave.showSuggestedApplicationRoles = false
        }

        if (restFilters.hasOwnProperty('tCode')) {
            filtersToSave.tCode = restFilters.tCode
        } else {
            filtersToSave.tCode = ''
        }

        if (restFilters.accountStoreUsageType) {
            filtersToSave.accountStoreUsageTypeId =
                restFilters.accountStoreUsageType.id
        } else {
            filtersToSave.accountStoreUsageTypeId = ''
        }
        if (restFilters.accountStore) {
            filtersToSave.accountStoreId = restFilters.accountStore.id
        } else {
            filtersToSave.accountStoreId = ''
        }

        if (restFilters.applicationProcess || restFilters.businessDomain) {
            const idToUse = restFilters.applicationProcess
                ? restFilters.applicationProcess.id
                : restFilters.businessDomain
                ? restFilters.businessDomain.id
                : ''
            filtersToSave.selectedResourceLocationId = idToUse
        } else {
            filtersToSave.selectedResourceLocationId = ''
        }

        if (restFilters.referencePerson) {
            filtersToSave.referencePerson = restFilters.referencePerson
        } else {
            filtersToSave.referencePerson = null
        }

        if (advancedSearchApplied) {
            filtersToSave.advanced = {}

            if (restFilters['advancedSearch.forms.friendlyName']) {
                filtersToSave.advanced['advancedSearch@forms@friendlyName'] =
                    restFilters['advancedSearch.forms.friendlyName']
            }

            if (restFilters['advancedSearch.forms.technicalName']) {
                filtersToSave.advanced['advancedSearch@forms@technicalName'] =
                    restFilters['advancedSearch.forms.technicalName']
            }

            if (restFilters['advancedSearch.forms.owner']) {
                filtersToSave.advanced['advancedSearch@forms@owner'] =
                    restFilters['advancedSearch.forms.owner']
            }

            if (restFilters['advancedSearch.forms.highLevelClassification']) {
                filtersToSave.advanced[
                    'advancedSearch@forms@highLevelClassification'
                ] = restFilters['advancedSearch.forms.highLevelClassification']
            }

            if (restFilters['advancedSearch.forms.description']) {
                filtersToSave.advanced['advancedSearch@forms@description'] =
                    restFilters['advancedSearch.forms.description']
            }

            if (
                restFilters['advancedSearch.tags'] &&
                restFilters['advancedSearch.tags'].length > 0
            ) {
                filtersToSave.advanced['advancedSearch@tags'] =
                    restFilters['advancedSearch.tags']
            }
        } else {
            filtersToSave.advanced = {
                'advancedSearch@forms@friendlyName': '',
                'advancedSearch@forms@technicalName': '',
                'advancedSearch@forms@owner': null,
                'advancedSearch@forms@highLevelClassification': '',
                'advancedSearch@forms@description': '',
                'advancedSearch@tags': [],
            }
        }

        saveFilters({
            resourceType: currentResourceType.name,
            ...filtersToSave,
        }).then(() => {
            clearGuidedShopFilters()
        })
    }

    const { view, viewIndicator } = useViewIndicator({
        view: query.get('view'),
        onCardViewClick: () => {
            query.set('view', 'card')
            history.push(`${history.location.pathname}?${query.toString()}`)
        },
        onListViewClick: () => {
            query.delete('view')
            history.push(`${history.location.pathname}?${query.toString()}`)
        },
    })

    const applicationId = query.get('applicationId')
    const showBackButton = query.get('workflow')
    return (
        <Layout
            subHeader={
                !isSmallScreen && (
                    <SubHeaderContainer>
                        <SubHeader
                            totalCount={totalCount}
                            viewSwitcher={viewIndicator}
                            resourceTypes={resourceTypes}
                            currentResourceType={currentResourceType}
                            showNavTabs={!applicationId}
                            showWorkFlows={true}
                            showBackButton={showBackButton}
                        />
                    </SubHeaderContainer>
                )
            }
        >
            {isSmallScreen ? (
                <ListingPageMobile
                    handleSaveFilters={handleSaveFilters}
                    savingFilters={savingFilters}
                    totalCount={totalCount}
                    loading={loading}
                    loadingMore={loadingMore}
                    data={list}
                    id={id}
                    currentResourceType={currentResourceType}
                    resourceTypeAttributes={resourceTypeAttributes}
                    infiniteScrollObervableDiv={infiniteScrollObervableDiv}
                    onItemClick={(item) => {
                        query.set('selected', item.id)
                        history.push(
                            `${appConfig.APP_SUBPATH}/${
                                currentResourceType.name
                            }?${query.toString()}`,
                        )
                    }}
                />
            ) : (
                <ListingPageDesktop
                    handleSaveFilters={handleSaveFilters}
                    savingFilters={savingFilters}
                    totalCount={totalCount}
                    loading={loading}
                    loadingMore={loadingMore}
                    data={list}
                    id={id}
                    currentResourceType={currentResourceType}
                    resourceTypeAttributes={resourceTypeAttributes}
                    infiniteScrollObervableDiv={infiniteScrollObervableDiv}
                    onSort={(sortBy, sortOrder) => {
                        setSortQuery({
                            sortBy:
                                sortBy.columnName &&
                                sortBy.columnName.length > 0
                                    ? sortBy.columnName
                                    : sortBy.name,
                            sortOrder,
                        })
                    }}
                    sortOrder={sortQuery.sortOrder}
                    sortBy={sortQuery.sortBy}
                    onItemClick={(item) => {
                        query.set('selected', item.id)
                        history.push(
                            `${appConfig.APP_SUBPATH}/${
                                currentResourceType.name
                            }?${query.toString()}`,
                        )
                    }}
                />
            )}
        </Layout>
    )
}

const ensureFilters = (ChildComponent) => (props) => {
    const { data: onBoardingFilters } = useDefaultFilters(props.resourceType)

    if (onBoardingFilters === undefined) return <></>
    return <ChildComponent {...props} />
}

export default ensureFilters(ListingPage)
