import React from 'react'
import { Box, Fab, makeStyles } from '@material-ui/core'
import { Loader } from 'packages/eid-ui'

const drawerButtonStyles = makeStyles(() => ({
    root: ({ loading, color }) => ({
        width: '80px',
        height: '31px',
        border: `solid 1px ${color}`,
        backgroundColor: loading ? color : '#ffffff',
        boxShadow: '0 0 0 0',
        color: loading ? '#fff' : color,
        textTransform: 'capitalize !important',
        '& path': {
            fill: color,
        },
        '&:hover': {
            backgroundColor: color,
            color: 'white',
            '& path': {
                fill: '#ffffff',
            },
            '& span': {
                color: '#fff',
            },
        },
    }),
}))
const drawerButtonStylesFilled = makeStyles(() => ({
    root: ({ color, fill, isDisabled }) => ({
        width: '80px',
        height: '31px',
        border: `solid 1px ${color}`,
        backgroundColor: fill,
        boxShadow: '0 0 0 0',
        color: 'white',
        cursor: isDisabled ? 'not-allowed' : 'pointer',
        textTransform: 'capitalize !important',
        '& path': {
            fill: 'white',
        },
        '& .MuiFab-label': {
            width: 'auto !important',
        },
        '&:hover': {
            backgroundColor: fill,
            color: 'white',
            border: `solid 1px ${fill}`,
            '& path': {
                fill: '#ffffff',
            },
            '& span': {
                color: '#fff',
            },
        },
    }),
}))
const Button = ({
    onClick,
    loading,
    children,
    color,
    fill,
    isFilled,
    isDisabled,
}) => {
    const classes = isFilled
        ? drawerButtonStylesFilled({ color, fill, isDisabled })
        : drawerButtonStyles({ loading, color })
    return (
        <Fab classes={classes} variant="extended" onClick={onClick}>
            {loading ? (
                <div
                    style={{
                        width: '12px',
                    }}
                >
                    <Loader
                        color="#ffffff"
                        height="100%"
                        size={0.5}
                        display="inline"
                    />
                </div>
            ) : (
                children
            )}
        </Fab>
    )
}

Button.defaultProps = {
    color: '#01ae8f',
}

export default Button
