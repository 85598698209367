import { Box, createStyles, makeStyles } from '@material-ui/core'
import { FC, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import Attribute from './Attribute'
import { ICartItemRisk, IRiskFunction } from '../types'
import { useRiskFunctions } from 'hooks'
import { RiskIndicator } from 'packages/eid-ui'
import { Skeleton } from '@material-ui/lab'

const useStyles = makeStyles(
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            padding: '16px 24px',
        },
        title: {
            padding: '8px  0px',
            fontSize: '14px',
            fontWeight: 'bold',
            color: '#000000',
        },
        skeleton: {
            marginTop: '10px',
        },
        functionsContainer: {
            display: 'flex',
            marginTop: '-12px',
        },
        singleFunction: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '12px',
            marginRight: '24px',
        },
        functionName: {
            lineHeight: 1.33,
            width: '240px',
        },
        riskFunctionLabel: {
            minWidth: '80px',
        },
    }),
)

export interface ViolationDetailsProps {
    risk: ICartItemRisk
}

const ViolationDetails: FC<ViolationDetailsProps> = (props) => {
    const { risk } = props

    const {
        data: leftSideFunctions,
        isLoading: isLoadingLeftSideFunctions,
    } = useRiskFunctions(
        risk.localRiskId,
        risk.assigneeId,
        true,
        risk.riskDetailsInversed,
    )

    const {
        data: rightSideFunctions,
        isLoading: isLoadingRightSideFunctions,
    } = useRiskFunctions(
        risk.localRiskId,
        risk.riskType === 'SegregationOfDuties'? risk.riskReason_AssigneeId: '',
        false,
        risk.riskDetailsInversed,
    )

    const classes = useStyles()
    const { t } = useTranslation()

    const renderRiskFunctions = (functions: IRiskFunction[]) => {
        if (functions.length === 0) {
            return (
                <Box className={classes.singleFunction}>
                    {t('Common_NoDataFound')}
                </Box>
            )
        }
        return (
            <Box>
                {functions.map((rf) => (
                    <Box key={rf.id} className={classes.singleFunction}>
                        <Box className={classes.functionName}>
                            {rf.friendlyName}
                        </Box>
                        <Box className={classes.riskFunctionLabel}>
                            <RiskIndicator
                                variant={rf.riskLevel}
                                label={t(`Common_${rf.riskLevel}Risk`)}
                                renderAsText
                            />
                        </Box>
                    </Box>
                ))}
            </Box>
        )
    }

    const renderFunctionsGranted = (loading: boolean, riskFunctions: any) => {
        if (loading) {
            return (
                <>
                    {[1, 2].map((i) => (
                        <Skeleton
                            key={i}
                            variant={'rect'}
                            width={'100%'}
                            height={20}
                            className={classes.skeleton}
                        />
                    ))}
                </>
            )
        } else {
            return <Fragment>{renderRiskFunctions(riskFunctions)}</Fragment>
        }
    }

    return (
        <Box className={classes.root}>
            <Box className={classes.title}>{t('Common_ViolationDetails')}</Box>

            <Attribute
                label={t('Common_RiskType')}
                value={t(`Common_${risk.riskType}`)}
            />
            <Attribute
                label={t('Common_Description')}
                value={risk.description}
            />
            <Attribute
                label={t(
                    risk.riskType === 'SegregationOfDuties'
                        ? 'Common_FunctionsLeft'
                        : 'Common_Functions',
                )}
                value={
                    <Box className={classes.functionsContainer}>
                        {renderFunctionsGranted(
                            isLoadingLeftSideFunctions,
                            leftSideFunctions,
                        )}
                    </Box>
                }
            />
            {risk.riskType === 'SegregationOfDuties' && (
                <Attribute
                    label={t('Common_FunctionsRight')}
                    value={
                        <Box className={classes.functionsContainer}>
                            {renderFunctionsGranted(
                                isLoadingRightSideFunctions,
                                rightSideFunctions,
                            )}
                        </Box>
                    }
                />
            )}
        </Box>
    )
}

export default ViolationDetails
