import { FC } from 'react'
import { Box } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Icon } from 'packages/eid-icons'
import { Tooltip } from 'packages/eid-ui'
import { Instructions } from 'components/ApplicationBanner/Instructions'
import { useTranslation } from 'react-i18next'
import Button from './Button'

export interface IResourceListingInfoIconProps {
    attribute: any
    data: any
    isCardView?: boolean
}

const useStyles = makeStyles((theme) =>
    createStyles({
        rootListView: {
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '40px',
            minHeight: '32px',
            paddingTop: '4px',
            paddingBottom: '4px',
            marginBottom: '35px',
        },
        rootCardView: {
            position: 'relative',
            width: '100%',
        },
        icon: {
            position: 'absolute',
            cursor: 'pointer',
        },
    }),
)

export const ResourceListingInfoIcon: FC<IResourceListingInfoIconProps> = (
    props,
) => {
    const classes = useStyles()

    const { t } = useTranslation()

    const { data, isCardView } = props

    const getTooltipTitle = () => t('Common_MoreInfo')
    return (
        <>
            {data && data.instructions && (
                <Box
                    className={
                        isCardView ? classes.rootCardView : classes.rootListView
                    }
                >
                    <Instructions
                        title={data.friendlyName}
                        instructions={data.instructions}
                        button={(onClick: any) => {
                            return !isCardView ? (
                                <Tooltip title={getTooltipTitle()}>
                                    <Box
                                        className={classes.icon}
                                        onClick={() => onClick(true)}
                                    >
                                        <Icon
                                            name="Info"
                                            color="#8b909a"
                                            width="24px"
                                            height="25px"
                                        ></Icon>
                                    </Box>
                                </Tooltip>
                            ) : (
                                <Button
                                    title={t('Common_MoreInfo')}
                                    onClick={() => onClick(true)}
                                ></Button>
                            )
                        }}
                    />
                </Box>
            )}
        </>
    )
}
