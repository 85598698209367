import { useState, Fragment } from 'react'
import { Box, styled } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { ItemDetails } from 'components'
import { useIsSmallScreen } from 'packages/core'
import { Loader } from 'packages/eid-ui'
import { useCredentialHistory } from 'hooks'
import moment from 'moment'
import { Person } from 'components/ApplicationBanner/PersonDetails'
import { useUrlGenerator } from 'packages/core'
import { useAllApplications } from 'hooks'
import { Link } from '@material-ui/core'

const PaddedDiv = styled(Box)({
    padding: '0 31px',
})

const overflowStyleProps = {
    maxHeight: '220px',
    overflow: 'auto',
}

const OverviewSection = ({ item }) => {
    const { t } = useTranslation()

    const attributeOrientation = useIsSmallScreen() ? 'vertical' : 'horizontal'

    const { getMyTasksUrl } = useUrlGenerator(useAllApplications)

    return (
        <ItemDetails.AttributesContainer>
            <PaddedDiv>
                <ItemDetails.Attribute
                    label={t('DisplayName')}
                    value={item.externalCredentialName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('AccountLogonName')}
                    value={item.checkedOutByLogin}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('DomainOrDirectory')}
                    value={item.resourceSystemFriendlyName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('CheckedOutBy')}
                    value={<Person personId={item.checkedOutByPersonId} />}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('AllowMultipleCheckout')}
                    value={item.allowMultiCheckOut ? t('True') : t('False')}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('CheckoutTime')}
                    value={moment
                        .utc(item?.startTimeUtc)
                        .clone()
                        .local()
                        .format('L LT')}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('StartTime')}
                    value={moment
                        .utc(item?.startTimeUtc)
                        .clone()
                        .local()
                        .format('L LT')}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('EndTime')}
                    value={moment
                        .utc(item?.endTimeUtc)
                        .clone()
                        .local()
                        .format('L LT')}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('AccessType')}
                    value={
                        item.isPreApproved
                            ? t('CredentialPreApproved')
                            : t('CredentialOneTime')
                    }
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('CredentialType')}
                    value={item.externalCredentialTypeName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('CredentialCategory')}
                    value={item.categoryName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('Application')}
                    value={'Dummy Application'}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('Description')}
                    value={item.description}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('Common_BusinessRequest')}
                    value={
                        item.businessRequestId &&
                        item.businessRequestName && (
                            <Link
                                target="_blank"
                                href={getMyTasksUrl(
                                    `/myRequests/businessRequests?id=${item.businessRequestID}`,
                                )}
                            >
                                {item.businessRequestName}
                            </Link>
                        )
                    }
                    orientation={attributeOrientation}
                />

                <ItemDetails.Attribute
                    label={t('Common_BusinessRequestItem')}
                    value={
                        item.businessRequestItemId &&
                        item.businessRequestItemName && (
                            <Link
                                target="_blank"
                                href={getMyTasksUrl(
                                    `/myRequests/businessRequestItems?id=${item.businessRequestItemId}`,
                                )}
                            >
                                {item.businessRequestItemName}
                            </Link>
                        )
                    }
                    orientation={attributeOrientation}
                />
            </PaddedDiv>
        </ItemDetails.AttributesContainer>
    )
}

const CredentialHistoryDetails = ({ item, toggleDrawer }) => {
    const { t } = useTranslation()

    const { data, isLoading } = useCredentialHistory(item.resource.id)

    const credentialHistory = data

    const [activeTab, setActiveTab] = useState('overview')

    if (isLoading) {
        return <Loader />
    }
    return (
        <>
            <ItemDetails.Tabs
                variant="standard"
                value={activeTab}
                onChange={(_, value) => setActiveTab(value)}
            >
                <ItemDetails.Tabs.Tab
                    value="overview"
                    label={t('Common_Overview')}
                />
            </ItemDetails.Tabs>

            {activeTab === 'overview' && (
                <OverviewSection item={credentialHistory} />
            )}
        </>
    )
}

export default CredentialHistoryDetails
