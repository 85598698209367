import { useState } from 'react'
import { Box } from '@material-ui/core'
import { useDebounce, useIsSmallScreen } from 'packages/core'
import { Pagination } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'
import { FunctionsList, StyledSearchInput } from 'components'
import { useBusinessRoleLocalSensitiveFunctions } from 'hooks'

const take = 10

export const LocalSensitiveFunctions = ({
    businessRole,
    differentiationValue,
}) => {
    const { t } = useTranslation()

    const isSmallScreen = useIsSmallScreen()

    const [page, setPage] = useState(1)
    const [searchKey, setSearchKey] = useState('')

    const handlePageChange = (_, value) => {
        setPage(value)
    }
    const debouncedSearchValue = useDebounce(searchKey)

    const handleSearchChange = (event) => {
        setPage(1)
        const keyword = event.target.value
        setSearchKey(keyword)
    }

    const { latestData } = useBusinessRoleLocalSensitiveFunctions(
        businessRole.id,
        differentiationValue?.id,
        (page - 1) * take,
        take,
        debouncedSearchValue && encodeURIComponent(debouncedSearchValue),
    )

    const data = latestData ? latestData.data : undefined

    const isLoading = !Boolean(latestData)

    const numberOfPages = latestData
        ? Math.ceil(latestData.totalCount / take)
        : 0
    return (
        <>
            <Box
                width="100%"
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                padding="16px 32px 16px 32px"
                style={{ backgroundColor: '#ebebed' }}
            >
                <Box width={!isSmallScreen ? '308px' : '200px'}>
                    <StyledSearchInput
                        fullWidth
                        type="text"
                        placeholder={t('Common_Search')}
                        value={searchKey}
                        onChange={handleSearchChange}
                    />
                </Box>
            </Box>

            <FunctionsList loading={isLoading} data={data} />
            {numberOfPages > 1 && (
                <Box
                    padding={!isSmallScreen ? '20px 32px' : '16px'}
                    display="flex"
                    alignItems="center"
                    justifyContent={!isSmallScreen ? 'flex-start' : 'center'}
                >
                    <Pagination
                        count={numberOfPages}
                        size={!isSmallScreen ? 'large' : 'small'}
                        page={page}
                        onChange={handlePageChange}
                    />
                </Box>
            )}
        </>
    )
}
