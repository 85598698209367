import { Button } from 'components'
import { FC } from 'react'
import { PreApprpovedLoginAccess } from './LoginSessionExistingAccess'
import { PreApprovedMembershipAccess } from './MembershipBasedAccess'
import { Typography } from '@material-ui/core'
import { Icon } from 'packages/eid-icons'
import { useTranslation } from 'react-i18next'

export type PreApprovedItemProps = {
    item: any
    setShowScheduledOptions: () => void
    showScheduledOptions: boolean
    handleViewRequest: () => void
}

export const PreApprovedItems: FC<PreApprovedItemProps> = (
    props: PreApprovedItemProps,
) => {
    const {
        item: { computer, personResourceAssignments },
        setShowScheduledOptions,
        showScheduledOptions,
        handleViewRequest,
    } = props
    const { t } = useTranslation()
    return (
        <>
            {computer?.isPreApproved && (
                <>
                    {personResourceAssignments?.length > 0 && (
                        <PreApprovedMembershipAccess
                            handleViewRequest={handleViewRequest}
                            item={personResourceAssignments}
                            canActivateNow={computer?.canActivateNow}
                        />
                    )}
                    <PreApprpovedLoginAccess
                        handleViewRequest={handleViewRequest}
                        item={computer}
                    />
                    {!showScheduledOptions && (
                        <Button
                            rootStylesProp={{
                                width: '293px',
                                height: '48px',
                                borderRadius: '5px',
                                backgroundColor: '#307fc1',
                                margin: '16px 49px 12px 36px',
                                '&:hover': {
                                    backgroundColor: '#307fc1',
                                },
                            }}
                            onClick={() => setShowScheduledOptions()}
                        >
                            <Icon name="Schedule" />
                            <Typography
                                style={{
                                    paddingLeft: '12px',
                                }}
                            >
                                {t('Common_ScheduleNewLoginSession')}
                            </Typography>
                        </Button>
                    )}
                </>
            )}
        </>
    )
}
