import {
    Box,
    styled,
    Typography,
    createStyles,
    makeStyles,
} from '@material-ui/core'
import { Icon } from 'packages/eid-icons'

const useStyles = makeStyles(
    createStyles({
        sectionHeader: {
            overflowWrap: 'break-word',
            wordBreak: 'normal',
            textTransform: 'uppercase',
            color: '#000000 !important',
            overflow: 'hidden',
            lineHeight: '15px',
            fontSize: '16px',
            padding: '16px 31px',
            fontWeight: 'bold',
            boxShadow:'0 4px 17px 0 rgba(0, 0, 0, 0.07)',
            border:'solid 1px #dfdfe5',
            backgroundImage:
                'linear-gradient(0deg, rgb(0, 0, 0, 0.02) 12.5%, rgb(255, 255, 255) 12.5%, rgb(255, 255, 255) 50%, rgb(0, 0, 0, 0.02) 50%, rgb(0, 0, 0, 0.02) 62.5%, rgb(255, 255, 255) 62.5%, rgb(255, 255, 255) 100%)',
            backgroundSize: '8.00px 8.00px',
            '& svg': {
                margin: '0px !important',
            },
        },
    }),
)

const PromptContainer = styled('div')({
    padding: '48px 31px 20px 31px',
    minHeight: '232px',
    display: 'flex',
})

export const SelectorSection = (props) => {
    const classes = useStyles()
    const { parentStyle = {}} = props

    return (
        <>
            <Box className={classes.sectionHeader}>{props.sectionLabel}</Box>
            <PromptContainer style={parentStyle}>
                {props.iconName ? (
                    <Box
                        width="172px"
                        display="flex"
                        flexDirection="column"
                        zIndex={1}
                    >
                        <Icon
                            name={props.iconName}
                            height="150px"
                            width="150px"
                            color="#D2D2D9"
                        />
                    </Box>
                ) : (
                    <></>
                )}
                <Box width="auto" paddingLeft="16px">
                    {props.children}
                </Box>
            </PromptContainer>
        </>
    )
}
