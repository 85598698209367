import { Icon } from 'packages/eid-icons'
import { Box, makeStyles } from '@material-ui/core'
import { Tooltip } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles({
    root: {
        color: '#FF7E00',
        '&:hover': {
            '& g': { fill: '#FF7E00 !important' },
            color: '#ffffff',
        },
    },
})

const ConnectSwitch = ({ attribute, data }) => {
    const classes = useStyles()
    const { t } = useTranslation()

    return (
        <Tooltip title={t('Common_Requestable')}>
            <Box display="flex" alignItems="center" justifyContent="center">
                <Icon name="ConnectSwitch" className={classes.root} />
            </Box>
        </Tooltip>
    )
}

export default ConnectSwitch
