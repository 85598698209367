import React from 'react'
import { Box, styled } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { ItemDetails } from 'components'
import { useIsSmallScreen } from 'packages/core'
import { CommonAttributes } from '../CommonAttributes'

const overflowStyleProps = {
    maxHeight: '220px',
    overflow: 'auto',
}

const PaddedDiv = styled(Box)({
    padding: '0 31px',
})

export const OverviewSection = ({ applicationRole }: any) => {
    const { t } = useTranslation()

    const attributeOrientation = useIsSmallScreen() ? 'vertical' : 'horizontal'

    return (
        <ItemDetails.AttributesContainer>
            <PaddedDiv>
                <ItemDetails.Attribute
                    label={t('Common_TechnicalName')}
                    value={applicationRole.name}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('ApplicationRoles_ResourceSystem')}
                    value={applicationRole.resourceSystemFriendlyName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('ApplicationRoles_AccountStore')}
                    value={applicationRole.accountStoreFriendlyName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                {applicationRole.applicationProcess_FriendlyName && (
                    <ItemDetails.Attribute
                        label={t('ApplicationRoles_Process')}
                        value={applicationRole.applicationProcess_FriendlyName}
                        orientation={attributeOrientation}
                        valueProps={overflowStyleProps}
                    />
                )}
                {applicationRole.differentiationValue_FriendlyName && (
                    <ItemDetails.Attribute
                        label={t('ApplicationRoles_DifferentiationValue')}
                        value={
                            applicationRole.differentiationValue_FriendlyName
                        }
                        orientation={attributeOrientation}
                        valueProps={overflowStyleProps}
                    />
                )}

                <ItemDetails.Attribute
                    label={t('ApplicationRoles_HighLevelClassification')}
                    value={applicationRole.highLevelClassification}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('Common_Description')}
                    value={applicationRole.description}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <CommonAttributes resource={applicationRole} />
            </PaddedDiv>
        </ItemDetails.AttributesContainer>
    )
}
