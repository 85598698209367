import React from 'react'
import { Box, Link, styled } from '@material-ui/core'
import { Spinner, Tooltip, WithCloseButton } from 'packages/eid-ui'
import { useIsSmallScreen } from 'packages/core'
import { useTranslation } from 'react-i18next'
import {
    useCurrentPerson,
    useTargetPerson,
    useUpdateDefaultFilters,
} from 'hooks'
import useConfiguration from 'useConfiguration'

const TextButton = styled(Link)({
    textDecoration: 'none',
    backgroundColor: 'transparent',
    border: 'none',
    color: '#000000',
    fontSize: '12px',
    '&:focus': {
        outline: 'none',
    },
})

const DeleteFiltersCard = styled(WithCloseButton)({
    height: '40px',
    borderRadius: '5px',
    width: '100%',
    display: 'flex',
    padding: '10px 15px',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    fontSize: '12px',
    '&:hover': {
        backgroundColor: 'rgba(255,255,255,0.7)',
    },
})

const DeleteFilters = ({ resourceType }) => {
    const { t } = useTranslation()
    const isSmallScreen = useIsSmallScreen()

    const { data: currentPerson } = useCurrentPerson()

    const [targetPerson] = useTargetPerson()

    const shoppingForSomeoneElse = targetPerson.id !== currentPerson.id

    const { getResourceType } = useConfiguration()
    const currentResourceType = getResourceType(resourceType)

    const [saveFilters, { isLoading }] = useUpdateDefaultFilters()

    const deleteAllFilters = () => {
        saveFilters({ resourceType: currentResourceType.name })
    }

    const tooltipTitle = shoppingForSomeoneElse
        ? t('Common_DeleteFiltersDisabledForSomeoneElseHelpText')
        : t('Common_DeleteSavedFiltersHelpText')

    return !isSmallScreen ? (
        <Tooltip title={tooltipTitle}>
            <DeleteFiltersCard
                iconColor={shoppingForSomeoneElse ? '#b4b4b4' : 'red'}
                onClick={shoppingForSomeoneElse ? undefined : deleteAllFilters}
                style={{
                    color: shoppingForSomeoneElse ? '#b4b4b4' : '#000000',
                    cursor: shoppingForSomeoneElse ? 'default' : 'pointer',
                    opacity: shoppingForSomeoneElse ? 0.7 : 1,
                }}
                renderCloseButton={!isLoading}
            >
                {isLoading ? (
                    <Box display="flex" width="100%" justifyContent="center">
                        <Spinner size={0.8} color="red" />
                    </Box>
                ) : (
                    <div>{t('Common_DeleteFilters')}</div>
                )}
            </DeleteFiltersCard>
        </Tooltip>
    ) : (
        <Tooltip title={tooltipTitle}>
            <TextButton
                style={{
                    color: shoppingForSomeoneElse ? '#b4b4b4' : '#000000',
                    cursor: shoppingForSomeoneElse ? 'default' : 'pointer',
                }}
                component="button"
                onClick={shoppingForSomeoneElse ? undefined : deleteAllFilters}
            >
                {isLoading ? (
                    <Box display="flex" width="100%" justifyContent="center">
                        <Spinner size={0.5} color="red" />
                    </Box>
                ) : (
                    <div>{t('Common_DeleteFilters')}</div>
                )}
            </TextButton>
        </Tooltip>
    )
}

export default DeleteFilters
