import React from 'react'
import { ItemDetails } from 'components'
import { useIsSmallScreen } from 'packages/core'
import { styled } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { CommonAttributes } from '../CommonAttributes'
import useSubcomponents from 'useSubcomponents'

const PaddedDiv = styled('div')({
    padding: '0 31px',
})

export const OverviewSection = ({ azureLicense }: any) => {
    const { t } = useTranslation()

    const attributeOrientation = useIsSmallScreen() ? 'vertical' : 'horizontal'

    const {
        canSeeAzureLicenseTenantAttribute,
        canSeeAzureLicenseLicensePoolAttribute,
        canSeeAzureLicenseTenantSubscriptionAttribute,
        canSeeAzureLicenseLicensedAssigneeAttribute,
    } = useSubcomponents()


    return (
        <ItemDetails.AttributesContainer>
            <PaddedDiv>
                <ItemDetails.Attribute
                    label={t('AzureLicenses_LicensePoolSubBundle')}
                    value={azureLicense.name}
                    orientation={attributeOrientation}
                />

                {canSeeAzureLicenseTenantAttribute && azureLicense.resourceSystemFriendlyName && (
                    <ItemDetails.Attribute
                        label={t('AzureLicenses_Tenant')}
                        value={azureLicense.resourceSystemFriendlyName}
                        orientation={attributeOrientation}
                    />
                )}

                {canSeeAzureLicenseLicensePoolAttribute && (
                    <ItemDetails.Attribute
                        label={t('AzureLicenses_LicensePool')}
                        value={azureLicense.azureLicensePool_FriendlyName}
                        orientation={attributeOrientation}
                    />
                )}

                {canSeeAzureLicenseTenantSubscriptionAttribute && (
                    <ItemDetails.Attribute
                        label={t('AzureLicenses_TenantSubscription')}
                        value={azureLicense.azureTenantSubscription_FriendlyName}
                        orientation={attributeOrientation}
                    />
                )}

                {canSeeAzureLicenseLicensedAssigneeAttribute && (
                    <ItemDetails.Attribute
                        label={t('AzureLicenses_LicenseGroup')}
                        value={azureLicense.licensedAssignee_FriendlyName}
                        orientation={attributeOrientation}
                    />
                )}

                <ItemDetails.Attribute
                    label={t('Common_Description')}
                    value={azureLicense.description}
                    orientation={attributeOrientation}
                />
                <CommonAttributes resource={azureLicense} />
            </PaddedDiv>
        </ItemDetails.AttributesContainer>
    )
}
