import { FC, Fragment } from 'react'
import { useParams } from 'react-router-dom'
import ListingPage from 'containers/ListingPage'

export type ApplicationBasedListingProps = {}

export const ApplicationBasedListing: FC<ApplicationBasedListingProps> = (
    props,
) => {
    const { resourceType }: any = useParams()

    return (
        <Fragment>
            <ListingPage resourceType={resourceType} />
        </Fragment>
    )
}
