import { Box, makeStyles, createStyles } from '@material-ui/core'

const useStyles = makeStyles(
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            padding: '8px 0',
        },
        label: {
            fontSize: '12px',
            fontWeight: 'bold',
            color: '#9b9b9b',
            padding: '4px 0px',
            textTransform: 'uppercase',
        },
        value: {
            fontSize: '14px',
            color: '#000000',
            padding: '4px 0px',
        },
    }),
)

const Attribute = ({ label, value }: { label: string; value: any }) => {
    const classes = useStyles()

    return (
        <Box className={classes.root}>
            <Box className={classes.label}>{label}</Box>
            <Box className={classes.value}>{value}</Box>
        </Box>
    )
}

export default Attribute
