import { Box, makeStyles, styled, Button } from '@material-ui/core'
import { refreshComputerList, useTerminateSession } from 'hooks'
import moment from 'moment'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ComputerSessionHistoryDetails from '../SessionHistoryDetail'
import { Avatar, Tooltip } from 'packages/eid-ui'
import { getFullImageUrl } from 'utils'
import { Icon } from 'packages/eid-icons'
import ComputerMapDialog from '../MapDialog'
import config from 'config'
import { useQuery } from 'packages/core'
import { useHistory } from 'react-router'
const PaddedDiv = styled(Box)({
    padding: '0 31px',
})

const useStyles = makeStyles({
    requestmaincontainer: {
        borderRadius: '8px',
        boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.05)',
        border: 'solid 1px rgba(0, 0, 0, 0.11)',
        background: '#fff',
        marginTop: '16px',
        paddingBottom: '20px',
        overflow: 'hidden',
    },
    requestHeadertitle: {
        fontSize: '14px',
        fontWeight: 600,
        marginTop: '5px',
        marginBottom: '5px',
    },
    requestHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: 'solid 1px rgba(0, 0, 0, 0.11)',
        padding: '4px 20px ',
        backgroundImage:
            'linear-gradient(0deg, rgb(0, 0, 0, 0.02) 12.5%, rgb(255, 255, 255) 12.5%, rgb(255, 255, 255) 50%, rgb(0, 0, 0, 0.02) 50%, rgb(0, 0, 0, 0.02) 62.5%, rgb(255, 255, 255) 62.5%, rgb(255, 255, 255) 100%)',
        backgroundSize: '8.00px 8.00px',

    },
    requestHeadertitlebutton: {
        '& button': {
            fontSize: '11px',
            color: '#fff',
            background: '#01ae8f',
            borderRadius: '5px',
            padding: '1px 4px 1px',
            '&:hover': {
                background: '#01ae8f',
            }
        }
    },
    requestitemshow: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '10px 20px ',
        marginTop: '10px',
    },
    requestitemshowsecond: {
        display: 'flex',
        padding: '10px 20px ',
        marginTop: '10px'
    },
    requestleftshow: {
        marginLeft: '30px',
    },
    requestitembtn: {
        marginLeft: '10%',
    },

    requestitemtitle: {
        fontSize: '12px',
        fontWeight: 500,
        color: '#9b9b9b',
        margin: '0px',
        textTransform: 'uppercase',
    },
    requestitemheading: {
        fontSize: '14px',
        fontWeight: 500,
        color: '#307fc1',
        margin: '0px',
    },
    requestitemtime: {
        fontSize: '14px',
        fontWeight: 500,
        color: '#000',
        margin: '0px',
        '& button': {
            fontSize: '11px',
            borderRadius: '5px',
            padding: '1px 4px 1px',
            '&:hover': {
                background: '#01ae8f',
            }
        }
    },
    locationicon: {
        height: '20px',
        width: '20px',
        borderRadius: '50%',
        background: '#fff',
        boxShadow: ' 0 2px 16px 0 rgba(0, 0, 0, 0.11)',
        textAlign: 'center',
    },

    checkinbutton: {
        fontSize: '11px',
        color: '#fff',
        background: '#307fc1',
        borderRadius: '5px',
        '&:hover': {
            background: '#307fc1',
        }
    },
    avtarsection: {
        display: 'flex',
        alignItems: 'center',
        ' & > div': {
            height: '20px',
            width: '20px',
        }
    },
    avtarname: {
        marginLeft: '5px',
    }
})

export interface ComputerSessionHistoryTileProps {
    item: any,
    parent: any
}

export const ComputerSessionHistoryTile: FC<ComputerSessionHistoryTileProps> = (props) => {
    const { item, parent } = props
    const classes = useStyles()
    const { t } = useTranslation()
    const query = useQuery()
    const history = useHistory()
    const [showDetailsDialog, setShowDetalsDialog] = useState(false)
    const [showMapDialog, setShowMapDialog] = useState(false)
    const handleDetailClick = () => {
        setShowDetalsDialog(true)
    }

    const [
        terminateSession,
        { data: terminateSessionData, isLoading },
    ] = useTerminateSession()

    const handleTerminate = () => {
        terminateSession(item.id)
    }

    useEffect(() => {
        if (terminateSessionData && !isLoading) {
            refreshListAFterTerminate()
        }
    }, [terminateSessionData, isLoading])

    const refreshListAFterTerminate = () => {
        try {
            refreshComputerList()
            query.delete('selected')
            history.push(
                `${history.location.pathname}?${query.toString()}`,
            )
        } catch (err) {
            // Ignore
        }
    }

    const ComputersConnectedWhenAndDuration = (name: string, data: any) => {
        const { t } = useTranslation()
        const whenData = data[name]
        let connectedWhen = `0 ${t('min')}`
        if (whenData?.days > 0) {
            connectedWhen = `${whenData.days} ${whenData.days > 1 ? t('days') : t('day')}`
        }
        if (whenData?.hours > 0) {
            connectedWhen = connectedWhen === `0 ${t('min')}` ? `${whenData.hours} ${whenData.hours > 1 ? t('hours') : t('hour')}` : connectedWhen + ` ${whenData.hours} ${whenData.hours > 1 ? t('hours') : t('hour')}`
        }
        if (whenData?.minutes > 0) {
            connectedWhen = connectedWhen === `0 ${t('min')}` ? `${whenData.minutes} ${whenData.minutes > 1 ? t('mins') : t('min')}` : connectedWhen + ` ${whenData.hours} ${whenData.hours > 1 ? t('mins') : t('min')}`
        }
        return (
            <>
                {connectedWhen}
            </>
        )
    }

    const bindComputerName = () => {
        return `${item.computerName}, ${t('Common_StartTime')} ${moment.utc(item.startTimeUtc).local().format('L LT')}`
    }

    const caclulateRemianingTime = () => {
        const current = moment();
        const endTime = moment.utc(item.startTimeUtc).local()
        const seconds = current.diff(endTime, 'minutes')
        const d = Math.floor(seconds / (3600 * 24));
        const h = Math.floor(seconds % (3600 * 24) / 3600);
        const m = Math.floor(seconds % 3600 / 60);

        const dDisplay = d > 0 ? d + ' ' + (d > 1 ? t('days') : t('day')) : "";
        const hDisplay = h > 0 ? h + ' ' + (h > 1 ? t('hours') : t('hour')) : "";
        const mDisplay = m > 0 ? m + ' ' + (m > 1 ? t('mins') : t('min')) : "";
        return dDisplay + ' ' + hDisplay + ' ' + mDisplay;
    }

    return (
        <>
            <PaddedDiv>
                <Box className={classes.requestmaincontainer}>
                    <Box className={classes.requestHeader}>
                        <Box component="h2" className={classes.requestHeadertitle}>
                            {item.computerFriendlyName ? bindComputerName() : '---'}
                        </Box>
                        <Box className={classes.requestHeadertitlebutton}>
                            <Button onClick={() => handleDetailClick()}>
                                {t('ManageAccess_Details')}
                            </Button>
                        </Box>
                    </Box>
                    <Box className={classes.requestitemshow}>
                        <Box >
                            <Box component="h2" className={classes.requestitemtitle}>
                                {t('Computers_PersonDisplayName')}
                            </Box>
                            <Box component="h2" className={classes.requestitemheading}>
                                <Box className={classes.avtarsection}>
                                    <Avatar size="small" src={getFullImageUrl(item.checkedOutByPersonThumbUrl)} />
                                    <span className={classes.avtarname}>
                                        {item.checkedOutByPersonName ? item.checkedOutByPersonName : '---'}
                                    </span>
                                </Box>
                            </Box>
                        </Box>
                        <Box >
                            <Box component="h2" className={classes.requestitemtitle}>
                                {t('Common_Computer')}
                            </Box>
                            <Box component="h2" className={classes.requestitemheading}>
                                {item.computerFriendlyName ? item.computerFriendlyName : '---'}
                            </Box>
                        </Box>
                        <Box >
                            <Box component="h2" className={classes.requestitemtitle}>
                                {t('Common_Credential')}
                            </Box>
                            <Box component="h2" className={classes.requestitemheading}>
                                {item.externalCredentialFriendlyName ? item.externalCredentialFriendlyName : '---'}
                            </Box>
                        </Box>
                        {item.canTerminateSession ?
                            <Box >
                                <Box component="h2" className={classes.requestitemtitle}>
                                    {t('RemainingTime')}
                                </Box>
                                <Box component="h2" className={classes.requestitemtime}>
                                    {caclulateRemianingTime()}
                                </Box>
                            </Box> :
                            <Box>
                                <Box component="h2" className={classes.requestitemtitle}>
                                    {t('Common_ConnectedWhen')}
                                </Box>
                                <Box component="h2" className={classes.requestitemtime}>
                                    {ComputersConnectedWhenAndDuration("when", item)}
                                </Box>
                            </Box>
                        }

                    </Box>

                    <Box className={classes.requestitemshowsecond}>
                        <Box >
                            <Box component="h2" className={classes.requestitemtitle}>
                                {t('Common_Duration')}
                            </Box>
                            <Box component="h2" className={classes.requestitemtime}>
                                {ComputersConnectedWhenAndDuration("duration", item)}
                            </Box>
                        </Box>
                        <Box className={classes.requestleftshow}>
                            <Box component="h2" className={classes.requestitemtitle}>
                                {t('Common_LOA')}
                            </Box>
                            <Box component="h2" className={classes.requestitemtime}>
                                {item.assuranceLevel ? item.assuranceLevel : '---'}
                            </Box>
                        </Box>
                        <Box className={classes.requestleftshow}>
                            <Box component="h2" className={classes.requestitemtitle}>
                                {t('Common_Location')}
                            </Box>
                            <Tooltip title={`${item.latitude && item.longitude && config.GOOGLE_MAP_API_KEY ? t('Common_ViewLocationOnMap') : !config.GOOGLE_MAP_API_KEY ? (t('Common_LocationServiceNotEnabled')) : t('Common_LocationDataNotAvailable')}`}>
                                <Box className={classes.locationicon}>
                                    <Icon onClick={() => setShowMapDialog(true)} name="Map" />
                                </Box>
                            </Tooltip>
                        </Box>

                        {item.canTerminateSession ? <Box className={classes.requestitembtn}>
                            <Button onClick={() => handleTerminate()} className={classes.checkinbutton}>{t('Common_Disconnect')}</Button>
                        </Box> : <></>}

                    </Box>
                </Box>
            </PaddedDiv>
            <ComputerSessionHistoryDetails handleTerminate={handleTerminate} item={item} open={showDetailsDialog} onClose={() => setShowDetalsDialog(false)} title={item.computerFriendlyName ? bindComputerName() : '---'} />
            {item.latitude && item.longitude && showMapDialog ? <ComputerMapDialog latitude={item.latitude} langitutde={item.longitude} open={showMapDialog} onClose={() => setShowMapDialog(false)} title={item.computerFriendlyName} /> : <></>}
        </>
    )
}

export default ComputerSessionHistoryTile
