import { Box, FormControlLabel, makeStyles } from '@material-ui/core'
import moment from 'moment'
import { isNilOrEmpty } from 'packages/core'
import { Icon } from 'packages/eid-icons'
import { Checkbox, TextField } from 'packages/eid-ui'
import { NativeDatePicker } from 'packages/eid-ui/DateTimePickers'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'



const useStyles = makeStyles({
    label: {
        color: '#b4b4b4',
        fontSize: '10px',
        textTransform: 'uppercase',
        width: '65px',
        position: 'absolute',
        left: '10px',
        top: '4px',
        zIndex: 50,
    },
    dateMaincontainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '18px',
        marginBottom: '32px',
        '&  span': {
            fontSize: '14px',
        },

    },
   
    dateMainwidthcontainer: {
        '& > div': {
            width: '90%',
        },
    },
    dueDateInputContainer: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',

        '& svg': {
            marginTop: '-10px',
            marginRight: '4px',

        },
        '& input': {
            padding: '10px',
            paddingLeft: '80px',
            color: '#5d6870',
            fontSize: '16px',

        },
        '& fieldset': {
            border: 'none',

        },
        '& > div:nth-child(1)': {
            width: '100%',
        },
        '&:hover': {
            cursor: 'pointer',
            '& input': {
                cursor: 'pointer',
                // borderColor: '#307fc1',
            },
        },
    },
})

export interface ComputerSessionRequestFiltersProps {
    isActiveConnectionOnly: boolean,
    handleCheckedClick: (e: React.ChangeEvent<HTMLInputElement>) => void,
    startDate: string,
    endDate: string,
    setStartDate: (e: any) => void,
    setEndDate: (e: any) => void
}

export const ComputerSessionRequestFilters: FC<ComputerSessionRequestFiltersProps> = (props) => {
    const { startDate, endDate, setStartDate, setEndDate, handleCheckedClick, isActiveConnectionOnly } = props
    const classes = useStyles()
    const { t } = useTranslation()
    return (
        <Box className={classes.dateMaincontainer}>
            <Box className={classes.dateMainwidthcontainer}>
                <NativeDatePicker
                    value={startDate}
                    handleChange={(e: any) => {
                        if (isNilOrEmpty(e)) {
                        } else {
                            const date = moment(e)
                            const dateToSet = date
                                .clone()
                                .endOf('day')
                                .set('milliseconds', 0)
                                .utc()
                            setStartDate(dateToSet)
                        }
                    }}
                >
                    <Box className={classes.dueDateInputContainer}>

                        <Box component="p" className={classes.label}>
                            {t('Common_StartDate')}
                        </Box>
                        <TextField
                            type="text"
                            margin="none"
                            readOnly
                            value={startDate ? moment.utc(startDate).local().format('L') : ''}
                            style={{
                                backgroundColor: '#fff',
                                width: '100%',
                                boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
                                borderRadius: '5px'
                            }}
                            placeholder={t('Common_SelectDate')}
                        />
                        <Box position="absolute" right="8px" top="16px">
                            <Icon
                                name="Tasks"
                                width={19}
                                height={20}
                                color="#aab0b4"
                            />
                        </Box>
                    </Box>
                </NativeDatePicker>
            </Box>
            <Box className={classes.dateMainwidthcontainer}>
                {/* End Date picker */}
                <NativeDatePicker
                    value={null}
                    handleChange={(e: any) => {
                        if (isNilOrEmpty(e)) {
                        } else {
                            const date = moment(e)
                            const dateToSet = date
                                .clone()
                                .endOf('day')
                                .set('milliseconds', 0)
                                .utc()
                            setEndDate(dateToSet)
                        }

                    }}
                >
                    <Box className={classes.dueDateInputContainer}>
                        <Box component="p" className={classes.label}>
                            {t('Common_EndDate')}
                        </Box>
                        <TextField
                            type="text"
                            margin="none"
                            readOnly
                            value={endDate ? moment.utc(endDate).local().format('L') : ''}
                            style={{
                                backgroundColor: '#fff',
                                width: '100%',
                                boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
                                borderRadius: '5px',
                                color: '#5d6870'
                            }}
                            placeholder={t('Common_SelectDate')}
                        />
                        <Box position="absolute" right="8px" top="16px">
                            <Icon
                                name="Tasks"
                                width={19}
                                height={20}
                                color="#aab0b4"
                            />
                        </Box>
                    </Box>
                </NativeDatePicker>
            </Box>

            {/* Checkbox for checkout */}
            <Box >
            <FormControlLabel
                control={
                    <Checkbox
                        edge="start"
                        color="primary"
                        onChange={(e) => {
                            handleCheckedClick(e)
                        }}
                        checked={isActiveConnectionOnly}
                    />
                }
                label={t('Common_ActiveSession')}
            />
            </Box>
        </Box>

    )
}

export default ComputerSessionRequestFilters