import React from 'react'
import PropTypes from 'prop-types'
import { Card } from '@material-ui/core'
import RequestableResourceDetails from 'components/RequestableResourceDetails'
import Box from '@material-ui/core/Box'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { Button } from 'components'
import ItemHeader from './ItemHeader'
import { useTranslation } from 'react-i18next'
import { ResourceListingInfoIcon } from 'components/DynamicComponents/ResourceListingInfoIcon'
import ActivateNowButton from './ActivateNowButton'

const useCardStyles = makeStyles({
    root: (props) => ({
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 'none !important',
        position: 'relative',
        overflow: 'visible',
        boxShadow: 'none !important',
        border: 'none !important',
        '&:hover': {
            borderBottomRightRadius: '0 !important',
            borderBottomLeftRadius: '0 !important',
            overflow: 'visible',
            zIndex: 4,
            '&:not(:hover)': {
                zIndex: 2,
                transition: 'z-index 0.5s ease 0s',
            },
        },
        ...props.cardStyles,
    }),
})

const styles = {
    card: (props) => ({
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '5px',
        position: 'relative',
        overflow: 'visible',

        '&:hover': {
            borderBottomRightRadius: '0 !important',
            borderBottomLeftRadius: '0 !important',
            overflow: 'visible',

            zIndex: 4,

            '&:not(:hover)': {
                zIndex: 2,
                transition: 'z-index 0.5s ease 0s',
            },
        },
        ...props.cardStyles,
    }),
}

const ItemCard = withStyles(styles)((props) => {
    const {
        item,
        currentResourceType,
        onItemClick,
        buttonStyles,
        cardStyles,
        attributes,
        color,
    } = props

    const { t } = useTranslation()
    const cardClasses = useCardStyles({ cardStyles })
    const headerAttribute = attributes.find((x) => x.isHeader)

    const showActivateNow =
        currentResourceType.name !== 'Computers' && item?.canActivateNow

    return (
        <Card classes={cardClasses} role="button">
            <ItemHeader title={item[headerAttribute.name]} />
            <ResourceListingInfoIcon
                data={item}
                isCardView
            ></ResourceListingInfoIcon>
            <RequestableResourceDetails
                item={item}
                attributes={attributes}
                color={color}
                resourceType={currentResourceType}
            />

            <Box padding="20px" width="100%" display="flex">
                <Button
                    onClick={onItemClick}
                    color="#01ae8f"
                    rootStylesProp={{
                        borderRadius: 0,
                        width: showActivateNow ? '50%' : '100%',
                        height: '50px',
                        ...buttonStyles,
                    }}
                >
                    {item?.canActivateNow
                        ? t('Common_Details')
                        : t('Common_RequestAccess')}
                </Button>

                {showActivateNow && (
                    <ActivateNowButton
                        resource={item}
                        resourceType={currentResourceType}
                        forDetailsDrawer={true}
                        width="50%"
                        marginTop="0px"
                        paddingRight="0px"
                    />
                )}
            </Box>
        </Card>
    )
})

ItemCard.propTypes = {
    item: PropTypes.object,
    onItemClick: PropTypes.func,
    className: PropTypes.string,
}

export default ItemCard
