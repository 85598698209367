import { useGetControlsAccess } from 'packages/core'

const useSubcomponents = () => {
    const controls = useGetControlsAccess().map((c) => c.name)

    const hasAccessToWorkflowsTab = controls.includes(
        'ITShop-Workflow-Tab-Control',
    )

    const canSeeSimpleTextSearch = controls.includes(
        'ITShop-SimpleTextSearch-Control',
    )

    const hasAccessToTCodesGrid = controls.includes('ITShop-TCodesGrid-Control')

    const hasAccessToLocalSensitiveFunctionsGrid = controls.includes(
        'ITShop-LocalSensitiveFunctionsGrid-Control',
    )

    const canSeeManagementRolesGranted = controls.includes(
        'ITShop-ManagementRolesGrantedGrid-Control',
    )

    const canShopForOthers = controls.includes(
        'ITShop-ShopForTargetPerson-Control',
    )

    const canSeeApplicationRolesGranted = controls.includes(
        'ITShop-ApplicationRolesGrantedGrid-Control',
    )

    const canSeeApplications = controls.includes(
        'ITShop-ApplicationsGrid-Control',
    )

    const hasAccessToSeeApproverControl = controls.includes(
        'ITShop-ShowCartApprover-Control',
    )

    const hasAccessToCartDueDate = controls.includes(
        'ITShop-CartDueDate-Control',
    )

    const canSeeBusinessRequest = controls.includes(
        'ITShop-ManageAccessBusinessRequestAttribute-Control',
    )

    const canSeeAccessRequestPolicy = controls.includes(
        'ITShop-ResourceAccessRequestPolicy-Control',
    )

    const canSeeShopByApplications = controls.includes(
        'ITShop-ShopByApplications-Control',
    )

    const canSeeManagementRolesSuggestedGrid = controls.includes(
        'ITShop-ManagementRolesSuggestedGrid-Control',
    )

    const canSeeShowOnlyAzureApplications = controls.includes(
        'ITShop-ShowOnlyAzureApplications-Control',
    )

    const canSeeManageAccessFiltersBar = controls.includes(
        'ITShop-ShowManageAccessFiltersBar-Control',
    )

    const canSeeAzureLicenseTenantAttribute = controls.includes(
        'ITShop-AzureLicensesResourceSystemAttribute-Control',
        )

    const canSeeAzureLicenseLicensePoolAttribute = controls.includes(
        'ITShop-AzureLicensesLicensePoolAttribute-Control'
        )

    const canSeeAzureLicenseTenantSubscriptionAttribute = controls.includes(
        'ITShop-AzureLicensesTenantSubscriptionAttribute-Control'
        )

    const canSeeAzureLicenseLicensedAssigneeAttribute = controls.includes(
        'ITShop-AzureLicensesLicensedAssigneeAttribute-Control'
        )

    const canSeeAzureLicenseServicePlansSection = controls.includes(
        'ITShop-AzureLicensesTenantSubscriptionServicesSection-Control'
        )

    const canSeeAtLeastOneFilter = (resourceType, screen) => {
        let filters = []
        if (screen === 'RequestAccess') {
            filters = resourceType.filters.filter(
                (f) => f.forRequestAccess === true,
            )
        } else if (screen === 'ManageAccess') {
            filters = resourceType.filters.filter(
                (f) => f.forManageAccess === true,
            )
        }
        filters = filters.filter((f) =>
            f.requireAccess
                ? controls.indexOf(f.requireAccess.control) >= 0
                : true,
        )

        return filters.length > 0
    }

    return {
        canSeeSimpleTextSearch,
        hasAccessToTCodesGrid,
        hasAccessToLocalSensitiveFunctionsGrid,
        canSeeManagementRolesGranted,
        canSeeApplicationRolesGranted,
        hasAccessToSeeApproverControl,
        hasAccessToCartDueDate,
        canSeeBusinessRequest,
        canSeeAccessRequestPolicy,
        canSeeApplications,
        canSeeShopByApplications,
        canSeeAtLeastOneFilter,
        canShopForOthers,
        canSeeManagementRolesSuggestedGrid,
        canSeeShowOnlyAzureApplications,
        hasAccessToWorkflowsTab,
        canSeeManageAccessFiltersBar,
        canSeeAzureLicenseTenantAttribute,
        canSeeAzureLicenseLicensePoolAttribute,
        canSeeAzureLicenseTenantSubscriptionAttribute,
        canSeeAzureLicenseLicensedAssigneeAttribute,
        canSeeAzureLicenseServicePlansSection
    }
}
export default useSubcomponents
