import { FC, useState } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Attribute, AttributeLabel, AttributeValue } from 'packages/eid-ui'
import { ApplicationUserCard } from '../UserCard'
const smallScreenWidth = 960

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            position: 'relative',
            padding: theme.spacing(2),
            display: 'flex',
            miHeight: '156px',
            justifyContent: 'space-between',

            [`@media (max-width:${smallScreenWidth - 1}px)`]: {
                flexDirection: 'column',
            },
        },
        paddingdiv: {
            padding: '0 20px',
            width: '100%',
        },
        heading: {},
        result: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        showmore: {
            fontSize: '13px',
            margin: '0',
            marginTop: '2px',
            color: '#307fc1',
            cursor: 'pointer',
        },
        showless: {
            fontSize: '13px',
            margin: '0',
            marginTop: '2px',
            color: '#307fc1',
            cursor: 'pointer',
        },
    }),
)

export const ApplicationOwnerDeputies: FC<any> = ({ label, data }) => {
    const classes = useStyles()
    const [showMore, setShowMore] = useState(data.length > 2 ? true : false)

    const bindUserInfo = (users: any) => {
        if (users && users.length > 0) {
            if (showMore) {
                return users.map((item: any, index: number) => {
                    if (index < 2)
                        return (
                            <ApplicationUserCard
                                key={`application-apprvers-${index}`}
                                data={item}
                            />
                        )
                })
            } else {
                return users.map((item: any, index: number) => (
                    <ApplicationUserCard
                        key={`application-apprvers-${index}`}
                        data={item}
                    />
                ))
            }
        } else {
            return '-'
        }
    }

    return (
        <Attribute orientation="horizontal">
            <AttributeLabel className={classes.heading}>{label}</AttributeLabel>
            <AttributeValue className={classes.result}>
                {bindUserInfo(data)}{' '}
                {showMore ? (
                    <p
                        onClick={() => setShowMore(!showMore)}
                        className={classes.showmore}
                    >
                        + {data.length - 2} more
                    </p>
                ) : (
                    data.length > 2 && (
                        <p
                            onClick={() => setShowMore(!showMore)}
                            className={classes.showless}
                        >
                            less
                        </p>
                    )
                )}
            </AttributeValue>
        </Attribute>
    )
}
