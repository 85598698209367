import React from 'react'
import { usePaginatedQuery } from 'react-query'
import { Pagination } from 'packages/eid-ui'
import { useResourceTypeContext } from 'resourceTypeContext'

const useApiWithInfiniteScroll = (
    queryKey,
    queryFn,
    queryConfig,
    defaultTake = 16,
) => {
    const [{ page }, dispatch] = useResourceTypeContext()

    const handlePageChange = (_, value) => {
        dispatch({
            type: 'SET_PAGE',
            payload: value,
        })
    }

    const skip = (page - 1) * defaultTake
    const take = defaultTake

    const { latestData, error, refetch } = usePaginatedQuery(
        [...queryKey, skip, take],
        () => queryFn(skip, take),
        queryConfig,
    )

    const isLoading = !Boolean(latestData)

    const numberOfPages = latestData
        ? Math.ceil(latestData.totalCount / take)
        : 0

    const pagination = numberOfPages > 1 && (
        <Pagination
            count={numberOfPages}
            size="medium"
            page={page}
            onChange={handlePageChange}
            parentIndex={9}
        />
    )

    return {
        loading: isLoading,
        list: latestData ? latestData.data : undefined,
        totalCount: latestData ? latestData.totalCount : undefined,
        rest: latestData ? latestData : undefined,
        pagination,
        error,
        refetch,
    }
}

export default useApiWithInfiniteScroll
