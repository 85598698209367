import { FC, useState } from 'react'
import { Box } from '@material-ui/core'
import { Modal } from 'components/Modal'
import { PasswordInput } from 'components/PasswordInput'
import { ItemDetails } from 'components'
import { useTranslation } from 'react-i18next'

export interface SetPasswordProps {
    open?: any
    onClose?: any
    onCancel?: any
}

export const SetPassword: FC<SetPasswordProps> = (props) => {
    const { open, onClose, onCancel } = props
    const [value, setValue] = useState('')
    const [confirmationValue, setConfirmationValue] = useState('')

    const { t } = useTranslation()

    const footerContent = (
        <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            padding="24px 32px"
        >
            <Box minWidth="88px">
                <ItemDetails.ActionButton
                    fontColor="#ffffff"
                    bgColor={'#307fc1'}
                    width="100%"
                >
                    {t('Common_Ok')}
                </ItemDetails.ActionButton>
            </Box>

            <Box minWidth="130px">
                <ItemDetails.ActionButton
                    fontColor="#ffffff"
                    bgColor={'#aab0b4'}
                    width="100%"
                    onClick={onCancel}
                >
                    {t('Common_Cancel')}
                </ItemDetails.ActionButton>
            </Box>
        </Box>
    )

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={t(
                'Common_EnterTheNewMasterPasswordToEncrypt/DecryptYourSecrets',
            )}
            footerContent={footerContent}
        >
            <Box>
                In order to access priviledged resources you first have to set
                you a Master Password helpful text will go here to help users
                make better sense of this operation.
            </Box>

            <Box marginY="24px">
                <PasswordInput
                    value={value}
                    placeholder={t('Common_NewMasterPassword')}
                />
            </Box>

            <Box marginY="24px">
                <PasswordInput
                    value={confirmationValue}
                    placeholder={t('Common_ConfirmPassword')}
                />
            </Box>
        </Modal>
    )
}
