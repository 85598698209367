import { useTranslation } from 'react-i18next'
import { StatusIndicator } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import CheckInButton from './CheckInButton'
import CheckOutButton from './CheckOutButton'
import ExtendButton from './ExtendButton'
import moment from 'moment'
import { Box, makeStyles } from '@material-ui/core'
import { useQuery } from 'packages/core'
import { useHistory } from 'react-router'

const useStyles = makeStyles(() => ({
    taskicon: {
        width: '24px',
        height: '24px',
        background: '#307fc1',
        border: 'solid 1px #307fc1',
        borderRadius: '5px',
        padding: '4px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: '12px',
    },
    taskbadgesection: {
        position: 'relative',
        cursor: 'pointer',
    },
    taskbadge: {
        position: 'absolute',
        top: '-8px',
        right: '-6px',
        background: '#ff3c00',
        height: '15px',
        width: '15px',
        borderRadius: '50%',
        fontSize: '10px',
        fontWeight: '500',
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '1px',
    },
}))

const CredentialStatusButton = ({ data }) => {
    const item = data?.resource
    const assignment = data?.assignment
    const { t } = useTranslation()
    const classes = useStyles()
    const query = useQuery()
    const history = useHistory()

    const checkedOutBySomeoneElse = item.isCheckedOutBySomeoneElse

    const isExtendable =
        assignment?.assignmentDetails?.timeConstraintActive &&
        moment(assignment?.assignmentDetails?.startDateUtc).isBefore(moment())

    const showCalenderIcon = item.checkOutCount > 0

    if (item.isExpired) {
        return (
            <Box style={{ width: '120px' }}>
                <StatusIndicator
                    className="Status-Indicator"
                    variant="Expired"
                    label={t('Expired')}
                    size="medium"
                />
            </Box>
        )
    }

    if (item.canCheckIn) {
        return (
            <Box display="flex">
                <CheckInButton data={data} drawerView={false} />
                {isExtendable && (
                    <div style={{ marginLeft: '16px' }}>
                        <ExtendButton data={data} />
                    </div>
                )}
                {showCalenderIcon && (
                    <Box
                        onClick={() => {
                            query.set('selected', item.id)
                            query.set('showSession', true)
                            history.push(
                                `${
                                    history.location.pathname
                                }?${query.toString()}`,
                            )
                        }}
                        className={classes.taskbadgesection}
                    >
                        <Box className={classes.taskicon}>
                            <Icon name="ActiveCalender" />
                        </Box>
                        <span className={classes.taskbadge}>
                            {item.checkOutCount}
                        </span>
                    </Box>
                )}
            </Box>
        )
    } else if (item.canCheckOut || checkedOutBySomeoneElse) {
        return (
            <Box display="flex">
                <CheckOutButton
                    data={data}
                    drawerView={false}
                    checkedOutBySomeoneElse={checkedOutBySomeoneElse}
                />
                {isExtendable && (
                    <div style={{ marginLeft: '16px' }}>
                        <ExtendButton data={data} />
                    </div>
                )}
                {showCalenderIcon && (
                    <Box
                        onClick={() => {
                            query.set('selected', item.id)
                            query.set('showCheckouts', true)
                            history.push(
                                `${
                                    history.location.pathname
                                }?${query.toString()}`,
                            )
                        }}
                        className={classes.taskbadgesection}
                    >
                        <Box className={classes.taskicon}>
                            <Icon name="ActiveCalender" />
                        </Box>
                        {/* <span className={classes.taskbadge}>
                                {item.checkOutCount}
                            </span> */}
                    </Box>
                )}
            </Box>
        )
    } else return <>-</>
}

CredentialStatusButton.defaultProps = {
    drawerView: false,
}

export default CredentialStatusButton
