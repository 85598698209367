import React from 'react'
import { styled, Box } from '@material-ui/core'

const AttributesContainer = styled(({ topPadding, bottomPadding, ...rest }) => (
    <Box {...rest} />
))(({ topPadding, bottomPadding }) => ({
    padding: `${topPadding} 0 ${bottomPadding} 0`,
    backgroundColor: '#ffffff',
    boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
    // minHeight: '256px',
}))

AttributesContainer.defaultProps = {
    topPadding: '14px',
    bottomPadding: '14px',
}

export default AttributesContainer
