import React from 'react'
import { styled, Box } from '@material-ui/core'
import { isNilOrEmpty } from 'packages/core'

const AttributeLabel = styled(Box)({
    fontSize: '12px',
    lineHeight: 1.33,
    color: '#9b9b9b',
    textTransform: 'uppercase',
    minWidth: '250px',
    display: 'block',
    wordBreak: 'break-word',
    wordWrap: 'break-word',
    fontWeight: 'bold',
})

const AttributeValue = styled(Box)({
    fontSize: '12px',
    lineHeight: 1.33,
    color: '#000000',
    width: '100%',
    display: 'block',
    wordBreak: 'break-word',
    wordWrap: 'break-word',
})

const Attribute = (props) => {
    const { label, value, orientation, labelProps, valueProps } = props
    return (
        <Box
            display={orientation === 'vertical' ? 'inherit' : 'flex'}
            paddingY="8.5px"
        >
            <AttributeLabel {...labelProps}>{label}</AttributeLabel>
            <AttributeValue
                marginTop={orientation === 'vertical' ? '12px' : '0px'}
                {...valueProps}
            >
                {isNilOrEmpty(value) ? '-' : value}
            </AttributeValue>
        </Box>
    )
}

Attribute.defaultProps = {
    orientation: 'horizontal',
}

export default Attribute
