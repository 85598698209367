import React from 'react'
import { ItemDetails, PeopleList } from 'components'
import { isNilOrEmpty, useIsSmallScreen } from 'packages/core'
import { Box, styled } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { CommonAttributes } from '../CommonAttributes'

const PaddedDiv = styled(Box)({
    padding: '0 31px',
})

export const OverviewSection = ({ managementRole }: any) => {
    const { t } = useTranslation()

    const attributeOrientation = useIsSmallScreen() ? 'vertical' : 'horizontal'

    return (
        <ItemDetails.AttributesContainer>
            <PaddedDiv>
                <ItemDetails.Attribute
                    label={t('Common_TechnicalName')}
                    value={managementRole.name}
                    orientation={attributeOrientation}
                />
                <ItemDetails.Attribute
                    label={t('Common_Type')}
                    value={managementRole.typeFriendlyName}
                    orientation={attributeOrientation}
                />

                {managementRole.owners && (
                    <ItemDetails.Attribute
                        label={t('ManagementRoles_Owners')}
                        value={<PeopleList list={managementRole.owners} />}
                        orientation={attributeOrientation}
                    />
                )}

                <ItemDetails.Attribute
                    label={t('Common_Description')}
                    value={managementRole.localizedDescription}
                    orientation={attributeOrientation}
                />

                <ItemDetails.Attribute
                    label={t('Common_IsHighSecurity')}
                    value={
                        isNilOrEmpty(managementRole.isHighSecurity)
                            ? '-'
                            : managementRole.isHighSecurity
                            ? t('Common_Yes')
                            : t('Common_No')
                    }
                    orientation={attributeOrientation}
                />

                <CommonAttributes resource={managementRole} />
            </PaddedDiv>
        </ItemDetails.AttributesContainer>
    )
}
