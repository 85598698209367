import { useState } from 'react'
import { Box, styled } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useComputerOwners } from 'hooks'
import { ItemDetails, PeopleList } from 'components'
import { useIsSmallScreen, useQuery } from 'packages/core'
import { Icon } from 'packages/eid-icons'
import moment from 'moment'
import ComputerSessionRequest from 'components/ComputerSessionRequest'
import ActivateNowButton from '../../../ListingPage/ActivateNowButton'
import useConfiguration from 'useConfiguration'

const PaddedDiv = styled(Box)({
    padding: '0 31px',
})
const overflowStyleProps = {
    maxHeight: '220px',
    overflow: 'auto',
}

const OverviewSection = ({ item }) => {
    const computer = item.resource
    const { assignmentDetails, resourceAssignment } = item.assignment
    const { t } = useTranslation()
    const attributeOrientation = useIsSmallScreen() ? 'vertical' : 'horizontal'
    return (
        <ItemDetails.AttributesContainer>
            <PaddedDiv>
                <ItemDetails.Attribute
                    label={t('Common_DisplayName')}
                    value={computer?.friendlyName ? computer.friendlyName : '-'}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('Common_DnsHostName')}
                    value={computer.dnsHostName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('Common_Infrastructure')}
                    value={computer.computerPlatformType && computer.computerPlatformType !== "Unknown" ? computer.computerPlatformType : '-'}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('Computers_Environment')}
                    value={computer.environmentTypeFriendlyName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('Common_ComputerType')}
                    value={computer.computerTypeName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('Common_DomainOrDirectory')}
                    value={computer.resourceSystemFriendlyName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('Common_OperatingSystem')}
                    value={computer.operatingSystem}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('Common_PsmEnabled')}
                    value={
                        computer.isPsmEnabled === null ? (
                            '-'
                        ) : computer.isPsmEnabled === true ? (
                            <Icon
                                style={{ width: '20px', height: '20px' }}
                                name="CheckedRound"
                            ></Icon>
                        ) : (
                            <Icon
                                style={{ width: '20px', height: '20px' }}
                                name="UncheckedRound"
                            ></Icon>
                        )
                    }
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('Common_JitEnabled')}
                    value={
                        computer.isComputerLocalInventoried === null ? (
                            '-'
                        ) : computer.isComputerLocalInventoried === true ? (
                            <Icon
                                style={{ width: '20px', height: '20px' }}
                                name="CheckedRound"
                            ></Icon>
                        ) : (
                            <Icon
                                style={{ width: '20px', height: '20px' }}
                                name="UncheckedRound"
                            ></Icon>
                        )
                    }
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('Common_Description')}
                    value={computer.description ? computer.description : '-'}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('Common_AccessLevel')}
                    value={
                        resourceAssignment?.friendlyName
                            ? resourceAssignment.friendlyName
                            : '-'
                    }
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('Common_AssignedVia')}
                    value={
                        resourceAssignment?.assigneePreviewName
                            ? resourceAssignment.assigneePreviewName
                            : '-'
                    }
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
                <ItemDetails.Attribute
                    label={t('Common_EndTime')}
                    value={
                        assignmentDetails?.endTimeUtc
                            ? moment.utc(assignmentDetails.endTimeUtc)
                                  .local()
                                  .format('L LT')
                            : '-'
                    }
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
            </PaddedDiv>
        </ItemDetails.AttributesContainer>
    )
}
const AdditionalInformationSection = ({ item }) => {
    const computer = item.resource
    const { t } = useTranslation()
    const attributeOrientation = useIsSmallScreen() ? 'vertical' : 'horizontal'
    const { data: owners, isLoading: ownersLoading } = useComputerOwners(
        computer.id,
    )
    return (
        <>
            {ownersLoading ? (
                <ItemDetails.AttributesContainer>
                    <PaddedDiv>
                        <ItemDetails.AttributesLoader />
                    </PaddedDiv>
                </ItemDetails.AttributesContainer>
            ) : (
                <ItemDetails.AttributesContainer bottomPadding="0">
                    <PaddedDiv>
                        <ItemDetails.Attribute
                            label={t('Common_Owners')}
                            value={<PeopleList list={owners} />}
                            orientation={attributeOrientation}
                        />
                    </PaddedDiv>
                </ItemDetails.AttributesContainer>
            )}
        </>
    )
}
const isResourceExpired = (endDateUtc) => {
    if (!endDateUtc || endDateUtc.length <= 0) {
        return false
    }
    const today = moment().utc()
    const endDate = moment(endDateUtc).utc()
    return endDate.isBefore(today)
}

const ComputerDetails = ({ item, toggleDrawer }) => {
    const { t } = useTranslation()
    const query = useQuery()

    const { getResourceType } = useConfiguration()
    const currentResourceType = getResourceType('Computers')
    const [activeTab, setActiveTab] = useState('overview')
    let showSessionRequestTab = false
    const selectedTabForComputers = query.get('tab')
    if (selectedTabForComputers && selectedTabForComputers === 'loginsession') {
        showSessionRequestTab = true
    }

    return (
        <>
            <ItemDetails.Tabs
                variant="standard"
                value={activeTab}
                onChange={(_, value) => setActiveTab(value)}
            >
                <ItemDetails.Tabs.Tab
                    value="overview"
                    label={t('Common_Overview')}
                />

                <ItemDetails.Tabs.Tab
                    value="additionalInformation"
                    label={t('Common_MoreInformation')}
                />
                {showSessionRequestTab && (
                    <ItemDetails.Tabs.Tab
                        value="sessionRequest"
                        label={t('Common_SessionRequest')}
                    />
                )}
                {showSessionRequestTab && (<span style={{
                    position: 'relative',
                    top: '12px',
                    right: '16px',
                    background: '#ff3c00',
                    height: '15px',
                    width: '15px',
                    borderRadius: '50%',
                    fontSize: '10px',
                    color: '#fff',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: "center",
                    paddingTop: '1px',
                }}>
                    {item?.resource?.checkOutCount ? item.resource.checkOutCount : 0}
                </span>)}
            </ItemDetails.Tabs>

            {activeTab === 'overview' && <OverviewSection item={item} />}

            {activeTab === 'additionalInformation' && (
                <AdditionalInformationSection item={item} />
            )}

            {activeTab === 'sessionRequest' && (
                <ComputerSessionRequest item={item.resource} />
            )}

            {item?.resource.canActivateNow && (
                <ActivateNowButton
                    resource={item?.resource}
                    resourceType={currentResourceType}
                    forDetailsDrawer={true}
                />
            )}
            {/* TODO: Uncomment below if we need renew functionality */}
            {/* <RenewRevoke
                resourceType={computerType}
                resource={item.resource}
                assignment={item.assignment}
                preRevoke={preRevoke}
                postRevoke={toggleDrawer}
                postRenew={toggleDrawer}
                item={item}
            /> */}
        </>
    )
}

export default ComputerDetails
