import { Box, styled } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { ItemDetails } from 'components'
import { useIsSmallScreen } from 'packages/core'
import { CommonAttributes } from '../../ListingPage/CommonAttributes'

const PaddedDiv = styled(Box)({
    padding: '0 31px',
})
const attributeValueStyleProps = {
    fontSize: '13px !important',
}
const labelProps = {
    fontWeight: 'bold',
}

export const OverviewSection = ({ credential }: any) => {
    const { t } = useTranslation()

    const attributeOrientation = useIsSmallScreen() ? 'vertical' : 'horizontal'

    return (
        <ItemDetails.AttributesContainer>
            <PaddedDiv>
                <ItemDetails.Attribute
                    label={t('Common_DisplayName')}
                    value={credential.friendlyName}
                    orientation={attributeOrientation}
                    valueProps={attributeValueStyleProps}
                    labelProps={labelProps}
                />

                <ItemDetails.Attribute
                    label={t('Credentials_AccountLogonName')}
                    value={credential.checkedOutByLogin}
                    orientation={attributeOrientation}
                    valueProps={attributeValueStyleProps}
                    labelProps={labelProps}
                />

                <ItemDetails.Attribute
                    label={t('Common_DomainOrDirectory')}
                    value={credential.resourceSystemFriendlyName}
                    orientation={attributeOrientation}
                    valueProps={attributeValueStyleProps}
                    labelProps={labelProps}
                />

                <ItemDetails.Attribute
                    label={t('DefaultAccessDurationInMinutes')}
                    value={credential.defaultCheckOutTimeMinutes}
                    orientation={attributeOrientation}
                    valueProps={attributeValueStyleProps}
                    labelProps={labelProps}
                />

                <ItemDetails.Attribute
                    label={t('MaxAccessDurationInMinutes')}
                    value={credential.maxCheckOutTimeMinutes}
                    orientation={attributeOrientation}
                    valueProps={attributeValueStyleProps}
                    labelProps={labelProps}
                />

                <ItemDetails.Attribute
                    label={t('Common_Description')}
                    value={credential.description}
                    orientation={attributeOrientation}
                    valueProps={attributeValueStyleProps}
                    labelProps={labelProps}
                />
                <CommonAttributes resource={credential} />
            </PaddedDiv>
        </ItemDetails.AttributesContainer>
    )
}
