import React from 'react'
import { styled, Box } from '@material-ui/core'
import { SelectionBox } from 'components'
import { LinkTile } from 'packages/eid-ui'
import useConfiguration from 'useConfiguration'
import { Icon } from 'packages/eid-icons'
import { useTranslation } from 'react-i18next'

const OptionContainer = styled('div')({
    width: '226px',
    padding: '16px',
})

function ResourceSelection({ onSelect, onBackClick, onSkipClick }) {
    const { resourceTypes } = useConfiguration()
    const { t } = useTranslation()

    return (
        <SelectionBox
            tileSelection
            headingText={t('OnBoarding_YouAreShoppingFor')}
            footerText={t('OnBoarding_FooterTextMessage')}
            onBackClick={onBackClick}
            onSkipClick={onSkipClick}
        >
            {(isSmallScreen) => (
                <Box display="flex" justifyContent="center" alignItems="center">
                    <Box
                        maxWidth={isSmallScreen ? '250px' : '700px'}
                        marginTop={isSmallScreen ? '20px' : '40px'}
                        maxHeight={isSmallScreen ? 'initial' : '280px'}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        flexWrap="wrap"
                        style={{ overflow: 'auto' }}
                    >
                        {resourceTypes.map((rt) => (
                            <OptionContainer key={rt.name}>
                                <LinkTile
                                    icon={
                                        <Icon
                                            name={rt.icon.large}
                                            color="#D2D2D9"
                                        />
                                    }
                                    label={t(rt.title)}
                                    onClick={() => onSelect(rt.name)}
                                />
                            </OptionContainer>
                        ))}
                    </Box>
                </Box>
            )}
        </SelectionBox>
    )
}

export default ResourceSelection
