import {
    MenuItem,
    makeStyles,
    ListItemText,
    ListItemIcon,
} from '@material-ui/core'
import { Icon } from 'packages/eid-icons'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles({
    root: {
        fontSize: '16px',
        position: 'relative',
        minHeight: '40px',
        paddingLeft: '30px',
        borderLeft: '2px solid transparent',
    },
    icon: {
        minWidth: '40px',
        color: 'black',

        '& svg': {
            height: '16px',
            width: '16px',
        },
    },
    selected: {
        backgroundColor: 'rgba(48, 127, 193, 0.07) !important',
        borderLeft: '2px solid #307fc1',
        '& img': {
            opacity: 1,
            filter: 'grayscale(0%) ',
        },
    },
})

const CredentialTabsItem = ({ label, icon, selected, onClick }) => {
    const classes = useStyles()
    return (
        <MenuItem onClick={onClick} classes={classes} selected={selected}>
            <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
            <ListItemText>{label}</ListItemText>
        </MenuItem>
    )
}

const CredentialTabs = ({ active, onChange }) => {
    const { t } = useTranslation()
    const allTabs = [
        {
            label: t('ManageCredentials'),
            value: 'manage',
            icon: <Icon name="ApplicationsSmall" />,
            display: true,
        },
        {
            label: t('History'),
            value: 'history',
            icon: <Icon name="BusinessRolesSmall" />,
            display: true,
        },
    ]

    return (
        <>
            {allTabs
                .filter((x) => x.display === true)
                .map((tab, index) => (
                    <CredentialTabsItem
                        key={`${tab.value}_${index}`}
                        label={tab.label}
                        onClick={() => {
                            onChange(tab.value)
                        }}
                        selected={active === tab.value}
                        icon={tab.icon}
                    ></CredentialTabsItem>
                ))}
        </>
    )
}

export default CredentialTabs
