import { FC, Fragment } from 'react'
import { Box, createStyles, styled, Typography } from '@material-ui/core'
import { Loader, Tooltip } from 'packages/eid-ui'
import { useAppState } from 'appContext'
import { useTranslation } from 'react-i18next'
import { useAccessRequestPolicy, useAddItemToCart, useCart } from 'hooks'
import { Icon } from 'packages/eid-icons'
import { ItemDetails } from 'components'
import { IResource, IResourceType } from 'core'
import { makeStyles } from '@material-ui/core/styles'

export type AddToCartProps = {
    type?: 'Request' | 'Renew' | 'RequestAccess'
    resource: IResource
    resourceType: IResourceType
    preValidate?: any
    preAdd: any
    postAdd: any
    secondary?: any
    isSmallScreen?: boolean
}

const PaddedDiv = styled(Box)({
    padding: '0 31px',
})

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            display: 'flex',
            marginBottom: '16px',
            [theme.breakpoints.up('sm')]: {
                height: '80px',
                alignItems: 'center',
                position: 'relative',
            },
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column-reverse',
                paddingTop: '16px !important',
                paddingBottom: '16px !important',
            },
        },
    }),
)

export const AddToCart: FC<AddToCartProps> = (props) => {
    const { t } = useTranslation()

    const classes = useStyles()

    const { data: cart } = useCart()
    const [{ appConfig }]: any = useAppState()

    const accessRequestPolicy = useAccessRequestPolicy(
        props.resource.requestPolicyId,
    )

    const [addItemToCart, { isLoading }] = useAddItemToCart()

    if (!accessRequestPolicy) return <Fragment />

    const handleAddItemToCart = () => {
        const itemToAdd = props.preAdd()
        if (itemToAdd === undefined) return
        addItemToCart(itemToAdd).then(() => props.postAdd())
    }

    if (!cart) return <Loader />

    const itemAlreadyInCart = props.resourceType.alreadyInCart(
        cart.cartItems,
        props.resource,
        props.secondary,
    )

    const getTooltipTitle = () => {
        let message = ''
        if (props.preValidate) {
            message = props.preValidate()
        }
        if (message === '' && itemAlreadyInCart) {
            message = t('Common_ItemAlreadyInCart')
        }

        // Condition for Preapproved login schedule
        if (message === '' && props.type === 'RequestAccess') {
            const checkIfAlreadyExistInCart = cart.cartItems.find((x: any) => x.requestableResourceId === props.resource.id)
            if (checkIfAlreadyExistInCart) {
                message = t('Common_ItemAlreadyInCart')
            }
        }
        return message
    }

    const renderAddToCartButton = () => {
        return (
            <Tooltip title={getTooltipTitle()}>
                <Box minWidth="167px">
                    <ItemDetails.ActionButton
                        fontColor="#ffffff"
                        bgColor={appConfig.style.colors.primary}
                        width="100%"
                        loading={isLoading}
                        disabled={getTooltipTitle() !== ''}
                        onClick={handleAddItemToCart}
                    >
                        <Box display="flex" alignItems="center">
                            <Icon name={props.type === 'RequestAccess' ? 'RightTick' : "AddToCart"} color="#ffffff" />
                        </Box>
                        <Box
                            display="flex"
                            alignItems="center"
                            marginLeft="10px"
                        >
                            <Typography style={{ color: '#ffffff' }}>
                                {t(
                                    props.type === 'Request'
                                        ? 'Common_AddToCart'
                                        : props.type === 'RequestAccess' ? 'Common_RequestAccess' : 'Common_Renew',
                                )}
                            </Typography>
                        </Box>
                    </ItemDetails.ActionButton>
                </Box>
            </Tooltip>
        )
    }

    if (!accessRequestPolicy.isRequestAllowed) {
        return <Fragment />
    }

    return (
        <PaddedDiv className={classes.root}>
            {renderAddToCartButton()}
        </PaddedDiv>
    )
}

AddToCart.defaultProps = {
    type: 'Request',
}
