import { Box, makeStyles, Dialog, Typography, IconButton } from '@material-ui/core'
import { FC, useEffect, useState } from 'react'
import Geocode from "react-geocode";
import { Icon } from 'packages/eid-icons'
import GoogleMapReact from 'google-map-react';
import config from 'config'
import { Tooltip } from 'packages/eid-ui';
const mobileScreenWidth = '767px'

const useDialogStyles = makeStyles(() => ({
    paper: {
        maxWidth: '1000px',
        width: '840px',
        [`@media (max-width:${mobileScreenWidth})`]: {
            margin: '8px ',
            width: 'calc(100% - 16px) !important',
            maxWidth: 'calc(100% - 16px) !important ',
        },
    },
}))

const useStyles = makeStyles({
    body: ({ }) => ({
        backgroundColor: '#fbfbfd',
        padding: '24px 40px',
        boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',

        '&:focus': {
            outline: 'none',
        },
        height: '500px'
    }),
    modalHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        height: '80px',
        alignItems: 'center',
        padding: '16px 12px 16px 32px',
        backgroundColor: '#ffffff',
    },
    closeIcon: {
        display: 'flex',
    },
    radioBtn: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row'
    },
    title: {
        fontSize: '18px',
        fontWeight: 'bold',
        color: '#3b454d',
    },
    sectionHeader: {
        overflowWrap: 'break-word',
        wordBreak: 'normal',
        color: '#000000 !important',
        overflow: 'hidden',
        lineHeight: '15px',
        fontSize: '14px',
        padding: '15px 31px',
        borderTop: '1px solid rgba(0, 0, 0, .05)',
        borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
        fontWeight: 'bold',
        boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
        backgroundImage:
            'linear-gradient(0deg, rgb(0, 0, 0, 0.02) 12.5%, rgb(255, 255, 255) 12.5%, rgb(255, 255, 255) 50%, rgb(0, 0, 0, 0.02) 50%, rgb(0, 0, 0, 0.02) 62.5%, rgb(255, 255, 255) 62.5%, rgb(255, 255, 255) 100%)',
        backgroundSize: '8.00px 8.00px',
        '& svg': {
            margin: '0px !important',
        },

    },
    bodyinner: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        marginBottom: '16px',
    },
    bodyleftinner: {
        maxWidth: '232px',
        width: '100%',
    },
    bodyrightinner: {

    },
    bodyleftinnertitle: {
        fontSize: '12px',
        fontWeight: 500,
        textTransform: 'uppercase',
        color: '#9b9b9b',
        margin: 0,
    },
    bodyrightinnerresult: {
        fontSize: '14px',
        fontWeight: 500,
        color: '#000',
        margin: 0,

    },
    bodyrightresult: {
        fontSize: '14px',
        fontWeight: 500,
        color: '#307fc1',
        margin: 0,
    },
    footer: {
        '& button': {
            background: '#307fc1',
            '&:hover': {
                background: '#307fc1',
            }
        },

    },

})

export interface ComputerSessionRequestDetailsProps {
    latitude: number,
    langitutde: number,
    onClose: () => void,
    title: string,
    open: boolean
}

export const ComputerMapDialog: FC<ComputerSessionRequestDetailsProps> = (props) => {
    const { open, onClose, title, latitude, langitutde } = props
    const [locationInfo, setLocationInfo] = useState("")
    const [center, setCenter] = useState({ lat: latitude, lng: langitutde });
    const classes = useStyles()
    const dialogClasses = useDialogStyles()

    const MyMapMarker = (lat: any, lng: any) => {
        return <Box>
            <Tooltip title={locationInfo}>
                <Box>
                    <Icon name="MapMarker" />
                </Box>
            </Tooltip>
        </Box>
    }

    useEffect(() => {
        if (latitude && langitutde) {
            setCenter({ lat: latitude, lng: langitutde })
            getLocationInfo()
        }

    }, [latitude, langitutde])

    const getLocationInfo = () => {
        try {
            const lat = latitude.toString()
            const lang = langitutde.toString()
            Geocode.setApiKey(config.GOOGLE_MAP_API_KEY);
            Geocode.setLanguage("en");
            Geocode.fromLatLng(lat, lang).then(
                (response) => {
                    setLocationInfo(response.results[0].formatted_address);
                },
                (error) => {
                    console.error(error);
                }
            );
        } catch (err) {

        }
    }


    return (
        <Dialog
            scroll={'body'}
            open={open}
            onClose={onClose}
            aria-labelledby={title}
            aria-describedby={title}
            classes={dialogClasses}
        >
            <Box className={classes.modalHeader}>
                <Typography className={classes.title}>{title ? title : '---'}</Typography>

                <Box className={classes.closeIcon}>
                    <IconButton onClick={onClose}>
                        <Icon name="Close" color="#959598" />
                    </IconButton>
                </Box>
            </Box>
            <Box className={classes.body}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: config.GOOGLE_MAP_API_KEY }}
                    defaultCenter={center}
                    defaultZoom={15}
                >
                    <MyMapMarker
                        lat={latitude}
                        lng={langitutde}
                    />
                </GoogleMapReact>
            </Box>
        </Dialog>
    )
}

export default ComputerMapDialog
