import moment from 'moment'

const applyPolicy = (policy, item) => {
    if (policy.isTimeConstrained) {
        const currentDatetime = moment().utc().second(0)

        item.timeConstraintActive = true
        item.startDateUtc = currentDatetime
        if (policy.defaultValueInMinutes) {
            item.endDateUtc = currentDatetime
                .clone()
                .add(policy.defaultValueInMinutes, 'minutes')
        }
    } else {
        item.timeConstraintActive = false
        item.startDateUtc = null
        item.endDateUtc = null
    }
}

export const reducers = (state, { type, payload }) => {
    let itemIndex
    let newState
    let newItem
    switch (type) {
        case 'ADD_ITEM':
            newItem = {
                requestableResourceId: payload.item.id,
                resource_FriendlyName: payload.item.friendlyName,
                resourceTypeId: payload.item.resourceTypeId,
                resourceType_Name: payload.item.resourceTypeName,
                targetPersonId: payload.targetPersonId,
                resourceTypeRoleId: payload.item.resourceAssignmentId,
                resourceTypeRole_FriendlyName:
                    payload.item.resourceAssignment_FriendlyName,
                policy: payload.policy,
            }
            applyPolicy(payload.policy, newItem)

            return [...state, newItem]

        case 'REMOVE_ITEM':
            itemIndex = state.findIndex(
                (i) => i.requestableResourceId === payload.id,
            )
            newState = [...state]
            newState.splice(itemIndex, 1)

            return newState

        case 'ADD_ALL_ITEMS':
            const tempState = []

            for (let i = 0; i < payload.items.length; i++) {
                const currentItem = payload.items[i]
                const index = state.findIndex(
                    (item) => item.requestableResourceId === currentItem.id,
                )

                if (index === -1) {
                    newItem = {
                        requestableResourceId: currentItem.id,
                        resource_FriendlyName: currentItem.friendlyName,
                        resourceTypeId: currentItem.resourceTypeId,
                        resourceType_Name: currentItem.resourceTypeName,
                        targetPersonId: payload.targetPersonId,
                        resourceTypeRoleId: currentItem.resourceAssignmentId,
                        resourceTypeRole_FriendlyName:
                            currentItem.resourceAssignment_FriendlyName,
                        policy: currentItem.policy,
                    }
                    applyPolicy(currentItem.policy, newItem)

                    tempState.push(newItem)
                }
            }
            return [...state, ...tempState]

        case 'REMOVE_ALL_ITEMS':
            return state.filter(
                (s) => !payload.includes(s.requestableResourceId),
            )

        case 'SET_TIME_CONSTRAINT':
            itemIndex = state.findIndex(
                (i) => i.requestableResourceId === payload.item.id,
            )
            newItem = { ...state[itemIndex] }
            newState = [...state]
            newState.splice(itemIndex, 1)

            if (payload.timeConstraintActive) {
                const currentDatetime = moment().utc().second(0)

                newItem.timeConstraintActive = true
                newItem.startDateUtc = currentDatetime
                if (payload.policy.defaultValueInMinutes) {
                    newItem.endDateUtc = currentDatetime
                        .clone()
                        .add(payload.policy.defaultValueInMinutes, 'minutes')
                }
            } else {
                newItem.timeConstraintActive = false
                newItem.startDateUtc = null
                newItem.endDateUtc = null
            }

            return [...newState, newItem]


        case 'SET_START_DATE':
            itemIndex = state.findIndex(
                (i) => i.requestableResourceId === payload.id,
            )
            newItem = { ...state[itemIndex] }
            newState = [...state]
            newState.splice(itemIndex, 1)

            newItem.startDateUtc = payload.startDateUtc

            if (payload.policy &&
                payload.policy.isTimeConstrained &&
                payload.policy.defaultValueInMinutes) {

                newItem.endDateUtc = payload.startDateUtc
                    .clone()
                    .add(payload.policy.defaultValueInMinutes, 'minutes')
            }

            return [...newState, newItem]

        case 'SET_END_DATE':
            itemIndex = state.findIndex(
                (i) => i.requestableResourceId === payload.id,
            )
            newItem = { ...state[itemIndex] }
            newState = [...state]
            newState.splice(itemIndex, 1)

            newItem.endDateUtc = payload.endDateUtc

            return [...newState, newItem]

        default:
            return state
    }
}
