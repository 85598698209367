import { useQuery } from 'react-query'
import { useAxios } from 'packages/core'

const AZURE_LICENSE_KEY_PREFIX = 'AZURE_LICENSE'

export const useAzureLicense = (id) => {
    const callApi = useAxios()
    return useQuery(
        [AZURE_LICENSE_KEY_PREFIX, id],
        () =>
            callApi({
                method: 'GET',
                url: `api/azureLicenses/${id}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id),
        },
    )
}

export const useAzureLicenseServicePlans = (azureTenantSubscriptionId) => {
    const callApi = useAxios()

    return useQuery(
        [AZURE_LICENSE_KEY_PREFIX, azureTenantSubscriptionId],
        () =>
            callApi({
                method: 'GET',
                url: `/api/AzureLicenses/tenantSubscriptionServices/${azureTenantSubscriptionId}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(azureTenantSubscriptionId),
        },
    )
}

export const useCheckAzureLicenseAccess = (id, targetPersonId) => {
    const callApi = useAxios()
    return useQuery(
        [`${AZURE_LICENSE_KEY_PREFIX}_ACCESS`, id, targetPersonId],
        () =>
            callApi({
                method: 'GET',
                url: `/api/azureLicenses/checkAssigmentStatus/${id}/${targetPersonId}`,
            }).then((data) => data.data[0]),
        {
            enabled: Boolean(id) && Boolean(targetPersonId),
        },
    )
}

export const useAzureLicenseBr = (targetPersonId, resourceId, resourceTypeId) => {
    const callApi = useAxios()
    return useQuery(
        [
            AZURE_LICENSE_KEY_PREFIX,
            'Business_Request',
            targetPersonId,
            resourceId,
            resourceTypeId,
        ],
        () =>
            callApi({
                method: 'GET',
                url: `/api/azureLicenses/businessRequestItemAssignmentDetails?targetPersonId=${targetPersonId}&resourceId=${resourceId}&resourceTypeId=${resourceTypeId}`,
            }).then((data) => data.data),
        {
            enabled:
                Boolean(targetPersonId) &&
                Boolean(resourceId) &&
                Boolean(resourceTypeId),
        },
    )
}
