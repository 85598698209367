import React from 'react'
import { styled, Box, Divider } from '@material-ui/core'
import {
    SelectionBox,
    ShoppingForMyself,
    ShoppingForSomeoneElse,
    Button,
} from 'components'
import { Stepper } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'

const OptionContainer = styled('div')({
    width: '271px',
    height: '354px',
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
})

const ShoppingForMyselfOption = ({ style, onSelect }) => (
    <OptionContainer style={style}>
        <ShoppingForMyself onSelect={onSelect} />
    </OptionContainer>
)

const ShoppingForSomeoneElseOption = ({ style, onSelect }) => (
    <OptionContainer style={{ paddingTop: '53px', ...style }}>
        <ShoppingForSomeoneElse onSelect={onSelect} />
    </OptionContainer>
)

const OptionsDivider = styled((props) => (
    <Divider orientation="vertical" variant="middle" {...props} />
))({
    height: '58%',
    top: '32%',
    border: 'solid 0.5px #d2d2d9',
    opacity: '0.6',
    position: 'relative',
})

const LeftNavigationButton = styled('div')({
    position: 'absolute',
    top: '50%',
    msTransform: 'translate(50%, -50%)',
    transform: 'translate(50%, -50%)',
    left: 0,
})

const RightNavigationButton = styled('div')({
    position: 'absolute',
    top: '50%',
    msTransform: 'translate(-50%, -50%)',
    transform: 'translate(-50%, -50%)',
    right: 0,
})

const renderStepper = (stepperSteps, previousButton, nextButton) => (
    <div
        style={{
            display: 'flex',
            width: '300px',
            position: 'absolute',
            bottom: '0',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            msTransform: 'translate(-50%, -50%)',
        }}
    >
        <LeftNavigationButton>{previousButton}</LeftNavigationButton>
        <div style={{ margin: 'auto' }}>{stepperSteps}</div>
        <RightNavigationButton>{nextButton}</RightNavigationButton>
    </div>
)

const Mobile = ({ onSelect }) => {
    const { t } = useTranslation()
    return (
        <Box height="81vh" paddingTop="6vh">
            <Stepper steps={2}>
                {(
                    stepperSteps,
                    previousButton,
                    nextButton,
                    activeStep,
                    handleNext,
                ) => {
                    if (activeStep === 0) {
                        return (
                            <>
                                <ShoppingForMyselfOption onSelect={onSelect} />
                                <div
                                    style={{
                                        textAlign: 'center',
                                        paddingTop: '5vh',
                                    }}
                                >
                                    <Button
                                        rootStylesProp={{
                                            color: '#453fbb',
                                            backgroundColor: '#fff',
                                            border: '1px solid #453fbb',
                                            '&:hover': {
                                                color: '#fff',
                                                backgroundColor: '#453fbb',
                                            },
                                        }}
                                        onClick={handleNext}
                                    >
                                        Or {t('OnBoarding_ShopForSomeoneElse')}
                                    </Button>
                                </div>
                                {renderStepper(
                                    stepperSteps,
                                    previousButton,
                                    nextButton,
                                    handleNext,
                                )}
                            </>
                        )
                    } else {
                        return (
                            <>
                                <ShoppingForSomeoneElseOption
                                    onSelect={onSelect}
                                />
                                {renderStepper(
                                    stepperSteps,
                                    previousButton,
                                    nextButton,
                                )}
                            </>
                        )
                    }
                }}
            </Stepper>
        </Box>
    )
}

const Desktop = ({ onSelect }) => {
    return (
        <Box width="680px" display="flex">
            <ShoppingForMyselfOption onSelect={onSelect} style={{ flex: 1 }} />
            <div>
                <OptionsDivider />
            </div>
            <ShoppingForSomeoneElseOption
                onSelect={onSelect}
                style={{ flex: 1 }}
            />
        </Box>
    )
}

function ShoppingFor({ onSelect, onSkipClick }) {
    const { t } = useTranslation()

    return (
        <SelectionBox
            headingText={t('OnBoarding_YouAreShoppingFor')}
            footerText={t('OnBoarding_FooterTextMessage')}
            onSkipClick={onSkipClick}
        >
            {(isSmallDevice) =>
                isSmallDevice ? (
                    <Mobile onSelect={onSelect} />
                ) : (
                    <Desktop onSelect={onSelect} />
                )
            }
        </SelectionBox>
    )
}

export default ShoppingFor
