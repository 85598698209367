import React, { useEffect, useState } from 'react'
import ResourceSelection from './ResourceSelection'
import ShoppingFor from './ShoppingFor'
import TargetSystemSelection from './TargetSystemSelection'
import ApplicationProcessSelection from './ApplicationProcessSelection'
import ApplicationSubProcessSelection from './ApplicationSubProcessSelection'
import BusinessDomainSelection from './BusinessDomainSelection'
import BusinessSubDomainSelection from './BusinessSubDomainSelection'
import { Loader } from 'packages/eid-ui'
import useConfiguration from 'useConfiguration'
import { useGetControlsAccess } from 'packages/core'
import { useGuidedShopFilters, useTargetPerson, useCurrentPerson } from 'hooks'
import { useHistory } from 'react-router'
import appConfig from 'config'

const STEP = {
    TARGET_PERSON_SELECTION: 'TARGET_PERSON_SELECTION',
    TARGET_SYSTEM_SELECTION: 'TARGET_SYSTEM_SELECTION',
    RESOURCE_SELECTION: 'RESOURCE_SELECTION',
    PROCESS_SELECTION: 'PROCESS_SELECTION',
    SUB_PROCESS_SELECTION: 'SUB_PROCESS_SELECTION',
    DOMAIN_SELECTION: 'DOMAIN_SELECTION',
    SUB_DOMAIN_SELECTION: 'SUB_DOMAIN_SELECTION',
}

const useAccess = () => {
    const { resourceTypes } = useConfiguration()
    const controls = useGetControlsAccess()

    const canShopForOthers =
        controls.findIndex(
            (c) => c.name === 'ITShop-ShopForTargetPerson-Control',
        ) >= 0

    const hasAccessToTargetSystemControl =
        controls.findIndex((c) => c.name === 'ITShop-TargetSystem-Control') >= 0

    const hasAccessToApplicationProcesses =
        controls.findIndex(
            (c) => c.name === 'ITShop-ApplicationProcesses-Control',
        ) >= 0

    const hasAccessToBusinessDomains =
        controls.findIndex(
            (c) => c.name === 'ITShop-BusinessDomains-Control',
        ) >= 0

    return {
        canShopForOthers,
        hasAccessToAnyResource: resourceTypes.length > 0,
        resourceTypes,
        hasAccessToApplicationProcesses,
        hasAccessToBusinessDomains,
        hasAccessToTargetSystemControl,
    }
}

const useGuidedShop = () => {
    const {
        canShopForOthers,
        hasAccessToApplicationProcesses,
        hasAccessToBusinessDomains,
        hasAccessToTargetSystemControl,
    } = useAccess()
    const history = useHistory()

    const { data: currentPerson } = useCurrentPerson()
    const storageKey = `guidedShop.${currentPerson.id}:${window.location.origin}`

    const [_, setTargetPerson] = useTargetPerson()

    const [currentStep, setCurrentStep] = useState(null)

    useEffect(() => {
        if (canShopForOthers) {
            setCurrentStep(STEP.TARGET_PERSON_SELECTION)
        } else {
            setCurrentStep(STEP.RESOURCE_SELECTION)
        }
    }, [])

    const state = JSON.parse(sessionStorage.getItem(storageKey))

    const updateSessionStorage = (data) => {
        sessionStorage.setItem(storageKey, JSON.stringify(data))
    }

    const selectTargetPerson = (targetPerson) => {
        setTargetPerson(targetPerson)
        setCurrentStep(STEP.RESOURCE_SELECTION)
    }

    const handleSelectApplicationRoles = () => {
        if (hasAccessToTargetSystemControl) {
            setCurrentStep(STEP.TARGET_SYSTEM_SELECTION)
        } else {
            history.push(`${appConfig.APP_SUBPATH}/ApplicationRoles`)
        }
    }

    const handleSelectBusinessRoles = () => {
        if (hasAccessToBusinessDomains) {
            setCurrentStep(STEP.DOMAIN_SELECTION)
        } else {
            history.push(`${appConfig.APP_SUBPATH}/BusinessRoles`)
        }
    }

    const selectResource = (resource) => {
        updateSessionStorage({
            resource,
        })

        if (resource === 'BusinessRoles') {
            handleSelectBusinessRoles()
        } else if (resource === 'ApplicationRoles') {
            handleSelectApplicationRoles()
        } else history.push(`${appConfig.APP_SUBPATH}/${resource}`)
    }

    const selectTargetSystem = (accountStoreUsageType, accountStore) => {
        updateSessionStorage({
            resource: state.resource,
            accountStoreUsageType,
            accountStore,
        })
        if (hasAccessToApplicationProcesses) {
            setCurrentStep(STEP.PROCESS_SELECTION)
        } else {
            history.push(state.resource)
        }
    }

    const selectFirstLevel = (firstLevel) => {
        const obj = {
            resource: state.resource,
            firstLevel,
        }
        if (state.resource === 'ApplicationRoles') {
            obj.accountStoreUsageType = state.accountStoreUsageType
            obj.accountStore = state.accountStore
        }
        updateSessionStorage(obj)
        if (state.resource === 'ApplicationRoles') {
            setCurrentStep(STEP.SUB_PROCESS_SELECTION)
        } else {
            setCurrentStep(STEP.SUB_DOMAIN_SELECTION)
        }
    }

    const selectAll = () => {
        const obj = {
            resource: state.resource,
            firstLevel: state.firstLevel,
        }
        if (state.resource === 'ApplicationRoles') {
            obj.accountStoreUsageType = state.accountStoreUsageType
            obj.accountStore = state.accountStore
        }
        obj.secondLevel = null
        updateSessionStorage(obj)
        history.push(state.resource)
    }

    const selectSecondLevel = (secondLevel) => {
        const obj = {
            resource: state.resource,
            firstLevel: state.firstLevel,
            secondLevel,
        }
        if (state.resource === 'ApplicationRoles') {
            obj.accountStoreUsageType = state.accountStoreUsageType
            obj.accountStore = state.accountStore
        }
        updateSessionStorage(obj)
        history.push(state.resource)
    }

    return {
        currentStep,
        setCurrentStep,
        selectTargetPerson,
        selectResource,
        selectTargetSystem,
        selectFirstLevel,
        selectAll,
        selectSecondLevel,
        state,
    }
}

function GuidedShop({ history }) {
    const {
        canShopForOthers,
        hasAccessToAnyResource,
        resourceTypes,
    } = useAccess()

    const {
        currentStep,
        setCurrentStep,
        selectTargetPerson,
        selectResource,
        selectTargetSystem,
        selectFirstLevel,
        selectAll,
        selectSecondLevel,
        state,
    } = useGuidedShop()

    switch (currentStep) {
        case STEP.TARGET_PERSON_SELECTION:
            return (
                <ShoppingFor
                    onSelect={(person) => selectTargetPerson(person)}
                    onSkipClick={
                        hasAccessToAnyResource
                            ? () =>
                                  history.push(
                                      `${appConfig.APP_SUBPATH}${resourceTypes[0].route}`,
                                  )
                            : undefined
                    }
                />
            )

        case STEP.RESOURCE_SELECTION:
            return (
                <ResourceSelection
                    onBackClick={
                        canShopForOthers
                            ? () => setCurrentStep(STEP.TARGET_PERSON_SELECTION)
                            : undefined
                    }
                    onSelect={selectResource}
                    onSkipClick={
                        hasAccessToAnyResource
                            ? () =>
                                  history.push(
                                      `${appConfig.APP_SUBPATH}${resourceTypes[0].route}`,
                                  )
                            : undefined
                    }
                />
            )

        case STEP.TARGET_SYSTEM_SELECTION:
            return (
                <TargetSystemSelection
                    onBackClick={() => setCurrentStep(STEP.RESOURCE_SELECTION)}
                    onSelect={(accountStoreUsageType, accountStore) =>
                        selectTargetSystem(accountStoreUsageType, accountStore)
                    }
                    onSkipClick={
                        hasAccessToAnyResource
                            ? () => history.push(state.resource)
                            : undefined
                    }
                />
            )

        case STEP.PROCESS_SELECTION:
            return (
                <ApplicationProcessSelection
                    onBackClick={() =>
                        setCurrentStep(STEP.TARGET_SYSTEM_SELECTION)
                    }
                    onSelect={selectFirstLevel}
                    onSkipClick={
                        hasAccessToAnyResource
                            ? () => history.push(state.resource)
                            : undefined
                    }
                />
            )
        case STEP.SUB_PROCESS_SELECTION:
            return (
                <ApplicationSubProcessSelection
                    onBackClick={() => setCurrentStep(STEP.PROCESS_SELECTION)}
                    process={state.firstLevel}
                    onSelect={selectSecondLevel}
                    onSelectAll={selectAll}
                    onSkipClick={
                        hasAccessToAnyResource
                            ? () => history.push(state.resource)
                            : undefined
                    }
                />
            )
        case STEP.DOMAIN_SELECTION:
            return (
                <BusinessDomainSelection
                    onBackClick={() => setCurrentStep(STEP.RESOURCE_SELECTION)}
                    onSelect={selectFirstLevel}
                    onSkipClick={
                        hasAccessToAnyResource
                            ? () => history.push(state.resource)
                            : undefined
                    }
                />
            )
        case STEP.SUB_DOMAIN_SELECTION:
            return (
                <BusinessSubDomainSelection
                    onBackClick={() => setCurrentStep(STEP.DOMAIN_SELECTION)}
                    domain={state.firstLevel}
                    onSelect={selectSecondLevel}
                    onSelectAll={selectAll}
                    onSkipClick={
                        hasAccessToAnyResource
                            ? () => history.push(state.resource)
                            : undefined
                    }
                />
            )
        default:
            return <Loader />
    }
}

const ensureClearExisting = (ChildComponent) => (props) => {
    const { clearGuidedShopFilters } = useGuidedShopFilters()

    useEffect(() => {
        clearGuidedShopFilters()
    }, [])

    return <ChildComponent {...props} />
}

export default ensureClearExisting(GuidedShop)
