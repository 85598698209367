import { Fragment, useEffect, useState } from 'react'
import { Box, styled, Typography } from '@material-ui/core'
import { Loader } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'
import { useCart, useCheckMailboxAccess, useTargetPerson } from 'hooks'
import { Icon } from 'packages/eid-icons'
import {
    DifferentiationValueSelector as AccessLevelValueSelector,
    ItemDetails,
} from 'components'
import { useIsSmallScreen } from 'packages/core'
import cartHelpers from 'containers/Cart/cartHelpers'
import { AddToCart } from 'components/AddToCart'
import { ResourceTypesNamespace, useRegistry } from 'core'
import { OverviewSection } from './OverviewSection'
import { MoreInformationSection } from './MoreInformationSection'

const PromptContainer = styled('div')({
    padding: '48px 31px 20px 31px',
    minHeight: '232px',
    display: 'flex',
})

const PaddedDiv = styled(Box)({
    padding: '0 31px',
})

const MailboxDetails = ({ mailbox, toggleDrawer }) => {
    const { t } = useTranslation()
    const isSmallScreen = useIsSmallScreen()

    const [targetPerson] = useTargetPerson()

    const { data: cart } = useCart()

    const [activeTab, setActiveTab] = useState('overview')

    const {
        data: accessLevels,
        isLoading: isCheckingAccess,
    } = useCheckMailboxAccess(mailbox.id, targetPerson.id)

    const [accessLevel, setAccessLevelValue] = useState(null)

    useEffect(() => {
        if (accessLevel && accessLevels.length === 1) {
            setAccessLevelValue(accessLevels[0])
        }
    }, [accessLevels, setAccessLevelValue])

    const registry = useRegistry()

    const mailboxType = registry.get(ResourceTypesNamespace, 'Mailboxes')

    const getPreValidationMessage = () => {
        if (!Boolean(accessLevel)) {
            return t('Common_SelectAccessLevel')
        }
        if (accessLevel && accessLevel.isAssigned) {
            return t('ResourceAlreadyAssigned')
        }
        return ''
    }

    const preAdd = (baseObj) => {
        const itemToAdd = cartHelpers.mailboxToCartItem({
            targetPerson,
            assignmentType: 'Add',
            mailbox,
            assignmentStatus: accessLevel,
            ...baseObj,
        })

        if (!itemToAdd) return undefined

        return itemToAdd
    }

    if (!cart) return <Loader />

    const accessLevelValueSelector = (
        <AccessLevelValueSelector
            loading={isCheckingAccess}
            onChange={(val) => setAccessLevelValue(val)}
            data={accessLevels && accessLevels.filter((o) => !o.isAssigned)}
            value={accessLevel?.resourceAssignment.resourceAssignmentId}
            getOptionLabel={(o) => o.resourceAssignment.friendlyName}
            checkSelectedOption={(o, selectedValueId) =>
                o.resourceAssignment.resourceAssignmentId === selectedValueId
            }
            readOnlyOptions={
                accessLevels && accessLevels.filter((o) => o.isAssigned)
            }
        />
    )

    return (
        <Fragment>
            <ItemDetails.Tabs
                variant="standard"
                value={activeTab}
                onChange={(_, value) => setActiveTab(value)}
            >
                <ItemDetails.Tabs.Tab
                    value="overview"
                    label={t('Common_Overview')}
                />
                <ItemDetails.Tabs.Tab
                    value="additionalInformation"
                    label={t('Common_MoreInformation')}
                />
            </ItemDetails.Tabs>

            {activeTab === 'overview' && <OverviewSection mailbox={mailbox} />}

            {activeTab === 'additionalInformation' && (
                <MoreInformationSection mailbox={mailbox} />
            )}

            {!isSmallScreen && (
                <Fragment>
                    <PromptContainer>
                        <Box
                            width="172px"
                            display="flex"
                            flexDirection="column"
                            zIndex={1}
                        >
                            <Typography
                                style={{
                                    fontSize: '14px',
                                    lineHeight: 1.43,
                                    fontWeight: 600,
                                    textTransform: 'uppercase',
                                    wordBreak: 'break-word',
                                }}
                            >
                                {t('Common_SelectAccessLevel')}
                            </Typography>
                            <Box
                                marginTop="24px"
                                width="100%"
                                display="flex"
                                justifyContent="center"
                            >
                                <Icon
                                    name="AccessLevel"
                                    height="150px"
                                    width="150px"
                                    color="#D2D2D9"
                                />
                            </Box>
                        </Box>
                        <Box width="334px" paddingLeft="16px">
                            {accessLevelValueSelector}
                        </Box>
                    </PromptContainer>
                </Fragment>
            )}
            {isSmallScreen && (
                <PaddedDiv>
                    <Box
                        paddingY="24px"
                        display="flex"
                        flexDirection="column"
                        position="relative"
                    >
                        <Box position="absolute" top="16px" right="0">
                            <Icon
                                name="AccessLevel"
                                height="100px"
                                width="100px"
                                color="#D2D2D9"
                            />
                        </Box>
                        <Box width="172px" paddingY="4px" zIndex={1}>
                            <Typography
                                style={{
                                    fontSize: '14px',
                                    lineHeight: 1.43,
                                    fontWeight: 600,
                                    textTransform: 'uppercase',
                                    wordBreak: 'break-word',
                                }}
                            >
                                {t('Common_SelectAccessLevel')}
                            </Typography>
                        </Box>
                        <Box paddingY="16px">{accessLevelValueSelector}</Box>
                    </Box>
                </PaddedDiv>
            )}

            <AddToCart
                resourceType={mailboxType}
                resource={mailbox}
                secondary={accessLevel}
                preValidate={getPreValidationMessage}
                preAdd={preAdd}
                postAdd={toggleDrawer}
            />
        </Fragment>
    )
}

export default MailboxDetails
