import React, { useState } from 'react'
import { Grid } from '@material-ui/core'
import { WorkflowIFrame } from 'packages/eid-ui'
import { useHistory } from 'react-router'
import { useQuery } from 'packages/core'
import appConfig from 'config'
import { useAppState } from 'appContext'
import { PasswordModal } from 'components/PasswordModal'

const ComputerConnectWorkFlow: React.FC<any> = () => {
    const query = useQuery()
    const history = useHistory()
    const [{ masterPassword }]: any = useAppState()
    const [open, setOpen] = useState(masterPassword ? '' : 'enterPassword')
    const workflowName = query.get('workflowName')
    const TargetPersonGuid = query.get('targetperson')
    const AccessRequestPolicyID = query.get('accessrequestpolicy')
    const TargetComputerGuid = query.get('targetcomputer')
    const IsAdvanceMode = query.get('isadvancemode')
    const CheckOutStartDate = query.get('checkOutStartDate')
    const CheckOutEndDate = query.get('checkOutEndDate')
    const ExternalCredentialGuid = query.get('externalCredentialGuid')
    const wfParams: any = []
    if (TargetPersonGuid) {
        wfParams.push(`TargetPersonGuid=${TargetPersonGuid}`)
    }
    if (AccessRequestPolicyID) {
        wfParams.push(`AccessRequestPolicyID=${AccessRequestPolicyID}`)
    }
    if (TargetComputerGuid) {
        wfParams.push(`TargetComputerGuid=${TargetComputerGuid}`)
    }
    if (IsAdvanceMode) {
        wfParams.push(`IsAdvanceMode=${IsAdvanceMode}`)
    }
    if (masterPassword) {
        wfParams.push(`MasterPassword=${masterPassword}`)
    }
    if (CheckOutStartDate) {
        wfParams.push(`CheckOutStartDate=${CheckOutStartDate}`)
    }
    if (CheckOutEndDate) {
        wfParams.push(`CheckOutEndDate=${CheckOutEndDate}`)
    }
    if (ExternalCredentialGuid) {
        wfParams.push(`ExternalCredentialGuid=${ExternalCredentialGuid}`)
    }

    return (
        <Grid container style={{ justifyContent: 'start', gap: '24px' }}>
            {masterPassword ? (
                <>
                    {workflowName ? (
                        <WorkflowIFrame
                            baseEidUrl={appConfig.BASE_EID_URL}
                            workflowName={workflowName}
                            onComplete={() => {
                                history.push(
                                    `${appConfig.APP_SUBPATH}/computers/manageAccess?tab=loginsession`,
                                )
                            }}
                            workflowParams={wfParams}
                            height={'100vh'}
                        />
                    ) : (
                        <>
                            <div>No work flow found</div>
                        </>
                    )}
                </>
            ) : (
                <>
                    <PasswordModal
                        showDefaultContent={false}
                        open={open}
                        setOpen={setOpen}
                    />
                </>
            )}
        </Grid>
    )
}
export default ComputerConnectWorkFlow
