import { FC } from 'react'
import { Box, Typography } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Avatar } from 'packages/eid-ui'
import { getFullImageUrl } from 'utils'
import { Icon } from 'packages/eid-icons'

const useStyles = makeStyles((theme) =>
    createStyles({
        paddingdiv: {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            marginRight: '20px',
            marginBottom: '10px',
        },
        avtartdiv: {
            marginRight: '10px',
        },
        contanttext: {
            fontSize: '14px',
            color: '#5d6870',
        },
        infoDiv:{
            marginBottom: '5px'
        }
    }),
)

export const ApplicationUserCard: FC<any> = ({ data }) => {
    const classes = useStyles()
    return (
        <Box className={classes.paddingdiv}>
            <Box className={classes.avtartdiv}>
                {data.id === '00000000-0000-0000-0000-000000000000' ? (
                    <Icon name="DefaultAvatar" />
                ) : (
                    <Avatar
                        size={'small'}
                        src={getFullImageUrl(data.imageThumbUrl)}
                    />
                )}
            </Box>
            <Box className={classes.infoDiv}>
                {data.friendlyName && (
                    <Typography className={classes.contanttext}>
                        {data.friendlyName}
                    </Typography>
                )}
            </Box>
        </Box>
    )
}
