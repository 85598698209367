import { useState } from 'react'
import { Box, styled } from '@material-ui/core'
import { HorizontalTabs } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'
import { DropdownMenu } from 'components/DropdownMenu'
import { useHistory } from 'react-router'
import { Icon } from 'packages/eid-icons'
import useSubcomponents from 'useSubcomponents'
import appConfig from 'config'
import { SubPathLevel } from 'appConfiguration'

const SubHeaderTabs = styled(HorizontalTabs)(({ theme }) => ({
    '& .MuiTabs-flexContainer': {
        height: theme.mixins.navBar.minHeight,
    },
    '& .MuiTabs-indicator': {
        backgroundColor: '#307fc1',
        height: '4px',
    },
    '& .MuiTab-textColorInherit.Mui-selected': {
        fontWeight: 'bold',
    },
}))

const SubHeader = (props) => {
    const {
        showNavTabs = true,
        viewSwitcher,
        totalCount,
        resourceTypes,
        currentResourceType,
        viewPendingAccess,
        showWorkFlows = false,
        showBackButton = false,
    } = props

    const { t } = useTranslation()

    const { hasAccessToWorkflowsTab } = useSubcomponents()

    const history = useHistory()
    const {
        location: { pathname },
    } = history

    let subPaths = pathname.split('/')
    const areaName =
        subPaths.length >= 2 + SubPathLevel ? subPaths[2 + SubPathLevel] : ''

    const [activeTab, setActiveTab] = useState(
        areaName === 'manageAccess'
            ? 'manageAccess'
            : areaName === 'workflows'
            ? 'workFlows'
            : 'requestAccess',
    )

    const BackButton = () => {
        return (
            <Icon
                name="BackButton"
                style={{
                    cursor: 'pointer',
                }}
                onClick={() => {
                    history.goBack()
                }}
            />
        )
    }

    return (
        <Box
            padding="0 20px 0 20px"
            borderBottom="1px solid rgba(0, 0, 0, 0.1)"
            height="60px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            position="relative"
            width="100%"
        >
            <Box display="flex" position="absolute" left="0px" bottom="0px">
                <DropdownMenu
                    routeOptions={resourceTypes}
                    currentResourceType={currentResourceType}
                    totalCount={totalCount}
                />
            </Box>
            <Box display="flex" position="absolute" right="10px">
                {viewPendingAccess}
                {showBackButton ? <BackButton /> : viewSwitcher}
            </Box>

            {showNavTabs && (
                <SubHeaderTabs
                    variant="standard"
                    value={activeTab}
                    onChange={(_, value) => {
                        let route
                        if (value === 'manageAccess') {
                            if(currentResourceType.name === "Computers"){
                                route = `${appConfig.APP_SUBPATH}${currentResourceType.route}/manageAccess?tab=loginsession`
                            }else{
                                route = `${appConfig.APP_SUBPATH}${currentResourceType.route}/manageAccess`
                            }
                        } else if (value === 'workFlows') {
                            route = `${appConfig.APP_SUBPATH}${currentResourceType.route}/workflows`
                        } else {
                            route = `${appConfig.APP_SUBPATH}${currentResourceType.route}`
                        }
                        history.push(route)
                        setActiveTab(value)
                    }}
                >
                    <HorizontalTabs.Tab
                        key={1}
                        value="requestAccess"
                        label={t('Common_RequestAccess')}
                    />
                    <HorizontalTabs.Tab
                        key={2}
                        value="manageAccess"
                        label={t('Common_ManageAccess')}
                    />
                    {showWorkFlows && hasAccessToWorkflowsTab && (
                        <HorizontalTabs.Tab
                            key={3}
                            value="workFlows"
                            label={t('Workflows')}
                        />
                    )}
                </SubHeaderTabs>
            )}
        </Box>
    )
}

export default SubHeader
