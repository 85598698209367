import React from 'react'
import Fab from '@material-ui/core/Fab'
import { makeStyles } from '@material-ui/core'
const useStyles = makeStyles(() => ({
    root: (props) => ({
        textTransform: 'capitalize !important',
        backgroundColor: '#fff',
        color: '#307fc1',
        border: 'solid 1px #307fc1',
        boxShadow: 'none',
        overflow: 'hidden',
        ...props.style,
        width: props.style.width && `${props.style.width}!important`,
    }),
    label: { lineHeight: 1, wordBreak: 'break-all' },
}))

const ExtendedFab = ({ style, label, onClick, ...rest }) => {
    const classes = useStyles({ style })

    return (
        <Fab variant="extended" classes={classes} onClick={onClick} {...rest}>
            {label}
        </Fab>
    )
}

export default ExtendedFab
