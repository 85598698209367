import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { SelectionBox, ChipsContainer } from 'components'
import { SubProcessIcon } from 'packages/eid-icons'
import { useTranslation } from 'react-i18next'
import { useTargetPerson, useApplicationProcesses } from 'hooks'

function ApplicationSubProcessSelection({
    onSelect,
    onSelectAll,
    process,
    onBackClick,
    onSkipClick,
}) {
    const { t } = useTranslation()
    const [targetPerson] = useTargetPerson()

    const { data: applicationProcesses } = useApplicationProcesses(
        targetPerson.id,
    )

    return (
        <SelectionBox
            icon={<SubProcessIcon />}
            onBackClick={onBackClick}
            onSkipClick={onSkipClick}
            style={{
                contentStyles: { minHeight: '55vh', marginBottom: '40px' },
                hedaerStyles: { padding: '175px 10px 0px 10px' },
            }}
            headingText={
                <>
                    <Box component="span">
                        {t('OnBoarding_SubProcessSelection')}
                        <Typography
                            style={{
                                textDecoration: 'underline',
                                fontWeight: 500,
                                paddingLeft: '5px',
                            }}
                        >
                            {process.friendlyName}
                        </Typography>
                    </Box>
                </>
            }
            footerText={t('OnBoarding_FooterTextMessage')}
        >
            {() => (
                <>
                    <ChipsContainer
                        loading={!applicationProcesses}
                        data={
                            applicationProcesses
                                ? applicationProcesses.filter(
                                      (b) => b.parentId === process.id,
                                  )
                                : []
                        }
                        onSelect={onSelect}
                        selectAllButton
                        onSelectAll={onSelectAll}
                    />
                </>
            )}
        </SelectionBox>
    )
}

export default ApplicationSubProcessSelection
