import React, { useEffect, useState } from 'react'
import {
    Box,
    Button as MuiButton,
    makeStyles,
    Link,
    Typography,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useActivateNow } from '../ListingPage/useRequestAccessResources'
import { useTargetPerson } from 'hooks'
import { Spinner } from 'packages/eid-ui'
import { Modal } from 'components/Modal'
import { Icon } from 'packages/eid-icons'
import { ItemDetails } from 'components'
import { useAppState } from 'appContext'
import { useNotification } from 'packages/eid-ui'
import config from 'config'

const drawerButtonStyles = makeStyles(() => ({
    root: {
        minHeight: '31px',
        border: `solid 1px #01AE8F`,
        backgroundColor: '#01AE8F',
        boxShadow: '0 0 0 0',
        color: '#fff',
        textTransform: 'capitalize !important',
        borderRadius: '16px',
        lineHeight: '1.3',
        '&:hover': {
            backgroundColor: '#01AE8F',
        },
    },
}))

const ActivateNowButton = ({
    resourceType,
    resource,
    forDetailsDrawer = false,
    ...rest
}) => {
    const { t } = useTranslation()

    const [{ appConfig }] = useAppState()
    const { showWarningMessage } = useNotification()
    const [targetPerson] = useTargetPerson()

    const [showModal, setShowModal] = useState(false)

    const [activateResource, { data, isError, isLoading }] = useActivateNow()

    useEffect(() => {
        if (isError) {
            showWarningMessage(t('FailedToActivateNow'))
        } else if (data) {
            setShowModal(true)
        }
    }, [data, isError, isLoading])

    const classes = drawerButtonStyles()

    const handleActivateNow = () => {
        const dataObject = {
            resourceType: resourceType,
            resourceId: resource.id,
            targetPersonPersonId: targetPerson.id,
            resourceAssignmentId: resource?.resourceAssignmentId,
        }
        activateResource(dataObject)
    }

    const getManageAccessUrl = () => {
        if (resourceType.route === '/computers') {
            return `${config.APP_SUBPATH}${resourceType.route}/manageAccess?tab=membership&selected=${resource.id}`
        }
        return `${config.APP_SUBPATH}${resourceType.route}/manageAccess?selected=${resource.id}`
    }
    return (
        <>
            <Modal
                open={showModal}
                onClose={() => setShowModal(false)}
                title={
                    <>
                        <p>
                            <Icon
                                name="CheckedRound"
                                style={{ marginRight: '10px' }}
                            />
                            {resource.friendlyName} {t('ActivatedSoonMessage')}.
                        </p>
                        <p>
                            {t('CheckExistingAccess')}{' '}
                            <Link
                                underline="hover"
                                target="_blank"
                                href={getManageAccessUrl()}
                            >
                                {t('Here')}
                            </Link>
                            .
                        </p>
                    </>
                }
            />
            {forDetailsDrawer ? (
                <Box
                    display="flex"
                    padding="0 31px"
                    margin="16px 0px"
                    {...rest}
                >
                    <ItemDetails.ActionButton
                        fontColor="#ffffff"
                        bgColor={'#01AE8F'}
                        width="100%"
                        loading={isLoading}
                        onClick={handleActivateNow}
                    >
                        <Box display="flex" alignItems="center">
                            <Icon name="Check" color="#ffffff" />
                        </Box>
                        <Box
                            display="flex"
                            alignItems="center"
                            marginLeft="10px"
                        >
                            <Typography style={{ color: '#ffffff' }}>
                                {t('Activate')}
                            </Typography>
                        </Box>
                    </ItemDetails.ActionButton>
                </Box>
            ) : (
                <MuiButton
                    size="small"
                    classes={classes}
                    variant="outlined"
                    onClick={handleActivateNow}
                    {...rest}
                    endIcon={
                        <Icon
                            name="ArrowSmall"
                            fill="white"
                            color="white"
                            direction="right"
                        />
                    }
                >
                    {isLoading ? <Spinner color="#fff" /> : t('Activate')}
                </MuiButton>
            )}
        </>
    )
}
export default ActivateNowButton
