import { FC, Fragment } from 'react'
import { Box, Card, Paper } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Button } from '../ApplicationBanner/Button'
import { Attribute, AttributeLabel, AttributeValue } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'
import { Skeleton } from '@material-ui/lab'
import { getFullImageUrl } from 'utils'
import { useHandleSelectApplication } from 'hooks'
import { Instructions } from 'components/ApplicationBanner/Instructions'
import { isNilOrEmpty } from 'packages/core'
import { TextFormatter } from 'components'
import { Owners } from '../ApplicationBanner/Owners'
import { Icon } from 'packages/eid-icons'

export interface ApplicationCardProps {
    data?: any
    onSelectApplication?: any
    selectedApplicationId?: any
    loading?: boolean
}

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            position: 'relative',
            padding: '16px',
            width: '100%',
            borderRadius: '8px !important',
        },
        imgContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',

            position: 'relative',
            width: '100%',
            minHeight: '124px',
            marginBottom: theme.spacing(2),
            boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.08)',
            borderRadius: '8px',

            '& img': {
                height: '92px',
            },
        },

        actionArea: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
        },

        moreInfoButton: {
            position: 'relative',
            width: '100%',

            '& >button': {
                position: 'absolute',
                right: 0,
                minWidth: '90px',
                padding: '4px',

                backgroundColor: '#8b909a',
                color: theme.palette.common.white,

                border: 'solid 1px #8b909a',

                '&:hover': {
                    backgroundColor: '#8b909a',

                    '& span': {
                        //  color: theme.palette.common.white,
                    },
                },
            },
        },

        button: {
            width: '100%',
            minWidth: '280px',

            display: 'flex',
            justifyContent: 'center',

            padding: '20px',

            '& >button': {
                backgroundColor: 'transparent',
                color: '#01ae8f',
                width: '100%',
                border: 'solid 1px #01ae8f',

                '&:hover': {
                    backgroundColor: '#01ae8f',
                    color: theme.palette.common.white,

                    '& span': {
                        //  color: theme.palette.common.white,
                    },
                },
            },
        },

        borderBox: {
            marginBottom: '-16px',
            borderImageSource:
                'linear-gradient(to right, rgba(1, 174, 143, 0), #04ab93 7%, #2c83bd 91%, rgba(48, 127, 193, 0)) !important',
            borderImageSlice: `${1} !important`,
            borderBottom: '1px solid !important',
            opacity: '0.5',
        },

        imageSkeleton: {},
        attributeSkeletonContainer: {},
        attributeSkeleton: {},
    }),
)

const tooltipProps = {
    color: '#5D6870',
    fontColor: '#ffffff',
    enterDelay: 500,
    enterNextDelay: 500,
    interactive: true,
    tooltipStyles: {
        boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
        padding: '8px',
        maxWidth: '500px',
    },
}

export const ApplicationCard: FC<ApplicationCardProps> = (props) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const { data, selectedApplicationId, loading } = props

    const { selectApplication } = useHandleSelectApplication()

    if (loading)
        return (
            <Card className={classes.root}>
                <SkeletonLoader />
            </Card>
        )

    return (
        <Card className={classes.root}>
            <Paper className={classes.imgContainer}>
                <img
                    alt="generic logo"
                    src={
                        isNilOrEmpty(data.applicationLogoImageUrl)
                            ? getFullImageUrl(
                                  '/webcdn/Images/AppLogos/Genric-1.png',
                              )
                            : getFullImageUrl(data.applicationLogoImageUrl)
                    }
                />

                {data.instructions && (
                    <Instructions
                        title={data.friendlyName}
                        instructions={data.instructions}
                        button={(onClick: any) => (
                            <Box className={classes.moreInfoButton}>
                                <Button onClick={() => onClick(true)}>
                                    {t('Common_MoreInfo')}
                                </Button>
                            </Box>
                        )}
                    />
                )}
            </Paper>

            <Box>
                <Attribute>
                    <AttributeLabel> {t('Common_FriendlyName')}</AttributeLabel>
                    <AttributeValue>{data.friendlyName}</AttributeValue>
                </Attribute>

                <Attribute>
                    <AttributeLabel>{t('Common_TechnicalName')}</AttributeLabel>
                    <AttributeValue>{data.name}</AttributeValue>
                </Attribute>

                <Box>
                    <AttributeLabel>{t('Common_Owners')}</AttributeLabel>
                    <Owners applicationId={data && data.id} />
                </Box>

                <Attribute>
                    <AttributeLabel>{t('Common_Description')}</AttributeLabel>
                    <AttributeValue>
                        <TextFormatter
                            value={data.description}
                            tooltipProps={tooltipProps}
                            style={{ fontSize: '12px' }}
                            maxCharacters={50}
                        ></TextFormatter>
                    </AttributeValue>
                </Attribute>
            </Box>

            <Box className={classes.actionArea}>
                <Box className={classes.button}>
                    <Button
                        onClick={(event: any) => selectApplication(data)}
                        disabled={selectedApplicationId === data.id}
                        endIcon={
                            <Icon
                                name="ArrowSmall"
                                fill="#01ae8f"
                                direction="right"
                            />
                        }
                    >
                        {t('Common_SelectApplication')}
                    </Button>
                </Box>
            </Box>

            <Box className={classes.borderBox} />
        </Card>
    )
}

ApplicationCard.defaultProps = {}

const SkeletonLoader = () => {
    return (
        <Fragment>
            <Skeleton animation="wave" variant="rect" height={80} />
            <Box marginTop={2} padding={2} width="100%">
                <Skeleton variant="rect" animation="wave" height={8} />
                <Skeleton
                    variant="rect"
                    animation="wave"
                    height={8}
                    style={{ marginTop: 8 }}
                />
                <Skeleton
                    variant="rect"
                    animation="wave"
                    height={8}
                    style={{ marginTop: 8 }}
                />
                <Skeleton
                    variant="rect"
                    animation="wave"
                    style={{ height: 96, marginTop: 8 }}
                />
                <Skeleton
                    variant="rect"
                    animation="wave"
                    height={48}
                    style={{ marginTop: 16 }}
                />
            </Box>
        </Fragment>
    )
}
