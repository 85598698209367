import React from 'react'
import { CardHeader, Typography, Box } from '@material-ui/core'
import HeaderBgImage from './header-bg.png'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { useAppState } from 'appContext'
import { isNilOrEmpty } from 'packages/core'
import { Tooltip } from 'packages/eid-ui'

const useCardContentStyles = makeStyles({
    headingBG: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        zIndex: 0,
        right: 0,
        top: 0,
    },
    headingContentBox: (props) => ({
        padding: '20px',
        paddingLeft: props.mobile ? '20px' : '32px',
        position: 'relative',
        minHeight: '78px',
        width: '100%',
    }),
    heading: {
        width: '100%',
        height: '100%',
        zIndex: 1,
        overflow: 'hidden',
        maxWidth: '690px',
    },
})

const MyCardHeader = withStyles({
    root: {
        padding: 0,
        width: '100%',
        overflow: 'hidden',
    },
    content: {
        width: '100%',
    },
    title: {
        fontSize: '24px',
        fontWeight: '600',
    },
})(CardHeader)

const maxCharacters = 80

const tooltipStyles = {
    boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
    padding: '8px',
    maxWidth: '500px',
}

const ItemHeader = ({ title }) => {
    const [{ appConfig }] = useAppState()

    const contentClasses = useCardContentStyles()

    let titleText = isNilOrEmpty(title) ? '' : title
    titleText =
        titleText.length > maxCharacters
            ? `${titleText.substring(0, maxCharacters)}...`
            : titleText

    const titleJsx = (
        <Typography
            className={contentClasses.heading}
            style={{
                fontWeight: 'bold',
                fontSize: '24px',
                lineHeight: '38px',
                color: '#fff',
                display: 'block',
                wordBreak: 'break-word',
                wordWrap: 'break-word',
            }}
        >
            {titleText}
        </Typography>
    )

    let wrappedTitle

    if (titleText.length > maxCharacters) {
        wrappedTitle = (
            <Tooltip
                title={title}
                color="#5D6870"
                fontColor="#ffffff"
                enterDelay={500}
                enterNextDelay={500}
                interactive={true}
                tooltipStyles={tooltipStyles}
            >
                {titleJsx}
            </Tooltip>
        )
    } else {
        wrappedTitle = titleJsx
    }

    return (
        <MyCardHeader
            className={contentClasses.cardHeader}
            title={
                <Box
                    className={contentClasses.headingContentBox}
                    display="flex"
                    alignItems="center"
                    overflow="hidden"
                    style={{
                        backgroundColor: appConfig.style.colors.primary,
                    }}
                >
                    {wrappedTitle}
                    <img
                        className={contentClasses.headingBG}
                        src={HeaderBgImage}
                        alt="background"
                    />
                </Box>
            }
        />
    )
}

export default ItemHeader
