import React from 'react'
import { styled } from '@material-ui/core'
import Button from 'components/Button'
import MyselfSrc from './myself.png'
import { useTranslation } from 'react-i18next'
import { useCurrentPerson } from 'hooks'

const ShoppingForMyselfDiv = styled('div')({
    position: 'relative',
    width: '271px',
    '& > img': {
        width: '251px',
    },
    '& > button': {
        position: 'absolute',
        top: '234px',
        left: '88px',
    },
})

const ShoppingForMyself = ({ onSelect }) => {
    const { t } = useTranslation()
    const { data: currentPerson } = useCurrentPerson()

    return (
        <ShoppingForMyselfDiv>
            <img src={MyselfSrc} alt={t('OnBoarding_ShoppingForMySelf')} />
            <Button color="#453fbb" onClick={() => onSelect(currentPerson)}>
                {t('OnBoarding_ShoppingForMySelf')}
            </Button>
        </ShoppingForMyselfDiv>
    )
}

export default ShoppingForMyself
