import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, makeStyles } from '@material-ui/core'
import moment from 'moment'
import { useCurrentPerson, useAccessRequestPolicy, useComputerBookNow, useComputerCheckIn, refreshComputerList, useTargetPerson } from 'hooks'
import { EidButton } from 'packages/eid-ui'
import { isNilOrEmpty, useQuery } from 'packages/core'
import { ConnectAdvanced } from 'components/ConnectAdvanced'
import appConfig from 'config'
import { Icon } from 'packages/eid-icons'
import { useHistory } from 'react-router'
import { useNotification } from 'packages/eid-ui'

const useStyles = makeStyles(() => ({
    connectbtndiv: {
        display: 'flex',
        alignItems: 'center',
    },
    taskicon: {
        width: '24px',
        height: '24px',
        background: '#307fc1',
        border: 'solid 1px #307fc1',
        borderRadius: '5px',
        padding: '4px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: "center",
        marginLeft: '12px',

    },
    connectbtn: {
        maxWidth: '90px',
        width: '100%',
    },
    taskbadgesection: {
        position: 'relative',
        cursor: 'pointer'
    },
    taskbadge: {
        position: 'absolute',
        top: '-8px',
        right: '-6px',
        background: '#ff3c00',
        height: '15px',
        width: '15px',
        borderRadius: '50%',
        fontSize: '10px',
        fontWeight: '500',
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: "center",
        paddingTop: '1px',


    }

}))
const ComputerConnectBtn = ({ data, drawerView, parentStyle, showCalenderIcon = true, showPrefixIcon }) => {
    const itemData = data.resource ? data.resource : data
    const { t } = useTranslation()
    const [item, setItem] = useState(itemData)
    const { data: currentPerson } = useCurrentPerson()
    const [targetPerson] = useTargetPerson()
    const shoppingForSomeoneElse = targetPerson.id !== currentPerson.id
    const query = useQuery()
    const history = useHistory()
    const accessRequestPolicy = useAccessRequestPolicy(itemData.requestPolicyId)
    const { showSuccessMessage } = useNotification()
    const [showAdvancedCheckout, setShowAdvancedCheckout] = useState(false)

    const [startDateTime, setStartDateTime] = useState(
        moment(new Date()).utc().format(),
    )
    const [duration, setDuration] = useState('')
    const [credentialsType, setCredentialsType] = useState(
        'personalCredentials',
    )
    const [selectedCredentials, setSelectedCredentials] = useState({})

    useEffect(() => {
        if (itemData && itemData.canCheckOut !== item.canCheckOut) {
            setItem(itemData)
        }
        if(itemData && itemData.checkOutCount !== item.checkOutCount){
            setItem(itemData)
        }
    }, [itemData])

    const [
        checkInComputer,
    ] = useComputerCheckIn()

    useEffect(() => {
        if (accessRequestPolicy) {
            setDuration(accessRequestPolicy.defaultValueInMinutes)
        }
    }, [accessRequestPolicy])

    const violatesPolicy =
        accessRequestPolicy?.maximumValueInMinutes &&
        duration > accessRequestPolicy?.maximumValueInMinutes
    const hasInvalidDates =
        isNilOrEmpty(startDateTime) || duration < 1 || violatesPolicy

    let invalidDatesMessage = ''
    if (duration < 1) {
        invalidDatesMessage = t('InvalidDuration')
    }
    if (isNilOrEmpty(startDateTime)) {
        invalidDatesMessage = t('InvalidStartDateTime')
    }
    if (violatesPolicy) {
        invalidDatesMessage = t('Common_PolicyAllowsMaxDurationInMinError', {
            count: accessRequestPolicy?.maximumValueInMinutes,
        })
    }


    const [
        bookComputer,
        { data: bookComputerData, isError, isLoading },
    ] = useComputerBookNow()

    useEffect(() => {
        if (bookComputerData && !isError) {
            showSuccessMessage(t('Common_ComputerBookedSccessfully'))
            setShowAdvancedCheckout(false)
        }
    }, [bookComputerData, isError])

    const updateDataObjectOnCHange = () => {
        try {
            let tItem = {
                ...item,
                canCheckOut: !item.canCheckOut
            }

            setItem(tItem)
        } catch (err) {

        }
    }

    const handleConnectBtnClick = () => {
        if(item.canCheckOut){
            handleCheckOut()
        }
    }

    const handleCheckOut = () => {
        setShowAdvancedCheckout(false)
        updateDataObjectOnCHange()
        const wfParams = []
        wfParams.push(`TargetPersonGuid=${currentPerson.id}`)
        wfParams.push(`AccessRequestPolicyID=${item.requestPolicyId}`)
        wfParams.push(`TargetComputerGuid=${item.id}`)
        wfParams.push(`IsAdvanceMode=${false}`)
        const fullWorkflowUrl = constructApprovalWorkflowUrl(
            appConfig.BASE_EID_URL,
            'OpenPSMSessionWF',
            wfParams,
        )

        window.open(fullWorkflowUrl, '_blank', 'noopener,noreferrer')
        query.delete('selected')
        history.push(
            `${history.location.pathname}?${query.toString()}`,
        )
        setTimeout(() => {
            refreshComputerList()
        }, 20000);
    }

    const handleAdvancedCheckOut = (type) => {
        if (type === "Connect") {
            updateDataObjectOnCHange()
            setShowAdvancedCheckout(false)
            const wfParams = []
            wfParams.push(`TargetPersonGuid=${currentPerson.id}`)
            wfParams.push(`AccessRequestPolicyID=${item.requestPolicyId}`)
            wfParams.push(`TargetComputerGuid=${item.id}`)
            wfParams.push(`IsAdvanceMode=${true}`)
            wfParams.push(`CheckOutStartDate=${startDateTime}`)
            wfParams.push(`CheckOutEndDate=${moment(startDateTime).clone().add(duration, 'm').utc().format()}`)
            if (selectedCredentials?.id)
                wfParams.push(`ExternalCredentialGuid=${selectedCredentials?.id}`)
            const fullWorkflowUrl = constructApprovalWorkflowUrl(
                appConfig.BASE_EID_URL,
                'OpenPSMSessionWF',
                wfParams,
            )
            window.open(fullWorkflowUrl, '_blank', 'noopener,noreferrer')
            query.delete('selected')
            history.push(
                `${history.location.pathname}?${query.toString()}`,
            )

            setTimeout(() => {
                refreshComputerList()
            }, 20000);

        } else {
            const dataObject = {
                "computerId": item.id,
                "targetPersonId": currentPerson.id,
                "credentialId": selectedCredentials?.id ? selectedCredentials.id : undefined,
                "checkOutStartDate": startDateTime,
                "checkOutEndDate": moment(startDateTime).clone().add(duration, 'm').utc().format()
            }
            bookComputer(dataObject)
        }
    }

    const handleReconnect = () => {
        try {
            const wfParams = []
            wfParams.push(`TargetPersonGuid=${currentPerson.id}`)
            wfParams.push(`AccessRequestPolicyID=${item.requestPolicyId}`)
            wfParams.push(`TargetComputerGuid=${item.id}`)
            wfParams.push(`IsAdvanceMode=${true}`)
            if (item?.checkedOutCredentialId)
                wfParams.push(`ExternalCredentialGuid=${item?.checkedOutCredentialId}`)
            const fullWorkflowUrl = constructApprovalWorkflowUrl(
                appConfig.BASE_EID_URL,
                'OpenPSMSessionWF',
                wfParams,
            )
            window.open(fullWorkflowUrl, '_blank', 'noopener,noreferrer')
        } catch (err) {

        }
    }

    const checkOutOptionsDisConnected = [
        {
            onClick: handleCheckOut,
            label: t('Now'),
        },
        {
            onClick: () => {
                setStartDateTime(moment(new Date()).utc().format())
                setShowAdvancedCheckout(true)
            },
            label: t('AdvancedMode'),
        },
    ]

    const checkOutOptionsConnected = [
        {
            onClick: handleReconnect,
            label: t('Common_Reconnect'),
        },
        {
            onClick: () => {
                updateDataObjectOnCHange()
                checkInComputer(item.checkOutId)
            },
            label: t('Common_Disconnect'),
        },
    ]

    const handleChangeStartDate = (startDate) => {
        setStartDateTime(startDate)
    }
    const classes = useStyles()
    const handleChangeDuration = (duration) => {
        setDuration(duration)
    }
    const connectedStyles = item.canCheckOut ? {} : {
        backgroundColor: '#aab0b4',
        border: 'solid 1px #aab0b4',
        color: '#fff !important',
        '&:hover': {
            backgroundColor: '#aab0b4',
        },
    }

    const checkIfSomeOneElseSession = () => {
        if(shoppingForSomeoneElse && !item.canCheckOut){
            return true
        }
        return false
    }


    return (
        <>
            <Box className={classes.connectbtndiv}>
                {!drawerView ? (
                    <Box style={{ height: '24px' }} className={classes.connectbtn}>
                        <EidButton.CheckOutListing
                            loading={false}
                            label={(item.canCheckOut === undefined || item.canCheckOut) ? t('Connect') : t('Connected')}
                            onButtonClick={handleConnectBtnClick}
                            checkoutOptions={item.canCheckOut ? checkOutOptionsDisConnected : checkOutOptionsConnected}
                            parentStyle={{ ...parentStyle, ...connectedStyles }}
                            showPrefixIcon={showPrefixIcon}
                            // UnComment when logic for right access is clear
                            // showEndIcon ={(!item.isPreApproved && item.canCheckOut) || checkIfSomeOneElseSession() ? false :  true}
                            showEndIcon ={!item.isPreApproved && item.canCheckOut? false :  true}
                        />
                    </Box>
                ) : (
                    <Box minWidth="170px" height="48px">
                        <EidButton.CheckOutDetailButton
                            loading={false}
                            label={(item.canCheckOut === undefined || item.canCheckOut) ? t('Connect') : t('Connected')}
                            onButtonClick={handleConnectBtnClick}
                            checkoutOptions={item.canCheckOut ? checkOutOptionsDisConnected : checkOutOptionsConnected}
                            parentStyle={{ ...parentStyle, ...connectedStyles }}
                            // UnComment when logic for right access is clear
                            // showEndIcon ={(!item.isPreApproved && item.canCheckOut) || checkIfSomeOneElseSession()? false :  true}
                            showEndIcon ={!item.isPreApproved && item.canCheckOut? false :  true}
                        />
                    </Box>
                )}
                {(item?.checkOutCount > 0 && showCalenderIcon) &&
                    <>
                        <Box onClick={() => {
                            query.set(
                                'selected',
                                item.id,
                            )
                            query.set('showSession', true)
                            history.push(
                                `${history.location
                                    .pathname
                                }?${query.toString()}`,
                            )
                        }} className={classes.taskbadgesection}>
                            <Box className={classes.taskicon}>
                                <Icon name="ActiveCalender" />
                            </Box>
                            <span className={classes.taskbadge}>
                                {item.checkOutCount}
                            </span>
                        </Box>
                    </>
                }

            </Box>
            {showAdvancedCheckout && <ConnectAdvanced
                onSucces={(type) => handleAdvancedCheckOut(type)}
                loading={false}
                open={showAdvancedCheckout}
                onClose={() => setShowAdvancedCheckout(false)}
                requestPolicy={accessRequestPolicy}
                selectedStartDate={startDateTime}
                setSelectedStartDate={handleChangeStartDate}
                selectedDuration={duration}
                setSelectedDuration={handleChangeDuration}
                hasInvalidDates={hasInvalidDates}
                invalidDatesMessage={invalidDatesMessage}
                setCredentialsType={(e) => setCredentialsType(e)}
                credentialsType={credentialsType}
                item={item}
                selectedCredentials={selectedCredentials}
                setSelectedCredentials={(e) => setSelectedCredentials(e)}
            />}
        </>
    )
}

ComputerConnectBtn.defaultProps = {
    drawerView: false,
}

export default ComputerConnectBtn


const constructApprovalWorkflowUrl = (
    baseEidUrl,
    workflowName,
    workflowParams,
) => {
    let queryParams = ['popupNav=true']

    if (workflowParams) {
        queryParams = queryParams.concat(workflowParams)
    }

    const queryString = queryParams.join('&')
    return `${baseEidUrl}/ui?hideHeader=true&wfMsContext=true&wfMsUrl=${window.location.origin}#w/${workflowName}?${queryString}`
}
