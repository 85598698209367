import { Fragment, useState } from 'react'
import { Box } from '@material-ui/core'
import { Loader } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'
import { useCart, useAccessRequestPolicy, useTargetPerson } from 'hooks'
import {
    DifferentiationValueSelector as AccessTypeValueSelector,
    ItemDetails,
} from 'components'
import cartHelpers from 'containers/Cart/cartHelpers'
import { AddToCart } from './AddToCart'
import { ResourceTypesNamespace, useRegistry } from 'core'
import { OverviewSection } from './OverviewSection'
import { MoreInformationSection } from './MoreInformationSection'
import { PasswordModal } from 'components/PasswordModal'
import { useAppState } from 'appContext'
import { SelectorSection } from '../Computers/SelectorSection'
import { useTimeConstrainedControl } from '../Computers/TimeConstrainedControl'

const ONE_TIME_ACCESS = 'One Time Access'
const PRE_APPROVED_ACCESS = 'Pre-Approved Access'
const allAccessTypes = [
    {
        id: 1,
        friendlyName: ONE_TIME_ACCESS,
        fullPath: 'Allows you request One Time',
    },
    {
        id: 2,
        friendlyName: PRE_APPROVED_ACCESS,
        fullPath: 'Allows you to request PreApproved',
    },
]

const CredentialDetails = ({ credential, toggleDrawer }) => {
    const { t } = useTranslation()

    const [targetPerson] = useTargetPerson()

    const { data: cart } = useCart()

    const [activeTab, setActiveTab] = useState('overview')
    const [open, setOpen] = useState('')
    const [{ masterPassword }, dispatch] = useAppState()
    // const [accessTypes, setAccessTypes] = useState(allAccessTypes)
    const [accessType, setAccessType] = useState(allAccessTypes[0])

    // const {
    //     data: accessTypes,
    //     isLoading: isCheckingAccess,
    // } = useCheckCredentialAccess(credential.id, targetPerson.id)

    const requestPolicy = useAccessRequestPolicy(credential.requestPolicyId)

    const {
        timeConstrained,
        selectedStartDate,
        selectedEndDate,
        hasInvalidDates,
        timeConstrainedControl,
    } = useTimeConstrainedControl(requestPolicy, true)

    // useEffect(() => {
    //     if (accessType && accessTypes.length === 1) {
    //         setAccessTypeValue(accessTypes[0])
    //     }
    // }, [accessTypes, setAccessTypeValue])

    const registry = useRegistry()

    const credentialType = registry.get(ResourceTypesNamespace, 'Credentials')

    const getPreValidationMessage = () => {
        if (!Boolean(accessType)) {
            return t('Common_SelectAccessType')
        }

        if (accessType?.friendlyName === ONE_TIME_ACCESS && hasInvalidDates)
            return t('Common_InvalidDates')

        if (accessType && accessType.isAssigned) {
            return t('ResourceAlreadyAssigned')
        }
        return ''
    }

    const preAdd = (baseObj) => {
        const itemToAdd = cartHelpers.sharedCredentialToCartItem({
            targetPerson,
            assignmentType: 'Add',
            credential,
            // assignmentStatus: accessType,
            timeConstrained: accessType?.friendlyName === ONE_TIME_ACCESS,
            startDateUtc:
                accessType?.friendlyName === ONE_TIME_ACCESS
                    ? selectedStartDate
                    : null,
            endDateUtc:
                accessType?.friendlyName === ONE_TIME_ACCESS
                    ? selectedEndDate
                    : null,
            ...baseObj,
        })

        if (!itemToAdd) return undefined

        return itemToAdd
    }

    const accessTypeSelectorSection = (
        <SelectorSection
            sectionLabel={t('Common_SelectAccessType')}
            iconName="TypeOfAccess"
        >
            <AccessTypeValueSelector
                onChange={(val) => setAccessType(val)}
                data={allAccessTypes}
                value={accessType?.id}
                getOptionLabel={(o) => o.friendlyName}
                checkSelectedOption={(o, selectedValueId) =>
                    o.id === selectedValueId
                }
            />
        </SelectorSection>
    )

    if (!cart) return <Loader />

    return (
        <Fragment>
            <ItemDetails.Tabs
                variant="standard"
                value={activeTab}
                onChange={(_, value) => setActiveTab(value)}
            >
                <ItemDetails.Tabs.Tab
                    value="overview"
                    label={t('Common_Overview')}
                />
                <ItemDetails.Tabs.Tab
                    value="additionalInformation"
                    label={t('Common_MoreInformation')}
                />
            </ItemDetails.Tabs>

            {activeTab === 'overview' && (
                <OverviewSection credential={credential} />
            )}
            {activeTab === 'additionalInformation' && (
                <MoreInformationSection credential={credential} />
            )}

            {accessTypeSelectorSection}

            {!masterPassword ? (
                <PasswordModal open={open} setOpen={setOpen} />
            ) : (
                <Box display="flex" flexDirection="row" flexWrap={'wrap'}>
                    <AddToCart
                        resource={credential}
                        resourceType={credentialType}
                        secondary={accessType}
                        preValidate={getPreValidationMessage}
                        preAdd={preAdd}
                        postAdd={toggleDrawer}
                    />
                    {accessType.friendlyName === ONE_TIME_ACCESS &&
                        timeConstrainedControl}
                </Box>
            )}
        </Fragment>
    )
}

export default CredentialDetails
