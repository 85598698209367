import React from 'react'
import { Layout } from 'components'
import { Box, Grid, Paper, styled } from '@material-ui/core'
import SubHeader from 'components/SubHeader'
import { WorkflowIFrame } from 'packages/eid-ui'
import { useHistory, useParams } from 'react-router'
import { useIsSmallScreen, useQuery } from 'packages/core'
import useConfiguration from 'useConfiguration'
import { useWorkflows } from 'hooks/workflowsHooks'
import { Skeleton } from '@material-ui/lab'
import WorkFlowsItems from './WorkflowItems.tsx'
import './workFlow.css'
import { bindResourceTypeNounAndVerbQueryParams } from 'utils'
import useRequestAccessResources from 'containers/ListingPage/useRequestAccessResources'
import config from 'config'

const WorkFlows: React.FC<any> = () => {
    const SubHeaderContainer = styled(Box)({
        position: 'fixed',
        top: 70,
        width: '100%',
        maxWidth: '1920px',
        display: 'flex',
        paddingTop: '10px',
        paddingRight: '20px',
        paddingLeft: '90px',
        backgroundColor: '#eef0f4',
        zIndex: 5,
    })

    const isSmallScreen = useIsSmallScreen()
    const query = useQuery()

    const history = useHistory()
    //TODO: use resource type to detect the type and bind current resource type
    const { resourceType }: any = useParams()

    const { resourceTypes } = useConfiguration()

    const {
        location: { pathname },
    } = history

    const resourceTypeSelected: any = resourceTypes.filter(
        (x) => x.name.toLowerCase() === resourceType.toLowerCase(),
    )

    const currentResourceType =
        resourceTypeSelected && resourceTypeSelected.length > 0
            ? resourceTypeSelected[0]
            : {}
    const { data, isLoading } = useWorkflows(
        resourceType,
        bindResourceTypeNounAndVerbQueryParams(resourceType),
    )
    const totalCount = data ? data.length : 0

    const workflowName = query.get('workflowName')
    const resourceID = query.get('resourceID')
    const wfParams: any = []
    if (resourceID) {
        wfParams.push(`SelectedResourceID=${resourceID}`)
    }

    return (
        <Layout
            subHeader={
                !isSmallScreen && (
                    <SubHeaderContainer>
                        <SubHeader
                            totalCount={totalCount}
                            resourceTypes={resourceTypes}
                            showNavTabs={true}
                            showWorkFlows={true}
                            currentResourceType={currentResourceType}
                            showBackButton={workflowName}
                        />
                    </SubHeaderContainer>
                )
            }
        >
            <Paper className={'root workflow'}>
                <Grid
                    container
                    style={{ justifyContent: 'start', gap: '24px' }}
                >
                    {workflowName ? (
                        <WorkflowIFrame
                            baseEidUrl={config.BASE_EID_URL}
                            workflowName={workflowName}
                            onComplete={() => {
                                query.delete('workflowName')
                                history.push(
                                    history.location.pathname,
                                    query.toString(),
                                )
                            }}
                            workflowParams={wfParams}
                            height={'60vh'}
                        />
                    ) : (
                        <>
                            {isLoading && !data ? (
                                <Box width={'100%'}>
                                    <Skeleton height={100} />
                                </Box>
                            ) : data?.length > 0 ? (
                                data.map((item: any, index: number) => (
                                    <WorkFlowsItems
                                        key={`workflow-item${index}`}
                                        title={item.friendlyName}
                                        description={item.description}
                                        isEnabled={item.requestWorkflowName}
                                        onClick={() => {
                                            item.requestWorkflowName
                                                ? history.push(
                                                      `${history.location.pathname}?workflowName=${item.requestWorkflowName}`,
                                                  )
                                                : history.push(
                                                      `${history.location.pathname}`,
                                                  )
                                        }}
                                    />
                                ))
                            ) : (
                                //TODO: Need to update with locale
                                <div>No work flow found</div>
                            )}
                        </>
                    )}
                </Grid>
            </Paper>
        </Layout>
    )
}
export default WorkFlows
