import { Drawer, Loader } from 'packages/eid-ui'
import ItemHeader from '../ItemHeader'
import { isNilOrEmpty, useIsSmallScreen } from 'packages/core'
import ItemsDetailsMobileWrapper from 'components/ItemsDetailsMobileWrapper'
import ComputerDetails from './ComputerDetails'
import { useComputer } from 'hooks'
import { useTargetPerson } from 'hooks'

const ComputerDrawer = ({ id, toggleDrawer, attributes }) => {
    const isSmallScreen = useIsSmallScreen()
    const [targetPerson] = useTargetPerson()

    const { data: computer } = useComputer(id, targetPerson.id)

    return isSmallScreen ? (
        <ItemsDetailsMobileWrapper toggleDrawer={toggleDrawer}>
            <ItemHeader title={computer?.friendlyName} />
            {!computer && <Loader />}
            {computer && (
                <ComputerDetails
                    computer={computer}
                    toggleDrawer={toggleDrawer}
                    attributes={attributes}
                />
            )}
        </ItemsDetailsMobileWrapper>
    ) : (
        <Drawer
            width="768px"
            open={!isNilOrEmpty(id)}
            toggleDrawer={toggleDrawer}
            stickyHeader
            closeOnOutsideClick
            header={<ItemHeader title={computer?.friendlyName} />}
        >
            {!computer && <Loader />}
            {computer && (
                <ComputerDetails
                    computer={computer}
                    toggleDrawer={toggleDrawer}
                />
            )}
        </Drawer>
    )
}

export default ComputerDrawer
