import { withRouter } from 'react-router'
import { EidLogoIcon, Icon, ShoppingCartIcon } from 'packages/eid-icons'
import { Header, Tooltip } from 'packages/eid-ui'
import {
    Badge,
    Box,
    Divider,
    Link,
    makeStyles,
    styled,
    Typography,
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { useQuery } from 'packages/core'
import { useAllCarts, useCart, useEnvironment } from 'hooks'
import { Cart } from 'components'
import LanguageMenu from './LanguageMenu'
import HelpMenu from './HelpMenu'
import AccountDropdown from './AccountDropdown'
import Search from './Search'
import CartDropdown from '../CartDropdown'
import useSubcomponents from 'useSubcomponents'
import appConfig from 'config'

const GroupedButtonContainer = styled(Box)({
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#307fc1',

    height: '34px',

    border: '1px solid transparent',
    borderRadius: '5px',

    '& >div': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '&:hover': {
            cursor: 'pointer',
            opacity: 0.7,
        },
    },

    '& >div:first-child': {
        borderRight: '1px solid  #b4b4b4',
    },
})

const StyledChip = styled('div')({
    position: 'absolute',
    top: '0px',
    left: '0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ff6100 !important',
    color: '#ffffff',
    height: '13px',
    maxWidth: '45px',
    minWidth: '24px',
    transform: 'skew(-20deg)',
    borderRadius: '2px',
    '@media (max-width:374px)': {
        display: 'none',
    },
})

const itemStyles = {
    minWidth: '30px',
    padding: '0px 8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}

const StyledBadge = styled(Badge)({
    '& .MuiBadge-badge': {
        backgroundColor: 'rgb(255, 60, 0)',
        color: '#ffffff',
        height: '16px',
        minWidth: '12px',
        fontSize: '10px',
    },
})

const useStyles = makeStyles({
    multipleCartIndicator: {
        position: 'absolute',
        top: '-4px',
        right: 0,
        height: '8px',
        width: '8px',
        borderRadius: '50%',
        backgroundColor: '#ff3c00',
        zIndex: 3,
    },
})

const ITShopMobileHeader = ({ history, config }) => {
    const query = useQuery()
    const classes = useStyles()

    const cartOpen = query.get('cart') === 'visible'

    const { data: cart } = useCart()

    const { data: environmentName } = useEnvironment()

    const { hasMultipleCarts } = useAllCarts()

    const { canSeeSimpleTextSearch } = useSubcomponents()

    return (
        <>
            <Header.Mobile bgColor={config.bgColor}>
                <div
                    style={{
                        width: '100%',

                        display: 'flex',
                    }}
                >
                    <Link
                        href={`${appConfig.APP_SUBPATH}/`}
                        underline="none"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <Header.Item style={{ ...itemStyles, padding: 0 }}>
                            {config.appLogo.type === 'base64' ? (
                                <img
                                    style={config.appLogo.style}
                                    src={config.appLogo.value}
                                    alt={config.appLogo.name}
                                />
                            ) : config.showEidLogo ? (
                                <EidLogoIcon />
                            ) : (
                                <Icon name={config.appLogo.value} />
                            )}
                        </Header.Item>
                    </Link>
                    {environmentName && (
                        <Box position="relative" width="32px">
                            <Tooltip title={environmentName}>
                                <StyledChip>
                                    <Typography
                                        noWrap
                                        style={{
                                            fontSize: '8px',
                                            padding: '0px 1.5px',
                                        }}
                                    >
                                        {environmentName}
                                    </Typography>
                                </StyledChip>
                            </Tooltip>
                        </Box>
                    )}

                    <Header.RightSection>
                        <Header.Item style={itemStyles}>
                            <LanguageMenu
                                showCone={false}
                                anchorPosition={{
                                    right: '-32px',
                                    top: '54px',
                                }}
                            />
                        </Header.Item>

                        <Header.Item style={itemStyles}>
                            <HelpMenu showCone={false} />
                        </Header.Item>

                        <Header.Item style={itemStyles}>
                            <AccountDropdown />
                        </Header.Item>

                        <Header.Item style={itemStyles}>
                            <Divider
                                orientation="vertical"
                                variant="fullWidth"
                                style={{
                                    backgroundColor: '#e8e8e8',
                                    height: 26,
                                }}
                            />
                        </Header.Item>
                        {!cart ? (
                            <Skeleton width="80px" height="34px" />
                        ) : (
                            <GroupedButtonContainer>
                                <Box
                                    width="45px"
                                    onClick={() => {
                                        query.delete('selected')
                                        query.set('cart', 'visible')
                                        history.push(
                                            `${
                                                history.location.pathname
                                            }?${query.toString()}`,
                                        )
                                    }}
                                >
                                    <StyledBadge
                                        badgeContent={cart.cartItems.length}
                                    >
                                        <ShoppingCartIcon
                                            color="#307FC1"
                                            height="27px"
                                            width="27px"
                                        />
                                    </StyledBadge>
                                </Box>
                                <CartDropdown />
                                {hasMultipleCarts && (
                                    <Box
                                        className={
                                            classes.multipleCartIndicator
                                        }
                                    />
                                )}
                            </GroupedButtonContainer>
                        )}
                    </Header.RightSection>
                </div>

                {config.search.show && canSeeSimpleTextSearch && (
                    <div
                        style={{
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                        }}
                    >
                        <Search styleData={config.search.style} />
                    </div>
                )}
            </Header.Mobile>
            <Cart
                open={cartOpen}
                toggleDrawer={() => {
                    query.delete('cart')
                    history.push(
                        `${history.location.pathname}?${query.toString()}`,
                    )
                }}
            />
        </>
    )
}
export default withRouter(ITShopMobileHeader)
