import { FC } from 'react'
import {
    Button as MuiButton,
    createStyles,
    makeStyles,
} from '@material-ui/core'
import { Loader } from 'packages/eid-ui'

const useStyles = makeStyles(
    createStyles({
        root: {
            minWidth: '177px',
            height: '31px',

            border: `solid 1px #307fc1`,
            borderRadius: '16px',

            backgroundColor: '#ffffff',
            color: '#307fc1',

            boxShadow: '0 0 0 0',
            textTransform: 'capitalize',
        },
    }),
)

export interface ButtonProps {
    onClick: any
    isLoading?: boolean
    disabled?: boolean
    endIcon? : any
}

export const Button: FC<ButtonProps> = (props) => {
    const classes = useStyles()

    return (
        <MuiButton
            size="small"
            classes={classes}
            variant="outlined"
            onClick={props.onClick}
            disabled={props.disabled}
            endIcon={props.endIcon}
        >
            {props.isLoading ? <Loader size={0.6} /> : props.children}
        </MuiButton>
    )
}
