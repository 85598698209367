import { FC, Fragment } from 'react'
import { Box, styled, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Icon } from 'packages/eid-icons'
import { DifferentiationValueSelector as AccessLevelValueSelector } from 'components'
import { useIsSmallScreen } from 'packages/core'
import { Skeleton } from '@material-ui/lab'
import { AddToCart } from 'components/AddToCart'
import { IResourceType, ResourceTypesNamespace, useRegistry } from 'core'
import cartHelpers from 'containers/Cart/cartHelpers'
import { useHistory } from 'react-router'
import appConfig from 'config'

const PromptContainer = styled('div')({
    padding: '48px 31px 20px 31px',
    minHeight: '232px',
    display: 'flex',
    justifyContent: 'center',
})

const PaddedDiv = styled(Box)({
    padding: '0 31px',
})

interface ApplicationAccessLevelProps {
    accessLevels: any
    accessLevel: any
    setAccessLevelValue: (val: any) => void
    isCheckingAccess: boolean
    application: any
    targetPerson: string
}
const ApplicationAccessLevel: FC<ApplicationAccessLevelProps> = ({
    accessLevels,
    accessLevel,
    targetPerson,
    setAccessLevelValue,
    isCheckingAccess,
    application,
}) => {
    const { t } = useTranslation()
    const registry = useRegistry()
    const history = useHistory()
    const applicationsType: IResourceType = registry.get(
        ResourceTypesNamespace,
        'Applications',
    )

    const isSmallScreen = useIsSmallScreen()

    const getPreValidationMessage = () =>
        !Boolean(accessLevel) ? t('Common_SelectAccessLevel') : ''

    const preAdd = (baseObj: any) => {
        const itemToAdd = cartHelpers.applicationsAccessToCartItem({
            targetPerson,
            assignmentType: 'Add',
            application,
            assignmentStatus: accessLevel,
            ...baseObj,
        })
        if (!itemToAdd) return undefined

        return itemToAdd
    }
    return (
        <Fragment>
            {!isSmallScreen && (
                <PromptContainer>
                    <Box
                        width="30%"
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        zIndex={1}
                    >
                        <Typography
                            style={{
                                fontSize: '14px',
                                lineHeight: 1.43,
                                fontWeight: 600,
                                textTransform: 'uppercase',
                                wordBreak: 'break-word',
                                textAlign: 'center',
                            }}
                        >
                            {t('Common_SelectAccessLevel')}
                        </Typography>
                        <Box
                            marginTop="24px"
                            width="100%"
                            display="flex"
                            justifyContent="center"
                        >
                            <Icon
                                name="AccessLevel"
                                height="150px"
                                width="150px"
                                color="#D2D2D9"
                            />
                        </Box>
                    </Box>
                    <Box width="20%">
                        <AccessLevelValueSelector
                            loading={!accessLevels}
                            onChange={(val: any) => setAccessLevelValue(val)}
                            data={
                                accessLevels &&
                                accessLevels.filter((o: any) => !o.isAssigned)
                            }
                            value={
                                accessLevel?.resourceAssignment
                                    .resourceAssignmentId
                            }
                            getOptionLabel={(o: any) =>
                                o.resourceAssignment.friendlyName
                            }
                            checkSelectedOption={(
                                o: any,
                                selectedValueId: any,
                            ) =>
                                o.resourceAssignment.resourceAssignmentId ===
                                selectedValueId
                            }
                            readOnlyOptions={
                                accessLevels &&
                                accessLevels.filter((o: any) => o.isAssigned)
                            }
                        />
                    </Box>
                    <Box width="50%">
                        {!application ? (
                            <Box padding={'24px'}>
                                <Skeleton height={40} />
                            </Box>
                        ) : (
                            <AddToCart
                                resourceType={applicationsType}
                                resource={application}
                                secondary={accessLevel}
                                preValidate={getPreValidationMessage}
                                preAdd={preAdd}
                                postAdd={() =>
                                    history.push(
                                        `${appConfig.APP_SUBPATH}/applications`,
                                    )
                                }
                            />
                        )}
                    </Box>
                </PromptContainer>
            )}

            {isSmallScreen && (
                <PaddedDiv>
                    <Box
                        paddingY="24px"
                        display="flex"
                        flexDirection="column"
                        position="relative"
                    >
                        <Box position="absolute" top="16px" right="0">
                            <Icon
                                name="AccessLevel"
                                height="100px"
                                width="100px"
                                color="#D2D2D9"
                            />
                        </Box>
                        <Box width="172px" paddingY="4px" zIndex={1}>
                            <Typography
                                style={{
                                    fontSize: '14px',
                                    lineHeight: 1.43,
                                    fontWeight: 600,
                                    textTransform: 'uppercase',
                                    wordBreak: 'break-word',
                                }}
                            >
                                {t('Common_SelectAccessLevel')}
                            </Typography>
                        </Box>
                        <Box paddingY="16px">
                            <AccessLevelValueSelector
                                loading={!accessLevels}
                                onChange={(val: any) =>
                                    setAccessLevelValue(val)
                                }
                                data={
                                    accessLevels &&
                                    accessLevels.filter(
                                        (o: any) => !o.isAssigned,
                                    )
                                }
                                value={
                                    accessLevel?.resourceAssignment
                                        .resourceAssignmentId
                                }
                                getOptionLabel={(o: any) =>
                                    o.resourceAssignment.friendlyName
                                }
                                checkSelectedOption={(
                                    o: any,
                                    selectedValueId: any,
                                ) =>
                                    o.resourceAssignment
                                        .resourceAssignmentId ===
                                    selectedValueId
                                }
                                readOnlyOptions={
                                    accessLevels &&
                                    accessLevels.filter(
                                        (o: any) => o.isAssigned,
                                    )
                                }
                            />
                        </Box>
                        <Box width="100%">
                            {!application ? (
                                <Box padding={'24px'}>
                                    <Skeleton height={40} />
                                </Box>
                            ) : (
                                <AddToCart
                                    resourceType={applicationsType}
                                    resource={application}
                                    secondary={accessLevel}
                                    preValidate={getPreValidationMessage}
                                    preAdd={preAdd}
                                    postAdd={() =>
                                        history.push(
                                            `${appConfig.APP_SUBPATH}/applications`,
                                        )
                                    }
                                    isSmallScreen={isSmallScreen}
                                />
                            )}
                        </Box>
                    </Box>
                </PaddedDiv>
            )}
        </Fragment>
    )
}

export default ApplicationAccessLevel
