import { Fragment } from 'react'
import { Box, styled } from '@material-ui/core'
import { useIsSmallScreen } from 'packages/core'
import { useTranslation } from 'react-i18next'
import { ItemDetails, PeopleList } from 'components'
import { useComputerOwners } from 'hooks'

const PaddedDiv = styled(Box)({
    padding: '0 31px',
})

export const MoreInformationSection = ({ computer }: any) => {
    const { t } = useTranslation()

    const attributeOrientation = useIsSmallScreen() ? 'vertical' : 'horizontal'

    const { data: owners, isLoading: ownersLoading } = useComputerOwners(
        computer.id,
    )

    return (
        <Fragment>
            {ownersLoading ? (
                <ItemDetails.AttributesContainer>
                    <PaddedDiv>
                        <ItemDetails.AttributesLoader />
                    </PaddedDiv>
                </ItemDetails.AttributesContainer>
            ) : (
                <ItemDetails.AttributesContainer bottomPadding="0">
                    <PaddedDiv>
                        <ItemDetails.Attribute
                            label={t('Common_Owners')}
                            value={<PeopleList list={owners} />}
                            orientation={attributeOrientation}
                        />
                    </PaddedDiv>
                </ItemDetails.AttributesContainer>
            )}
        </Fragment>
    )
}
