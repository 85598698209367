import { useQuery } from 'react-query'
import { useAxios } from 'packages/core'

export const SHARED_FOLDER_KEY_PREFIX = 'SHARED_FOLDER'

export const useSharedFolder = (id) => {
    const callApi = useAxios()
    return useQuery(
        [SHARED_FOLDER_KEY_PREFIX, id],
        () =>
            callApi({
                method: 'GET',
                url: `/api/sharedFolders/${id}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id),
        },
    )
}

export const useSharedFolderOwners = (id) => {
    const callApi = useAxios()
    return useQuery(
        [`${SHARED_FOLDER_KEY_PREFIX}_OWNERS`, id],
        () =>
            callApi({
                method: 'GET',
                url: `/api/People/resourceOwners?resourceId=${id}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id),
        },
    )
}

export const useCheckSharedFolderAccess = (id, targetPersonId) => {
    const callApi = useAxios()
    return useQuery(
        [`${SHARED_FOLDER_KEY_PREFIX}_ACCESS`, id, targetPersonId],
        () =>
            callApi({
                method: 'GET',
                url: `/api/SharedFolders/checkAssigmentStatus/${id}/${targetPersonId}`,
            }).then((data) => data.data),
        {
            enabled: Boolean(id) && Boolean(targetPersonId),
        },
    )
}

export const useSharedFolderBr = (
    targetPersonId,
    resourceId,
    resourceTypeId,
    resourceAssignmentId,
) => {
    const callApi = useAxios()
    return useQuery(
        [
            SHARED_FOLDER_KEY_PREFIX,
            'Business_Request',
            targetPersonId,
            resourceId,
            resourceTypeId,
            resourceAssignmentId,
        ],
        () =>
            callApi({
                method: 'GET',
                url: `/api/sharedFolders/businessRequestItemAssignmentDetails?targetPersonId=${targetPersonId}&resourceId=${resourceId}&resourceTypeId=${resourceTypeId}&resourceAssignmentId=${resourceAssignmentId}`,
            }).then((data) => data.data),
        {
            enabled:
                Boolean(targetPersonId) &&
                Boolean(resourceId) &&
                Boolean(resourceTypeId) &&
                Boolean(resourceAssignmentId),
        },
    )
}
