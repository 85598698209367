import * as React from 'react'
import { Box, TextField } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useCurrentPerson } from 'hooks'
import { DatePicker, TimePicker } from '@material-ui/pickers'
import { isNilOrEmpty, isBrowserLocale24h } from 'packages/core'
import moment from 'moment'
import {
    NativeDatePicker,
    NativeTimePicker,
} from 'packages/eid-ui/DateTimePickers'

export interface EndDateControlProps {
    selectedStartDate: any
    selectedEndDate: any
    setSelectedEndDate: any
    maxEndDate?: any
    hasInvalidDates: any
    disabled?: boolean
}

export const EndDateControl: React.FC<EndDateControlProps> = ({
    selectedStartDate,
    selectedEndDate,
    setSelectedEndDate,
    maxEndDate,
    hasInvalidDates,
    disabled,
}) => {
    const { t } = useTranslation()
    const { data: currentPerson } = useCurrentPerson()

    const currentLanguage = currentPerson.language.languageCode

    return (
        <Box
            display="flex"
            style={{ borderBottom: hasInvalidDates ? '1px solid red' : '' }}
        >
            <NativeDatePicker
                minDate={selectedStartDate.clone().local().format('YYYY-MM-DD')}
                maxDate={maxEndDate?.clone().local().format('YYYY-MM-DD')}
                value={
                    isNilOrEmpty(selectedEndDate)
                        ? null
                        : selectedEndDate.clone().local().format('YYYY-MM-DD')
                }
                handleChange={(e: any) => {
                    if (isNilOrEmpty(e)) {
                        setSelectedEndDate(null)
                    } else {
                        const date = moment(e)
                        const dateToSet = date
                            .clone()
                            .endOf('day')
                            .set('milliseconds', 0)
                            .utc()
                        setSelectedEndDate(dateToSet)
                    }
                }}
                disabled={disabled}
            >
                <TextField
                    type="text"
                    value={
                        isNilOrEmpty(selectedEndDate)
                            ? '-- -- ----'
                            : selectedEndDate.local().format('L')
                    }
                    label={t('Common_EndDate')}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </NativeDatePicker>
            <NativeTimePicker
                value={
                    isNilOrEmpty(selectedEndDate)
                        ? null
                        : selectedEndDate.clone().local().format('HH:mm')
                }
                handleChange={(e: any) => {
                    const time = moment(e, 'HH:mm')
                    let dateToSet

                    if (!isNilOrEmpty(selectedEndDate)) {
                        dateToSet = selectedEndDate.clone().local()
                    } else {
                        dateToSet = moment()
                    }

                    dateToSet.set({
                        hour: time.hour(),
                        minute: time.minute(),
                        second: 0,
                    })

                    dateToSet.utc()
                    setSelectedEndDate(dateToSet)
                }}
                disabled={disabled}
            >
                <TextField
                    label={t('Common_EndTime')}
                    type="text"
                    value={
                        isNilOrEmpty(selectedEndDate)
                            ? '-- -- --'
                            : selectedEndDate.local().format('LT')
                    }
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </NativeTimePicker>
        </Box>
    )
}
