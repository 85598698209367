import { Button } from 'components'
import { Typography, Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useComputerCheckIn } from 'hooks'


const styles = {
    normal: {
        backgroundColor: '#307fc1',
        color: '#fff',
        border: 'solid 1px #307fc1',
        borderRadius: '5px',
        padding: '5px 8px 4px',

    },
    disabled: {
        backgroundColor: '#red !important',
        border: 'solid 1px #c5cbda',
        color: '#ffffff !important',
        opacity: '0.5',
    },

    label: {
        fontSize: '11px',
        lineHeight: 'normal',
    },
}



const CredentialStatusButton = ({ data }) => {
    const item = data?.resource
    const { t } = useTranslation()
    const [
        checkInComputer,
        { isLoading },
    ] = useComputerCheckIn()

    const handleCheckInClick = () => {
        checkInComputer(item.checkOutId)
    }

    return <Button onClick={() => handleCheckInClick()} color={(!item.canCheckIn || isLoading) ? '#c5cbda' : '#307fc1'} disabled={!item.canCheckIn || isLoading} style={styles.normal} rootStylesProp={{
        opacity: (!item.canCheckIn || isLoading) ? 0.5 : 1,
    }}>
        <Typography style={styles.label}>{t('Common_CheckIn')}</Typography>
    </Button>
}

CredentialStatusButton.defaultProps = {
    drawerView: false,
}

export default CredentialStatusButton
