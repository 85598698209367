import React from 'react'
import { Button as MuiButton, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const drawerButtonStyles = makeStyles((labeled) => ({
    root: {
        minHeight: '31px',
        border: `solid 1px #56BEAC`,
        backgroundColor: '#ffffff',
        boxShadow: '0 0 0 0',
        whiteSpace: 'nowrap',
        color: '#56BEAC',
        textTransform: 'capitalize !important',
        borderRadius: '16px',
        marginRight: `${labeled ? '5px' : '0px'}`,
        lineHeight: '1.3',
        '&:hover': {
            backgroundColor: '#56BEAC',
            color: 'white',
            '& span': {
                color: '#fff',
            },
        },
    },
}))
const RequestAccessButton = ({ onClick, label }) => {
    const { t } = useTranslation()

    const classes = drawerButtonStyles(label ? true : false)
    return (
        <MuiButton
            size="small"
            classes={classes}
            variant="outlined"
            onClick={onClick}
        >
            {label ? label : t('Common_RequestAccess')}
        </MuiButton>
    )
}
export default RequestAccessButton
