import { useState, useEffect } from 'react'
import { Box, Button as MuiButton, makeStyles } from '@material-ui/core'
import { Tooltip, useNotification } from 'packages/eid-ui'
import { useCredentialHiddenAttributes } from 'hooks'
import { Spinner } from 'packages/eid-ui'
import { TextFormatter } from 'components'
import { useTranslation } from 'react-i18next'
import { Icon } from 'packages/eid-icons'

const LockedPasswordBox = ({
    targetUrl,
    targetProperty,
    isClickable,
    validationMessage,
    showIcon,
    isDetailed = false,
}) => {
    const useStyles = makeStyles({
        root: {
            background: '#262fa1',
            marginTop: isDetailed ? '-32px' : '-20px',
            width: 'fit-content',
            position: 'absolute',
            zIndex: 999,
            borderRadius: '5px',
            marginLeft: '-40px',
            '&:before': {
                content: "''",
                position: 'absolute',
                left: '60px',
                bottom: '-5px',
                width: '10px',
                height: '10px',
                transform: 'rotate(45deg)',
                backgroundColor: '#262fa1',
            },
        },
    })
    
    useEffect(()=>{
        if(!isClickable && isRevealed){
            setIsRevealed(false)
        }
    },[isClickable])

    const [enableApi, setEnableApi] = useState(false)
    const { data, isLoading } = useCredentialHiddenAttributes(
        targetUrl,
        enableApi,
    )
    const { t } = useTranslation()
    const classes = useStyles()
    const { showSuccessMessage, showWarningMessage } = useNotification()
    const [fetchedValue, setFetchedValue] = useState()
    const [isRevealed, setIsRevealed] = useState(false)
    const [openPopover, setOpenPopover] = useState(false)
    const [clickCopy, setClickCopy] = useState(false)
    const [clickReveal, setClickReveal] = useState(false)

    useEffect(() => {
        if (clickCopy && data) {
            let result = data[targetProperty]
            hidePopover()
            if (result) {
                navigator.clipboard.writeText(result)
                showSuccessMessage(t('CopiedToClipboard'))
            } else {
                showWarningMessage(t('FailedToCopy'))
            }
            setClickCopy(false)
        }
    }, [clickCopy, data])

    useEffect(() => {
        if (clickReveal && data) {
            let result = data[targetProperty] || '-'
            hidePopover()
            setTimeout(() => {
                setIsRevealed(!isRevealed)
            }, [100])
            setFetchedValue(result)
            setClickReveal(false)
        }
    }, [clickReveal, data])

    const showPopover = () => {
        setOpenPopover(true)
    }

    const hidePopover = () => {
        setOpenPopover(false)
    }

    const handleCopy = () => {
        setEnableApi(true)
        setClickCopy(true)
    }

    const toogleReveal = async () => {
        if (isRevealed) {
            setIsRevealed(!isRevealed)
            return
        }
        setEnableApi(true)
        setClickReveal(true)
    }

    if (isLoading) {
        return (
            <Box display="flex">
                <Spinner />
            </Box>
        )
    }

    const HiddenPasswordText = (
        <TextFormatter
            style={{
                fontSize: '13px',
                padding: isDetailed ? 'none' : '15px 6px',
            }}
            value={
                <>
                    {isRevealed
                        ? fetchedValue
                            ? fetchedValue
                            : '-'
                        : '*'.repeat(5)}
                    {showIcon && (
                        <Icon
                            name="View"
                            color="grey"
                            style={{ marginLeft: '4px', paddingTop: '2px' }}
                            onClick={toogleReveal}
                            width="10px"
                        />
                    )}
                </>
            }
        />
    )

    if (!isClickable) {
        return (
            <Tooltip
                placement="top-start"
                title={validationMessage}
                arrow={false}
                tooltipStyles={{ marginBottom: '-10px' }}
            >
                <Box>{HiddenPasswordText}</Box>
            </Tooltip>
        )
    }
    return (
        <Box
            style={{ cursor: 'pointer' }}
            onMouseEnter={showPopover}
            onMouseLeave={hidePopover}
        >
            <div
                className={classes.root}
                style={{
                    display: openPopover ? 'flex' : 'none',
                }}
                onMouseLeave={hidePopover}
            >
                <MuiButton
                    style={{
                        color: 'white',
                        textTransform: 'none',
                    }}
                    size="small"
                    variant="text"
                    onClick={handleCopy}
                >
                    {t('Copy')}
                </MuiButton>
                <MuiButton
                    style={{
                        color: 'white',
                        borderRadius: '0px',
                        borderLeft: '1px solid white',
                        textTransform: 'none',
                    }}
                    size="small"
                    variant="text"
                    onClick={toogleReveal}
                >
                    {isRevealed ? t('Hide') : t('Reveal')}
                </MuiButton>
            </div>
            {HiddenPasswordText}
        </Box>
    )
}

export default LockedPasswordBox
