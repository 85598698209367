import React from 'react'
import { isNilOrEmpty, useQuery } from 'packages/core'
import { EidAdvancedSearch } from 'packages/eid-controls'
import { useResourceTypeContext } from 'resourceTypeContext'
import FormsFilter from './FormsFilter'
import { useTranslation } from 'react-i18next'
import useConfiguration from 'useConfiguration'
import { useHistory } from 'react-router'

const AdvancedFilter = ({ forms, tags, resourceType }) => {
    const { t } = useTranslation()
    const {
        getResourceType
    } = useConfiguration()
    const currentResourceType = getResourceType(resourceType)
    const [state, dispatch] = useResourceTypeContext()
    const tagsContextValue = state[tags?.contextProp] ?? []
    const history = useHistory()
    const query = useQuery()
    const selectedTabForComputers = query.get('tab')
    
    const isManageAccess = history.location.pathname.indexOf("/manageAccess") >= 0;
    let showTags = true;
    let checkFormFields = false;
    let isManageAccessRoute = false
    if (currentResourceType && currentResourceType.name === "Computers" && isManageAccess) {
        showTags = false;
        checkFormFields = true;
        isManageAccessRoute = true
    } else if (currentResourceType && currentResourceType.name === "Computers") {
        checkFormFields = true;
    }

    const bindFilters = (filters) => {
        if (checkFormFields) {
            if (isManageAccessRoute) {
                if(selectedTabForComputers){
                    const selectedTabInfo = currentResourceType.manageAccessTabs ? currentResourceType.manageAccessTabs.find(x=>x.path === selectedTabForComputers) : null
                    return filters.filter((fl) => fl.showForManageAccess && fl[selectedTabInfo.path])
                }else{
                    const selectedTabInfo = currentResourceType.manageAccessTabs ? currentResourceType.manageAccessTabs.find(x=>x.default === true) : null
                    return filters.filter((fl) => fl.showForManageAccess && fl[selectedTabInfo.path])
                }
            } else {
                return filters.filter((fl) => fl.showForRequestAccess)
            }
        } else {
            return filters
        }
    }
    const props = {}
    props.showTags = showTags
    if (!isNilOrEmpty(forms)) {
        props.forms = {
            colorCode: forms.colorCode,
            filters: bindFilters(forms.filters)
        }
    }
    if (!isNilOrEmpty(tags)) {
        props.tags = {
            colorCode: tags.colorCode,
            allTags: state.tags,
            selectedTags: tagsContextValue,
            onTagClick: (tag) => {
                const currentIndex = tagsContextValue.findIndex(
                    (f) => f === tag,
                )
                const newChecked = [...tagsContextValue]

                if (currentIndex === -1) {
                    newChecked.push(tag)
                } else {
                    newChecked.splice(currentIndex, 1)
                }

                dispatch({
                    type: 'SET_PROP',
                    payload: {
                        key: tags.contextProp,
                        value: newChecked,
                    },
                })
            },
        }
    }

    return (
        <EidAdvancedSearch
            formsFilter={
                <FormsFilter resourceType={resourceType} {...props.forms} />
            }
            formsLabel={t(forms.title)}
            tagsLabel={tags && t(tags.title)}
            resourceType={resourceType}
            loadTags={() =>
                dispatch({
                    type: 'SET_PROP',
                    payload: {
                        key: 'shouldLoadTags',
                        value: true,
                    },
                })
            }
            tabsContinerStyles={{
                width: 'calc(100% + 40px)',
                marginLeft: '-20px',
                marginTop: '-20px',
            }}
            showTags={showTags}
            {...props}
        />
    )
}

export default AdvancedFilter
