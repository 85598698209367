import React from 'react'
import { styled, Box, Typography } from '@material-ui/core'
import { FilterChip, PaginationStepper, If, Loader } from 'packages/eid-ui'
import { useIsSmallScreen } from 'packages/core'
import ExtendedFab from 'components/ExtendedFab'
import { useTranslation } from 'react-i18next'

const MobileContainer = styled('div')({
    position: 'relative',
    marginTop: '60px',
    width: '700px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
})
const DesktopContainer = styled('div')({
    maxHeight: '200px',
    width: '700px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
})
const FilterChipDiv = styled('div')({
    margin: '7px',
})

const ProcessNavigationButtonContainer = styled('div')({
    position: 'absolute',
    top: '50%',
    msTransform: 'translateY(-50%)',
    transform: 'translateY(-50%)',
})

const PreviousProcessesButtonContainer = styled(
    ProcessNavigationButtonContainer,
)({
    left: '0px',
})

const NextProcessesButtonContainer = styled(ProcessNavigationButtonContainer)({
    right: '0px',
})

const StepperContainer = styled('div')({
    position: 'absolute',
    top: '100%',
    left: '50%',
    msTransform: 'translate(-50%, -50%)',
    transform: 'translate(-50%, -50%)',
})

function ChipsContainer({
    loading,
    data,
    onSelect,
    selectAllButton,
    onSelectAll,
}) {
    const isSmallScreen = useIsSmallScreen()
    const { t } = useTranslation()

    const items = data.length
    const countPerPage = isSmallScreen ? 4 : 9

    const renderStepper = (stepperSteps, backButton, nextButton) => (
        <div
            style={{
                display: 'flex',
                width: '300px',
                position: 'absolute',
                bottom: '-70px',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                msTransform: 'translate(-50%, -50%)',
            }}
        >
            <PreviousProcessesButtonContainer>
                {backButton}
            </PreviousProcessesButtonContainer>
            <div style={{ margin: 'auto' }}>{stepperSteps}</div>
            <NextProcessesButtonContainer>
                {nextButton}
            </NextProcessesButtonContainer>
        </div>
    )

    const renderDesktopSteppers = (stepperSteps, backButton, nextButton) => {
        return (
            <>
                <PreviousProcessesButtonContainer>
                    {backButton}
                </PreviousProcessesButtonContainer>
                <NextProcessesButtonContainer>
                    {nextButton}
                </NextProcessesButtonContainer>
                <StepperContainer>{stepperSteps}</StepperContainer>
            </>
        )
    }

    const content = (stepperSteps, backButton, nextButton, data) => {
        const chips = (
            <>
                {data &&
                    data.map((item) => (
                        <FilterChipDiv key={item.id}>
                            <FilterChip
                                label={item.friendlyName}
                                onClick={() => onSelect(item)}
                                style={{
                                    height: '40px',
                                    borderRadius: '22px',
                                    color: '#91959d',
                                    border: 'solid 1px #91959d ',
                                    padding: '10px 10px ',
                                    '&:hover': {
                                        backgroundColor: '#f2f5fb',
                                        border: '1px solid #453fbb',
                                        color: '#453fbb',
                                        cursor: 'pointer',
                                    },
                                }}
                            ></FilterChip>
                        </FilterChipDiv>
                    ))}
            </>
        )

        return (
            <>
                {isSmallScreen ? (
                    <MobileContainer>
                        {chips}

                        <If condition={items > countPerPage}>
                            {renderStepper(
                                stepperSteps,
                                backButton,
                                nextButton,
                            )}
                        </If>
                        {selectAllButton && (
                            <ExtendedFab
                                label={t('OnBoarding_SelectAll')}
                                onClick={() => onSelectAll()}
                                size="small"
                                style={{
                                    width: '130px',
                                    fontSize: '16px',
                                    border: 'solid 1px #91959d',
                                    color: '#91959d',
                                    '&:hover': {
                                        backgroundColor: '#f2f5fb',
                                        border: '1px solid #453fbb',
                                        color: '#453fbb',
                                        cursor: 'pointer',
                                    },
                                    position: 'absolute',
                                    left: '50%',
                                    transform: 'translate(-50%,-50%)',
                                    bottom: -115,
                                }}
                            />
                        )}
                    </MobileContainer>
                ) : (
                    <DesktopContainer>
                        {chips}

                        {selectAllButton && (
                            <ExtendedFab
                                label={t('OnBoarding_SelectAll')}
                                onClick={() => onSelectAll()}
                                size="small"
                                style={{
                                    width: '130px',
                                    fontSize: '16px',
                                    border: 'solid 1px #91959d',
                                    color: '#91959d',
                                    '&:hover': {
                                        backgroundColor: '#f2f5fb',
                                        border: '1px solid #453fbb',
                                        color: '#453fbb',
                                        cursor: 'pointer',
                                    },
                                    position: 'absolute',
                                    right: 0,
                                    bottom: -10,
                                }}
                            />
                        )}
                    </DesktopContainer>
                )}

                <If condition={items > countPerPage}>
                    {!isSmallScreen &&
                        renderDesktopSteppers(
                            stepperSteps,
                            backButton,
                            nextButton,
                        )}
                </If>
            </>
        )
    }

    return (
        <Box
            maxWidth="827px"
            //margin="auto"
            height="225px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            padding="5px"
            position="relative"
        >
            {loading && <Loader />}
            {!loading && data && data.length === 0 && (
                <Typography>{t('Common_NoDataFound')}</Typography>
            )}
            {data && data.length > 0 && (
                <PaginationStepper data={data} countPerPage={countPerPage}>
                    {(stepperSteps, backButton, nextButton, data) =>
                        content(stepperSteps, backButton, nextButton, data)
                    }
                </PaginationStepper>
            )}
        </Box>
    )
}

export default ChipsContainer
