import React, { FC, Fragment } from 'react'
import { useAzureRole } from '../../../hooks'
import AzureAdminRoleDrawer from './AzureAdminRoles'
import AzureRbacRoleDrawer from './AzureRbacRoles'

export const AzureRoles: FC<any> = (props) => {
    const { data, isLoading } = useAzureRole(props.id)

    if (isLoading || !data) {
        return <Fragment />
    }

    if (data.roleGroupType === 'Admin') {
        return <AzureAdminRoleDrawer {...props} />
    } else if (data.roleGroupType === 'Rbac')
        return <AzureRbacRoleDrawer {...props} />

    return <Fragment />
}
