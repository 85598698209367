import { Box, styled } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { ItemDetails } from 'components'
import { useIsSmallScreen } from 'packages/core'
import { CommonAttributes } from '../CommonAttributes'
import { Icon } from 'packages/eid-icons'

const overflowStyleProps = {
    maxHeight: '220px',
    overflow: 'auto',
}

const PaddedDiv = styled(Box)({
    padding: '0 31px',
})

export const OverviewSection = ({ computerRequestableDetails }: any) => {
    const { t } = useTranslation()
    const attributeOrientation = useIsSmallScreen() ? 'vertical' : 'horizontal'
    return (
        <ItemDetails.AttributesContainer>
            <PaddedDiv>
                <ItemDetails.Attribute
                    label={t('Common_DisplayName')}
                    value={computerRequestableDetails?.computer.friendlyName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('Common_DnsHostName')}
                    value={computerRequestableDetails?.computer.dnsHostName}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('Common_ComputerType')}
                    value={
                        computerRequestableDetails?.computer.computerTypeName
                    }
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('Common_DomainOrDirectory')}
                    value={
                        computerRequestableDetails?.computer
                            .accountStoreFriendlyName
                    }
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('Common_OperatingSystem')}
                    value={computerRequestableDetails?.computer.operatingSystem}
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('Common_PsmEnabled')}
                    value={
                        computerRequestableDetails?.computer?.isPsmEnabled ? (
                            <Icon name="CheckedRound" />
                        ) : (
                            <Icon name="UncheckedRound" color="#D8D8D8" />
                        )
                    }
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />

                <ItemDetails.Attribute
                    label={t('Common_JitEnabled')}
                    value={
                        computerRequestableDetails?.isJitEnabled ? (
                            <Icon name="CheckedRound" />
                        ) : (
                            <Icon name="UncheckedRound" color="#D8D8D8" />
                        )
                    }
                    orientation={attributeOrientation}
                    valueProps={overflowStyleProps}
                />
            </PaddedDiv>
        </ItemDetails.AttributesContainer>
    )
}
