import { Box, styled, Grid } from '@material-ui/core'
import { ItemDetails } from 'components'
import { useCredentialCheckouts, useTargetPerson } from 'hooks'
import React, { FC, Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CheckoutFilters from '../ComputerSessionRequest/Filters'
import CheckoutTile from './CheckoutTile'
import Pagination from '../ComputerSessionRequest/Pagination'

const PaddedDiv = styled(Box)({
    padding: '0 31px',
})

export interface CredentialCheckoutProps {
    item: any
}

export const CredentialCheckouts: FC<CredentialCheckoutProps> = (props) => {
    const { item } = props
    const { t } = useTranslation()
    const [targetPerson] = useTargetPerson()
    const [isCheckedOut, setIsCheckedout] = useState<boolean>(false)
    const [selectedPerson, setSelectedPerson] = useState<any>(null)
    const [startDate, setStartDate] = useState<any>(null)
    const [endDate, setEndDate] = useState<any>(null)
    const [page, setPage] = useState(1)
    const defaultTake = 10
    const handlePageChange = (_: any, value: any) => {
        setPage(value)
    }

    const skip = (page - 1) * defaultTake
    const take = defaultTake
    const [
        getCredentialCheckouts,
        { data: checkoutRequets, isLoading },
    ] = useCredentialCheckouts()

    const numberOfPages = checkoutRequets
        ? Math.ceil(checkoutRequets.totalCount / take)
        : 0

    useEffect(() => {
        const dataObject: any = {
            targetPersonId: selectedPerson
                ? selectedPerson.id
                : targetPerson.id,
            credentialId: item.id,
            startDateAfter: startDate,
            endDateBefore: endDate,
            checkedOut: isCheckedOut,
            skip: skip,
            take: take,
        }
        getCredentialCheckouts(dataObject)
    }, [isCheckedOut, startDate, endDate, page, selectedPerson])

    const handleWhoRequestedChange = (e: any) => {
        setSelectedPerson(e)
    }

    return (
        <ItemDetails.AttributesContainer>
            <PaddedDiv>
                <CheckoutFilters
                    setEndDate={(e: string) => setEndDate(e)}
                    setStartDate={(e: string) => setStartDate(e)}
                    endDate={endDate}
                    startDate={startDate}
                    isCheckedOut={isCheckedOut}
                    checkboxLabel={t('Common_CheckedOut')}
                    handleCheckedClick={(
                        e: React.ChangeEvent<HTMLInputElement>,
                    ) => setIsCheckedout(e.target.checked)}
                    onPersonSelect={(e: any) => handleWhoRequestedChange(e)}
                    selectedPerson={selectedPerson}
                />
            </PaddedDiv>
            {isLoading && (
                <PaddedDiv>
                    <ItemDetails.AttributesLoader />
                </PaddedDiv>
            )}
            {checkoutRequets?.data?.length &&
            checkoutRequets?.data?.length > 0 ? (
                <Fragment>
                    <Grid
                        container
                        spacing={2}
                        style={{
                            padding: '20px 20px 0px 20px',
                            width: '100%',
                        }}
                    >
                        {checkoutRequets.data.map(
                            (checkoutRequest: any, index: number) => (
                                <CheckoutTile
                                    parent={item}
                                    key={`credential-checkout-${index}`}
                                    item={checkoutRequest}
                                />
                            ),
                        )}
                    </Grid>
                    <Pagination
                        page={page}
                        handlePageChange={handlePageChange}
                        pageCount={numberOfPages}
                    />
                </Fragment>
            ) : (
                <PaddedDiv>
                    <p>
                        {t('Common_NoItemsFound', {
                            itemType: t('Checkouts'),
                        })}
                    </p>
                </PaddedDiv>
            )}
        </ItemDetails.AttributesContainer>
    )
}

export default CredentialCheckouts
