import { Box, styled } from '@material-ui/core'
import { ItemDetails } from 'components'
import { useComputerSessionRequest, useTargetPerson } from 'hooks'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ComputerSessionRequestFilters from './Filters'
import ComputerSessionRequestTile from './SessionRequestTile'
import ComputersPagination from './Pagination'

const PaddedDiv = styled(Box)({
    padding: '0 31px',
})

export interface ComputerSessionRequestProps {
    item: any
}

export const ComputerSessionRequest: FC<ComputerSessionRequestProps> = (
    props,
) => {
    const { item } = props
    const { t } = useTranslation()
    const [targetPerson] = useTargetPerson()
    const [isCheckedOut, setIsCheckedout] = useState<boolean>(false)
    const [selectedPerson, setSelectedPerson] = useState<any>(null)
    const [startDate, setStartDate] = useState<any>(null)
    const [endDate, setEndDate] = useState<any>(null)
    const [page, setPage] = useState(1)
    const defaultTake = 10
    const handlePageChange = (_: any, value: any) => {
        setPage(value)
    }
    const skip = (page - 1) * defaultTake
    const take = defaultTake
    const [
        getComputerSessionRequest,
        { data: sessionRequets, isLoading },
    ] = useComputerSessionRequest()

    const numberOfPages = sessionRequets
        ? Math.ceil(sessionRequets.totalCount / take)
        : 0

    useEffect(() => {
        const dataObject: any = {
            targetPersonId: selectedPerson
                ? selectedPerson.id
                : targetPerson.id,
            computerId: item.id,
            startDate: startDate,
            endDate: endDate,
            checkedOut: isCheckedOut,
            skip: skip,
            take: take,
        }
        getComputerSessionRequest(dataObject)
    }, [isCheckedOut, startDate, endDate, page, selectedPerson])

    const handleWhoRequestedChange = (e: any) => {
        setSelectedPerson(e)
    }

    return (
        <ItemDetails.AttributesContainer>
            <PaddedDiv>
                <ComputerSessionRequestFilters
                    setEndDate={(e: string) => setEndDate(e)}
                    setStartDate={(e: string) => setStartDate(e)}
                    endDate={endDate}
                    startDate={startDate}
                    isCheckedOut={isCheckedOut}
                    checkboxLabel={t('Connected')}
                    handleCheckedClick={(
                        e: React.ChangeEvent<HTMLInputElement>,
                    ) => setIsCheckedout(e.target.checked)}
                    onPersonSelect={(e: any) => handleWhoRequestedChange(e)}
                    selectedPerson={selectedPerson}
                />
            </PaddedDiv>
            {isLoading && (
                <PaddedDiv>
                    <ItemDetails.AttributesLoader />
                </PaddedDiv>
            )}
            {sessionRequets?.data?.length ? (
                <>
                    {sessionRequets.data.map((sesReq: any, index: number) => (
                        <ComputerSessionRequestTile
                            parent={item}
                            key={`session-request-${index}`}
                            item={sesReq}
                        ></ComputerSessionRequestTile>
                    ))}
                    <ComputersPagination
                        page={page}
                        handlePageChange={handlePageChange}
                        pageCount={numberOfPages}
                    />
                </>
            ) : null}
            {!isLoading &&
                sessionRequets &&
                sessionRequets.data &&
                sessionRequets.data.length <= 0 && (
                    <PaddedDiv>
                        <p>
                            {t('Common_NoItemsFound', {
                                itemType: t('Common_SessionRequest'),
                            })}
                        </p>
                    </PaddedDiv>
                )}
        </ItemDetails.AttributesContainer>
    )
}

export default ComputerSessionRequest
