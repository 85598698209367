import { FC, Fragment } from 'react'
import { IAccessRequestPolicy } from 'core'
import { useTranslation } from 'react-i18next'
import ItemDetails from './ItemDetails'

export type AccessRequestPolicyDetailsProps = {
    policy: IAccessRequestPolicy
    orientation?: 'vertical' | 'horizontal'
}

const overflowStyleProps = {
    maxHeight: '220px',
    overflow: 'auto',
    fontSize: '13px !important',
}
const Attribute = ItemDetails.Attribute

export const AccessRequestPolicyDetails: FC<AccessRequestPolicyDetailsProps> = (
    props,
) => {
    const { t } = useTranslation()

    const { policy } = props
    return (
        <Fragment>
            <Attribute
                label={t('Common_AccessRequestPolicy')}
                value={policy.friendlyName}
                orientation={props.orientation}
                valueProps={overflowStyleProps}
            />

            <Attribute
                label={t('Common_AllowsTimeConstrainedAccess')}
                value={
                    policy.isTimeConstrained ? t('Common_Yes') : t('Common_No')
                }
                orientation={props.orientation}
                valueProps={overflowStyleProps}
            />

            {policy.isTimeConstrained && policy.defaultValueInMinutes && (
                <Attribute
                    label={t('Common_DefaultAccessDuration')}
                    value={policy.defaultValueInMinutes}
                    orientation={props.orientation}
                    valueProps={overflowStyleProps}
                />
            )}
            {policy.isTimeConstrained && policy.maximumValueInMinutes && (
                <Attribute
                    label={t('Common_MaxAccessDuration')}
                    value={policy.maximumValueInMinutes}
                    orientation={props.orientation}
                    valueProps={overflowStyleProps}
                />
            )}
        </Fragment>
    )
}

AccessRequestPolicyDetails.defaultProps = {
    orientation: 'horizontal',
}
