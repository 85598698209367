import PropTypes from 'prop-types'
import { useTheme, Box, Typography, Link, makeStyles } from '@material-ui/core'
import { WithCloseButton, Divider } from 'packages/eid-ui'
import { Icon, InfoIcon } from 'packages/eid-icons'
import { useTranslation } from 'react-i18next'
import { useAllApplications } from 'hooks'
import { useUrlGenerator } from 'packages/core'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles({
    titleMessage: {
        fontSize: '16px',
        fontWeight: 600,
        color: '#1b1f1f',
    },
    infoMessageContainer: {
        position: 'relative',
        width: '100%',
        minHeight: '48px',
        paddingLeft: '48px',

        backgroundColor: '#ffffff',
        color: '#5d6870',
        display: 'flex',
        alignItems: 'center',

        boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1)',

        borderRadius: '5px',
        border: 'solid 1px #ebebed',
    },
    listItemIndicator: {
        color: '#d8d8d8',
        marginRight: '20px',
    },
    iconContainer: {
        position: 'absolute',
        left: 0,
        top: 0,
        height: '100%',
        width: '48px',

        padding: '12px',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#8b909a',

        borderRadius: '5px 0px 0px 5px',
    },
})

const statusColors = {
    Completed: 'green',
    WentForApproval: 'orange',
    Error: 'red',
    Unknown: 'blue',
}

const icons = {
    Completed: <Icon name="FilledCheck" color={statusColors['Completed']} />,
    WentForApproval: (
        <Icon name="FilledCheck" color={statusColors['WentForApproval']} />
    ),
    Error: <Icon name="Attention" color={statusColors['Error']} />,
    Unknown: (
        <Icon name="GeneralNotification" color={statusColors['Unknown']} />
    ),
}

const CartSubmissionMessage = ({
    data,
    error,
    onClose,
    successMessage,
    errorMessage,
}) => {
    const { t } = useTranslation()
    const theme = useTheme()

    const classes = useStyles()

    const { getMyTasksUrl } = useUrlGenerator(useAllApplications)
    const history = useHistory()

    if (error) {
        return (
            <WithCloseButton
                style={{
                    backgroundColor: theme.palette.background.paper,
                    borderRadius: '5px',
                    boxShadow: '0 3px 7px 4px rgba(0, 0, 0, 0.08)',
                    padding: theme.spacing(2, 4),
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                }}
                onClose={onClose}
                iconColor="#65686C"
            >
                <Box display="flex">
                    <Box display="flex" marginX="8px">
                        {icons['Error']}
                    </Box>
                    <Box display="flex" marginX="8px">
                        <Typography>{errorMessage}</Typography>
                    </Box>
                </Box>
            </WithCloseButton>
        )
    }

    const myTasksBusinessRequestUrl = getMyTasksUrl(
        `/myRequests/businessRequests?id=${data.businessRequestId}`,
    )

    const myTasksUrl = getMyTasksUrl()
    const area = history.location.pathname

    return (
        <WithCloseButton
            style={{
                backgroundColor: theme.palette.background.paper,
                borderRadius: '5px',
                boxShadow: '0 3px 7px 4px rgba(0, 0, 0, 0.08)',
                padding: theme.spacing(3, 4),
                alignItems: 'center',
                justifyContent: 'space-between',
                display: 'flex',
                flexDirection: 'column',
            }}
            onClose={onClose}
            iconColor="#65686C"
            iconPosition={{
                right: 0,
                top: '20px',
            }}
        >
            <Box display="flex" flexDirection="column" width="100%">
                <Box display="flex" width="100%" marginLeft="-16px">
                    <Box display="flex" marginX="8px">
                        {icons['Completed']}
                    </Box>
                    <Box display="flex" marginX="8px">
                        <Typography className={classes.titleMessage}>
                            {successMessage}
                        </Typography>
                    </Box>
                </Box>
                {myTasksBusinessRequestUrl && (
                    <Box marginTop="12px" display="flex">
                        <Box
                            component="span"
                            className={classes.listItemIndicator}
                        >
                            {'>'}
                        </Box>

                        <Box component="span">
                            <Box component="span">
                                {`${t('Common_CartTrackStatusHere')}: `}
                            </Box>

                            <Box component="span">
                                <Link
                                    href={myTasksBusinessRequestUrl}
                                    target="_blank"
                                >
                                    {data.businessRequestName}
                                </Link>
                            </Box>
                        </Box>
                    </Box>
                )}

                <Box width="100%" paddingY="24px">
                    <Divider />
                </Box>

                <Box className={classes.infoMessageContainer}>
                    <Box className={classes.iconContainer}>
                        <InfoIcon width="24" height="23" color="#ffffff" />
                    </Box>

                    {myTasksUrl && (
                        <Box mx="18px">
                            {t('Common_CartTrackStatusOfAllRequests')}

                            <Box component="span" marginX="3px">
                                <Link href={myTasksUrl} target="_blank">
                                    {t('Common_MyTasks')}
                                </Link>
                            </Box>

                            <Box
                                component="span"
                                marginRight="3px"
                                style={{ textTransform: 'lowercase' }}
                            >
                                {t('Common_CartFindApprovedRequests', {
                                    text: `${t('Common_And')}`,
                                })}
                            </Box>

                            <Box component="span">
                                <Link
                                    href={`manageAccess${area}`}
                                    target="_blank"
                                >
                                    {t('Common_ManageAccess')}.
                                </Link>
                            </Box>
                        </Box>
                    )}

                    {!myTasksUrl && (
                        <Box mx="18px">
                            {t('Common_CartFindApprovedRequests')}

                            <Box component="span" marginLeft="3px">
                                <Link
                                    href={`manageAccess${area}`}
                                    target="_blank"
                                >
                                    {t('Common_ManageAccess')}.
                                </Link>
                            </Box>
                        </Box>
                    )}
                </Box>
            </Box>
        </WithCloseButton>
    )
}

CartSubmissionMessage.propTypes = {
    onClose: PropTypes.func.isRequired,
}

export default CartSubmissionMessage
