import React, { Fragment } from 'react'
import AzureAdminRoleDetails from './AzureAdminRoleDetails'
import AzureRbacRoleDetails from './AzureRbacRoleDetails'

export const AzureRoles = ({ item, toggleDrawer }) => {
    if (item.resource.roleGroupType === 'Admin') {
        return <AzureAdminRoleDetails item={item} toggleDrawer={toggleDrawer} />
    } else if (item.resource.roleGroupType === 'Rbac')
        return <AzureRbacRoleDetails item={item} toggleDrawer={toggleDrawer} />
    return <Fragment />
}
