export const attributes = [
    //Add columnName property as sorting property if sorting can't be done on name property

    {
        name: 'applicationLogoImageUrl',
        component: 'applicationImage',
        
    },
    {
        name: 'friendlyName',
        label: 'Common_FriendlyName',
        sortable: true,
    },
    {
        name: 'name',
        label: 'Common_TechnicalName',
        sortable: true,
    },
    {
        name: 'ownerFriendlyName',
        sortable: true,
        label: 'Applications_Owner',
        component: 'applicationOwner',
        headerCellStyles: {
            width: '170px',
            minWidth: '170px',
            fontSize: '13px',
        },
        styles: {
            width: '170px',
            color: '#282828',
            fontSize: '13px',
        },
    },
    {
        name: 'description',
        label: 'Common_Description',
        sortable: true,
    },
]
