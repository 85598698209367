import { useEffect, useState } from 'react'
import { Box } from '@material-ui/core'
import { Autocomplete, Card, ExpansionPanel } from 'packages/eid-ui'
import { map, pipe, prop, uniqBy } from 'ramda'
import { useResourceTypeContext } from 'resourceTypeContext'
import { useIsSmallScreen, useSwrApiGet } from 'packages/core'
import useConfiguration from 'useConfiguration'
import { useTranslation } from 'react-i18next'
import { useDefaultFilters } from 'hooks'

const TargetSystem = ({ resourceType }) => {
    const { t } = useTranslation()
    const isSmallScreen = useIsSmallScreen()
    const { getResourceType } = useConfiguration()
    const currentResourceType = getResourceType(resourceType)

    const filter = currentResourceType.filters.find(
        (f) => f.name === 'TargetSystem',
    )

    const { data: onBoardingData } = useDefaultFilters(resourceType)

    const [callEndpoint, setCallEndpoint] = useState(false)
    const [activeType, setActiveType] = useState('')

    const [options, setOptions] = useState([])
    const { data } = useSwrApiGet(
        () => (callEndpoint ? '/api/ApplicationRoles/targetSystemInfo' : false),
        {
            dedupingInterval: 1800000,
        },
    )
    useEffect(() => {
        if (onBoardingData.accountStoreId) {
            setCallEndpoint(true)
        }
    }, [onBoardingData])

    useEffect(() => {
        if (data) {
            setOptions(data.data)
        }
    }, [data])

    const [state, dispatch] = useResourceTypeContext()

    useEffect(() => {
        if (options.length > 0) {
            const accountStore = options.find(
                (a) => a.accountStoreId === onBoardingData.accountStoreId,
            )
            if (accountStore) {
                dispatch({
                    type: 'SET_MULTIPLE_PROPS',
                    payload: {
                        accountStoreUsageType: {
                            friendlyName:
                                accountStore.accountStoreUsageTypeFriendlyName,
                            id: accountStore.accountStoreUsageTypeId,
                        },
                        [filter.contextProp]: {
                            friendlyName: accountStore.accountStoreFriendlyName,
                            id: accountStore.accountStoreId,
                        },
                    },
                })
            }
        }
    }, [onBoardingData, options])

    const selectedAccountStoreUsageType = state['accountStoreUsageType'] ?? null
    const accountStore = state[filter.contextProp] ?? null

    const fetchAccountStoreUsageType = pipe(
        uniqBy(prop('accountStoreUsageTypeId')),
        map((x) => ({
            id: x.accountStoreUsageTypeId,
            friendlyName: x.accountStoreUsageTypeFriendlyName,
        })),
    )

    const fetchAccountStores = () => {
        let filtered = options

        if (selectedAccountStoreUsageType) {
            filtered = options.filter(
                (x) =>
                    x.accountStoreUsageTypeId ===
                    selectedAccountStoreUsageType.id,
            )
        }
        return pipe(
            uniqBy((x) => x.accountStoreId),
            map((x) => ({
                id: x.accountStoreId,
                friendlyName: x.accountStoreFriendlyName,
            })),
        )(filtered)
    }

    const accountStoreUsageTypeActive = activeType === 'accountStoreUsageType'
    const accountStoreActive = activeType === 'accountStore'

    const content = () => (
        <>
            <Box
                padding="0"
                style={{
                    borderTop: 'solid 1px #efeff1',
                    backgroundColor: '#fbfbfd',
                }}
            >
                <Box padding="20px 20px 0px 20px">
                    <Autocomplete
                        label={t('ApplicationRoles_SelectAccountStoreType')}
                        placeholder={t(
                            'ApplicationRoles_SelectAccountStoreType',
                        )}
                        options={fetchAccountStoreUsageType(options)}
                        loading={
                            callEndpoint && !data && accountStoreUsageTypeActive
                        }
                        loadingText=""
                        noOptionsText=""
                        multiple={false}
                        onInputChange={() => {}}
                        onChange={(_, selectedItem) => {
                            dispatch({
                                type: 'SET_MULTIPLE_PROPS',
                                payload: {
                                    accountStoreUsageType: selectedItem,
                                    [filter.contextProp]: null,
                                },
                            })
                        }}
                        value={selectedAccountStoreUsageType}
                        getOptionLabel={(option) => option.friendlyName}
                        onOpen={() => {
                            setCallEndpoint(true)
                            setActiveType('accountStoreUsageType')
                        }}
                        onClose={() => {
                            setCallEndpoint(false)
                            setActiveType('')
                        }}
                        filterOptions={undefined}
                    />
                </Box>
                <Box padding="20px 20px 0px 20px">
                    <Autocomplete
                        label={t('ApplicationRoles_SelectAccountStore')}
                        placeholder={t('ApplicationRoles_SelectAccountStore')}
                        options={fetchAccountStores()}
                        loading={callEndpoint && !data && accountStoreActive}
                        loadingText=""
                        noOptionsText=""
                        multiple={false}
                        onChange={(_, selectedItem) =>
                            dispatch({
                                type: 'SET_PROP',
                                payload: {
                                    key: filter.contextProp,
                                    value: selectedItem,
                                },
                            })
                        }
                        value={accountStore}
                        getOptionLabel={(option) => option.friendlyName}
                        onOpen={() => {
                            setCallEndpoint(true)
                            setActiveType('accountStore')
                        }}
                        onClose={() => {
                            setCallEndpoint(false)
                            setActiveType('')
                        }}
                        filterOptions={undefined}
                    />
                </Box>
            </Box>
        </>
    )

    return isSmallScreen ? (
        <ExpansionPanel
            expanded={false}
            title={t('ApplicationRoles_TargetSystem')}
        >
            <Box paddingBottom="20px">{content()}</Box>
        </ExpansionPanel>
    ) : (
        <Card
            cardTitle={t('ApplicationRoles_TargetSystem')}
            colorCode={filter.colorCode.primary}
            collapsible
            expanded={false}
        >
            {content()}
        </Card>
    )
}

export default TargetSystem
